import classes from './FilterDropdown.module.css';
import SmallButton from '../Buttons/SmallButton';

export default function EasyFilter({
  setShowFilter,
  children,
  title,
  resetOneFilter,
  filter
}) {
  const handleClick = () => {
    setShowFilter(false);
    if (typeof filter !== 'string') {
      resetOneFilter([...filter])
    } else {
      resetOneFilter([filter])
    }
  };

  return (
    <div className={classes.content}>
      <label>{title}</label>
      {children}
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <div className={classes.button}>
          <SmallButton onClick={() => setShowFilter(false)}>Cerrar</SmallButton>
        </div>
        <div className={classes.button}>
          <SmallButton onClick={handleClick}>Limpiar</SmallButton>
        </div>
      </div>
    </div>
  );
}
