import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function Vendedor() {
    const navigate = useNavigate();


  useEffect(() => {
    // Redirigir a /en-venta
          navigate(`/en-venta`);

  }, []);

  return <div>{/* Aquí puedes agregar contenido si es necesario */}</div>;
}

export default Vendedor;
