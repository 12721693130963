import classes from './SmallButton.module.css';
import { IoIosArrowBack } from 'react-icons/io';

function SmallButton(props) {
  return (
    <button className={classes.button} onClick={props.onClick}>
      {props.backBtn ? <IoIosArrowBack /> : <></>} {props.children}
    </button>
  );
}

export default SmallButton;
