import classes from './CarListHeader.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCarOrderBy } from '../../store/VehicleStore/vehicleActions';
import { useEffect, useState, useRef } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';

export default function CarListHeader({ setOpenFiler, showMobileFilter, handleOrderChange,
dropdownValue, setDropdownValue }) {

  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const value = useRef(0);
  const { carCount, orderBy: currentOrderBy } = useSelector(
    (state) => state.CarOptions
  );
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('orderBy', JSON.stringify(0));
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropDownSelection = (e) => {
    if (e.target.value) {
      const newValue = {
        id: e.target.value,
        description: e.target.textContent,
      };
      setDropdownValue(newValue);
      setOpenDropdown(false);
      handleOrderChange(newValue.id);
    }
  };

  return (
    <div className={classes.header}>
      <div className={classes.firstElement}>
        {!showMobileFilter ? (
          <button
            className={classes.filterBtn}
            onClick={() => {
              setOpenFiler((prev) => {
                return !prev;
              });
            }}
          >
            <TbAdjustmentsHorizontal />
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.selectContainer} ref={dropdownRef}>
        <button
          className={classes.selectButton}
          onClick={() => {
            setOpenDropdown((prev) => {
              return !prev;
            });
          }}
          value={currentOrderBy ?? 1}
        >
          <span>
            {dropdownValue.description}
            <IoIosArrowDown className={openDropdown ? classes.rotate : ''} />
          </span>
        </button>
        <ul
          ref={value}
          onClick={(e) => handleDropDownSelection(e)}
          className={classes.dropDown}
          style={{
            display: openDropdown ? 'flex' : 'none',
            height: !openDropdown && '0px',
          }}
        >
          <li value={1}>Precio menor a mayor</li>
          <li value={2}>Precio mayor a menor</li>
          <li value={3}>Kilometraje menor a mayor</li>
          <li value={4}>Kilometraje mayor a menor</li>
          <li value={5}>Año menor a mayor</li>
          <li value={6}>Año mayor a menor</li>
          <li value={7}>Último agregado</li>
        </ul>
      </div>
    </div>
  );
}
