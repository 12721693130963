import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import classes from './Footer.module.css';
import { AiFillFacebook } from 'react-icons/ai';
import React, { useEffect, useState } from 'react';
import { FaInstagramSquare, FaWhatsappSquare } from 'react-icons/fa';

function Footer() {
  // const user = useSelector((state) => state.UserProfile.userProfile);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const whatsappNumber = '50670096354';
  const messageReporte = encodeURIComponent('Reporte: ');

  useEffect(() => {
    // Actualiza el año actual una vez al año en enero
    const interval = setInterval(() => {
      const now = new Date();
      if (now.getMonth() === 0 && now.getDate() === 1) {
        setCurrentYear(now.getFullYear());
      }
    }, 1000 * 60 * 60); // Verifica cada hora si es enero 1st

    return () => clearInterval(interval); // Limpia el intervalo cuando se desmonta el componente
  }, []);

  return (
    <footer className={classes.footer}>
      <div className={classes.content}>
        <div>
          <h6>Autos Nova</h6>
          {/* <p>Quienes somos</p> */}
          <ul className={classes.media}>
            <ul className={classes.media}>
              <li>
                <a
                  aria-label="Visita Instagram de Autos Nova"
                  href="https://www.instagram.com/AutosNovacr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagramSquare />
                </a>
              </li>
              <li>
                <a
                  aria-label="Visita Canal de WhatsApp de Autos Nova"
                  href="https://whatsapp.com/channel/0029VaDPAQ70rGiEpwrWHq3y"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsappSquare />
                </a>
              </li>
              <li>
                <a
                  aria-label="Visita Facebook de Autos Nova"
                  href="https://www.facebook.com/AutosNovaCR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillFacebook />
                </a>
              </li>
            </ul>
          </ul>
        </div>
        <div>
          <h6>Asistencia</h6>
          <p>
            {' '}
            <Link
              to="/Contacto"
              className={classes.link}
              rel="noopener noreferrer"
            >
              Contacto y soporte
            </Link>
          </p>
          <p>
            <Link
              to="/faq"
              className={classes.link} // Add this class
              rel="noopener noreferrer"
            >
              Preguntas frecuentes
            </Link>
          </p>
          <p>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Soporte por WhatsApp
            </a>
          </p>
          {/* Add target and rel attributes */}
        </div>
        <div>
          <h6>Autos Nova</h6>
          <p>
            <Link to="/en-venta" className={classes.link}>
              Carros en venta
            </Link>
          </p>
          <p>
            <Link to="/vender-mi-carro" className={classes.link}>
              Anunciar mi carro
            </Link>
          </p>
          <p>
            <Link to="/RegistroNegocio" className={classes.link}>
              Registrarme como vendedor
            </Link>
          </p>

          {/* <p>Consejos de seguridad</p> */}
        </div>
      </div>
      <div className={classes.bottom}>
        <p>
          © {currentYear} Autos Nova ·{' '}
          <Link
            to="/terminos"
            className={classes.link}
            rel="noopener noreferrer"
          >
            Términos y condiciones
          </Link>{' '}
          ·{' '}
          <Link
            to="/privacidad"
            className={classes.link}
            rel="noopener noreferrer"
          >
            Políticas de privacidad
          </Link>{' '}
          {/* · ·{' '} */}
          {/* TODO After Release: Hacer una página bonito para mostrar los sitemaps, así como Apple, Airbnb, TrueCAR, Etc */}
          {/* <Link
            to="/sitemap.txt"
            className={classes.link}
            rel="noopener noreferrer"
          >
            Sitemap
          </Link>{' '} */}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
