import axios from 'axios';
import './GoogleBtn.module.css';
import classes from './GoogleBtn.module.css';
import { default as googleLogo } from '../../images/appImages/google-logo.svg';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleBtn = ({ createUser }) => {
  const onFailure = (res) => {
    // Handle failure
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        createUser({
          idUser: 0,
          uuidUser: 'TBD',
          authProvider: 'Google',
          authProviderId: res.data.sub,
          firstName: res.data.given_name,
          lastName: res.data.family_name,
          email: res.data.email,
          userRole: 1,
          userStatus: 1,
          createdOn: '2023-03-04T16:59:43.492Z',
          lastActivity: '2023-03-04T16:59:43.492Z',
        });
      } catch (err) {
        console.log(err);
        // onFailure(err)
      }
    },
  });

  return (
    <button className={classes.google_button} onClick={() => login()}>
      <img src={googleLogo} alt="Google Logo" />
      &nbsp; Acceder con Google
    </button>
  );
};

export default GoogleBtn;
