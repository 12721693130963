import classes from './Button.module.css';

function Button(props) {
  return (
    <button
      className={`${classes.button} ${props.className} ${props.disabled ? classes.btnDisable : ''}`}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      {props.href ? (
        <a
          target="_blank"
          rel='noreferrer'
          className={classes.whatsappBtn}
          href={props.href}>
          {props.children}
        </a>
      ) : (
        <div>{props.children}</div>
      )}
    </button>
  );
}

export default Button;
