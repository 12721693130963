import React from 'react';
import ContentLoader from 'react-content-loader';

const CarListLoader = (props) => (
  <ContentLoader
    speed={2}
    width={280}
    height={360}
    viewBox="0 0 400 460"
    backgroundColor="#ebe4ed"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="22" cy="359" r="15" />
    <rect x="55" y="356" rx="2" ry="2" width="140" height="10" />
    <rect x="53" y="394" rx="2" ry="2" width="140" height="10" />
    <rect x="0" y="4" rx="2" ry="2" width="400" height="288" />
    <rect x="1" y="301" rx="0" ry="0" width="397" height="33" />
    <circle cx="21" cy="398" r="15" />
    <rect x="6" y="425" rx="0" ry="0" width="412" height="59" />
  </ContentLoader>
);

export default CarListLoader;
