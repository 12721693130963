import { useSelector } from 'react-redux';
import classes from './CarFilter.module.css';
import { useState } from 'react';
import Accordion from '../Accordion/Accordion';
import { Slider } from '@mui/material';
import DynamicAccordion from '../Accordion/AccordionDynamic';
import { FormControlLabel, Checkbox, Radio } from '@mui/material';
import { useEffect } from 'react';

// TODO: Limpiar filtros al momento de abrir/Cerrar la página/sesión

const CarFilter = ({
  updateFilter,
  activeFilters,
  getCanton,
  locationInfo,
  brands,
  carStyles,
  getModels,
  years,
  engineOptions,
  popularFeatures,
  handleResetFilter
}) => {
  const [colorsSelected, setColorsSelected] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [timer, setTimer] = useState(null);
  const [timerForKm, setTimerForKm] = useState(null);
  const models = useSelector((state) => state.CarOptions.carModels);


  const maxPrice = 50000000;
  const maxKm = 200000



  const transmissionOptions = [
    { item: 'Automático' },
    { item: 'Manual' },
    { item: 'Dual' },
  ];

  const colors = [
    { item: 'Amarillo', hexColor: '#FFFF00' },
    { item: 'Azul', hexColor: '#0000FF' },
    { item: 'Blanco', hexColor: '#FFFFFF' },
    { item: 'Gris', hexColor: '#808080' },
    { item: 'Marrón', hexColor: '#A52A2A' },
    { item: 'Naranja', hexColor: '#FFA500' },
    { item: 'Negro', hexColor: '#000000' },
    { item: 'Plata', hexColor: '#C0C0C0' },
    { item: 'Rojo', hexColor: '#FF0000' },
    { item: 'Verde', hexColor: '#008000' },
  ];

  const owners = [
    { description: 'No aplicar', value: 0 },
    { description: 'Único dueño', value: 1 },
  ];

  const doors = [2, 3, 4];

  const passengers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [value1, setValue1] = useState([0, 50000000]);
  const [value2, setValue2] = useState([100, 200000]);
  const [carFeatures, setCarFeatures] = useState([]);

  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  const provinciasList = [
    { value: 1, name: 'San José' },
    { value: 2, name: 'Alajuela' },
    { value: 3, name: 'Cartago' },
    { value: 4, name: 'Heredia' },
    { value: 5, name: 'Guanacaste' },
    { value: 6, name: 'Puntarenas' },
    { value: 7, name: 'Limón' },
  ];

  function areObjectsEqual(objA, objB) {
    return JSON.stringify(objA) === JSON.stringify(objB);
  }

  const handleReset = (e) => {
    setCarFeatures([])
    setColorsSelected([]);
    setValue1([0, 50000000]);
    setValue2([100, 200000]);
    handleResetFilter();
  };

  const handlePriceRequest = () => {
    const intiPrice = value1[0] === '' ? 0 : value1[0];
    const limPrice = value1[1] === '' ? 1 : value1[1];
    updateFilter({ initialPrice: intiPrice, limitPrice: limPrice });
  }
  const handleKilometreRequest = () => {
    const minKm = value2[0] === '' ? 0 : value2[0];
    const maxKm = value2[1] === '' ? 1 : value2[1];
    updateFilter({
      minKilometres: minKm,
      maxKilometres: maxKm
    });
  }

  const handleChange1 = (event, newValue, activeThumb) => {

    if (activeThumb === 0) {
      setValue1([newValue[0], value1[1]]);
    } else {
      setValue1([value1[0], newValue[1]]);
    }

  };

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue2([newValue[0], value2[1]]);
    } else {
      setValue2([value2[0], newValue[1]]);
    }

  };

  const handlePriceChange = (e, index) => {
    let newValue = parseInt(e.target.value);
    newValue = isNaN(newValue) ? '' : newValue;
    if (index === 1 && (parseInt(newValue) <= maxPrice || newValue === '')) {

      setValue1([newValue, value1[1]]);
    } else if (newValue >= 0 && index === 2) {
      setValue1([value1[0], newValue]);
    }
  };

  const handleKilometresChange = (e, index) => {

    let newValue = parseInt(e.target.value);
    newValue = isNaN(newValue) ? '' : newValue;
    if (index === 1) {
      setValue2([newValue, value2[1]]);
    } else {
      setValue2([value2[0], newValue]);
    }
  };

  const handleProvinciaChange = (e) => {
    getCanton(e.target.value);
    updateFilter({ idProvincia: e.target.value, idCanton: '0' })
  };

  const handleOwner = (event) => {
    const value = +event.target.value === 1 ? true : false; // Convierte el valor a booleano directamente
    setIsChecked(+event.target.value);
    updateFilter({ uniqueOwner: value })
  };


  const theme = {
    fontWeight: '600',
    display: 'inline-block',
    width: '100%',
    textAlign: 'start',
    fontSize: '14px !important',
    margin: '0px',
  };

  const handleBrandChange = (e) => {
    if (e.target.value !== '') {
      updateFilter({ brandSlug: e.target.value })
      getModels(e.target.value);
    } else {
      updateFilter({ brandSlug: e.target.value, model: 0 });
      getModels(0);
    }
  };

  const handleColorCheck = (event) => {
    let colorList = [];
    const newColor = event.target.value;
    const alreadyInList = colorsSelected.some((el) => el === newColor);

    if (alreadyInList) {
      colorList = colorsSelected.filter((el) => el !== newColor);
    } else {
      colorList = [...colorsSelected, event.target.value];
    }
    updateFilter({ color: colorList })
    setColorsSelected(colorList);
  };


  return (
    <div className={classes.container}>
      <div className={classes.filter}>
        <div className={classes.filterColumn}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >

            <h4>Ubicación</h4>
            {Object.entries(activeFilters).length >= 1 ? (

              <button className={classes.reset} onClick={handleReset}>
                Limpiar
              </button>
            ) : (
              <></>
            )}
          </div>

          <div className={classes.locationItems}>
            <div>
              <label>Provincia</label>
              <select
                value={activeFilters.idProvincia ?? 0}
                onChange={handleProvinciaChange}
              >
                <option value={0}>Todas</option>
                {provinciasList.map((el) => {
                  return (
                    <option value={el.value} key={el.value}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {locationInfo && locationInfo.cantonesList.length >= 1 ? (
              <div>
                <label>Cantón</label>
                <select
                  value={activeFilters.idCanton ?? 0}
                  onChange={(e) => {
                    updateFilter({ idCanton: e.target.value })
                  }}
                >
                  <option value={0}>Todos</option>
                  {locationInfo.cantonesList.map((el) => {
                    return (
                      <option value={el.idCanton} key={el.idCanton}>
                        {el.canton}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <div>
                <label>Cantón</label>
                <select disabled>
                  <option value={0}>Todos</option>
                </select>
              </div>
            )}
          </div>
        </div>

        <div className={classes.separator}></div>

        <div className={classes.filterOption}>
          <label>Marca</label>
          <select
            value={activeFilters.brandSlug ?? ''}
            onChange={(e) => {
              handleBrandChange(e);
            }}
          >
            <option value={''}>Todas</option>
            {brands ? (
              brands.map((el) => {
                return (
                  <option value={el.brandIdentifier} key={el.brandIdentifier}>
                    {el.brand}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>

        <div className={classes.filterOption}>
          <label>Modelo</label>
          <select
            value={activeFilters.model ?? 0}
            onChange={(e) => {
              updateFilter({ model: e.target.value });
            }}
          >
            <option value={0}>Todos</option>
            {models ? (
              models.map((el) => {
                return (
                  <option value={el.id} key={el.id}>
                    {el.model}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>

        <div className={classes.filterOption}>
          <label>Estilo</label>
          <select
            value={activeFilters.type ?? 0}
            onChange={(e) => {
              updateFilter({ type: e.target.value })
            }}
          >
            <option value={0}>Todos</option>
            {carStyles ? (
              carStyles.map((el) => {
                return (
                  <option value={el.idCarType} key={el.idCarType}>
                    {el.carType1}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>

        <div className={classes.filterOption}>
          <label>Año</label>
          <select
            value={activeFilters.initialYear ?? 0}
            onChange={(e) => {
              updateFilter({ initialYear: e.target.value })
            }}
          >
            <option value={0}>Min</option>
            {years.map((el) => {
              return (
                <option value={el.item} key={el.item}>
                  {el.item}
                </option>
              );
            })}
          </select>
          <select
            value={activeFilters.limitYear ?? 0}
            onChange={(e) => {
              updateFilter({ limitYear: e.target.value })
            }}
          >
            <option value={0}>Max</option>
            {years.map((el) => {
              return (
                <option value={el.item} key={el.item}>
                  {el.item}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.separator}></div>

        <div className={classes.rangeFilter}>
          <label>Precio (₡)</label>
          <div>
            <input
              type="text"
              value={`${value1[0]}`}
              onChange={(e) => handlePriceChange(e, 1)}
            />
            <span>hasta</span>
            <input
              type="text"
              value={`${value1[1]}`}
              onChange={(e) => handlePriceChange(e, 2)}
            ></input>
          </div>
          <Slider
            sx={{
              '& .MuiSlider-thumb': {
                color: '#000',
              },
              '& .MuiSlider-track': {
                color: '#c5c5c5',
              },
              '& .MuiSlider-rail': {
                color: '#c5c5c5',
              },
            }}
            value={value1}
            onChange={handleChange1}
            valueLabelDisplay="auto"
            disableSwap
            min={0}
            max={maxPrice}
            step={1000000}
            onChangeCommitted={handlePriceRequest}
            valueLabelFormat={(value) => {
              return `${abbreviateNumber(value)}`;
            }}
          />
        </div>

        <div className={classes.rangeFilter}>
          <label>Kilometraje</label>
          <div>
            <input
              type="number"
              value={value2[0]}
              onChange={(e) => handleKilometresChange(e, 1)}
            ></input>
            <span>hasta</span>
            <input
              type="number"
              te
              value={value2[1]}
              onChange={(e) => handleKilometresChange(e, 2)}
            ></input>
          </div>
          <Slider
            sx={{
              '& .MuiSlider-thumb': {
                color: '#000',
              },
              '& .MuiSlider-track': {
                color: '#c5c5c5',
              },
              '& .MuiSlider-rail': {
                color: '#c5c5c5',
              },
            }}
            value={value2}
            onChange={handleChange2}
            valueLabelDisplay="auto"
            onChangeCommitted={handleKilometreRequest}
            disableSwap
            min={1000}
            max={maxKm}
            step={5000}
            valueLabelFormat={(value) => {
              return `${abbreviateNumber(value)}`;
            }}
          />
        </div>
        <div className={classes.separator}></div>

        <div className={classes.filterOption}>
          <label>Transmisión</label>
          <select
            value={activeFilters.transmission ?? ''}
            onChange={(e) => {
              updateFilter({ transmission: e.target.value })
            }}
          >
            <option value={''}>Todos</option>
            {transmissionOptions.map((el) => {
              return (
                <option value={el.item} key={el.item}>
                  {el.item}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Combustible</label>
          <select
            value={activeFilters.fuelType ?? 0}
            onChange={(e) => {
              updateFilter({ fuelType: e.target.value })
            }}
          >
            <option value={0}>Todos</option>
            {engineOptions ? (
              engineOptions.map((el) => {
                return (
                  <option value={el.id} key={el.id}>
                    {el.fuelType}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Asientos</label>
          <select
            value={activeFilters.passengers ?? 0}
            onChange={(e) => {
              updateFilter({ passengers: e.target.value })
            }}
          >
            <option value={0}>Todos</option>
            {passengers.map((el) => {
              return (
                <option value={el} key={el}>
                  {el}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Puertas</label>
          <select
            value={activeFilters.doors ?? 0}
            onChange={(e) => {
              updateFilter({ doors: e.target.value })
            }}
          >
            <option value={0}>Todos</option>
            {doors.map((el) => {
              if (el === 4) {
                return (
                  <option value={el} key={el}>
                    4 o más
                  </option>
                );
              }
              return (
                <option value={el} key={el}>
                  {el}
                </option>
              );
            })}
          </select>
        </div>
        <DynamicAccordion title={'Dueños'}>
          <ul>
            {owners.map((item) => {
              return (
                <li className={classes.colorItem} key={item.id}>
                  <FormControlLabel
                    key={item.id}
                    checked={isChecked === item.value}
                    style={theme}
                    onChange={handleOwner}
                    value={item.value}
                    control={<Radio />}
                    label={item.description}
                    labelPlacement="start"
                  />
                </li>
              );
            })}
          </ul>
        </DynamicAccordion>

        <Accordion
          items={popularFeatures}
          updateFeatures={(items) => {
            updateFilter({ popularFeatures: items });
          }}
          carFeatures={carFeatures}
          setCarFeatures={setCarFeatures}
        />
        <DynamicAccordion title={'Color exterior'}>
          <ul>
            {colors.map((color) => {
              const isChecked = colorsSelected.includes(color.item);
              return (
                <li className={classes.colorItem} key={color.item}>
                  <FormControlLabel
                    style={theme}
                    onChange={handleColorCheck}
                    value={color.item}
                    control={<Checkbox />}
                    label={color.item}
                    labelPlacement="start"
                    key={color.item}
                    checked={isChecked}
                  />
                  <span
                    className={classes.colorDisplayer}
                    style={{ backgroundColor: `${color.hexColor}` }}
                  ></span>
                </li>
              );
            })}
          </ul>
        </DynamicAccordion>
      </div>
    </div>
  );
};

export default CarFilter;
