import ReactGA from 'react-ga4';
import Button from '../Buttons/Button';
import { TextField, cardHeaderClasses, Checkbox } from '@mui/material';
import classes from './CarOffer.module.css';
import { useEffect, useState } from 'react';
import SmallAlert from '../Alert/SmallAlert';
import DropDown from '../DropDowns/DropDown';
import Container from '../Container/Container';
import SmallButton from '../Buttons/SmallButton';
import { TbNumber1, TbNumber2, TbNumber3 } from 'react-icons/tb';
import FormLabel from '@mui/material/FormLabel';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import getToast from '../../helpers/getToast';
// TODO: Revisar porque con la placa 234 no funciona jeje
// TODO: Revisar porque con la placa ERT345 funciona jeje
// TODO: Limpiar el state del vehículo si se cambia de página, para replicar el bug:
// Ir a Anunciar mi carro, poner marca, selecionar marco y modelo, ir a en venta, y regresar a vender mi carro. El Dropdown tira opciones sin selecionar marcas
function CarOffer({
  saveCarDetails,
  checkCarByPlate,
  changePage,
  carInformation,
  showVehicle,
  getCarModels,
  locationInfo,
  getCantonesList,
  setSellingStep,
}) {
  const [plate, setPlate] = useState('');
  const [kilometreType, setKilometreType] = useState('Km');
  const [basicInfoFilled, setBasicInfoFilled] = useState(0);
  const [typeOfPlate, setTypeOfPlate] = useState('numPlate');
  const [model, setModel] = useState('');
  const [exteriorColor, setExteriorColor] = useState('');
  const [interiorColor, setInteriorColor] = useState('');
  const [carDetails, setCarDetails] = useState({
    currency: 'CRC',
    kilometreType: 'Km',
  });
  const [showAlert, setShowAlert] = useState({ show: false, text: '' });
  const [isCarInscrito, setIsCarInscrito] = useState(true);
  const [kilometreTypeLabel, setKilometreTypeLabel] = useState('Kilómetros');

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: 'Ingreso de placa/detalles del carro',
  });

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear + 1; i >= 1960; i--) {
    years.push({ item: `${i}` });
  }

  const transmissionOptions = [
    { item: 'Automático' },
    { item: 'Dual' },
    { item: 'Manual' },
  ];

  const tiposDeTraccion = [
    { item: '4X2' },
    { item: '4X4' },
  ];

  const carConditionOptions = [
    { item: 'Excelente' },
    { item: 'Muy bueno' },
    { item: 'Regular' },
  ];

  const tireConditionOptions = [
    // { item: 'Nuevas (Recien cambiadas)' },
    { item: 'Excelentes' },
    { item: 'Regular' },
    { item: 'Se recomienda cambio' },
  ];

  const electricChargerTypeOptions = [
    { item: 'CCS 1' },
    { item: 'CCS 2' },
    { item: 'CHAdeMO' },
    { item: 'GB/T' },
    { item: 'J1772 (Tipo 1)' },
    { item: 'J1772 (Tipo 2)' },
    { item: 'Mennekes (Tipo 2)' },
    { item: 'Tesla' },
    { item: 'Otro' },
  ];

  // const setCurrencyToCRC = () => {
  //   setCarDetails((prev) => {
  //     return { ...prev, currency: 'CRC' };
  //   });
  // };

  const colors = [
    { item: 'Amarillo' },
    { item: 'Azul' },
    { item: 'Beige' },
    { item: 'Blanco' },
    { item: 'Blanco Brillante' },
    { item: 'Blanco Perla' },
    { item: 'Bronce' },
    { item: 'Cobre' },
    { item: 'Dorado' },
    { item: 'Gris' },
    { item: 'Gris Espacial' },
    { item: 'Marrón' },
    { item: 'Morado' },
    { item: 'Naranja' },
    { item: 'Naranja Quemado' },
    { item: 'Negro' },
    { item: 'Negro Brillante' },
    { item: 'Negro Mate' },
    { item: 'Oro Rosa' },
    { item: 'Plateado' },
    { item: 'Plateado Brillante' },
    { item: 'Plata' },
    { item: 'Plata Metálica' },
    { item: 'Rojo' },
    { item: 'Rojo Cereza' },
    { item: 'Rojo Intenso' },
    { item: 'Turquesa' },
    { item: 'Verde' },
    { item: 'Verde Esmeralda' },
    { item: 'Otro' },
  ];

  const colorsInterior = [
    { item: 'Azul' },
    { item: 'Beige' },
    { item: 'Blanco' },
    { item: 'Crema' },
    { item: 'Gris' },
    { item: 'Gris y Beige' },
    { item: 'Marrón' },
    { item: 'Naranja' },
    { item: 'Negro' },
    { item: 'Negro y Beige' },
    { item: 'Negro y Gris' },
    { item: 'Plata' },
    { item: 'Púrpura' },
    { item: 'Rojo' },
    { item: 'Verde' },
    { item: 'Otro' },
  ];

  // console.log(carDetails)
  //Check Steps 0
  const addDetails = () => {
    let statement = false;
    if (!carDetails.plate) {
      carDetails.plate = '-1';
    }

    if (carDetails.modelId === -1) {
      statement =
        carDetails.brandSlug &&
        carDetails.carModifications !== undefined &&
        carDetails.idCarType &&
        carDetails.idEngine &&
        model.length > 1 &&
        carDetails.plate &&
        carDetails.traction &&
        carDetails.transmission;
    } else {
      statement =
        carDetails.brandSlug &&
        carDetails.carModifications !== undefined &&
        carDetails.idCarType &&
        carDetails.idEngine &&
        carDetails.modelId &&
        carDetails.plate &&
        carDetails.traction &&
        carDetails.transmission;
    }

    if (statement) {
      setBasicInfoFilled(1);
      window.scrollTo(0, 0);
      setShowAlert({ show: false, text: '' });
    } else {
      setShowAlert({ show: true, text: 'Por favor llene todos los campos.' });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showVehicle === 'ExistingCar') {
      setCarDetails({ ...carInformation.carDetails });
      getCarModels(carInformation.carDetails.brandSlug);
      if (carDetails?.idProvincia) {
        getCantones(carDetails.idProvincia);
      }
    }
  }, [showVehicle]);

  const getModels = (brand) => {
    const brandSlug = brand;
    getCarModels(brandSlug);
  };

  const checkPlate = async () => {
    const carPlate = plate.trim();
    let isAlphaNumPlateValid = false;
    let isNumPlateValid = false;

    if (typeOfPlate === 'alphaNumPlate') {
      const regexAlphaNumber = /^[A-Z]{3}[0-9]{3}$/;
      const alphaNumRegexPassed = regexAlphaNumber.test(carPlate);
      const containsVowels = /[AEIOU]/.test(carPlate); // Verifica si hay vocales

      if (carPlate.length === 6 && alphaNumRegexPassed && !containsVowels) {
        isAlphaNumPlateValid = true;
      }
    }

    if (typeOfPlate === 'numPlate') {
      const regexNumber = /^[0-9]*$/;
      const numRegexPassed = regexNumber.test(carPlate);

      if (carPlate.length >= 1 && numRegexPassed) {
        isNumPlateValid = true;
      }
    }

    if (isNumPlateValid || isAlphaNumPlateValid) {
      setShowAlert(false);
      const response = await checkCarByPlate(carPlate);
      if (response === 200) {
        setSellingStep(1, 1);
        changePage('ExistingCar');
      } else if (response === 402) {
        setShowAlert((prev) => {
          return {
            show: true,
            text: 'El carro ingresado ya está listado en venta o revisión.',
          };
        });
      } else {
        setCarDetails({ plate: carPlate });
        changePage('NewCar');
      }
    } else {
      setShowAlert((prev) => {
        return {
          show: true,
          text: 'Por favor ingrese una placa de carro válida.',
        };
      });
    }
  };

  const handleKeyPressPlate = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      checkPlate();
    }
  };
  const saveDetails = () => {
    let statement = false;
    carDetails.currency = 'CRC' // 👀

    if (carDetails.exteriorColor === 'Otro') {
      statement =
        carDetails.brandSlug &&
        carDetails.carCondition &&
        carDetails.carModifications !== undefined &&
        carDetails.currency &&
        carDetails.doors &&
        carDetails.idCanton &&
        carDetails.idCarType &&
        carDetails.idEngine &&
        carDetails.idProvincia &&
        carDetails.interiorColor &&
        carDetails.modelId &&
        carDetails.negotiablePrice !== undefined &&
        carDetails.passengers &&
        carDetails.plate &&
        carDetails.tireCondition &&
        carDetails.traction &&
        carDetails.transmission &&
        carDetails.year &&
        exteriorColor.length > 1;
    } else if (carDetails.interiorColor === 'Otro') {
      statement =
        carDetails.brandSlug &&
        carDetails.carCondition &&
        carDetails.carModifications !== undefined &&
        carDetails.currency &&
        carDetails.doors &&
        carDetails.idCanton &&
        carDetails.idCarType &&
        carDetails.idEngine &&
        carDetails.idProvincia &&
        carDetails.interiorColor &&
        carDetails.modelId &&
        carDetails.negotiablePrice !== undefined &&
        carDetails.passengers &&
        carDetails.plate &&
        carDetails.tireCondition &&
        carDetails.traction &&
        carDetails.transmission &&
        carDetails.year &&
        interiorColor.length > 1;
    } else {
      statement =
        carDetails.brandSlug &&
        carDetails.carCondition &&
        carDetails.carModifications !== undefined &&
        carDetails.currency &&
        carDetails.doors &&
        carDetails.exteriorColor &&
        carDetails.idCanton &&
        carDetails.idCarType &&
        carDetails.idEngine &&
        carDetails.idProvincia &&
        carDetails.interiorColor &&
        carDetails.modelId &&
        carDetails.negotiablePrice !== undefined &&
        carDetails.passengers &&
        carDetails.plate &&
        carDetails.tireCondition &&
        carDetails.traction &&
        carDetails.transmission &&
        carDetails.year;
    }

    if (statement) {
      const newValues = [];
      if (carDetails.modelId === -1) {
        newValues.push({ id: 0, refColumn: 'modelId', value: model, idCar: 0 });
      }
      if (carDetails.exteriorColor === 'Otro') {
        newValues.push({
          id: 0,
          refColumn: 'exteriorColor',
          value: exteriorColor,
          idCar: 0,
        });
      }
      if (carDetails.interiorColor === 'Otro') {
        newValues.push({
          id: 0,
          refColumn: 'interiorColor',
          value: interiorColor,
          idCar: 0,
        });
      }

      saveCarDetails({ ...carDetails }, newValues);
      setShowAlert({ show: false, text: '' });
    } else {
      setShowAlert({ show: true, text: 'Por favor llene todos los campos.' });
    }
  };
  const handleOther = (e, type) => {
    const newValue = e.target.value;

    // Expresión regular para permitir solo caracteres alfanuméricos
    const alphanumericRegex = /^[a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ +]+$/;

    if (alphanumericRegex.test(newValue) || newValue === '') {
      if (type === 'modelId') {
        setModel(newValue);
      } else if (type === 'exteriorColor') {
        setExteriorColor(newValue);
      } else if (type === 'interiorColor') {
        setInteriorColor(newValue);
      }
    }
  };

  // const handlePriceChange = (el) => {
  //   let newValue = el.target.value;
  //   const regex = /^[0-9]+$/;
  //   newValue = newValue.length >= 4 ? newValue.split(',').join('') : newValue
  //   if (isNaN(newValue)) {
  //     return;
  //   }
  //   if (regex.test(newValue) || newValue === '') {
  //     setCarDetails((prev) => {
  //       return {
  //         ...prev,
  //         carPriceCrc: newValue === '' ? 0 : +newValue,
  //       };
  //     });
  //   }
  // };

  const checkDetails = () => {

    //Validate Segunda tab
    // basicInfoFilled === 1 &&
    // TODO: Agregar validación para ver si millas o KM están vacios, dependiendo del input del usuario
    if (
      basicInfoFilled === 1 &&
      carDetails.brandSlug &&
      carDetails.carModifications !== undefined &&
      carDetails.carPriceCrc && //IMPORTANTE! Quitar este check cuando ya tengamos manejo de USD como precio principal
      carDetails.doors &&
      carDetails.idCarType &&
      carDetails.idEngine &&
      carDetails.modelId &&
      ((carDetails.kilometres !== undefined && carDetails.kilometres !== 0) ||
      (carDetails.miles !== undefined && carDetails.miles !== 0)) &&
      carDetails.negotiablePrice !== undefined &&
      carDetails.passengers &&
      carDetails.plate &&
      carDetails.traction &&
      carDetails.transmission
    ) {
      setBasicInfoFilled((prevFilled) => prevFilled + 1);
      window.scrollTo(0, 0);
      setShowAlert({ show: false, text: '' });
    } else {
      setShowAlert({ show: true, text: 'Por favor llene todos los campos.' });
    }
  };
  const getCantones = (el) => {
    getCantonesList(el);
  };

  const handleBackButton = (e) => {
    e.preventDefault();
    setBasicInfoFilled((prevFilled) => prevFilled - 1);
  };

  //Primer panel
  if (
    (showVehicle === 'NewCar' && basicInfoFilled === 0) ||
    (showVehicle === 'ExistingCar' && basicInfoFilled === 0)
  ) {
    return (
      <Container>
        <DropDown
          setValue={(el) => {
            getModels(el);
            setCarDetails((prev) => {
              return { ...prev, brandSlug: el };
            });
          }}
          value={carDetails.brandSlug}
          disabled={false}
          label={'Marca'}
          items={carInformation.carBrands}
        >
          {carInformation?.carBrands.map((option, index) => (
            <MenuItem key={index} value={option.brandIdentifier}>
              {option.brand}
            </MenuItem>
          ))}
        </DropDown>
        {carDetails.brandSlug === undefined ? (
          <DropDown disabled={true} type="model" label={'Modelo'} />
        ) : (
          <DropDown
            setValue={(el) =>
              setCarDetails((prev) => {
                return { ...prev, modelId: el };
              })
            }
            value={carDetails.modelId}
            label={'Modelo'}
          >
            {carInformation.carModels !== undefined ? (
              carInformation.carModels.map((element) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.model}
                  </MenuItem>
                );
              })
            ) : (
              <div></div>
            )}
            <MenuItem value={-1} style={{ fontWeight: 'bold' }}>
              Otro (Escribir)
            </MenuItem>
          </DropDown>
        )}
        {carDetails.modelId === -1 ? (
          <FormControl
            variant="outlined"
            sx={{ marginTop: '10px', minWidth: '100%' }}
            size="small"
          >
            <TextField
              onChange={(el) => handleOther(el, 'modelId')}
              value={model}
              required
              id="outlined-basic"
              label="Indique el modelo"
              variant="outlined"
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 20, // Establece la longitud máxima a 20 caracteres
              }}
              style={{ width: '100%', textAlign: 'center' }}
            />
          </FormControl>
        ) : (
          <></>
        )}
        <DropDown
          setValue={(el) =>
            setCarDetails((prev) => {
              return { ...prev, year: el };
            })
          }
          value={carDetails.year}
          label={'Año'}
          items={years}
        >
          {years !== undefined ? (
            years.map((element) => {
              return (
                <MenuItem key={element.item} value={element.item}>
                  {element.item}
                </MenuItem>
              );
            })
          ) : (
            <div></div>
          )}
        </DropDown>

        <DropDown
          setValue={(el) =>
            setCarDetails((prev) => {
              return { ...prev, idCarType: el };
            })
          }
          value={carDetails.idCarType}
          label={'Estilo'}
        >
          {carInformation.carStyles !== undefined ? (
            carInformation.carStyles.map((element) => {
              return (
                <MenuItem key={element.idCarType} value={element.idCarType}>
                  {element.carType1}
                </MenuItem>
              );
            })
          ) : (
            <div></div>
          )}
        </DropDown>

        <DropDown
          setValue={(el) =>
            setCarDetails((prev) => ({
              ...prev,
              transmission: el,
            }))
          }
          value={carDetails.transmission}
          label={'Transmisión'}
        >
          {transmissionOptions.map((option, index) => (
            <MenuItem key={index} value={option.item}>
              {option.item}
            </MenuItem>
          ))}
        </DropDown>

        <DropDown
          setValue={(el) =>
            setCarDetails((prev) => ({
              ...prev,
              idEngine: el,
            }))
          }
          value={carDetails.idEngine}
          label={'Tipo de motor'}
        >
          {carInformation.engineOptions.map((option, index) => (
            <MenuItem key={index} value={option.id}>
              {option.fuelType}
            </MenuItem>
          ))}
        </DropDown>
        <DropDown
          setValue={(el) =>
            setCarDetails((prev) => ({
              ...prev,
              traction: el,
            }))
          }
          value={carDetails.traction}
          label={'Tipo de tracción'}
        >
          {tiposDeTraccion.map((option, index) => (
            <MenuItem key={index} value={option.item}>
              {option.item}
            </MenuItem>
          ))}
        </DropDown>
        <DropDown
          setValue={(el) =>
            setCarDetails((prev) => ({
              ...prev,
              carModifications: el,
            }))
          }
          value={carDetails.carModifications}
          label={'¿El carro posee modificaciones?'}
        >
          {[
            { item: 'No', value: false },
            { item: 'Sí', value: true },
          ].map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.item}
            </MenuItem>
          ))}
        </DropDown>

        {showAlert.show ? <SmallAlert>{showAlert.text}</SmallAlert> : <></>}
        <Button onClick={addDetails}>Continuar</Button>
      </Container>
    );
  }

  //Segundo panel
  if (basicInfoFilled === 1) {
    return (
      <Container>
        <div className={classes.btn}>
          <SmallButton onClick={handleBackButton} backBtn={true}>
            Regresar
          </SmallButton>
        </div>
        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>¿Compró el carro de agencia?</InputLabel>
          <Select
            value={carDetails.uniqueOwner}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                uniqueOwner: selectedValue,
              }));
            }}
            label="¿Compró el carro de agencia?"
          >
            {[
              { item: 'No', value: false },
              { item: 'Sí, único dueño', value: true },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>¿Recibe vehículo?</InputLabel>
          <Select
            value={carDetails.ownerAcceptsCar}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                ownerAcceptsCar: selectedValue,
              }));
            }}
            label="¿Recibe vehículo?"
          >
            {[
              { item: 'No', value: false },
              { item: 'Sí', value: true },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>Precio negociable</InputLabel>
          <Select
            value={carDetails.negotiablePrice}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                negotiablePrice: selectedValue,
              }));
            }}
            label="Precio negociable"
          >
            {[
              { item: 'No', value: false },
              { item: 'Sí', value: true },
            ].map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          onChange={(el) => {
            const inputValue = el.target.value.replace(/[^0-9]/g, ''); // Solo permite dígitos del 0 al 9

            setCarDetails((prev) => ({
              ...prev,
              carPriceCrc:
                inputValue !== NaN ? inputValue : prev.carPriceCrc || 0,
            }));
          }}
          value={
            carDetails.carPriceCrc
              ? Number(carDetails.carPriceCrc).toLocaleString() // Formatear con comas
              : ''
          }
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 11,
            min: 0,
            max: 999999999,
          }}
          required
          id="outlined-basic"
          label="Precio de venta en Colónes (₡)"
          variant="outlined"
          style={{ marginTop: '15px', width: '100%' }}
        />

        {/* carDetails.fuelType */}
        {carDetails.idEngine !== 2 && (
          <TextField
            onChange={(el) => {
              setCarDetails((prev) => {
                return {
                  ...prev,
                  engineCc: +el.target.value.replace(/,/g, ''),
                };
              });
            }}
            value={
              carDetails.engineCc ? carDetails.engineCc.toLocaleString() : ''
            }
            required
            id="outlined-basic"
            label="Cilindrada (CC) del motor"
            variant="outlined"
            inputProps={{
              style: { textAlign: 'center' },
              maxLength: 4,
              min: 0,
              max: 8000,
            }}
            style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}
          />
        )}

        {carDetails.idEngine === 2 && (
          <TextField
            onChange={(el) => {
              setCarDetails((prev) => {
                return {
                  ...prev,
                  electricAutonomy: +el.target.value.replace(/,/g, ''),
                };
              });
            }}
            value={
              carDetails.electricAutonomy
                ? carDetails.electricAutonomy.toLocaleString()
                : ''
            }
            required
            id="outlined-basic"
            label="Autonomía (Km)"
            variant="outlined"
            inputProps={{
              style: { textAlign: 'center' },
              maxLength: 7,
              min: 0,
              max: 8000,
            }}
            style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}
          />
        )}

        {carDetails.idEngine === 2 && (
          <TextField
            onChange={(el) => {
              setCarDetails((prev) => {
                return {
                  ...prev,
                  electricBatteryCapacity: +el.target.value.replace(/,/g, ''),
                };
              });
            }}
            value={
              carDetails.electricBatteryCapacity
                ? carDetails.electricBatteryCapacity.toLocaleString()
                : ''
            }
            required
            id="outlined-basic"
            label="Capacidad de batería (kWh)"
            variant="outlined"
            inputProps={{
              style: { textAlign: 'center' },
              maxLength: 7,
              min: 0,
              max: 8000,
            }}
            style={{ marginTop: '15px', width: '100%', textAlign: 'center' }}
          />
        )}

        {carDetails.idEngine === 2 && (
          <FormControl
            variant="outlined"
            sx={{ marginTop: '10px', minWidth: '100%' }}
            size="small"
          >
            <InputLabel>Tipo de conector</InputLabel>
            <Select
              value={carDetails.electricChargerType}
              onChange={(event) => {
                const selectedValue = event.target.value;
                setCarDetails((prev) => ({
                  ...prev,
                  electricChargerType: selectedValue,
                }));
              }}
              label="Tipo de conector"
            >
              {electricChargerTypeOptions.map((option, index) => (
                <MenuItem key={index} value={option.item}>
                  {option.item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          onChange={(el) => {
            setCarDetails((prev) => {
              return { ...prev, passengers: +el.target.value };
            });
          }}
          value={carDetails.passengers ? carDetails.passengers : ''}
          required
          id="outlined-basic"
          label="Número de asientos"
          variant="outlined"
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 2,
            min: 0,
            max: 9,
          }}
          style={{ marginTop: '15px', width: '100%' }}
        />
        <TextField
          onChange={(el) => {
            setCarDetails((prev) => {
              return { ...prev, doors: +el.target.value };
            });
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          value={carDetails.doors ? carDetails.doors : ''}
          required
          id="outlined-basic"
          label="Número de puertas"
          variant="outlined"
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 1,
            min: 0,
            max: 9,
          }}
          style={{ marginTop: '15px', width: '100%' }}
        />

        <FormControl component="fieldset" style={{ marginTop: '20px' }}>
          <FormLabel id="demo-radio-buttons-group-label">
            ¿Kilómetros o millas?
          </FormLabel>
          <RadioGroup
            aria-label="kilometreType"
            name="kilometreType"
            value={kilometreType}
            onChange={(event) => {
              setKilometreType(event.target.value);
              if (event.target.value === 'Km') {
                carDetails.kilometreType = 'Km';
                setKilometreTypeLabel('Kilómetros');
                setCarDetails((prev) => ({
                  ...prev,
                  kilometres:
                    prev.kilometres !== undefined ? prev.kilometres : 0,
                  miles: 0,
                }));
              } else if (event.target.value === 'Mi') {
                carDetails.kilometreType = 'Mi';
                setKilometreTypeLabel('Millas');
                setCarDetails((prev) => ({
                  ...prev,
                  kilometres: 0,
                  miles: prev.miles !== undefined ? prev.miles : 0,
                }));
              }
            }}
          >
            <FormControlLabel
              value="Km"
              control={<Radio />}
              label="Kilómetros (Km)"
            />
            <FormControlLabel
              value="Mi"
              control={<Radio />}
              label="Millas (Mi)"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          onChange={(el) => {
            const inputValue = el.target.value.replace(/[^0-9]/g, ''); // Solo permite dígitos del 0 al 9
            const newKilometreType = kilometreType === 'Km' ? 'Km' : 'Mi';

            setCarDetails((prev) => ({
              ...prev,
              kilometres:
                newKilometreType === 'Km' ? inputValue : prev.kilometres || 0,
              miles: newKilometreType === 'Mi' ? inputValue : prev.miles || 0,
              kilometreType: newKilometreType, // Asignar automáticamente 'Km' o 'Mi'
            }));
          }}
          value={
            kilometreType === 'Km'
              ? carDetails.kilometres
                ? Number(carDetails.kilometres).toLocaleString() // Formatear con comas
                : ''
              : carDetails.miles
              ? Number(carDetails.miles).toLocaleString() // Formatear con comas
              : ''
          }
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 7,
            min: 0,
            max: 1000000,
          }}
          required
          id="outlined-basic"
          label={kilometreTypeLabel}
          variant="outlined"
          style={{ marginTop: '15px', width: '100%' }}
        />

        <Button onClick={checkDetails}>Continuar</Button>
        {showAlert.show ? <SmallAlert>{showAlert.text}</SmallAlert> : <></>}
      </Container>
    );
  }

  if (basicInfoFilled === 2) {
    return (
      <Container>
        <div className={classes.btn}>
          <SmallButton onClick={handleBackButton} backBtn={true}>
            Regresar
          </SmallButton>
        </div>

        <DropDown
          value={carDetails.idProvincia}
          setValue={(el) => {
            getCantones(el);
            setCarDetails((prev) => ({
              ...prev,
              idProvincia: el,
            }));
          }}
          label="Provincia"
        >
          {locationInfo.provinciasList !== undefined ? (
            locationInfo.provinciasList.map((element) => {
              return (
                <MenuItem key={element.idProvincia} value={element.idProvincia}>
                  {element.provincia}
                </MenuItem>
              );
            })
          ) : (
            <div></div>
          )}
        </DropDown>

        {locationInfo.cantonesList.length === 0 && !carDetails.idCanton ? (
          <DropDown disabled={true} label={'Cantón'} />
        ) : (
          <DropDown
            setValue={(el) =>
              setCarDetails((prev) => {
                return { ...prev, idCanton: el };
              })
            }
            value={carDetails.idCanton}
            disabled={false}
            label={'Cantón'}
          >
            {locationInfo?.cantonesList !== undefined ? (
              locationInfo?.cantonesList.map((element) => {
                return (
                  <MenuItem key={element.idCanton} value={element.idCanton}>
                    {element.canton}
                  </MenuItem>
                );
              })
            ) : (
              <div></div>
            )}
          </DropDown>
        )}

        <TextField
          onChange={(el) => {
            setCarDetails((prev) => {
              return {
                ...prev,
                lastMarchamo: +el.target.value.replace(/,/g, ''),
              };
            });
          }}
          value={
            carDetails.lastMarchamo
              ? carDetails.lastMarchamo.toLocaleString()
              : ''
          }
          inputProps={{
            style: { textAlign: 'center' },
            maxLength: 11,
            min: 0,
            max: 999999999,
          }}
          // required
          id="outlined-basic"
          label="Costo del marchamo (opcional)"
          variant="outlined"
          style={{ marginTop: '15px', width: '100%' }}
        />

        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>Condición general del carro</InputLabel>
          <Select
            value={carDetails.carCondition}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                carCondition: selectedValue,
              }));
            }}
            label="Condición general del carro"
          >
            {carConditionOptions.map((option, index) => (
              <MenuItem key={index} value={option.item}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>Condición de las llantas</InputLabel>
          <Select
            value={carDetails.tireCondition}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                tireCondition: selectedValue,
              }));
            }}
            label="Condición de las llantas"
          >
            {tireConditionOptions.map((option, index) => (
              <MenuItem key={index} value={option.item}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>Color exterior</InputLabel>
          <Select
            value={carDetails.exteriorColor}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                exteriorColor: selectedValue,
              }));
            }}
            label="Color exterior"
          >
            {colors.map((option, index) => (
              <MenuItem key={index} value={option.item}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {carDetails.exteriorColor === 'Otro' ? (
          <FormControl
            variant="outlined"
            sx={{ marginTop: '10px', minWidth: '100%' }}
            size="small"
          >
            <TextField
              onChange={(el) => handleOther(el, 'exteriorColor')}
              value={exteriorColor}
              required
              id="outlined-basic"
              label="Indique el color exterior del carro"
              variant="outlined"
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 20,
                max: 8000,
              }}
              style={{ width: '100%', textAlign: 'center' }}
            />
          </FormControl>
        ) : (
          <></>
        )}
        <FormControl
          variant="outlined"
          sx={{ marginTop: '10px', minWidth: '100%' }}
          size="small"
        >
          <InputLabel>Color interior</InputLabel>
          <Select
            value={carDetails.interiorColor}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setCarDetails((prev) => ({
                ...prev,
                interiorColor: selectedValue,
              }));
            }}
            label="Color interior"
          >
            {colorsInterior.map((option, index) => (
              <MenuItem key={index} value={option.item}>
                {option.item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {carDetails.interiorColor === 'Otro' ? (
          <FormControl
            variant="outlined"
            sx={{ marginTop: '10px', minWidth: '100%' }}
            size="small"
          >
            <TextField
              onChange={(el) => handleOther(el, 'interiorColor')}
              value={interiorColor}
              required
              id="outlined-basic"
              label="Indique el color interior del carro"
              variant="outlined"
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 20,
                max: 8000,
              }}
              style={{ width: '100%', textAlign: 'center' }}
            />
          </FormControl>
        ) : (
          <></>
        )}
        <Button onClick={saveDetails}>Continuar</Button>
        {showAlert.show ? <SmallAlert>{showAlert.text}</SmallAlert> : null}
      </Container>
    );
  }

  return (
    <div>
      <Container>
        <p>
          Pon la placa completa. <br />
          <strong> Solo se mostrará el último dígito en el anuncio </strong>
        </p>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={isCarInscrito}
              onChange={() => {
                setIsCarInscrito(!isCarInscrito);
                setPlate('');
                setShowAlert({ show: false, text: '' });
              }}
            />
          }
          label="El carro está inscrito y tiene placa"
        />

        {isCarInscrito ? (
          <TextField
            onChange={(e) => {
              const userInput = e.target.value.toUpperCase();
              const regexRemoveSpecialCharacters = /[^A-Z0-9]/g;
              let userInputClean = userInput.replace(
                regexRemoveSpecialCharacters,
                ''
              );

              setPlate(userInputClean);
              const regexAlphaCheck = new RegExp('[A-Z]{3}');
              const isAlphaPlate = regexAlphaCheck.test(userInputClean);

              // Check if plate is AlphaNum or just Num
              isAlphaPlate
                ? setTypeOfPlate('alphaNumPlate')
                : setTypeOfPlate('numPlate');
            }}
            onKeyPress={handleKeyPressPlate}
            value={plate}
            required
            id="outlined-basic"
            label="Placa completa del carro"
            variant="outlined"
            inputProps={{
              style: { textAlign: 'center' },
              maxLength: 7,
              min: 0,
              max: 999999,
            }}
            style={{ width: '100%' }}
          />
        ) : (
          <p style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
            Vas a registrar un carro que no está inscrito o no tiene placa
          </p>
        )}

        {showAlert.show ? <SmallAlert>{showAlert.text}</SmallAlert> : null}

        <Button
          onClick={() => {
            if (isCarInscrito) {
              checkPlate();
            } else {
              changePage('NewCar');
            }
          }}
        >
          Continuar
        </Button>
      </Container>

      <div className={classes.firstSection}>
        <h3>¿Cómo funciona?</h3>
        <div className={classes.information}>
          <div className={classes.infoItem}>
            <div className={classes.icon}>
              <TbNumber1 />
            </div>
            <h4>Publicá el carro</h4>
            <p className={classes.stepsInstructions}>
              Completá el formulario. Toma menos de 5 minutos
            </p>
          </div>
          <div className={classes.infoItem}>
            <div className={classes.icon}>
              <TbNumber2 />
            </div>
            <h4>Verificación</h4>
            <p className={classes.stepsInstructions}>
              El carro será revisado por Autos Nova antes de ser publicado
            </p>
          </div>
          <div className={classes.infoItem}>
            <div className={classes.icon}>
              <TbNumber3 />
            </div>
            {/* TODO: Definir si cambiamos el ícono para que no se confunda con el buscador jeje */}
            <h4>Recibe ofertas</h4>
            <p className={classes.stepsInstructions}>
              Empieza a recibir ofertas de tus posibles compradores.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarOffer;
