import classes from './PriceSlider.module.css';
import { useState, useEffect } from 'react';
import { Slider } from '@mui/material';
import SmallButton from '../Buttons/SmallButton';

export default function PriceSlider({
  updateFilter,
  resetOneFilter,
  setShowFilter,
  filterParams,
  button = false,
}) {

  const maxPrice = 50000000;
  const initValue = [
    filterParams.initialPrice ? filterParams.initialPrice : 0,
    filterParams.limitPrice ? filterParams.limitPrice : maxPrice,
  ];
  const [value1, setValue1] = useState(initValue);
  const [timer, setTimer] = useState(null);

  const handlePriceRequest = () => {
    const intiPrice = value1[0] === '' ? 0 : value1[0];
    const limPrice = value1[1] === '' ? 1 : value1[1];
    updateFilter({ initialPrice: intiPrice, limitPrice: limPrice });
  } 
  const handlePriceChange = (e, index) => {
    e.preventDefault();
    let newValue = e.target.value;
    const regex = /^[0-9]*$/;
    newValue = isNaN(newValue) ? '' : newValue;
    if (regex.test(newValue)) {
      if (index === 1 && (parseInt(newValue) <= maxPrice || newValue === '')) {
        setValue1([newValue, value1[1]]);
      } else {
        setValue1([value1[0], newValue]);
      }
    }
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue1([newValue[0], value1[1]]);
    } else {
      setValue1([value1[0], newValue[1]]);
    }
  };

  const handleClick = () => {
    resetOneFilter(['initialPrice', 'limitPrice'])
    setShowFilter(null);
  };

  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  return (
    <div className={classes.priceContent}>
      <label>Precio - ₡</label>
      <div>
        <input
          type="text"
          value={value1[0]}
          onChange={(e) => handlePriceChange(e, 1)}
        />
        <span>hasta</span>
        <input
          type="text"
          value={value1[1]}
          onChange={(e) => handlePriceChange(e, 2)}
        ></input>
      </div>
      <Slider
        sx={{
          '& .MuiSlider-thumb': {
            color: '#000',
          },
          '& .MuiSlider-track': {
            color: '#c5c5c5',
          },
          '& .MuiSlider-rail': {
            color: '#c5c5c5',
          },
        }}
        value={value1}
        onChange={handleChange1}
        valueLabelDisplay="auto"
        disableSwap
        min={0}
        max={maxPrice}
        step={1000000}
        onChangeCommitted={handlePriceRequest}
        valueLabelFormat={(value) => {
          return `${abbreviateNumber(value)}`;
        }}
      />
      {button ? (
        <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
          <div className={classes.button}>
            <SmallButton onClick={() => setShowFilter(null)}>
              Cerrar
            </SmallButton>
          </div>
          <div className={classes.button}>
            <SmallButton onClick={handleClick}>Limpiar</SmallButton>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
