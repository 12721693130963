import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import classes from './ImageUploader.module.css';

const ImageUploader = ({ onChange, planNumber }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [counter, setCounter] = useState(0);
  const [scrollDown, setScrollDown] = useState(false); // Added state for scrolling

  const planPicsCount = planNumber === 1 ? 5 : planNumber === 2 ? 10 : 20;

  useEffect(() => {
    const files = uploadedFiles.map((el) => {
      return el.file;
    });
    onChange(files);
    // Scroll down if there is at least one uploaded file
    if (uploadedFiles.length > 0 && !scrollDown) {
      const scrollTarget = window.innerHeight * 0.3; // Ajusta este valor según tus preferencias
      window.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
      setScrollDown(true);
    }
  }, [uploadedFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Filter out unsupported file types
      const filteredFiles = acceptedFiles.filter((file) =>
        file.type.startsWith('image/')
      );
      const sumOfUploadedAndNewImg = counter + filteredFiles.length;
      const filesWithPreview = filteredFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

      const newPics = [...uploadedFiles, ...filesWithPreview];
      const picsToUpload = newPics.slice(0, planPicsCount);

      setUploadedFiles(picsToUpload);

      if (sumOfUploadedAndNewImg <= planPicsCount) {
        setCounter(sumOfUploadedAndNewImg);
      } else {
        setCounter(planPicsCount);
        console.log(
          `Tienes un límite de ${planPicsCount} fotos. Mejora tu plan si quieres agregar más.`
        );
      }
    },
    [counter]
  );

  const deleteFile = (fileIndex) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, index) => index !== fileIndex)
    );
    setCounter(counter - 1);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/jpg',
  });

  return (
    <div className={classes.imageUploader}>
      {counter === planPicsCount ? (
        <div className={classes.reachedLimit}>
          <h4>Alcanzaste el límite de fotos para esta publicación.</h4>
        </div>
      ) : (
        <div {...getRootProps()} className={classes.dropzone}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Descarga tus imagenes aquí...</p>
          ) : (
            <div>
              <p>
                Arrastra tus imagenes aquí o da click y selecciona las imagenes
                que deseas.
              </p>
              <p>Favor subir fotos en formato png o jpeg.</p>
            </div>
          )}
        </div>
      )}
      <p>
        Te sugerimos cargar fotos desde todos los ángulos del auto, interior,
        llantas, motor, cajuela y accesorios.
      </p>
      {uploadedFiles.length > 0 && (
        <div className={classes.previewContainer}>
          {uploadedFiles.map((uploadedFile, index) => (
            <div key={index} className={classes.preview}>
              <img src={uploadedFile.preview} alt={`Preview ${index}`} />
              <button
                className={classes.deleteButton}
                onClick={() => deleteFile(index)}
              >
                <div className={classes.circle}>
                  <span className={classes.cross}></span>
                </div>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
