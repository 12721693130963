import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { useState } from 'react';

const DropDown = ({
  label,
  disabled = false,
  setValue,
  value = '',
  children
}) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{ marginTop: '10px', minWidth: '100%' }} size="small">
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={value}
        label={label}
        disabled={disabled}
        onChange={handleChange}
        style={{ width: '100%', height: '80%' }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default DropDown;
