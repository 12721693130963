import axios from 'axios';
import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import PageNotFound from '../../pages/PageNotFound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CircularProgress from '@mui/material/CircularProgress';
// import CarReviewDetails from '../CarReviewDetails/CarReviewDetails';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';

function Proxy({ }) {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const user = useSelector((state) => state.UserProfile.userProfile);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const BASE_URL = process.env.REACT_APP_API_URL;

  const handleSearch = () => {
    const token = getTokenFromLocalStorage();
    setLoading(true);

    // Llamada a la API con Axios y el término de búsqueda
    axios
      .get(`${BASE_URL}/api/Admin/GetUsersAsAdmin?searchTerm=${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('API Response:', response.data);
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('API Error:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const token = getTokenFromLocalStorage();


    // Llamada a la API con Axios
    axios
      .get(`${BASE_URL}/api/Admin/GetUsersAsAdmin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('API Response:', response.data);
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('API Error:', error);
        setLoading(false);
      });
  }, []);

  const handleProxyClick = (user) => {
    const { email, authProvider, authProviderId } = user;

    // Realiza la llamada a la API con los valores del usuario seleccionado
    axios
      .get(
        `${BASE_URL}/api/User/GetUser?email=${email}&authProvider=${authProvider}&authProviderId=${authProviderId}`
      )
      .then((response) => {
        console.log('User Details:', response.data);

        // Almacena el nuevo token en localStorage
        const newToken = response.data.token;
        localStorage.setItem('token', newToken);

        // Redirige a /mi-perfil y espera un breve momento antes de recargar
        window.location.replace('/mi-perfil');
        setTimeout(() => {
          window.location.reload();
        }, 100); // Puedes ajustar el tiempo según sea necesario
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  };


  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Proxy - Admin - Autos Nova</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      {user.userRole === 3 ? (
        <div>
          <div>
            <br />
            <h1>Proxy</h1>
          </div>
          <div>
            <div>
              <input
                type="text"
                placeholder="Usuario proxy"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button onClick={handleSearch}>Buscar</button>
            </div>
            {loading ? (
              <CircularProgress />
            ) : (
              // Renderiza tus datos de usuarios en una tabla
              <table>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Email</th>
                    <th>Rol</th>
                    <th>Proxy</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.idUser}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                      <td>{user.userRole}</td>
                      <td>
                        <button onClick={() => handleProxyClick(user)}>
                          Proxy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getCarsToReview: (token) => dispatch(getCarsInReview(token)),
  };
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Proxy);
