import classes from './KilometresSlider.module.css';
import { useState, useEffect } from 'react';
import { Slider } from '@mui/material';
import SmallButton from '../Buttons/SmallButton';

export default function KilometresSlider({ updateFilter, setShowFilter, resetOneFilter, filterParams, button = false }) {
  const maxKm = 200000
  const initalValue = [filterParams.minKilometres ? filterParams.minKilometres : 0
    , filterParams.maxKilometres ? filterParams.maxKilometres : maxKm];
  const [value, setValue] = useState(initalValue);

  const handleKilometreRequest = () => {
    const minKm = value[0] === '' ? 0 : value[0];
    const maxKm = value[1] === '' ? 1 : value[1];
    updateFilter({
      minKilometres: minKm,
      maxKilometres: maxKm
    });
  }



  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue([newValue[0], value[1]]);
    } else {
      setValue([value[0], newValue[1]]);
    }
  };

  const handleClick = () => {
    resetOneFilter(['minKilometres', 'maxKilometres'])
    setShowFilter(null);
  }

  const handleKilometresChange = (e, index) => {
    const newValue = e.target.value;
    if (index === 1) {
      setValue([newValue, value[1]]);
    } else {
      setValue([value[0], newValue]);
    }
  };


  var SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  return (
    <div className={classes.priceContent}>
      <label>Kilometraje</label>
      <div>
        <input
          type="number"
          value={value[0]}
          onChange={(e) => handleKilometresChange(e, 1)}
        ></input>
        <span>hasta</span>
        <input
          type="number"
          te
          value={value[1]}
          onChange={(e) => handleKilometresChange(e, 2)}
        ></input>
      </div>
      <Slider
        sx={{
          '& .MuiSlider-thumb': {
            color: '#000',
          },
          '& .MuiSlider-track': {
            color: '#c5c5c5',
          },
          '& .MuiSlider-rail': {
            color: '#c5c5c5',
          },
        }}
        value={value}
        onChange={handleChange2}
        valueLabelDisplay="auto"
        disableSwap
        min={1000}
        onChangeCommitted={handleKilometreRequest}
        max={maxKm}
        step={5000}
        valueLabelFormat={(value) => {
          return `${abbreviateNumber(value)}`;
        }}
      />
      {button ? (
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <div className={classes.button}>
            <SmallButton onClick={() => setShowFilter(null)}>
              Cerrar
            </SmallButton>
          </div>
          <div className={classes.button}>
            <SmallButton onClick={handleClick}>Limpiar</SmallButton>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}