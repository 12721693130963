import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { ImMobile } from 'react-icons/im';
import classes from './Header.module.css';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { IoMdVideocam } from 'react-icons/io';
import MenuIcon from '@mui/icons-material/Menu';
import { AiOutlineClose } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import GarageIcon from '@mui/icons-material/Garage';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { SiGooglemybusiness } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import { FaCarAlt, FaCheckSquare } from 'react-icons/fa';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { MdExpandLess, MdExpandMore, MdWorkspacePremium } from 'react-icons/md';
import { cleanUserRequest } from '../../store/UserStore/userActions';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
// import { FaCheckSquare } from 'react-icons/fa';
// import CssBaseline from '@mui/material/CssBaseline';

// import ListItemIcon from '@mui/material/ListItemIcon';
// import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
// import { ConnectingAirportsOutlined } from '@mui/icons-material';

import LogoNav from '../../images/appImages/logo_nav.webp'; // Import the SVG file

const drawerWidth = '100%';
// const navItems = ['Home', 'About', 'Contact'];

const BASE_URL = process.env.REACT_APP_API_URL;

function Header(props) {
  const [role, setRole] = useState();
  const user = useSelector((state) => state.UserProfile);

  React.useEffect(() => {
    setRole(user.userProfile.userRole);
  }, [user.userProfile]);
  const dispatch = useDispatch();
  const [dealerCustomUrl, setDealerCustomUrl] = useState(''); //Custom URL para Mi negocio

  // const [showLogin, setShowLogin] = useState(false);
  const [anchorElUsers, setAnchorElUsers] = useState(null);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);
  const [showUserItems, setShowUserItems] = useState(false);
  const [showAdminItems, setShowAdminItems] = useState(false);
  // const [showAgencyItems, setShowAgencyItems] = useState(false);

  React.useEffect(() => {
    setRole(user.userProfile.userRole);
    const savedDealerCustomUrl = localStorage.getItem('dealerCustomUrl');

    if (
      user.userProfile.userRole === 2 &&
      user.userProfile.uuidUser !== undefined &&
      savedDealerCustomUrl === null // Solo llamar al endpoint si no hay dealerCustomUrl guardado
    ) {
      // Llamar a la API solo si el rol es 2 y uuid está definido
      const fetchDataDealerCustomUrl = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/Dealer/getDealer?uuid=${user.userProfile.uuidUser}`
          );
          if (response.status === 200) {
            // Si la respuesta es 200, obtener la customUrl y asignarla al estado dealerCustomUrl
            const { customUrl } = response.data;
            setDealerCustomUrl(customUrl);
            // Guardar la customUrl en localStorage
            localStorage.setItem('dealerCustomUrl', customUrl);
          }
        } catch (error) {
          console.error('Error al llamar a la API:', error);
        }
      };
      fetchDataDealerCustomUrl();
    } else if (savedDealerCustomUrl !== null) {
      // Usar la dealerCustomUrl guardada en localStorage
      setDealerCustomUrl(savedDealerCustomUrl);
    }
  }, [user.userProfile]);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const anchorOpen = Boolean(anchorElUsers);
  const openAdmin = Boolean(anchorElAdmin);

  const handleClickAdmin = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };
  const handleCloseAdmin = () => {
    setAnchorElAdmin(null);
  };
  const handleCloseUser = () => {
    setAnchorElUsers(null);
  };
  const logOut = () => {
    dispatch(cleanUserRequest());
    localStorage.clear();
  };

  const handleClickUser = (event) => {
    setAnchorElUsers(event.currentTarget);
  };

  const handleToggleUserItems = () => {
    setShowUserItems(!showUserItems);
  };

  const handleToggleAdminItems = () => {
    setShowAdminItems(!showAdminItems);
  };

  // const handleToggleAgencyItems = () => {
  //   setShowAgencyItems(!showAgencyItems);
  // };
  const appBar = (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Box className={classes.menu}>
        <Link className={classes.link} to="/en-venta">
          En venta
        </Link>
        <Link className={classes.link} to="/vender-mi-carro">
          Vender
        </Link>
        {user.loggedIn ? (
          // TODO: Revisar bug al presionar el menu
          // MUI: The Menu component doesn't accept a Fragment as a child.Consider providing an array instead.
          <div>
            <Button
              className={classes.menudropdown}
              id="basic-button"
              aria-controls={anchorOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={anchorOpen ? 'true' : undefined}
              onClick={handleClickUser}
            >
              <FaCarAlt style={{ fontSize: '18px' }} />
              {user.userProfile?.firstName}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElUsers}
              open={anchorOpen}
              onClose={handleCloseUser}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleCloseUser}>
                <Link className={classes.link} to="/mis-anuncios">
                  <GarageIcon />
                  Mis anuncios
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseUser}>
                <Link className={classes.link} to="/mi-perfil">
                  <PersonIcon />
                  Mi perfil
                </Link>
              </MenuItem>

              {role === 2 ? (
                <MenuItem onClick={handleCloseUser}>
                  <Link
                    className={classes.link}
                    to={`/vendedor/${dealerCustomUrl}`}
                  >
                    <SiGooglemybusiness />
                    Mi negocio
                  </Link>
                </MenuItem>
              ) : (
                <></>
              )}

              <MenuItem
                onClick={() => {
                  handleCloseUser();
                  logOut();
                }}
              >
                <Link className={classes.link} to="/">
                  <LogoutIcon /> Cerrar sesión
                </Link>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <Link
            className={classes.login}
            onClick={() => {
              props.onLogin(true);
            }}
          >
            Iniciar sesión
          </Link>
        )}
        {user.loggedIn && role === 3 ? (
          <div>
            <Button
              id="admin-button"
              aria-controls={openAdmin ? 'admin-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAdmin ? 'true' : undefined}
              onClick={handleClickAdmin}
            >
              <AdminPanelSettingsIcon
                sx={{ color: 'black' }}
                fontSize="medium"
              />
            </Button>
            <Menu
              id="admin-menu"
              anchorEl={anchorElAdmin}
              open={openAdmin}
              onClose={handleCloseAdmin}
              MenuListProps={{
                'aria-labelledby': 'admin-button',
              }}
            >
              <Link className={classes.link} to="/admin/car-review">
                <MenuItem onClick={handleCloseAdmin}>
                  <CarCrashIcon /> Carros pendientes
                </MenuItem>
              </Link>
              <Link className={classes.link} to="/admin/links">
                <MenuItem onClick={handleCloseAdmin}>
                  <FaCheckSquare /> Post tracker
                </MenuItem>
              </Link>
              <Link className={classes.link} to="/admin/VideoTracker">
                <MenuItem onClick={handleCloseAdmin}>
                  <IoMdVideocam /> Video Tracker
                </MenuItem>
              </Link>
              <Link className={classes.link} to="/admin/FreeToPremium">
                <MenuItem onClick={handleCloseAdmin}>
                  <MdWorkspacePremium /> Free To Premium
                </MenuItem>
              </Link>
              <Link className={classes.link} to="/admin/proxy">
                <MenuItem onClick={handleCloseAdmin}>
                  <ImMobile /> Proxy
                </MenuItem>
              </Link>
              <MenuItem onClick={handleCloseAdmin}>
                <ShowChartIcon />
                Estadísticas (WIP)
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleCloseAdmin}>
                <Link className={classes.link} to="/admin">
                  <AdminPanelSettingsIcon />
                  Todas las opciones
                </Link>
              </MenuItem>
            </Menu>
          </div>
        ) : undefined}
      </Box>
    </div>
  );

  const drawer = (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          my: 2,
        }}
        className={classes.nav_top}
      >
        <Link
          className={classes.link}
          to="/"
          onClick={() => {
            handleDrawerToggle();
          }}
        >
          <div className={classes.logo}>
            <img
              // TODO After release migrar a SVG, cuando se hace el logo bien bien
              src="https://autosnova.blob.core.windows.net/autosnova/logo_nav.webp"
              alt="Autos Nova Logo"
              loading="lazy"
            />
          </div>
        </Link>
        <div onClick={handleDrawerToggle} className={classes.icon}>
          <AiOutlineClose />
        </div>
      </Typography>
      <List className={classes.header_list}>
        <ListItem
          disablePadding
          className={classes.parentItem}
          onClick={handleDrawerToggle}
        >
          <Link className={classes.link} to="/vender-mi-carro">
            <div>Anunciar mi carro</div>
          </Link>
        </ListItem>
        <ListItem
          disablePadding
          className={classes.parentItem}
          onClick={handleDrawerToggle}
        >
          <Link to="/en-venta" className={classes.link}>
            <div>En venta</div>
          </Link>
        </ListItem>
        {user.loggedIn && role === 3 && (
          <ListItem
            className={classes.parentItem}
            disablePadding
            onClick={handleToggleAdminItems}
          >
            <div className={classes.link}>Admin </div>
            {!showAdminItems ? (
              <MdExpandMore style={{ fontSize: '20px' }} />
            ) : (
              <MdExpandLess style={{ fontSize: '20px' }} />
            )}
          </ListItem>
        )}
        {showAdminItems && user.loggedIn && role === 3 ? (
          <div>
            <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin/car-review">
                <CarCrashIcon /> Carros pendientes
              </Link>
            </ListItem>
            <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin/links">
                <FaCheckSquare /> Post tracker
              </Link>
            </ListItem>
            <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin/VideoTracker">
                <IoMdVideocam /> Video Tracker
              </Link>
            </ListItem>
            <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin/FreeToPremium">
                <MdWorkspacePremium /> Free To Premium
              </Link>
            </ListItem>
            <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin/proxy">
                <ImMobile /> Proxy
              </Link>
            </ListItem>
            {/* <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin/car-review">
                <ShowChartIcon />
                Estadísticas (WIP)
              </Link>
            </ListItem> */}
            <ListItem onClick={handleDrawerToggle} disablePadding>
              <Link className={classes.link} to="/admin">
                <AdminPanelSettingsIcon />
                Todas las opciones
              </Link>
            </ListItem>
          </div>
        ) : undefined}
        {user.loggedIn && (
          <ListItem
            className={classes.parentItem}
            disablePadding
            onClick={handleToggleUserItems}
          >
            <div className={classes.link}>
              {user.userProfile?.firstName ?? 'Usuario'}
            </div>
            {!showUserItems ? (
              <MdExpandMore style={{ fontSize: '20px' }} />
            ) : (
              <MdExpandLess style={{ fontSize: '20px' }} />
            )}
          </ListItem>
        )}
        {showUserItems && user.loggedIn ? (
          <div>
            {role === 2 ? (
              <div>
                {' '}
                <ListItem onClick={handleDrawerToggle} disablePadding>
                  <Link
                    className={classes.link}
                    to={`/vendedor/${dealerCustomUrl}`}
                  >
                    <SiGooglemybusiness />
                    Mi negocio
                  </Link>
                </ListItem>
              </div>
            ) : (
              <></>
            )}
            <ListItem disablePadding onClick={handleDrawerToggle}>
              <Link className={classes.link} to="/mis-anuncios">
                <GarageIcon /> Mis anuncios
              </Link>
            </ListItem>
            <ListItem disablePadding onClick={handleDrawerToggle}>
              <Link className={classes.link} to="/mi-perfil">
                <PersonIcon /> Mi perfil
              </Link>
            </ListItem>
            <ListItem
              disablePadding
              onClick={() => {
                handleDrawerToggle();
                handleCloseUser();
                logOut();
              }}
            >
              <Link className={classes.link} to={'/'}>
                <LogoutIcon />
                Cerrar sesión
              </Link>
            </ListItem>{' '}
          </div>
        ) : (
          <></>
        )}
        {!user.loggedIn && (
          <Link
            className={classes.login}
            onClick={() => {
              handleDrawerToggle();
              props.onLogin(true);
            }}
          >
            Iniciar Sesión
          </Link>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar color="transparent" component="nav">
        <Toolbar className={classes.headBar}>
          <Link to="/">
            <div className={classes.logo}>
              <img src={LogoNav} alt="Autos Nova Logo" loading="lazy" />
            </div>
          </Link>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{appBar}</Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
