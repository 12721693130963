import React from 'react';

const OfflineBanner = () => {
  const bannerStyle = {
    backgroundColor: 'red',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
    marginTop: '20px', // Add margin on the top
    position: 'relative',
  };

  const textAnimation = `
    @keyframes moveText {
      0% { transform: translateX(100%); }
      100% { transform: translateX(-100%); }
    }

    span.moveText {
      display: inline-block;
      animation: moveText 20s linear infinite;
    }
  `;

  return (
    <>
      <style>{textAnimation}</style> {/* Add the animation styles */}
      <div style={bannerStyle}>
        <span className="moveText">
          Actualmente estamos en mantenimiento programado
        </span>
      </div>
    </>
  );
};

export default OfflineBanner;
