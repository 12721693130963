import axios from 'axios';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import classes from './MyCars.module.css';
import { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import * as jwtService from '../helpers/jwtService';
import useTheme from '@mui/material/styles/useTheme';
import { Link, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getUserCarList } from '../store/UserCarsStore/userCarsActions';
import CarListContainer from '../components/CarListContainer/CarListContainer';
// import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import NeedToLogin from '../components/NeedToLogin/NeedToLogin';

const BASE_URL = process.env.REACT_APP_API_URL;
const token = jwtService.getTokenFromLocalStorage();

// function NotLoggedIn() {
//   return <NeedToLogin />;
// }
function MyCars({ getUserCars, userState, userCars, loading }) {
  const navigate = useNavigate();
  const [sinpeDetails, setSinpeDetails] = useState('');
  const [uuidUser, setUuidUser] = useState('');

  const getSinpeDetails = async () => {
    const response = await axios.get(`${BASE_URL}/api/User/GetSinpe`);
    setSinpeDetails(response.data);
  };

  useEffect(() => {
    const checkTokenAndFetchData = async () => {
      const token = jwtService.getTokenFromLocalStorage();
      if (token) {
        const tokenData = await jwtService.getTokenDataFromLocalStorage();
        if (tokenData) {
          setUuidUser(tokenData.uuidUser);
          // Llamar a getUserCars solo si hay token y uuidUser
          getUserCars(token, tokenData.uuidUser);
          // Llamar a la función para obtener detalles de Sinpe
          getSinpeDetails();
        } else {
          // Si no se puede obtener el tokenData, redirigir al usuario a /en-venta
          navigate('/en-venta');
        }
      } else {
        // Si no hay token, redirigir al usuario a /en-venta
        navigate('/en-venta');
      }
    };

    checkTokenAndFetchData();
  }, [navigate]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecta si la pantalla es móvil (menos de 600px)

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  function OnSaleCars({ cars, refreshUserCars }) {
    return (
      <div className={classes.carContainer}>
        <div className={classes.title}>
          <h4>Anuncios activos</h4>
        </div>
        <CarListContainer
          likeBtn={false}
          carList={cars}
          carStatus={'onSale'}
          sinpeDetails={sinpeDetails}
          refreshUserCars={refreshUserCars}
        />
      </div>
    );
  }

  function SoldCars({ cars, refreshUserCars }) {
    return (
      <div className={classes.carContainer}>
        <div className={classes.title}>
          <h4>Anuncios vendidos/inactivos</h4>
        </div>

        <CarListContainer
          likeBtn={false}
          carList={cars}
          carStatus={'sold'}
          sinpeDetails={sinpeDetails}
          refreshUserCars={refreshUserCars}
        />
      </div>
    );
  }

  function DraftCars({ cars, refreshUserCars }) {
    return (
      <div className={classes.carContainer}>
        <div className={classes.title}>
          <h4>Anuncios en borrador</h4>
        </div>
        <CarListContainer
          likeBtn={false}
          carList={cars}
          carStatus={'draft'}
          sinpeDetails={sinpeDetails}
          refreshUserCars={refreshUserCars}
        />
      </div>
    );
  }
  function OnReviewCars({ cars, refreshUserCars }) {
    return (
      <div className={classes.carContainer}>
        <div className={classes.title}>
          <h4>Anuncios en revisión</h4>
        </div>
        <p>Tu publicación será revisada dentro de poco.</p>

        <CarListContainer
          likeBtn={false}
          carList={cars}
          carStatus={'onReview'}
          sinpeDetails={sinpeDetails}
          refreshUserCars={refreshUserCars}
        />
      </div>
    );
  }

  const refreshUserCars = () => {
    const token = jwtService.getTokenFromLocalStorage();
    getUserCars(token, uuidUser);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana a la parte superior
  }, []); // El efecto se ejecuta una vez, cuando el componente se monta
  const currentURL = window.location.href;
  const onSaleCars = userCars
    ?.filter((car) => car.carStatusId === 1)
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  const onDraftCars = userCars
    ?.filter((car) => car.carStatusId === 3)
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  const onReviewCars = userCars
    ?.filter((car) => car.carStatusId === 5)
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  const soldCars = userCars
    ?.filter((car) => [2, 4, 7].includes(car.carStatusId))
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));

  const MobileSkeleton = () => (
    <div className={classes.skeletonMobile}>
      <Skeleton variant="rectangular" width="100%" height={60} />

      <Skeleton
        variant="rectangular"
        width="100%"
        height={190}
        style={{ marginTop: '15px' }}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start', // Alineación a la izquierda
        }}
      >
        <Skeleton
          variant="text"
          width="15%"
          height={40}
          style={{ marginRight: '15px' }}
        />{' '}
        <Skeleton variant="text" width="40%" height={40} />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start', // Alineación a la izquierda
        }}
      >
        <Skeleton
          variant="circular"
          width={20}
          height={20}
          style={{ marginLeft: '5px', marginTop: '3px' }}
        />
        <Skeleton
          variant="text"
          width="20%"
          height={20}
          style={{ marginLeft: '15px', marginTop: '3px' }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start', // Alineación a la izquierda
        }}
      >
        <Skeleton
          variant="circular"
          width={20}
          height={20}
          style={{ marginLeft: '5px', marginTop: '3px' }}
        />
        <Skeleton
          variant="text"
          width="43%"
          height={20}
          style={{ marginLeft: '15px', marginTop: '3px' }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start', // Alineación a la izquierda
        }}
      >
        <Skeleton
          variant="circular"
          width={20}
          height={20}
          style={{ marginLeft: '5px', marginTop: '3px' }}
        />
        <Skeleton
          variant="text"
          width="12%"
          height={20}
          style={{ marginLeft: '15px', marginTop: '3px' }}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start', // Alineación a la izquierda
        }}
      >
        <Skeleton
          variant="text"
          width="40%"
          height={40}
          style={{ marginRight: '15px' }}
        />{' '}
        <Skeleton variant="text" width="30%" height={40} />
      </div>
    </div>
  );

  if (loading) {
    return (
      <div>
        {isMobile ? (
          <div>
            <MobileSkeleton />
            <MobileSkeleton />
            <MobileSkeleton />
            <MobileSkeleton />
          </div>
        ) : (
          <div>
            <MobileSkeleton />
            {/* <CarDetailsLoader />
            <CarDetailsLoader /> */}
          </div>

          // <DesktopSkeleton /> // Componente de esqueleto para escritorio
        )}
      </div>
    );
  }

  // if (!userState.loggedIn) {
  //   return <NotLoggedIn />;
  // }

  if (userCars.length >= 1) {
    return (
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Mis Anuncios - Autos Nova</title>
            <meta name="description" content="Mis Anuncios - Autos Nova" />
          </Helmet>
        </HelmetProvider>
        <br />
        <h2 style={{ textAlign: 'start', width: '95%', margin: '0 auto' }}>
          Mis anuncios
        </h2>
        <br />
        {onSaleCars.length > 0 && (
          <OnSaleCars cars={onSaleCars} refreshUserCars={refreshUserCars} />
        )}
        {onDraftCars.length > 0 && (
          <DraftCars cars={onDraftCars} refreshUserCars={refreshUserCars} />
        )}
        {onReviewCars.length > 0 && (
          <OnReviewCars cars={onReviewCars} refreshUserCars={refreshUserCars} />
        )}
        {soldCars.length > 0 && (
          <SoldCars cars={soldCars} refreshUserCars={refreshUserCars} />
        )}
      </div>
    );
  }

  if (userState.loggedIn && userCars.length === 0) {
    return (
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Mis Anuncios - Autos Nova</title>
            <meta name="description" content="Mis Anuncios - Autos Nova" />
          </Helmet>
        </HelmetProvider>
        <br />
        <h2>Mis anuncios</h2>
        <br />
        <div>
          Aún no has creado ningún carro. Para publicar tu primer carro&nbsp;
          <Link
            to="/vender-mi-carro"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'underline',
              color: 'blue',
              fontWeight: 'bold',
            }}
          >
            visita esta página
          </Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserCars: (token, uuidUser) => dispatch(getUserCarList(token, uuidUser)),
  };
};

const mapStateToProps = (state) => {
  return {
    userState: state.UserProfile,
    userCars: state.UserCars.userCars,
    loading: state.UserCars.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCars);
