import Header from '../components/Header/Header';
import { useState, useEffect } from 'react';
import Login from '../components/Login/Login';
import classes from './Layer.module.css';
import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import Footer from '../components/Footer/Footer';

const Layer = (props) => {
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const handleUnload = () => {
      // Perform cleanup of local storage here
      localStorage.removeItem('filterParams'); // This will clear the entire local storage
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const showLoginModal = (show) => {
    setShowLogin(show);
  };

  return (
    <div className={classes.layer}>
      <Header onLogin={showLoginModal} />
      <Toaster />
      <br />
      {showLogin ? (
        <div className={classes.container}>
          <Login onClose={showLoginModal} showLogin={showLogin} title={'Regístrate'} />
        </div>
      ) : (
        <></>
      )}
      <div className={classes.body}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Layer;

