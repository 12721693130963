import React, { useRef, useState, useEffect } from 'react';
import Container from '../Container/Container';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../../components/Buttons/Button.js';
import { Link } from 'react-router-dom';
import classes from './CarReviewClients.module.css';
// import { BiEdit } from 'react-icons/bi';
// import Modal from '../Modal/Modal.js';
// import EditCar from '../../pages/EditCar/EditCar.js';
import { Divider } from '@mui/material';
import { BsCash } from 'react-icons/bs';
// import { teal } from '@mui/material/colors';
import { LuClipboard } from 'react-icons/lu';
import getToast from '../../helpers/getToast.js';
// import { getCarDetails } from '../../store/VehicleStore/vehicleActions.js';
import { useSelector } from 'react-redux';
import { IoLogoModelS } from 'react-icons/io';
import { MdOutlineInvertColors } from 'react-icons/md';
import { TbManualGearbox } from 'react-icons/tb';
import { IoIosTimer } from 'react-icons/io';
import { IoIdCard } from 'react-icons/io5';
import { AiOutlineBgColors } from 'react-icons/ai';
import { BsCarFront, BsFillFuelPumpFill } from 'react-icons/bs';
import {
  // GiCarDoor,
  GiSandsOfTime,
  GiElectric,
  // GiGearStick,
  // GiCarWheel,
} from 'react-icons/gi';
import axios from 'axios';
import { callConfetti, clearConfetti } from '../../helpers/confeti.js';

function CarReviewClients({
  getCarModels,
  carInfo,
  savedDetails,
  setShowSuccessMsg,
  plan,
  setSellingStep,
}) {
  const [sinpeDetails, setSinpeDetails] = useState({});
  const [model, setModel] = useState(false);
  const [brand, setBrand] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [carStyle, setCarStyle] = useState('');
  const [fuelType, setFuelType] = useState('');
  const textAreaRef = useRef(null);
  const phoneRef = useRef(null);
  // const [editedCarDetails, setEditedCarDetails] = useState(savedDetails);
  const uuidUser = useSelector(
    (state) => state.UserProfile.userProfile.uuidUser
  );

  const BASE_URL = process.env.REACT_APP_API_URL;
  // const reCaptchaKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
  const reCaptchaKey = '6LfHGv0nAAAAAGEIXSn011UIX0L9NpPUq0sUI1Jw';
  const [timeRemaining, setTimeRemaining] = useState(86400); // 24 horas en segundos

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, []);

  const formatTimeRemaining = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes}:${remainingSeconds}`;
  };

  const {
    brandSlug,
    modelId,
    year,
    kilometres,
    transmission,
    carPriceCrc,
    exteriorColor,
    interiorColor,
    kilometreType,
    miles,
    plate,
  } = savedDetails.details;

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear + 1; i >= 1960; i--) {
    years.push({ item: `${i}` });
  }

  // const transmissionOptions = [
  //   { item: 'Automático' },
  //   { item: 'Manual' },
  //   { item: 'Automático/Dual' },
  // ];

  const formatNumberWithCommas = (number) => {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const captcha = useRef(null);
  // const [captchaCompleted, setCaptchaCompleted] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(true);

  function onChange(value) {
    if (value) {
      setCaptchaCompleted(true);
    } else {
      setCaptchaCompleted(false);
    }
  }
  const callAnimation = () => {
    callConfetti();
    setTimeout(() => {
      clearConfetti();
    }, 5000);
  };
  const copyPhoneToClipboard = async (el) => {
    const value = phoneRef.current.textContent;
    try {
      await navigator.clipboard.writeText(value);
      getToast({ type: 'SUCCESS', text: 'Se copió correctamente' });
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };

  const copyToClipboard = async (el) => {
    const value = textAreaRef.current.textContent;
    try {
      await navigator.clipboard.writeText(value);
      getToast({ type: 'SUCCESS', text: 'Se copió correctamente' });
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };

  function onExpired() {
    setCaptchaCompleted(false);
  }

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/Car/AddCarUnderReview?uuidCar=${carInfo.createdCarUuid}&uuidUser=${uuidUser}&selectedPlan=${plan}`
      );
      if (response.status === 200) {
        setShowModal(true);
        window.scrollTo(0, 0);
        setTimeout(() => {
          callConfetti();
        }, 300);
        setSellingStep(5);
      } else {
        getToast({
          text: 'No se ha podido completar la solicitud. Por favor contacta a un administrador.',
          type: 'ERROR',
        });
      }
    } catch (ex) {
      getToast({
        text: 'No se ha podido completar la solicitud. Por favor contacta a un administrador.',
        type: 'ERROR',
      });
    }
  };

  const getSinpeDetails = async () => {
    const response = await axios.get(`${BASE_URL}/api/User/GetSinpe`);
    setSinpeDetails(response.data);
  };

  useEffect(() => {
    getSinpeDetails();

    let model = '';
    if (modelId === -1) {
      const item = savedDetails.newValues.filter(
        (el) => el.refColumn === 'modelId'
      )[0];
      model = item.value;
    } else {
      const item = carInfo.carModels.find((el) => el.id === modelId);
      model = item.model;
    }

    const { brand } = carInfo.carBrands.find(
      (el) => el.brandIdentifier === brandSlug
    );
    const { carType1: style } = carInfo.carStyles?.filter(
      (el) => el.idCarType === savedDetails.details.idCarType
    )[0];
    const { fuelType: fuel } = carInfo.engineOptions?.filter(
      (el) => el.id === savedDetails.details.idEngine
    )[0];

    setModel(model);
    setBrand(brand);
    setCarStyle(style);
    setFuelType(fuel);
  }, []);

  const planName = plan === 1 ? 'Gratis' : plan === 2 ? 'Estandar' : 'Premium';
  const planPrice = plan === 1 ? '0' : plan === 2 ? '5900' : '8900';
  const formattedPrice = Number(planPrice).toLocaleString();

  if (showModal) {
    return (
      <div className={classes.review}>
        <h2>¡Hemos terminado! 🥳</h2>
        <br />
        <p>
          En breve, uno de nuestros miembros revisará tu publicación. Te
          notificaremos por WhatsApp una vez que el anuncio esté publicado.
          Recuerda que si adquiriste la opción Premium, cuentas con 24 horas
          para completar el pago.
        </p>
        <Link style={{ textDecoration: 'none' }} to="/mis-anuncios">
          <Button>Ir a mis anuncios</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.reviewContainer}>
      <div className={classes.carReview}>
        <Container>
          <div className={classes.header}>
            <h2>Detalles del carro</h2>
            <div>
              {plate === '-1' ? (
                <>
                  <h3>Sin Placa</h3>
                  <p className={classes.gray}>
                    Has registrado un carro sin placa
                  </p>
                </>
              ) : (
                <>
                  <h3>Placa - {plate}</h3>
                  <p className={classes.gray}>
                    Solo se mostrará el último dígito de la placa
                  </p>
                </>
              )}
            </div>
          </div>
          <br />
          <div className={classes.container}>
            <div className={classes.item}>
              <p>
                <IoIdCard />
                {'  '}
                Marca: <strong>{brand}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <IoLogoModelS /> Modelo: <strong>{model} </strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <BsCash /> Precio:{' '}
                <strong>₡ {formatNumberWithCommas(carPriceCrc)}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <IoIosTimer className={classes.icon} />
                {kilometreType === 'Km' ? (
                  <>
                    Kilometraje:{' '}
                    <strong>{formatNumberWithCommas(kilometres)} Kms</strong>
                  </>
                ) : (
                  <>
                    Millaje:{' '}
                    <strong>{formatNumberWithCommas(miles)} Mis</strong>
                  </>
                )}
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <TbManualGearbox /> Transmisión: <strong>{transmission}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <BsCarFront className={classes.icon} /> Estilo:{' '}
                <strong>{carStyle}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <GiSandsOfTime className={classes.icon} /> Año:{' '}
                <strong>{year}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                {fuelType === 'Eléctrico' ? (
                  <>
                    <GiElectric className={classes.icon} />{' '}
                  </>
                ) : (
                  <>
                    <BsFillFuelPumpFill className={classes.icon} />{' '}
                  </>
                )}
                Combustible: <strong>{fuelType}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <AiOutlineBgColors className={classes.icon} /> Color exterior:{' '}
                <strong>{exteriorColor}</strong>
              </p>
              <Divider />
            </div>
            <div className={classes.item}>
              <p>
                <MdOutlineInvertColors className={classes.icon} /> Color
                interior: <strong>{interiorColor}</strong>
              </p>
              <Divider />
            </div>
          </div>
          <br />
          <p className={classes.gray}>
            Los datos mostrados a continuación serán verificados por nuestro
            equipo antes de publicar el carro. En caso de que necesites editar
            algun dato de tu anúncio, podras hacerlo una vez finalices la
            publicación.
          </p>
          {/* <div className={classes.captchaButton}>
            <ReCAPTCHA
              ref={captcha}
              sitekey={reCaptchaKey}
              onChange={onChange}
              onExpired={onExpired}
              hl="es"
            />
          </div> */}
        </Container>
      </div>
      <div className={classes.planContainer}>
        <Container>
          <div className={classes.planDetails}>
            <h2>Anuncio - {planName}</h2>
            {plan !== 1 ? (
              <>
                <Divider />
                <br />
                <h4>Detalles del pago</h4>
                <p>Por favor realiza el pago al siguiente número.</p>

                <ul className={classes.list}>
                  <li>
                    <p>Número de télefono:</p>
                    <p ref={phoneRef}>
                      {sinpeDetails?.phone}{' '}
                      <LuClipboard
                        onClick={copyPhoneToClipboard}
                        style={{ cursor: 'pointer' }}
                      />
                    </p>
                  </li>
                  <li>
                    <p>A nombre de:</p>
                    <p>{sinpeDetails?.phoneOwner} </p>
                  </li>
                  <li>
                    <p>Motivo SINPE:</p>
                    <p ref={textAreaRef}>
                      {plate === '-1'
                        ? `${carInfo.createdCarUuid} P`
                        : `${plate} ${planName}`}
                      <LuClipboard
                        onClick={copyToClipboard}
                        style={{ cursor: 'pointer' }}
                      />
                    </p>
                  </li>

                  <li>
                    <p>Tiempo para pagar:</p>
                    <p>{formatTimeRemaining(timeRemaining)}</p>
                    {/* <p>24 horas</p> */}
                  </li>
                </ul>
                <p>
                  <span style={{ color: '#000', fontWeight: 'bold' }}>
                    ¡Importante!
                  </span>
                  <br />
                  Por favor, asegúrate de ingresar el motivo del SINPE
                  exactamente como se muestra arriba. Esto contribuirá a
                  agilizar el proceso de verificación del pago.
                </p>
              </>
            ) : (
              <>
                <Divider />
                <br />
                <h4>Detalles del anuncio</h4> <br />
                <p>
                  <span style={{ color: '#000', fontWeight: 'bold' }}>
                    ¡Importante!
                  </span>
                  <br />
                  Has seleccionado el anuncio gratuito, sin embargo, si después
                  de publicar el carro deseas aumentar el alcance de tu
                  publicación, puedes cambiar el tipo de anuncio en cualquier
                  momento. Para esto, solo tienes que contactarnos por WhatsApp.
                </p>
                <br />
                <p>
                  Una vez finalices, un miembro de nuestro equipo revisará y
                  aprobará tu publicación. Se te notificará por WhatsApp.
                </p>
              </>
            )}
          </div>
          <br />
          <Divider />
          <div className={classes.price}>
            <h3>Total</h3>
            <h3>CRC {formattedPrice}</h3>
          </div>
        </Container>
        <Button onClick={handleSubmit}>Finalizar</Button>
      </div>
    </div>
  );
}

export default CarReviewClients;
