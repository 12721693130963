import { useEffect, useState } from 'react';
import classes from './Review.module.css'
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { styled } from '@mui/material/styles';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import Rating from '@mui/material/Rating';
import SmallButton from '../Buttons/SmallButton';

const iconFull = <StarRoundedIcon sx={{ fontSize: '25px' }} />

const iconEmpty = <StarBorderRoundedIcon sx={{ fontSize: '25px' }} />


function ReviewComponent({ review }) {

    const [open, setOpen] = useState(false);

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#3861fb',
        },
        '& .MuiRating-iconHover': {
            color: '#3861fb',
        },
    });

    const formattedDate = (date) => {
        const dateObject = new Date(date);
        const month = dateObject.getMonth() + 1; // Months are zero-indexed
        const year = dateObject.getFullYear();
        return `${month
            .toString()
            .padStart(2, '0')
            } del ${year}`;
    };


    const date = formattedDate(review.date)
    return <div className={classes.container}>
        <div className={classes.row}>
            <h5>Calificación general:</h5>
            <StyledRating size="large" value={review.overallRating} emptyIcon={iconEmpty} readOnly icon={iconFull} />
        </div>
        <p>{review.expierenceDesc}</p>
        <div className={classes.item}><span>Compró en esta agencia:</span><p>{review.didBuy ? 'Si' : 'No'}</p></div>
        <div className={classes.item}><span>Fecha de visita:</span><p>{date}</p></div>
        <div style={{ alignItems: 'flex-end', width: 'fit-content', alignItems: 'center', gap: '5px' }}>
            {!open ? <SmallButton onClick={() => { setOpen(true) }}><FaPlus /> Expandir</SmallButton> : <SmallButton onClick={() => { setOpen(false) }}><FaMinus /> Reducir</SmallButton>}

        </div>

        {open ?
            <div className={classes.reviews}>
                <h6>Calificaciones:</h6>
                <div className={classes.reviewItem}><span>Comunicación:</span><p>{review.communication}</p></div>
                <div className={classes.reviewItem}><span>Proceso de compra:</span><p>{review.processExp}</p></div>
                <div className={classes.reviewItem}><span>Transparencia del vendedor:</span><p>{review.transparency}</p></div>
                <div className={classes.reviewItem}><span>Entrega del auto:</span><p>{review.deliveryExp}</p></div>
            </div> : <></>}
    </div>

}


export default ReviewComponent;