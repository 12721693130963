import { useSelector } from 'react-redux';
import classes from './MobileCarFilter.module.css';
import { useEffect, useState, useRef } from 'react';
// import { TbAdjustmentsHorizontal } from 'react-icons/bs';
import { TbAdjustmentsHorizontal } from 'react-icons/tb';
// import { Slider } from '@mui/material';
// import { ConnectingAirportsOutlined } from '@mui/icons-material';
import CloseBtn from '../Buttons/CloseBtn';
// import { useSearchParams } from 'react-router-dom';
// import Modal from '../Modal/Modal';
import Accordion from '../Accordion/Accordion';
import Button from '../Buttons/Button';
import {
  KILOMETRES_FILTER,
  PRICE_FILTER,
  STYLE_FILTER,
  YEAR_FILTER,
  LOCATION_FILTER,
  TRANSMISSION_FILTER,
  FUEL_FILTER,
  UNIQUE_OWNER,
  CAR_MAKER,
  CAR_BRAND,
} from '../../helpers/filterTypes';
import DynamicAccordion from '../Accordion/AccordionDynamic';
import { FormControlLabel, Checkbox, Radio } from '@mui/material';
import PriceSlider from '../PriceSlider/PriceSlider';
import KilometresSlider from '../KilometresSlider/KilometresSlider';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
const CarFilter = ({
  getCanton,
  locationInfo,
  brands,
  carStyles,
  getModels,
  years,
  engineOptions,
  popularFeatures,
  handleResetFilter,
  updateFilter,
  activeFilters,
  handleResetQuickFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const filterContent = useRef(null);
  const [showFilter, setShowFilter] = useState(null);
  const [isSticky, setSticky] = useState(false);
  const [colorsSelected, setColorsSelected] = useState([]);
  const [isChecked, setIsChecked] = useState(0);
  const [carFeatures, setCarFeatures] = useState([]);

  const models = useSelector((state) => state.CarOptions.carModels);
  const handleFilterClick = (event) => {
    setIsOpen((prev) => {
      return !prev;
    });
  };

  const colors = [
    { item: 'Amarillo', hexColor: '#FFFF00' },
    { item: 'Azul', hexColor: '#0000FF' },
    { item: 'Blanco', hexColor: '#FFFFFF' },
    { item: 'Gris', hexColor: '#808080' },
    { item: 'Marrón', hexColor: '#A52A2A' },
    { item: 'Naranja', hexColor: '#FFA500' },
    { item: 'Negro', hexColor: '#000000' },
    { item: 'Plata', hexColor: '#C0C0C0' },
    { item: 'Rojo', hexColor: '#FF0000' },
    { item: 'Verde', hexColor: '#008000' },
    { item: 'Otro', hexColor: '#fefefe' }, // Replace with the hex value for 'Otro'
  ];

  const owners = [
    { description: 'No aplicar', value: 0 },
    { description: 'Único dueño', value: 1 },
  ];

  const transmissionOptions = [
    { item: 'Automático' },
    { item: 'Manual' },
    { item: 'Dual' },
  ];

  const provinciasList = [
    { value: 1, name: 'San José' },
    { value: 2, name: 'Alajuela' },
    { value: 3, name: 'Cartago' },
    { value: 4, name: 'Heredia' },
    { value: 5, name: 'Guanacaste' },
    { value: 6, name: 'Puntarenas' },
    { value: 7, name: 'Limón' },
  ];

  const doors = [2, 3, 4];

  const passengers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const theme = {
    fontWeight: '600',
    display: 'inline-block',
    width: '100%',
    textAlign: 'start',
    fontSize: '14px !important',
    margin: '0px',
  };

  const executeFilter = (callBack) => {
    setShowFilter(false);
    setIsOpen((prev) => {
      return !prev;
    });
    if (callBack) {
      callBack();
    }
  };

  const handleProvinciaChange = (e) => {
    getCanton(e.target.value);
    updateFilter({ idProvincia: e.target.value, idCanton: '0' });
  };
  // console.log(activeFilters)
  const handleBrandChange = (e) => {
    if (e.target.value !== '') {
      updateFilter({ brandSlug: e.target.value });
      getModels(e.target.value);
    } else {
      updateFilter({ brandSlug: e.target.value, model: 0 });
      getModels(0);
    }
  };

  const handleFilterUpdate = (alreadyClosed = false) => {
    setIsOpen((prev) => !prev);
  };

  const handleModalClick = (e) => {
    const component = e.target.id;
    if (component === 'preview') {
      setShowFilter(false);
    }
  };

  const handleOwner = (event) => {
    let value = false;
    value = +event.target.value === 1 ? true : false; // Convierte el valor a booleano directamente
    setIsChecked(+event.target.value);
    updateFilter({ uniqueOwner: value });
  };

  const handleColorCheck = (event) => {
    let colorList = [];
    const newColor = event.target.value;
    const alreadyInList = colorsSelected.some((el) => el === newColor);

    if (alreadyInList) {
      colorList = colorsSelected.filter((el) => el !== newColor);
    } else {
      colorList = [...colorsSelected, event.target.value];
    }
    updateFilter({ color: colorList });
    setColorsSelected(colorList);
  };

  const resetOneFilter = (keyName) => {
    handleResetQuickFilter(keyName);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Set the state based on the scroll position
      setSticky(window.scrollY > 160);
    };
    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const componentToRender = (optionSelected) => {
    switch (optionSelected) {
      case PRICE_FILTER:
        return (
          <PriceSlider
            button={true}
            setShowFilter={setShowFilter}
            updateFilter={updateFilter}
            filterParams={activeFilters}
            resetOneFilter={resetOneFilter}
          />
        );
      case KILOMETRES_FILTER:
        return (
          <KilometresSlider
            button={true}
            setShowFilter={setShowFilter}
            updateFilter={updateFilter}
            filterParams={activeFilters}
            resetOneFilter={resetOneFilter}
          />
        );
      case LOCATION_FILTER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Provincia'}
            resetOneFilter={resetOneFilter}
            filter={'idProvincia'}
          >
            <select
              value={activeFilters.idProvincia}
              onChange={handleProvinciaChange}
            >
              <option value={0}>Todas</option>
              {provinciasList.map((el) => {
                return (
                  <option value={el.value} key={el.value}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </FilterDropdown>
        );
      case CAR_MAKER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Marca'}
            resetOneFilter={(param) => {
              resetOneFilter([...param, 'model'])
            }}
            filter={'brandSlug'}
          >
            <select
              value={activeFilters.brandSlug ?? ''}
              onChange={(e) => {
                updateFilter({ brandSlug: e.target.value });
                handleBrandChange(e);
              }}
            >
              <option value={''}>Todos</option>
              {brands ? (
                brands.map((el) => {
                  return (
                    <option value={el.brandIdentifier} key={el.brandIdentifier}>
                      {el.brand}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </FilterDropdown>
        );
      case CAR_BRAND:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Modelo'}
            resetOneFilter={resetOneFilter}
            filter={'model'}
          >
            <select
              value={activeFilters.model ?? ''}
              onChange={(e) => {
                updateFilter({ model: e.target.value });
              }}
            >
              <option value={''}>Todos</option>
              {models ? (
                models.map((el) => {
                  return (
                    <option value={el.id} key={el.id}>
                      {el.model}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </FilterDropdown>
        );
      case STYLE_FILTER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Estilo'}
            resetOneFilter={resetOneFilter}
            filter={'type'}
          >
            <select
              value={activeFilters.type ?? 0}
              onChange={(e) => {
                updateFilter({ type: e.target.value });
              }}
            >
              <option value={0}>Todos</option>
              {carStyles ? (
                carStyles.map((el) => {
                  return (
                    <option value={el.idCarType} key={el.idCarType}>
                      {el.carType1}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </FilterDropdown>
        );
      case YEAR_FILTER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Año'}
            resetOneFilter={resetOneFilter}
            filter={['initialYear', 'limitYear']}
          >
            <select
              value={activeFilters.initialYear ?? 0}
              onChange={(e) => {
                updateFilter({ initialYear: e.target.value });
              }}
            >
              <option value={0}>Min</option>
              {years.map((el) => {
                return (
                  <option value={el.item} key={el.item}>
                    {el.item}
                  </option>
                );
              })}
            </select>
            <select
              value={activeFilters.limitYear ?? 0}
              onChange={(e) => {
                updateFilter({ limitYear: e.target.value });
              }}
            >
              <option value={0}>Max</option>
              {years.map((el) => {
                return (
                  <option value={el.item} key={el.item}>
                    {el.item}
                  </option>
                );
              })}
            </select>
          </FilterDropdown>
        );
      case FUEL_FILTER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Combustible'}
            resetOneFilter={resetOneFilter}
            filter={'fuelType'}
          >
            <select
              value={activeFilters.fuelType ?? 0}
              onChange={(e) => {
                updateFilter({ fuelType: e.target.value });
              }}
            >
              <option value={0}>Todos</option>
              {engineOptions ? (
                engineOptions.map((el) => {
                  return (
                    <option value={el.id} key={el.id}>
                      {el.fuelType}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </FilterDropdown>
        );
      case UNIQUE_OWNER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Dueños'}
            resetOneFilter={(param) => {
              setIsChecked(0);
              resetOneFilter(param);
            }}
            filter={'uniqueOwner'}
          >
            <ul>
              {owners.map((item) => {
                return (
                  <li className={classes.colorItem} key={item.id}>
                    <FormControlLabel
                      key={item.id}
                      checked={isChecked === item.value}
                      style={theme}
                      onChange={handleOwner}
                      value={item.value}
                      control={<Radio />}
                      label={item.description}
                      labelPlacement="start"
                    />
                  </li>
                );
              })}
            </ul>
          </FilterDropdown>
        );
      case TRANSMISSION_FILTER:
        return (
          <FilterDropdown
            setShowFilter={setShowFilter}
            filterParams={activeFilters}
            title={'Transmisión'}
            resetOneFilter={resetOneFilter}
            filter={'transmission'}
          >
            <select
              value={activeFilters.transmission ?? ''}
              onChange={(e) => {
                updateFilter({ transmission: e.target.value });
              }}
            >
              <option value={''}>Todos</option>
              {transmissionOptions.map((el) => {
                return (
                  <option value={el.item} key={el.item}>
                    {el.item}
                  </option>
                );
              })}
            </select>
          </FilterDropdown>
        );
      default:
        break;
    }
  };

  let easyFilter = showFilter ? componentToRender(showFilter) : null;

  const content = isOpen ? (
    <div
      className={`${classes.container} ${isOpen ? classes.containerOpen : ''}`}
    >
      <div className={classes.header}>
        <CloseBtn onClick={(el) => handleFilterClick(el)} />
      </div>
      <div className={classes.buttonList}>
        <Button
          className={classes.clearFilter}
          onClick={() => executeFilter(handleResetFilter)}
        >
          Limpiar filtro
        </Button>
        <Button className={classes.applyFilter} onClick={handleFilterUpdate}>
          Aplicar filtro
        </Button>
      </div>
      <div className={classes.filter}>
        <div className={classes.filterColumn}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h4>Ubicación</h4>
          </div>

          <div className={classes.locationItems}>
            <div>
              <label>Provincia</label>
              <select
                value={activeFilters.idProvincia ?? 0}
                onChange={handleProvinciaChange}
              >
                <option value={0}>Todas</option>
                {provinciasList.map((el) => {
                  return (
                    <option value={el.value} key={el.value}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {locationInfo.cantonesList &&
            locationInfo.cantonesList.length >= 1 ? (
              <div>
                <label>Cantón</label>
                <select
                  value={activeFilters.idCanton ?? 0}
                  onChange={(e) => {
                    updateFilter({ idCanton: e.target.value });
                  }}
                >
                  <option value={0}>Todos</option>
                  {locationInfo.cantonesList.map((el) => {
                    return (
                      <option value={el.idCanton} key={el.idCanton}>
                        {el.canton}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <div>
                <label>Cantón</label>
                <select disabled>
                  <option value={0}>Todos</option>
                </select>
              </div>
            )}
          </div>
        </div>
        <div className={classes.separator}></div>
        <div className={classes.filterOption}>
          <label>Marca</label>
          <select
            value={activeFilters.brandSlug ?? ''}
            onChange={(e) => {
              handleBrandChange(e);
            }}
          >
            <option value={''}>Todos</option>
            {brands ? (
              brands.map((el) => {
                return (
                  <option value={el.brandIdentifier} key={el.brandIdentifier}>
                    {el.brand}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Modelo</label>
          <select
            value={activeFilters.model ?? 0}
            onChange={(e) => {
              updateFilter({ model: e.target.value });
            }}
          >
            <option value={0}>Todos</option>
            {models ? (
              models.map((el) => {
                return (
                  <option value={el.id} key={el.id}>
                    {el.model}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Estilo</label>
          <select
            value={activeFilters.type ?? 0}
            onChange={(e) => {
              updateFilter({ type: e.target.value });
            }}
          >
            <option value={0}>Todos</option>
            {carStyles ? (
              carStyles.map((el) => {
                return (
                  <option value={el.idCarType} key={el.idCarType}>
                    {el.carType1}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Año</label>
          <select
            value={activeFilters.initialYear ?? 0}
            onChange={(e) => {
              updateFilter({ initialYear: e.target.value });
            }}
          >
            <option value={0}>Min</option>
            {years.map((el) => {
              return (
                <option value={el.item} key={el.item}>
                  {el.item}
                </option>
              );
            })}
          </select>
          <select
            value={activeFilters.limitYear ?? 0}
            onChange={(e) => {
              updateFilter({ limitYear: e.target.value });
            }}
          >
            <option value={0}>Max</option>
            {years.map((el) => {
              return (
                <option value={el.item} key={el.item}>
                  {el.item}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.separator}></div>
        <div className={classes.rangeFilter}>
          <PriceSlider
            updateFilter={updateFilter}
            handleFilterUpdate={handleFilterUpdate}
            filterParams={activeFilters}
          />
        </div>
        <div className={classes.rangeFilter}>
          <KilometresSlider
            updateFilter={updateFilter}
            handleFilterUpdate={handleFilterUpdate}
            filterParams={activeFilters}
          />
        </div>
        <div className={classes.separator}></div>
        <div className={classes.filterOption}>
          <label>Transmisión</label>
          <select
            value={activeFilters.transmission ?? ''}
            onChange={(e) => {
              updateFilter({ transmission: e.target.value });
            }}
          >
            <option value={''}>Todos</option>
            {transmissionOptions.map((el) => {
              return (
                <option value={el.item} key={el.item}>
                  {el.item}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Combustible</label>
          <select
            value={activeFilters.fuelType ?? 0}
            onChange={(e) => {
              updateFilter({ fuelType: e.target.value });
            }}
          >
            <option value={0}>Todos</option>
            {engineOptions ? (
              engineOptions.map((el) => {
                return (
                  <option value={el.id} key={el.id}>
                    {el.fuelType}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Asientos</label>
          <select
            value={activeFilters.passengers ?? 0}
            onChange={(e) => {
              updateFilter({ passengers: e.target.value });
            }}
          >
            <option value={0}>Todos</option>
            {passengers.map((el) => {
              return (
                <option value={el} key={el}>
                  {el}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classes.filterOption}>
          <label>Puertas</label>
          <select
            value={activeFilters.doors ?? 0}
            onChange={(e) => {
              updateFilter({ doors: e.target.value });
            }}
          >
            <option value={0}>Todos</option>
            {doors.map((el) => {
              if (el === 4) {
                return (
                  <option value={el} key={el}>
                    4 o más
                  </option>
                );
              }
              return (
                <option value={el} key={el}>
                  {el}
                </option>
              );
            })}
          </select>
        </div>
        <DynamicAccordion title={'Dueños'}>
          <ul>
            {owners.map((item) => {
              return (
                <li className={classes.colorItem} key={item.id}>
                  <FormControlLabel
                    key={item.id}
                    checked={isChecked === item.value}
                    style={theme}
                    onChange={handleOwner}
                    value={item.value}
                    control={<Radio />}
                    label={item.description}
                    labelPlacement="start"
                  />
                </li>
              );
            })}
          </ul>
        </DynamicAccordion>
        <Accordion
          items={popularFeatures}
          updateFeatures={(items) => {
            updateFilter({ popularFeatures: items });
          }}
          carFeatures={carFeatures}
          setCarFeatures={setCarFeatures}
        />
        <DynamicAccordion title={'Color exterior'}>
          <ul>
            {colors.map((color) => {
              return (
                <li className={classes.colorItem}>
                  <FormControlLabel
                    style={theme}
                    onChange={handleColorCheck}
                    value={color.item}
                    control={<Checkbox />}
                    label={color.item}
                    labelPlacement="start"
                    key={color.item}
                  />
                  <span
                    className={classes.colorDisplayer}
                    style={{ backgroundColor: `${color.hexColor}` }}
                  ></span>
                </li>
              );
            })}
          </ul>
        </DynamicAccordion>
      </div>
    </div>
  ) : (
    <div
      className={`${classes.barContainer} ${isSticky ? classes.sticky : ''}`}
    >
      <div className={classes.filterContainer}>
        <ul className={classes.filterList}>
          <li
            onClick={(e) => handleFilterClick(e)}
            className={`${classes.listItem} ${classes.filtroBtn}`}
          >
            <TbAdjustmentsHorizontal className={classes.icon} />
            <span>Filtros</span>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.initialPrice || activeFilters?.limitPrice
                ? classes.active
                : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== PRICE_FILTER
                  ? setShowFilter(PRICE_FILTER)
                  : setShowFilter(false);
              }}
            >
              Precio
            </button>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.brandSlug ? classes.active : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== CAR_MAKER
                  ? setShowFilter(CAR_MAKER)
                  : setShowFilter(false);
              }}
            >
              Marca
            </button>
          </li>

          {activeFilters?.brandSlug && ( // Solo mostrar si hay algo seleccionado en la marca
            <li
              className={`${classes.listItem} ${
                activeFilters?.model ? classes.active : ''
              }`}
            >
              <button
                onClick={() => {
                  return showFilter !== CAR_BRAND //CAMBIAR
                    ? setShowFilter(CAR_BRAND)
                    : setShowFilter(false);
                }}
              >
                Modelo
              </button>
            </li>
          )}

          <li
            className={`${classes.listItem} ${
              activeFilters?.initialYear || activeFilters?.limitYear
                ? classes.active
                : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== YEAR_FILTER
                  ? setShowFilter(YEAR_FILTER)
                  : setShowFilter(false);
              }}
            >
              Año
            </button>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.minKilometres || activeFilters?.maxKilometres
                ? classes.active
                : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== KILOMETRES_FILTER
                  ? setShowFilter(KILOMETRES_FILTER)
                  : setShowFilter(false);
              }}
            >
              Kilometraje
            </button>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.uniqueOwner === true ? classes.active : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== UNIQUE_OWNER
                  ? setShowFilter(UNIQUE_OWNER)
                  : setShowFilter(false);
              }}
            >
              Dueños
            </button>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.type ? classes.active : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== STYLE_FILTER
                  ? setShowFilter(STYLE_FILTER)
                  : setShowFilter(false);
              }}
            >
              Estilo
            </button>
          </li>

          <li
            className={`${classes.listItem} ${
              activeFilters?.idProvincia ? classes.active : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== LOCATION_FILTER
                  ? setShowFilter(LOCATION_FILTER)
                  : setShowFilter(false);
              }}
            >
              Provincia
            </button>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.transmission ? classes.active : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== TRANSMISSION_FILTER
                  ? setShowFilter(TRANSMISSION_FILTER)
                  : setShowFilter(false);
              }}
            >
              Transmisión
            </button>
          </li>
          <li
            className={`${classes.listItem} ${
              activeFilters?.fuelType ? classes.active : ''
            }`}
          >
            <button
              onClick={() => {
                return showFilter !== FUEL_FILTER
                  ? setShowFilter(FUEL_FILTER)
                  : setShowFilter(false);
              }}
            >
              Combustible
            </button>
          </li>
          <li className={classes.listItem}>
            <button
              onClick={() => {
                handleResetFilter();
                setIsChecked(0);
                setShowFilter(false);
              }}
            >
              Limpiar filtros
            </button>
          </li>
        </ul>
      </div>
      {showFilter ? (
        <div
          id="preview"
          className={classes.filterPreview}
          onClick={handleModalClick}
        >
          <div className={classes.filterPreviewContent} ref={filterContent}>
            {easyFilter}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  return <div>{content}</div>;
};

export default CarFilter;
