import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import classes from './Stepper.module.css';

function Stepper({ args, items = [], carList = false, carDetails }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    if (carList || carDetails) {
      let code = item.split('/');
      code = code[code.length - 1];
      return (
        <CarouselItem
          slide={false}
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item}
        >
          <div
            className={
              carDetails ? classes.bigContainer : classes.smallImgContainer
            }
          >
            <img
              src={require(`../../images/userImages/${code}`)}
              alt="imagen de carro"
            />
          </div>
        </CarouselItem>
      );
    } else {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={item}
        >
          <div className={classes.imgContainer}>
            <img src={item} alt="imagen de carro" />
          </div>
        </CarouselItem>
      );
    }
  });

  return (
    <Carousel
      dark
      slide={false}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

Carousel.propTypes = {
  slide: false,
};
CarouselItem.propTypes = {
  slide: false,
};

export default Stepper;
