import {
  GET_GENERAL_STATS_START,
  GET_GENERAL_STATS_SUCCESS,
  GET_GENERAL_STATS_FAILURE,
  GET_CARS_IN_REVIEW_START,
  GET_CARS_IN_REVIEW_SUCCESS,
  GET_CARS_IN_REVIEW_FAILURE,
  UPDATE_CAR_IN_REVIEW_STATUS_START,
  UPDATE_CAR_IN_REVIEW_STATUS_SUCCESS,
  UPDATE_CAR_IN_REVIEW_STATUS_FAILURE,
  GET_CAR_DETAILS_IN_REVIEW_START,
  GET_CAR_DETAILS_IN_REVIEW_SUCCESS,
  GET_CAR_DETAILS_IN_REVIEW_FAILURE,
} from './adminTypes';

const initialState = {
  loading: false,
  error: '',
  successMsg: '',
  generalStats: {},
  carListInReview: [],
  carDetailsInReview: null,
};
const getGeneralStatsStart = (state, action) => {
  return { ...state, loading: true };
};

const getGeneralStatsSuccess = (state, action) => {
  return { ...state, loading: false, generalStats: action.payload };
};

const getGeneralStatsFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCarsInReviewStart = (state, action) => {
  return { ...state, loading: true };
};

const getCarsInReviewSuccess = (state, action) => {
  return { ...state, loading: false, carListInReview: action.payload };
};

const getCarsInReviewFailure = (state, action) => {
  return { ...state, loading: false };
};

const updateCarInReviewStatusStart = (state, action) => {
  return { ...state };
};

const updateCarInReviewStatusSuccess = (state, action) => {
  return { ...state, carListInReview: action.payload };
};

const updateCarInReviewStatusFailure = (state, action) => {
  return { ...state };
};

const getCarDetailsInReviewStart = (state, action) => {
  return { ...state };
};

const getCarDetailsInReviewSuccess = (state, action) => {
  return { ...state, carDetailsInReview: action.payload };
};

const getCarDetailsInReviewFailure = (state, action) => {
  return { ...state };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_STATS_START:
      return getGeneralStatsStart(state, action);
    case GET_GENERAL_STATS_SUCCESS:
      return getGeneralStatsSuccess(state, action);
    case GET_GENERAL_STATS_FAILURE:
      return getGeneralStatsFailure(state, action);
    case GET_CARS_IN_REVIEW_START:
      return getCarsInReviewStart(state, action);
    case GET_CARS_IN_REVIEW_SUCCESS:
      return getCarsInReviewSuccess(state, action);
    case GET_CARS_IN_REVIEW_FAILURE:
      return getCarsInReviewFailure(state, action);
    case UPDATE_CAR_IN_REVIEW_STATUS_START:
      return updateCarInReviewStatusStart(state, action);
    case UPDATE_CAR_IN_REVIEW_STATUS_SUCCESS:
      return updateCarInReviewStatusSuccess(state, action);
    case UPDATE_CAR_IN_REVIEW_STATUS_FAILURE:
      return updateCarInReviewStatusFailure(state, action);
    case GET_CAR_DETAILS_IN_REVIEW_START:
      return getCarDetailsInReviewStart(state, action);
    case GET_CAR_DETAILS_IN_REVIEW_SUCCESS:
      return getCarDetailsInReviewSuccess(state, action);
    case GET_CAR_DETAILS_IN_REVIEW_FAILURE:
      return getCarDetailsInReviewFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
