// import { isDOMComponent } from 'react-dom/test-utils';
import {
  GET_USER_CARS_START,
  GET_USER_CARS_SUCCESS,
  GET_USER_CARS_FAILURE,
} from './userCarsTypes';
import axios from 'axios';
// import { cardActionAreaClasses } from '@mui/material';

const baseUrl = process.env.REACT_APP_API_URL;


const getUserCarsStart = () => {
  return { type: GET_USER_CARS_START };
};

const getUserCarsSuccess = (data) => {
  return { type: GET_USER_CARS_SUCCESS, payload: data };
};

const getUserCarsFailure = (err) => {
  return { type: GET_USER_CARS_FAILURE, payload: err };
};

export const getUserCarList = (token, uuidUser) => (dispatch) => {
  dispatch(getUserCarsStart());
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/Car/GetAllCarsForSpecificUser?uuidUser=${uuidUser}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .request(config)
    .then((response) => {
      dispatch(getUserCarsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(getUserCarsFailure(error));
    });
};
