import React, { useState } from 'react';
import classes from './AdminFreeToPremiumComponent.module.css';
import Switch from '@mui/material/Switch';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;
const BASE_URL = process.env.REACT_APP_API_URL;
const token = getTokenFromLocalStorage();

const AdminFreeToPremiumComponent = ({ car }) => {
  // Inicializamos el estado plan con 1 o 3 según el valor de car.plan
  const [plan, setPlan] = useState(car.planId === 3 ? true : false);

  const handleToggle = async () => {
    // Cambiamos entre 1 y 3 según el estado actual de plan
    const newStatus = plan ? 1 : 3;

    try {
      const apiUrl = `${BASE_URL}/api/Admin/UpdateCarPlan?idCar=${car.idCar}&newStatus=${newStatus}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        console.log('Estado del plan actualizado correctamente.');
        // Cambiamos el estado local del plan
        setPlan(!plan);
      } else {
        console.error('Error al actualizar el estado del plan.');
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  const formatApproveDate = (approveDate) => {
    const date = new Date(approveDate);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate}`;
  };

  return (
    <div className={classes.linkApprover}>
      <div className={classes.carInfo}>
        <h2 className={classes.carTitle}>
          {car.brandName} {car.modelName} {car.year}
        </h2>
        <p className={classes.carId}>{formatApproveDate(car.approveDate)}</p>
        <p className={classes.carId}>
          {car.idCar} | {car.uuidCar}
        </p>
        <p className={classes.carId}>{car.email}</p>
        <div className={classes.imageContainer}>
          <a
            href={`/carro/${car.uuidCar}`}
            className={classes.imageContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${BLOB_STORAGE_URL}/CAR-${car.uuidCar}-IMG-1.jpeg`}
              alt="Car"
              className={classes.carImage}
            />
          </a>
        </div>
      </div>
      <div className={classes.checkboxes}>
        <div className={classes.toggle}>
          <span>Gratis | Premium</span>
        </div>
      </div>
      <Switch checked={plan} onChange={handleToggle} />
    </div>
  );
};

export default AdminFreeToPremiumComponent;
