import {
  GET_USER_CARS_START,
  GET_USER_CARS_SUCCESS,
  GET_USER_CARS_FAILURE,
} from './userCarsTypes';

const initialState = {
  loading: false,
  error: '',
  successMsg: '',
  userCars: [],

};

const getUserCarsStart = (state, action) => {
  return { ...state, loading: true };
};

const getUserCarsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    userCars: action.payload,
  };
};
const getUserCarsFailure = (state, action) => {
  return { ...state, loading: false };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CARS_START:
      return getUserCarsStart(state, action);

    case GET_USER_CARS_SUCCESS:
      return getUserCarsSuccess(state, action);

    case GET_USER_CARS_FAILURE:
      return getUserCarsFailure(state, action);

    default:
      return state;
  }
};

export default reducer;
