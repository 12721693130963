import { combineReducers } from 'redux';
import userReducer from './UserStore/userReducer';
import carListReducer from './VehicleStore/vehicleReducer';
import userCarsReducer from './UserCarsStore/userCarsReducer';
import locationReducer from './LocationStore/locationReducer';
import dealerReducer from './DealerStore/DealerReducer';
import adminReducer from './AdminStore/adminReducer';

const rootReducer = combineReducers({
  UserProfile: userReducer,
  UserCars: userCarsReducer,
  CarOptions: carListReducer,
  LocationInfo: locationReducer,
  AdminReducer: adminReducer,
  DealerReducer: dealerReducer,
});

export default rootReducer;
