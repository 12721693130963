import {
  GET_USED_CARLIST_START,
  GET_USED_CARLIST_SUCCESS,
  GET_USED_CARLIST_FAILURE,
  CREATE_CAR_START,
  CREATE_CAR_SUCCESS,
  CREATE_CAR_FAILURE,
  GET_CAR_MODELS_START,
  GET_CAR_MODELS_SUCCESS,
  GET_CAR_MODELS_FAILURE,
  GET_CAR_BRANDS_START,
  GET_CAR_BRANDS_SUCCESS,
  GET_CAR_BRANDS_FAILURE,
  GET_CAR_BY_PLATE_START,
  GET_CAR_BY_PLATE_SUCCESS,
  GET_CAR_BY_PLATE_FAILURE,
  GET_CAR_STYLES_START,
  GET_CAR_STYLES_SUCCESS,
  GET_CAR_STYLES_FAILURE,
  GET_ENGINE_OPTIONS_START,
  GET_ENGINE_OPTIONS_SUCCESS,
  GET_ENGINE_OPTIONS_FAILURE,
  GET_FEATURE_LIST_START,
  GET_FEATURE_LIST_SUCCESS,
  GET_FEATURE_LIST_FAILURE,
  ADD_CAR_FEATURES_START,
  ADD_CAR_FEATURES_SUCCESS,
  ADD_CAR_FEATURES_FAILURE,
  ADD_CAR_PICTURES_START,
  ADD_CAR_PICTURES_SUCCESS,
  ADD_CAR_PICTURES_FAILURE,
  GET_CAR_DETAILS_START,
  GET_CAR_DETAILS_SUCCESS,
  GET_CAR_DETAILS_FAILURE,
  MAKE_CAR_INACTIVE_START,
  MAKE_CAR_INACTIVE_SUCCESS,
  MAKE_CAR_INACTIVE_FAILURE,
  SET_CAR_LIST_PAGE,
  SET_CAR_ORDER,
  GET_CAR_VIEWS_START,
  GET_CAR_VIEWS_SUCCESS,
  GET_CAR_VIEWS_FAILURE,
  EDIT_CAR_START,
  EDIT_CAR_SUCCESS
  , EDIT_CAR_FAILURE,
  DELETE_DRAFT_START,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAILURE
} from './vehicleTypes';

const initialState = {
  loading: false,
  error: '',
  successMsg: '',
  carList: [],
  carDetails: {},
  loggedIn: false,
  carModels: [],
  carBrands: [],
  carStyles: [],
  engineOptions: [],
  carFeaturesList: [],
  createdCarId: 0,
  createdCarUuid: '',
  carCount: 0,
  carPage: 1,
  orderBy: 0
};

const getCarListStart = (state, action) => {
  return { ...state, loading: true };
};

const getCarListSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carList: action.payload.carList,
    carCount: action.payload.total,

  };
};

const getCarListFailure = (state, action) => {
  return { ...state, loading: false };
};

const createCarStart = (state, action) => {
  return { ...state, loading: true };
};

const createCarSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    createdCarUuid: action.payload.carUuid,
    createdCarId: action.payload.carId,
  };
};

const createCarFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCarModelsStart = (state, action) => {
  return { ...state };
};

const getCarModelsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carModels: action.payload,
  };
};

const getCarModelsFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCarBrandsStart = (state, action) => {
  return { ...state, loading: false };
};

const getCarBrandsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carBrands: action.payload,
  };
};

const getCarBrandsFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCarByPlateStart = (state, action) => {
  return { ...state, loading: true };
};

const getCarByPlateSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carDetails: action.payload[0],
    plan: action.payload[1]
  };
};

const getCarByPlateFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCarStylesStart = (state, action) => {
  return { ...state, loading: false };
};

const getCarStylesSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carStyles: action.payload,
  };
};

const getCarStylesFailure = (state, action) => {
  return { ...state, loading: false };
};

const getEngineOptionsStart = (state, action) => {
  return { ...state, loading: false };
};

const getEngineOptionsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    engineOptions: action.payload,
  };
};

const getEngineOptionsFailure = (state, action) => {
  return { ...state, loading: false };
};

const getFeatureListStart = (state, action) => {
  return { ...state, loading: false };
};

const getFeatureListSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carFeaturesList: action.payload,
  };
};

const getFeatureListFailure = (state, action) => {
  return { ...state, loading: false };
};

const addCarFeaturesStart = (state, action) => {
  return { ...state, loading: true };
};

const addCarFeaturesSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const addCarFeaturesFailure = (state, action) => {
  return { ...state, loading: false };
};

const addCarPicturesStart = (state, action) => {
  return { ...state, loading: true };
};

const addCarPicturesSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
  };
};

const addCarPicturesFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCarDetailsStart = (state, action) => {
  return { ...state, loading: true };
};

const getCarDetailsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carDetails: action.payload,
  };
};

const getCarDetailsFailure = (state, action) => {
  return { ...state, loading: false };
};

const makeCarInactiveStart = (state, action) => {
  return { ...state, loading: true };
};

const makeCarInactiveSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    successMsg: 'Car made inactive successfully.',
  };
};

const makeCarInactiveFailure = (state, action) => {
  return { ...state, loading: false, error: 'Failed to make car inactive.' };
};

const getCarViewsStart = (state, action) => {
  return { ...state, loading: true };
};

const getCarViewsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    carViews: action.payload,
  };
};

const getCarViewsFailure = (state, action) => {
  return { ...state, loading: false, error: 'Failed to make car inactive.' };
};

const setCarListPage = (state, action) => {
  return { ...state, carPage: action.payload, loading: false }
}

const setCarOrderBy = (state, action) => {
  return { ...state, orderBy: action.payload, carPage: 1, loading: false }
}

const editCarStart = (state, action) => {
  return { ...state, loading: true };
};

const editCarSuccess = (state, action) => {
  return { ...state, loading: false }
}

const editCarFailure = (state, action) => {
  return { ...state, loading: false }
}

const deleteDraftStart = (state, action) => {
  return { ...state, loading: true };
};

const deleteDraftSuccess = (state, action) => {
  const updatedCarlist = state.carList.filter(car => {
    return car.uuidCar !== action.payload;
  })
  return { ...state, loading: false, carList: [...updatedCarlist] };
};

const deleteDraftFailure = (state, action) => {
  return { ...state, loading: false, error: 'Failed to delete draft.' };
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USED_CARLIST_START:
      return getCarListStart(state, action);

    case GET_USED_CARLIST_SUCCESS:
      return getCarListSuccess(state, action);

    case GET_USED_CARLIST_FAILURE:
      return getCarListFailure(state, action);

    case CREATE_CAR_START:
      return createCarStart(state, action);

    case CREATE_CAR_SUCCESS:
      return createCarSuccess(state, action);

    case CREATE_CAR_FAILURE:
      return createCarFailure(state, action);

    case GET_CAR_MODELS_START:
      return getCarModelsStart(state, action);

    case GET_CAR_MODELS_SUCCESS:
      return getCarModelsSuccess(state, action);

    case GET_CAR_MODELS_FAILURE:
      return getCarModelsFailure(state, action);

    case GET_CAR_BRANDS_START:
      return getCarBrandsStart(state, action);

    case GET_CAR_BRANDS_SUCCESS:
      return getCarBrandsSuccess(state, action);

    case GET_CAR_BRANDS_FAILURE:
      return getCarBrandsFailure(state, action);

    case GET_CAR_BY_PLATE_START:
      return getCarByPlateStart(state, action);

    case GET_CAR_BY_PLATE_SUCCESS:
      return getCarByPlateSuccess(state, action);

    case GET_CAR_BY_PLATE_FAILURE:
      return getCarByPlateFailure(state, action);

    case GET_CAR_STYLES_START:
      return getCarStylesStart(state, action);

    case GET_CAR_STYLES_SUCCESS:
      return getCarStylesSuccess(state, action);

    case GET_CAR_STYLES_FAILURE:
      return getCarStylesFailure(state, action);

    case GET_ENGINE_OPTIONS_START:
      return getEngineOptionsStart(state, action);

    case GET_ENGINE_OPTIONS_SUCCESS:
      return getEngineOptionsSuccess(state, action);

    case GET_ENGINE_OPTIONS_FAILURE:
      return getEngineOptionsFailure(state, action);

    case GET_FEATURE_LIST_START:
      return getFeatureListStart(state, action);

    case GET_FEATURE_LIST_SUCCESS:
      return getFeatureListSuccess(state, action);

    case GET_FEATURE_LIST_FAILURE:
      return getFeatureListFailure(state, action);

    case ADD_CAR_FEATURES_START:
      return addCarFeaturesStart(state, action);

    case ADD_CAR_FEATURES_SUCCESS:
      return addCarFeaturesSuccess(state, action);

    case ADD_CAR_FEATURES_FAILURE:
      return addCarFeaturesFailure(state, action);

    case ADD_CAR_PICTURES_START:
      return addCarPicturesStart(state, action);

    case ADD_CAR_PICTURES_SUCCESS:
      return addCarPicturesSuccess(state, action);

    case ADD_CAR_PICTURES_FAILURE:
      return addCarPicturesFailure(state, action);

    case GET_CAR_DETAILS_START:
      return getCarDetailsStart(state, action);

    case GET_CAR_DETAILS_SUCCESS:
      return getCarDetailsSuccess(state, action);

    case GET_CAR_DETAILS_FAILURE:
      return getCarDetailsFailure(state, action);

    case MAKE_CAR_INACTIVE_START:
      return makeCarInactiveStart(state, action);

    case MAKE_CAR_INACTIVE_SUCCESS:
      return makeCarInactiveSuccess(state, action);

    case MAKE_CAR_INACTIVE_FAILURE:
      return makeCarInactiveFailure(state, action);

    case GET_CAR_VIEWS_START:
      return getCarViewsStart(state, action);

    case GET_CAR_VIEWS_SUCCESS:
      return getCarViewsSuccess(state, action);

    case GET_CAR_VIEWS_FAILURE:
      return getCarViewsFailure(state, action);

    case SET_CAR_LIST_PAGE:
      return setCarListPage(state, action);

    case SET_CAR_ORDER:
      return setCarOrderBy(state, action);
    case EDIT_CAR_START:
      return editCarStart(state, action);

    case EDIT_CAR_SUCCESS:
      return editCarSuccess(state, action);

    case EDIT_CAR_FAILURE:
      return editCarFailure(state, action);
    case DELETE_DRAFT_START:
      return deleteDraftStart(state, action);

    case DELETE_DRAFT_SUCCESS:
      return deleteDraftSuccess(state, action);

    case DELETE_DRAFT_FAILURE:
      return deleteDraftFailure(state, action);


    default:
      return state;
  }
};

export default reducer;
