// Create User
export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

// Get User
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

// Clean User
export const CLEAN_USER_START = 'CLEAN_USER_START';
export const CLEAN_USER_SUCCESS = 'CLEAN_USER_SUCCESS';
export const CLEAN_USER_FAILURE = 'CLEAN_USER_FAILURE';

// Set JWT User
export const SET_IS_LOGGED_IN_START = 'SET_IS_LOGGED_IN_START';
export const SET_IS_LOGGED_IN_SUCCESS = 'SET_IS_LOGGED_IN_SUCCESS';
export const SET_IS_LOGGED_IN_FAILURE = 'SET_IS_LOGGED_IN_FAILURE';

// Update User Phone
export const UPDATE_USER_PHONE_START = 'UPDATE_USER_PHONE_START';
export const UPDATE_USER_PHONE_SUCCESS = 'UPDATE_USER_PHONE_SUCCESS';
export const UPDATE_USER_PHONE_FAILURE = 'UPDATE_USER_PHONE_FAILURE';
