import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import classes from './AdminVideoTrackerComponent.module.css';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';
import { FaRegClipboard } from 'react-icons/fa';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;
const BASE_URL = process.env.REACT_APP_API_URL;
const token = getTokenFromLocalStorage();

const AdminVideoTrackerComponent = ({ car }) => {
  const [carState, setCar] = useState(car);
  const [isApproved, setIsApproved] = useState(carState.videoPublished);
  //Copy to clipboard
const copyToClipboard = (text) => {
  let numberWithCommas = text;

  // Verificar si el texto es un número y no el año del carro
  if (!isNaN(parseFloat(text)) && isFinite(text)) {
    // Si es un número, formatear con comas
    numberWithCommas = parseFloat(text).toLocaleString('es-ES');
  }

  navigator.clipboard
    .writeText(numberWithCommas)
    .then(() => {
      console.log('Texto copiado al portapapeles: ', numberWithCommas);
    })
    .catch((error) => {
      console.error('Error al copiar al portapapeles: ', error);
    });
};





  useEffect(() => {
    setIsApproved(carState.videoPublished);
  }, [carState.videoPublished]);

  const handleVideoPublishedToggle = async () => {
    // Cambiar el estado localmente
    const newStatus = !carState.videoPublished;
    setCar({ ...carState, videoPublished: newStatus });

    // Enviar la solicitud a la API
    try {
      const apiUrl = `${BASE_URL}/api/Admin/UpdateOrInsertSocialMediaPublicationLink?idCar=${carState.idCar}&uuidCar=${carState.uuidCar}&platform=video&newStatus=${newStatus}`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <div
      // className={`${classes.linkApprover} ${isApproved ? classes.approved : ''}`}
      className={`${classes.linkApprover}`}
    >
      <h4 className={classes.carId}>
        {carState.idCar} | {carState.uuidCar}
      </h4>
      <br />
      <div className={classes.carInfo}>
        <ul>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  `${carState.brandName} ${carState.modelName}, ${carState.year}`
                )
              }
            />
            <span>
              Carro:{' '}
              <b>
                {carState.brandName} {carState.modelName}, {carState.year}
              </b>
            </span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() => copyToClipboard(carState.dealerName)}
            />
            <span>Dealer: {carState.dealerName}</span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  `@${carState.instaUser}` // Agregar "@" antes del nombre de usuario
                )
              }
            />
            <span>Insta: @{carState.instaUser}</span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  `${carState.cantonNombre}, ${carState.provinciaNombre}`
                )
              }
            />
            <span>
              Lugar: {carState.cantonNombre}, {carState.provinciaNombre}
            </span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() => copyToClipboard(carState.kilometreType)}
            />
            <span>KilometreType: {carState.kilometreType}</span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  `${
                    carState.kilometres !== null
                      ? carState.kilometres.toLocaleString('us-US')
                      : 'null'
                  } Km`
                )
              }
            />
            <span>
              Km:{' '}
              {carState.kilometres !== null
                ? carState.kilometres.toLocaleString('us-US')
                : 'null'}{' '}
              Km
            </span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  `${
                    carState.miles !== null
                      ? carState.miles.toLocaleString('us-US')
                      : 'null'
                  } Mi`
                )
              }
            />
            <span>
              Mi:{' '}
              {carState.miles !== null
                ? carState.miles.toLocaleString('us-US')
                : 'null'}{' '}
              Mi
            </span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  `${
                    carState.engineCc !== null
                      ? carState.engineCc.toLocaleString('us-US')
                      : 'null'
                  } CC`
                )
              }
            />
            <span>
              CC Motor:{' '}
              {carState.engineCc !== null
                ? carState.engineCc.toLocaleString('us-US')
                : 'null'}{' '}
              CC
            </span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() => copyToClipboard(carState.transmission)}
            />
            <span>Trans: {carState.transmission}</span>
          </li>
          <li>
            <FaRegClipboard
              onClick={() =>
                copyToClipboard(
                  carState.fuelTypeName === 'Gasolina Regular/Super'
                    ? 'Gasolina'
                    : carState.fuelTypeName
                )
              }
            />
            <span>
              Fuel:{' '}
              {carState.fuelTypeName === 'Gasolina Regular/Super'
                ? 'Gasolina'
                : carState.fuelTypeName}
            </span>
          </li>
          <li>
            <span>IG/FB (Read Only) </span>
            <Switch
              checked={carState.instagramFacebookPublished}
              // onChange={() => handleVideoPublishedToggle()}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </li>
          <li>
            <span>
              WB{'  '}
              {'  '}(Read Only)
            </span>
            <Switch
              checked={carState.whatsappPublished}
              // onChange={() => handleVideoPublishedToggle()}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </li>
        </ul>
        <div className={classes.imageContainer}>
          <a
            href={`/carro/${carState.uuidCar}`}
            className={classes.imageContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${BLOB_STORAGE_URL}/CAR-${carState.uuidCar}-IMG-1.jpeg`}
              alt="Car"
              className={classes.carImage}
            />
          </a>
        </div>
      </div>
      <div>
        <span>Video</span>
        <Switch
          checked={carState.videoPublished}
          onChange={() => handleVideoPublishedToggle()}
          color="primary"
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
    </div>
  );
};

export default AdminVideoTrackerComponent;
