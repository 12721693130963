import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import classes from './Terms.module.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PrivacyPolicy() {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  // Auto Scroll
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana a la parte superior
  }, []); // El efecto se ejecuta una vez, cuando el componente se monta

  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <title>Términos y condiciones - Autos Nova</title>
          <meta
            name="description"
            content="Términos y condiciones - Autos Nova"
          />
        </Helmet>
      </HelmetProvider>
      <div className={classes.title}>
        <h1>Términos y condiciones</h1>
      </div>
      <div className={classes.section}>
        <h3>Aceptación de Términos y Condiciones</h3>
        <p>
          Al utilizar o acceder a los servicios de Autos Nova, aceptas estar
          sujeto a los presentes Términos y Condiciones. Si no estás de acuerdo
          con alguno de los términos, te pedimos que no utilices nuestros
          servicios.
        </p>
        <h3>Uso de la Plataforma</h3>
        <p>
          Autos Nova proporciona una plataforma en línea destinada a facilitar
          la venta de vehículos entre usuarios. Al publicar un anuncio en
          nuestra plataforma, te comprometes a proporcionar información precisa
          y veraz sobre el vehículo. Nos reservamos el derecho de moderar y
          eliminar cualquier contenido que viole nuestros términos o que
          consideremos inapropiado.
        </p>
        <h3>Responsabilidad del Contenido</h3>
        <p>
          Los usuarios son responsables del contenido que publican. Autos Nova
          no asume responsabilidad por la exactitud o veracidad de la
          información proporcionada por los usuarios. Nos reservamos el derecho
          de eliminar o modificar cualquier contenido que consideremos
          necesario.
        </p>
        <h3>Transacciones y pagos entre vendedor y comprador</h3>
        <p>
          Autos Nova no interviene en las transacciones entre compradores y
          vendedores. Cualquier acuerdo financiero o de otro tipo es
          responsabilidad exclusiva de las partes involucradas. Recomendamos
          precaución y diligencia debida en todas las transacciones. Es
          importante destacar que Autos Nova no actúa como entidad legal en las
          transacciones realizadas en la plataforma. No somos parte de los
          acuerdos legales entre compradores y vendedores. Se insta a los
          usuarios a buscar asesoramiento legal externo si es necesario para
          garantizar una transacción segura y satisfactoria.
        </p>
        <h3>Anuncios Premium</h3>
        <p>
          Ofrecemos la opción de anuncios Premium a un costo adicional. Los
          anuncios Premium ofrecen beneficios adicionales, como mayor
          visibilidad en la plataforma. Al adquirir un anuncio Premium, aceptas
          los términos y condiciones específicos asociados con esta opción.
        </p>
        <h3>Anuncios Premium</h3>
        <p>
          Los usuarios pueden cancelar anuncios en cualquier momento. No se
          ofrecen reembolsos por anuncios cancelados. En caso de problemas con
          un anuncio o pago, comunícate con nuestro equipo de soporte para
          obtener asistencia.
        </p>
        <h3>Cambios en los Términos y Condiciones</h3>
        <p>
          Nos reservamos el derecho de modificar o actualizar estos Términos y
          Condiciones en cualquier momento. Los cambios serán efectivos
          inmediatamente después de su publicación en la plataforma. Te
          recomendamos revisar periódicamente los términos para estar informado
          de las actualizaciones.
        </p>
        <h3>Contacto</h3>
        <p>
          Si tienes preguntas o inquietudes sobre estos Términos y Condiciones,
          no dudes en ponerte en contacto con nuestro equipo de soporte.
        </p>
        <h3>Publicación de anuncios</h3>
        <ul>
          <li>
            &bull; Solo se permiten anuncios de vehículos ubicados en territorio
            costarricense.
          </li>
          <li>
            &bull; Se admiten exclusivamente anuncios de vehículos completos y
            usados, excluyendo accesorios y partes por separado.
          </li>
          <li>
            &bull; Cada anuncio debe representar un único vehículo, y nos
            reservamos el derecho de determinar la cantidad de anuncios
            gratuitos permitidos por usuario.
          </li>
          <li>
            &bull; No se permiten anuncios de cuadraciclos, contenedores,
            go-carts, mulas, juguetes, trailers, tractores, plataformas,
            carretas, casas de remolque, motos, maquinaria, equipo especial,
            etc.
          </li>
          <li>
            &bull; Los anuncios deben contener información veraz y completa,
            incluyendo nombre del vendedor, números de teléfono, precio, marca,
            modelo, cilindrada, etc.
          </li>
          <li>
            &bull; La duración de un anuncio destacado en la página principal no
            está garantizada y depende de la demanda. Un anuncio destacado
            permanecerá hasta su vencimiento o eliminación.
          </li>
          <li>
            &bull; Los anuncios premium se compartirán en otras plataformas
            (Instagram, WhatsApp, Facebook) en un plazo no mayor a 12 horas
            después de su aprobación.
          </li>
          <li>
            &bull; El vendedor es responsable de eliminar cualquier anuncio una
            vez que el vehículo se haya vendido.
          </li>
          <li>
            &bull; El precio de venta debe expresarse en colones y corresponder
            al precio total del vehículo. No se permiten fotos que no coincidan
            con el vehículo anunciado.
          </li>
          <li>
            &bull; Solo se permite un anuncio por vehículo. Anuncios duplicados
            podrán ser eliminados a nuestra discreción.
          </li>
          <li>
            &bull; Solo se permiten anuncios de venta de vehículos. No se
            aceptan anuncios para la compra de vehículos.
          </li>
          <li>
            &bull; Nos reservamos el derecho de eliminar cualquier anuncio que
            viole nuestras políticas o afecte la imagen del sitio.
          </li>
          <li>
            &bull; Usuarios que no cumplan con los términos y condiciones podrán
            ser bloqueados de publicar anuncios en el futuro.
          </li>
          <li>
            &bull; Al publicar un anuncio, aceptas ser contactado(a) por
            nosotros o nuestros afiliados vía teléfono o WhatsApp.
          </li>
          <li>
            &bull; Nos reservamos el derecho de suspender o bloquear usuarios
            sin previo aviso.
          </li>
          <li>
            &bull; Anuncios que incumplan los términos y condiciones o
            representen un riesgo serán removidos sin devolución de dinero.
          </li>
          <li>
            &bull; Los términos y condiciones pueden cambiar sin previo aviso.
            Se recomienda contactarnos por WhatsApp ante cualquier duda antes de
            publicar un anuncio.
          </li>
        </ul>
      </div>
      <p>
        Al utilizar Autos Nova, reconoces haber leído, entendido y aceptado
        estos Términos y Condiciones.
      </p>
      <p>Actualizado: Enero 2024</p>
      {/* Agregar más secciones y contenido según sea necesario */}
      {/* <div> */}
      <br />
      <Link to="/en-venta" className={classes.link}>
        <button className={classes.goBackButton}>Ver carros en venta</button>
      </Link>
      {/* </div> */}
    </div>
  );
}

export default PrivacyPolicy;
