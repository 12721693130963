import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageNotFound from './PageNotFound';
import Button from '../components/Buttons/Button';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getGeneralStats } from '../store/AdminStore/adminActions';
import { getCarsInReview } from '../store/AdminStore/adminActions';
import { BsDatabaseFillCheck, BsFillCheckSquareFill } from 'react-icons/bs';

function Admin({
  getGeneralStats,
  generalStats,
  carListInReview,
  getCarsToReview,
}) {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  const user = useSelector((state) => state.UserProfile.userProfile);
  const token = user.token;

  useEffect(() => {
    getCarsToReview(token);
  }, [getCarsToReview]);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Admin Module - Autos Nova</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      {user.userRole === 3 ? (
        <div>
          <h1>Admin Module</h1>
          <span>
            {/* <h3>Pending cars to review</h3> */}
            <Link to="/admin/car-review">
              <Button>
                <BsFillCheckSquareFill style={{ fontSize: '18px' }} />
                &nbsp;&nbsp;Revisar carros pendientes ({carListInReview.length})
              </Button>
            </Link>
          </span>
          {/* <span>
            <GeneralStats
              getGeneralStats={getGeneralStats}
              generalStats={generalStats}
              token={token}
            />
          </span> */}
          <span>
            {/* <h3>DB Changes</h3> */}
            <Link to="/admin/car-brands">
              <Button>
                <BsDatabaseFillCheck style={{ fontSize: '18px' }} />
                Edit car brands
              </Button>
            </Link>
          </span>
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}

// export default Admin;
const mapDispatchToProps = (dispatch) => {
  return {
    getGeneralStats: (token) => dispatch(getGeneralStats(token)),
    getCarsToReview: (token) => dispatch(getCarsInReview(token)),
  };
};

const mapStateToProps = (state) => {
  return {
    generalStats: state.AdminReducer.generalStats,
    carListInReview: state.AdminReducer.carListInReview,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
