import { FaCarCrash } from 'react-icons/fa';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

function PageNotFound() {
  const [clickCount, setClickCount] = useState(0);
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const handleCarCrashClick = () => {
    setClickCount(clickCount + 1);
    if (clickCount === 9) {
      alert('🚀 ME Corp 🚀');
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col justify-center items-center px-4 sm:px-8 py-12 sm:py-24">
      <HelmetProvider>
        <Helmet>
          <title>Página no encontrada - Autos Nova</title>
          <meta name="description" content="404 - Autos Nova" />
        </Helmet>
      </HelmetProvider>
      <div className="text-center">
        <h1 className="mt-4 text-8xl sm:text-9xl font-bold tracking-tight text-gray-900">
          404
        </h1>
        <h2 className="mt-4 text-4xl sm:text-5xl font-bold tracking-tight text-gray-900">
          Página no encontrada
        </h2>
        <br />
        <FaCarCrash
          className="text-6xl sm:text-7xl text-red-500 cursor-pointer"
          onClick={handleCarCrashClick}
        />
        <div className="mt-8 flex flex-col gap-y-6 sm:flex-row sm:gap-x-6">
          <Link
            to="/en-venta"
            className="rounded-md bg-black px-8 py-4 text-lg sm:text-xl font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Buscar carros en venta
          </Link>
          <Link
            to="/vender-mi-carro"
            className="rounded-md bg-black px-8 py-4 text-lg sm:text-xl font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Anunciar mi carro
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
