import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Carousel.module.css';
// import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import FullSizeGrid from '../Grid/FullSizeGrid';
import { PiShareFat } from 'react-icons/pi';
// import { HiOutlineHeart } from 'react-icons/hi'
import Slider from 'react-slick';
import './slick.css';
import './slick-theme.css';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

const Carousel = ({
  images,
  carUuid,
  counter = false,
  likeBtn = true,
  link,
  target,
  originSite,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showGrid, setShowGrid] = useState(false);


  const shareContent = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Compartir Vehículo',
          text: 'Mira este vehículo publicado en Autos Nova!',
          url: window.location.href,
        })
        .then(() => {
          // console.log('Content shared successfully');
        })
        .catch((error) => {
          console.error('Error sharing content:', error);
        });
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  const handleOpenImages = () => {
    setShowGrid(true);
  };

  const carousel = (
    <div className={styles.carousel}>
      <div className={styles.btns}>
        {counter ? (
          <button onClick={shareContent}>
            <PiShareFat />{' '}
          </button>
        ) : (
          <></>
        )}
      </div>

      <Slider
        className={styles.slider}
        afterChange={(e) => {
          setCurrentIndex(e);
        }}
        {...settings}
      >
        {images.map((images, index) => {
          return (
            <Link
              key={index}
              to={link}
              target={target}
              className={styles.imageLink}
            >
              <img
                sizes="300px"
                onClick={counter && handleOpenImages}
                className={styles.image}
                src={`${BLOB_STORAGE_URL}/${images}`}
                alt="Imagen de vehículo"
              />
            </Link>
          );
        })}
      </Slider>
      {counter ? (
        <div className={styles.counter}>
          <p>
            {currentIndex + 1} / {images.length}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const grid = (
    <FullSizeGrid onClick={() => setShowGrid(false)} images={images} />
  );

  return !showGrid ? carousel : grid;
};

export default Carousel;
