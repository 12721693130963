// import Button from '../Buttons/Button';
import classes from './Pricing.module.css';
import { BsCheckLg, BsDashLg } from 'react-icons/bs';
import { CiBookmarkPlus } from "react-icons/ci";

function Pricing({ handleSelect }) {
  const handleClick = (id) => {
    handleSelect(id);
  };

  return (
    <div className={classes.pricing}>
      <div className={classes.pricingItem}>
        <div className={classes.content}>
          <h4 className={classes.planName}>Gratis</h4>
          <h2>
            <span>₡</span>0{' '}
            <span className={classes.smallText}>/por anuncio</span>
          </h2>
          <p className={classes.planDescription}>
            Personas que desean públicar su carro:
          </p>
          <ul className={classes.list}>
            <li>
              <BsDashLg className={classes.iconGrey} /> Un maximo de 5 fotos por
              anuncio.
            </li>
            <li>
              <BsDashLg className={classes.iconGrey} /> Capacidad de poder
              editar tu anuncio en cualquier momento.
            </li>
            <li>
              <BsDashLg className={classes.iconGrey} /> Anuncio en la plataforma
              hasta que tu carro se venda.
            </li>
            <br />
            <br />
          </ul>
        </div>
        <div className={classes.btnContainer}>
          <button className={classes.btn} onClick={(el) => handleClick(1)}>
            Seleccionar
          </button>
        </div>
      </div>
      {/* <div className={classes.pricingItem}>
        <div className={classes.content}>
          <h4 className={classes.planName}>Estándar</h4>
          <h2>
            <span>₡</span>5,900{' '}
            <span className={classes.smallText}>/por anuncio</span>
          </h2>
          <p className={classes.planDescription}>
            Los visitantes del sitio web encontrarán tu vehículo de forma
            sencilla. Ádemas se mostrará en la parte superior de las busquedas
            que realicen los usuarios.
          </p>
          <ul className={classes.list}>
            <li>
              <BsCheckLg className={classes.icon} /> Mayor visibilidad dentro de
              la página
            </li>
            <li>
              <BsCheckLg className={classes.icon} /> Prioridad en los resultados de
              búsqueda
            </li>
            <li>
              <BsCheckLg className={classes.icon} /> Hasta 10 fotos por anuncio
            </li>
            <li>
              <BsCheckLg className={classes.icon} /> Se muestra como anuncio destacado
            </li>
          </ul>
        </div>
        <div className={classes.btnContainer}>
          <button
            className={classes.btn}
            onClick={(el) => handleClick(2)}
          >
            Seleccionar
          </button>
        </div>
      </div> */}
      <div className={classes.pricingItem}>
        <div className={classes.content}>
          <h4 className={`${classes.planName}`}>Premium</h4>
          <h2>
            <span>₡</span>8,900{' '}
            <span className={classes.smallText}>/por anuncio</span>
          </h2>
          <p className={classes.planDescription}>
            Todo lo del anuncio gratis, más:
          </p>
          <ul className={classes.list}>
            <li>
              <BsCheckLg className={classes.icon} /> Publicación en nuestro
              <a
                aria-label="Visita Instagram de Autos Nova"
                href="https://www.instagram.com/AutosNovacr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Instagram
              </a>
              ,{' '}
              <a
                aria-label="Visita Canal de WhatsApp de Autos Nova"
                href="https://whatsapp.com/channel/0029VaDPAQ70rGiEpwrWHq3y"
                target="_blank"
                rel="noopener noreferrer"
              >
                canal de WhatsApp
              </a>{' '}
              y
              <a
                aria-label="Visita Facebook de Autos Nova"
                href="https://www.facebook.com/AutosNovaCR"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Facebook.
              </a>
            </li>

            <li>
              <BsCheckLg className={classes.icon} /> Hasta 20 fotos.
            </li>
            <li>
              <BsCheckLg className={classes.icon} /> El anuncio estará en línea
              en cuestión de minutos.
            </li>
            <li>
              <BsCheckLg className={classes.icon} /> Anuncio destacado en la
              plataforma para mayor visibilidad.
            </li>
            <li>
              <BsCheckLg className={classes.icon} /> Soporte preferencial por
              WhatsApp.
            </li>
          </ul>
        </div>
        <div className={classes.btnContainer}>
          <button
            className={`${classes.btn} ${classes.premium}`}
            onClick={(el) => handleClick(3)}
          >
            Seleccionar
          </button>
        </div>
        <p>Método de pago por SINPE Móvil</p>
      </div>
    </div>
  );
}

export default Pricing;
