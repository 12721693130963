import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import styles from './BigCarousel.module.css';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import Modal from '../Modal/Modal';
// import { ClassNames } from '@emotion/react';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;

const BigCarousel = ({ open, images, closeModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const carPicture = images
    ? images.filter((image, index) => {
        return index === currentIndex;
      })[0]
    : [];

  return (
    // <div className={styles.container}>
    <Modal className={styles.container} open={open} onClose={closeModal}>
      <div className={styles.row}>
        <div className={styles.closeBtn} onClick={closeModal}>
          <MdOutlineClose />
          <p> Cerrar</p>
        </div>
        <div className={styles.counter}>
          <p>
            {currentIndex + 1}/{images.length}
          </p>
        </div>
      </div>
      <div className={styles.main}>
        {images.length > 1 ? (
          <button className={styles.button} onClick={goToPreviousSlide}>
            <IoIosArrowBack />
          </button>
        ) : (
          <></>
        )}
        <div className={styles.imgContainer}>
          <img
            className={styles.image}
            src={`${BLOB_STORAGE_URL}/${carPicture}`}
            alt="Imagen de vehículo"
          />
        </div>
        {images.length > 1 ? (
          <button className={styles.button} onClick={goToNextSlide}>
            <IoIosArrowForward />
          </button>
        ) : (
          <></>
        )}
      </div>
    </Modal>
    // </div>
  );
};

export default BigCarousel;
