import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import classes from './Contact.module.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Contact() {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  // Auto Scroll
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana a la parte superior
  }, []); // El efecto se ejecuta una vez, cuando el componente se monta

  const whatsappNumber = '50670096354';

  const handleWhatsappClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
  };

  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <title>Contacto y soporte - Autos Nova</title>
          <meta name="description" content="Contacto y soporte - Autos Nova" />
        </Helmet>
      </HelmetProvider>
      <div className={classes.title}>
        <h1>Contacto y soporte</h1>
      </div>
      <div className={classes.section}>
        <h3>Canales de distribución oficiales</h3>
        <p>Estos son nuestros canales de distribución oficiales:</p>
        <ul>
          <li>
            <span>Instagram: </span>
            <a
              className={classes.linkSocial}
              href="https://www.instagram.com/AutosNovaCR/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @AutosNovaCR
            </a>
          </li>
          <li>
            <span>Canal de WhatsApp: </span>
            <a
              className={classes.linkSocial}
              href="https://whatsapp.com/channel/0029VaFSOnp8vd1Nrd1O2v1M"
              target="_blank"
              rel="noopener noreferrer"
            >
              Autos Nova
            </a>
          </li>
          <li>
            <span>Facebook: </span>
            <a
              className={classes.linkSocial}
              href="https://www.facebook.com/AutosNovaCR"
              target="_blank"
              rel="noopener noreferrer"
            >
              Autos Nova
            </a>
          </li>
          {/* <li>
            <span>TikTok: </span>
            <a
              href="https://www.tiktok.com/@AutosNova"
              target="_blank"
              rel="noopener noreferrer"
            >
              @Autos Nova
            </a>
          </li> */}
        </ul>
        <br />
        <h3>Canales de comunicación</h3>
        <p>Únicamente contamos con los siguientes números de WhatsApp</p>
        <br />
        <p>
          <strong>Número principal y SINPE Móvil: </strong>{' '}
          <a
            href={`https://wa.me/${whatsappNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleWhatsappClick}
          >
            <strong>+506 7009 6354</strong>
          </a>
        </p>
        <p>
          A este número podrás realizar el pago en caso de adquirir un anuncio
          Premium. Adicionalmente, puedes comunicarte directamente con nosotros
          si tienes alguna duda o necesitas asistencia con el proceso.
        </p>
        <br />
        <p>
          {' '}
          <strong>Número de notificaciones: +506 7125 8428</strong>
        </p>
        <p>
          Cuando publiques algún anuncio, se te notificará mediante este número.
          Para consultas de soporte, contactá al otro número.
        </p>
        <br />
        <p>
          <strong>
            Cualquier otro número o página que no esté explícitamente indicado
            en esta sección no está vinculado con Autos Nova.
          </strong>
        </p>
      </div>

      <br />
      <button className={classes.goBackButton}>
        <Link to="/en-venta" className={classes.link}>
          Ver carros en venta
        </Link>
      </button>
    </div>
  );
}

export default Contact;
