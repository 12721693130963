import classes from './FullSizeGrid.module.css';
import { IoIosArrowBack } from 'react-icons/io';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;
export default function FullSizeGrid({ images, onClick }) {
  return (
    <div className={classes.container}>
      <div className={classes.row} onClick={onClick}>
        <IoIosArrowBack fontSize={20} />
      </div>
      <div className={classes.grid}>
        {images.map((image) => {
          return (
            <div>
              <img
                className={classes.image}
                src={`${BLOB_STORAGE_URL}/${image}`}
                alt="Imagen del vehículo"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
