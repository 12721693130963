import PageNotFound from '../../pages/PageNotFound';
import { useSelector } from 'react-redux';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// import PageNotFound from './PageNotFound';

function ChangeCarBrands() {
  const user = useSelector((state) => state.UserProfile.userProfile);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Car Brands - Admin Module</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      {user.userRole === 3 ? (
        <div>
          <h1>Edit Car Brands</h1>
          <span>Space for Add/Remove/Edit Car brands </span>
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}

export default ChangeCarBrands;
