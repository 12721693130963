// Used Car List
export const GET_USED_CARLIST_START = 'GET_USED_CARLIST_START';
export const GET_USED_CARLIST_SUCCESS = 'GET_USED_CARLIST_SUCCESS';
export const GET_USED_CARLIST_FAILURE = 'GET_USED_CARLIST_FAILURE';

// Car Details
export const GET_CAR_DETAILS_START = 'GET_CAR_DETAILS_START';
export const GET_CAR_DETAILS_SUCCESS = 'GET_CAR_DETAILS_SUCCESS';
export const GET_CAR_DETAILS_FAILURE = 'GET_CAR_DETAILS_FAILURE';

// Create Car
export const CREATE_CAR_START = 'CREATE_CAR_START';
export const CREATE_CAR_SUCCESS = 'CREATE_CAR_SUCCESS';
export const CREATE_CAR_FAILURE = 'CREATE_CAR_FAILURE';

// Car Models
export const GET_CAR_MODELS_START = 'GET_CAR_MODELS_START';
export const GET_CAR_MODELS_SUCCESS = 'GET_CAR_MODELS_SUCCESS';
export const GET_CAR_MODELS_FAILURE = 'GET_CAR_MODELS_FAILURE';

// Car Brands
export const GET_CAR_BRANDS_START = 'GET_CAR_BRANDS_START';
export const GET_CAR_BRANDS_SUCCESS = 'GET_CAR_BRANDS_SUCCESS';
export const GET_CAR_BRANDS_FAILURE = 'GET_CAR_BRANDS_FAILURE';

// Car by Plate
export const GET_CAR_BY_PLATE_START = 'GET_CAR_BY_PLATE_START';
export const GET_CAR_BY_PLATE_SUCCESS = 'GET_CAR_BY_PLATE_SUCCESS';
export const GET_CAR_BY_PLATE_FAILURE = 'GET_CAR_BY_PLATE_FAILURE';

// Car Styles
export const GET_CAR_STYLES_START = 'GET_CAR_STYLES_START';
export const GET_CAR_STYLES_SUCCESS = 'GET_CAR_STYLES_SUCCESS';
export const GET_CAR_STYLES_FAILURE = 'GET_CAR_STYLES_FAILURE';

// Engine Options
export const GET_ENGINE_OPTIONS_START = 'GET_ENGINE_OPTIONS_START';
export const GET_ENGINE_OPTIONS_SUCCESS = 'GET_ENGINE_OPTIONS_SUCCESS';
export const GET_ENGINE_OPTIONS_FAILURE = 'GET_ENGINE_OPTIONS_FAILURE';

// Feature List
export const GET_FEATURE_LIST_START = 'GET_FEATURE_LIST_START';
export const GET_FEATURE_LIST_SUCCESS = 'GET_FEATURE_LIST_SUCCESS';
export const GET_FEATURE_LIST_FAILURE = 'GET_FEATURE_LIST_FAILURE';

// Add Car Features
export const ADD_CAR_FEATURES_START = 'ADD_CAR_FEATURES_START';
export const ADD_CAR_FEATURES_SUCCESS = 'ADD_CAR_FEATURES_SUCCESS';
export const ADD_CAR_FEATURES_FAILURE = 'ADD_CAR_FEATURES_FAILURE';

// Add Car Pictures
export const ADD_CAR_PICTURES_START = 'ADD_CAR_PICTURES_START';
export const ADD_CAR_PICTURES_SUCCESS = 'ADD_CAR_PICTURES_SUCCESS';
export const ADD_CAR_PICTURES_FAILURE = 'ADD_CAR_PICTURES_FAILURE';

// Inactive car by User
export const MAKE_CAR_INACTIVE_START = 'MAKE_CAR_INACTIVE_START';
export const MAKE_CAR_INACTIVE_SUCCESS = 'MAKE_CAR_INACTIVE_SUCCESS';
export const MAKE_CAR_INACTIVE_FAILURE = 'MAKE_CAR_INACTIVE_FAILURE';

// Inactive car by User
export const GET_CAR_VIEWS_START = 'GET_CAR_VIEWS_START';
export const GET_CAR_VIEWS_SUCCESS = 'GET_CAR_VIEWS_SUCCESS';
export const GET_CAR_VIEWS_FAILURE = 'GET_CAR_VIEWS_FAILURE';

//Update page of the car details to retrieve the cars based on it.
export const SET_CAR_LIST_PAGE = 'SET_CAR_LIST_PAGE';

export const SET_CAR_ORDER = 'SET_CAR_ORDER';

export const EDIT_CAR_START = 'EDIT_CAR_START';
export const EDIT_CAR_SUCCESS = 'EDIT_CAR_SUCCESS';
export const EDIT_CAR_FAILURE = 'EDIT_CAR_FAILURE';

export const DELETE_DRAFT_START = 'DELETE_DRAFT_START';
export const DELETE_DRAFT_SUCCESS = ' DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_FAILURE = ' DELETE_DRAFT_FAILURE';
