import ReactGA from 'react-ga4';
import Button from '../Buttons/Button';
import Textarea from '@mui/joy/Textarea';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import SmallAlert from '../Alert/SmallAlert';
import Container from '../Container/Container';
import classes from './CarFeatures.module.css';
// import { Checkbox, FormControlLabel } from '@mui/material';
import ChoiceChip from '../ChoiceChip/ChoiceChip'
import getToast from '../../helpers/getToast';

function CarFeatures(props) {
  const [carFeatures, setCarFeatures] = useState([]);
  const [features, setFeatures] = useState([]);
  const [note, setNote] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [remainingCharacters, setRemainingCharacters] = useState(1500);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const user = useSelector((state) => state.UserProfile.userProfile);
  const createBy = user.idUser;
  const createdByUuid = user.uuidUser;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      createCar();
    }
  };

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: 'Características del carro',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const featureNames = props?.features.map(item => {
      return item.feature
    })
    setFeatures(featureNames)

  }, []);

  useEffect(() => {
    if (createBy) {
      setShowAlert(false);
    }
  }, [createBy]);

  console.log(carFeatures, 'FEATURES')

  const handleChange = (el) => {
    const featureId = el.target.value;
    const isChecked = el.target.checked;
    if (isChecked) {
      setCarFeatures((prev) => [...prev, featureId]);
    } else {
      setCarFeatures((prev) => prev.filter((el) => el !== featureId));
    }
  };

const noteChange = (event) => {
  const value = event.target.value;

  if (value.length <= 1500) {
    setNote(value);
    setRemainingCharacters(1500 - value.length);
  }
};

  const createCar = () => {
    try {
      setIsButtonDisabled(true);
      if (createBy) {
        ReactGA.event({
          category: 'Nuevo vehículo',
          action: 'Nuevo vehículo',
          label: 'Proceso de nuevo vehículo completado',
        });
        const date = '2023-03-17T04:04:59.431Z';
        props.createNewCar(carFeatures, {
          idCar: 0,
          uuidCar: '',
          createdBy: createBy,
          createdByUuidUser: createdByUuid,
          carPriceUsd: 0,
          sellerNotes: note,
          carStatusId: 1,
          createdOn: date,
          lastUpdate: date,
          adExpirationDate: date,
        });
      } else {
        setShowAlert(true);
      }
    } catch (ex) {
      getToast({ type: 'ERROR', text: 'No se puedieron agregar los detalles del carro. Intentalo de nuevo.' });
      setIsButtonDisabled(false)
    }

  };

  return (
    <Container>
      {/* TODO: Modificar CSS para que no se vea tan abajo en el UI */}
      <h2 style={{ fontWeight: 'bold' }}>¿Qué opciones tiene el carro?</h2>
      <div className={classes.content}>
        {props.features.length === 0 ? (
          <></>
        ) : (
          <ChoiceChip
            title={'Seleccione todas las extras que posee su carro'}
            items={features}
            value={carFeatures}
            setValue={setCarFeatures}
          />
        )}
      </div>
      <div className={classes.textArea}>
        <label style={{ fontWeight: 'bold' }}>
          ¿Alguna nota adicional? (opcional)
        </label>
        <Textarea
          value={note}
          maxLength={1500}
          onChange={noteChange}
          placeholder="Versión deportiva, modelo de lujo, etc..."
        />
        <div className={`${classes.characterCounter} characterCounter`}>
          {remainingCharacters}/{1500} caracteres restantes
        </div>
      </div>
      {showAlert && (
        <SmallAlert>Por favor regístrate para poder continuar</SmallAlert>
      )}
      <div className={classes.btn}>
        {/* TODO: Hacer disable del botón mientras se termina el request para evitar doble llamado. Poner Spinner */}
        <Button
          onClick={createCar}
          onKeyDown={handleKeyDown}
          disabled={isButtonDisabled}
        >
          Continuar
        </Button>
      </div>
    </Container>
  );
}

export default CarFeatures;
