import {

  CREATE_DEALER_FAILURE,
  CREATE_DEALER_SUCCESS,
  CREATE_DEALER_START
  , GET_DEALER_TYPES_START
  , GET_DEALER_TYPES_SUCCESS
  , GET_DEALER_TYPES_FAILURE
  , GET_DEALER_START
  , GET_DEALER_SUCCESS
  , GET_DEALER_FAILURE
  , GET_DEALER_CARS_START
  , GET_DEALER_CARS_SUCCESS
  , GET_DEALER_CARS_FAILURE
  , POST_DEALER_PICTURE_START
  , POST_DEALER_PICTURES_SUCCESS
  , POST_DEALER_PICTURES_FAILURE
  , POST_DEALER_REVIEW_START
  , POST_DEALER_REVIEW_SUCCESS
  , POST_DEALER_REVIEW_FAILURE
  , GET_DEALER_REVIEW_START
  , GET_DEALER_REVIEW_SUCCESS
  , GET_DEALER_REVIEW_FAILURE

} from './DealerTypes';

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const createDealerStart = () => {
  return { type: CREATE_DEALER_START };
};

const createDealerSuccess = (res) => {
  return { type: CREATE_DEALER_SUCCESS, payload: res };
};

const createDealerFailure = (err) => {
  return { type: CREATE_DEALER_FAILURE, payload: err };
};

const getDealerTypesStart = () => {
  return { type: GET_DEALER_TYPES_START };
};

const getDealerTypesSuccess = (res) => {
  return { type: GET_DEALER_TYPES_SUCCESS, payload: res };
};

const getDealerTypesFailure = (err) => {
  return { type: GET_DEALER_TYPES_FAILURE, payload: err };
};

const getDealerStart = () => {
  return { type: GET_DEALER_START };
};

const getDealerSuccess = (res) => {
  return { type: GET_DEALER_SUCCESS, payload: res };
};

const getDealerFailure = (err) => {
  return { type: GET_DEALER_FAILURE, payload: err };
};

const getDealerCarsStart = () => {
  return { type: GET_DEALER_CARS_START };
};

const getDealerCarsSuccess = (res) => {
  return { type: GET_DEALER_CARS_SUCCESS, payload: res };
};

const getDealerCarsFailure = (err) => {
  return { type: GET_DEALER_CARS_FAILURE, payload: err };
};

const postDealerPictureStart = () => {
  return { type: POST_DEALER_PICTURE_START };
}

const postDealerPictureSuccess = (res) => {

  return { type: POST_DEALER_PICTURES_SUCCESS, payload: res };
}

const postDealerPictureFailure = (err) => {
  return { type: POST_DEALER_PICTURES_FAILURE, payload: err };
}

const postDealerReviewStart = () => {
  return { type: POST_DEALER_REVIEW_START };
}

const postDealerReviewSuccess = (res) => {

  return { type: POST_DEALER_REVIEW_SUCCESS, payload: res };
}

const postDealerReviewFailure = (err) => {
  return { type: POST_DEALER_REVIEW_FAILURE, payload: err };
}

const getDealerReviewsStart = () => {
  return { type: GET_DEALER_REVIEW_START };
}

const getDealerReviewsSuccess = (res) => {

  return { type: GET_DEALER_REVIEW_SUCCESS, payload: res };
}

const getDealerReviewsFailure = (err) => {
  return { type: GET_DEALER_REVIEW_FAILURE, payload: err };
}




export const createDealer = (body) => (dispatch) => {
  dispatch(createDealerStart());
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/api/Dealer/RegisterDealer`, body)
      .then((res) => { 
        createDealerSuccess(res.data); 
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(createDealerFailure(err));
        reject(false);
      });
  });
};


export const getDealerTypes = () => (dispatch) => {
  dispatch(getDealerTypesStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/Dealer/GetAllSellerTypes`)
      .then((res) => {
        getDealerTypesSuccess(res.data);
        resolve(res);
      })
      .catch((err) => {
        dispatch(getDealerTypesFailure(err));
        reject(err);
      });
  });
};

export const getDealer = (uuid) => (dispatch) => {
  // console.log(uuid, 'UUID')
  dispatch(getDealerStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/Dealer/getDealer?uuid=${uuid}`)
      .then((res) => {
        dispatch(getDealerSuccess(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(getDealerFailure(err));
        reject(err);
      });
  });
};

export const getDealerById = (dealerUrl) => (dispatch) => {
  dispatch(getDealerStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/Dealer/GetDealerByDealerUrl?dealerUrl=${dealerUrl}`)
      .then((res) => {
        dispatch(getDealerSuccess(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(getDealerFailure(err));
        reject(err);
      });
  });
};

export const getDealerByCarId = (id) => (dispatch) => {
  dispatch(getDealerStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/Dealer/getCarDealer?carUuid=${id}`)
      .then((res) => {
        dispatch(getDealerSuccess(res.data));
        resolve(res);
      })
      .catch((err) => {
        dispatch(getDealerFailure(err));
        reject(err);
      });
  });
};

export const getDealerCars = (userUuid) => (dispatch) => {

  dispatch(getDealerCarsStart());
  return new Promise((resolve, reject) => {
    axios.get(`${BASE_URL}/api/Dealer/GetAllDealerCars?uuidUser=${userUuid}`)
      .then((res) => {
        dispatch(getDealerCarsSuccess(res.data))
        resolve(res);
      })
      .catch(err => {
        dispatch(getDealerCarsFailure(err))
        reject(err);
      });
  })
}

export const postDealerPicture = (data) => (dispatch) => {

  dispatch(postDealerPictureStart());
  axios
    .post(`${BASE_URL}/api/Dealer/ProfilePicture`, data)
    .then((res) => {
      dispatch(postDealerPictureSuccess(res.data));
    })
    .catch((err) => {
      dispatch(postDealerPictureFailure(err));
    });

};

export const postDealerReview = (review) => (dispatch) => {

  dispatch(postDealerReviewStart());

  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/api/Dealer/AddDealerReview`, review)
      .then((res) => {
        dispatch(postDealerReviewSuccess(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(postDealerReviewFailure(err));
        reject(err);
      });
  });
};
export const getDealerReview = (idDealer) => (dispatch) => {
  dispatch(getDealerReviewsStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/Dealer/GetRecentReviews?idDealer=${idDealer}`)
      .then((res) => {
        dispatch(getDealerReviewsSuccess(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(getDealerReviewsFailure(err));
        reject(err);
      });
  });
};


export const getAllDealerReviews = (idDealer) => (dispatch) => {
  dispatch(getDealerReviewsStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${BASE_URL}/api/Dealer/GetAllDealerCars?idDealer=${idDealer}`)
      .then((res) => {
        dispatch(getDealerReviewsSuccess(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(getDealerReviewsFailure(err));
        reject(err);
      });
  });
};

