import classes from './SmallAlert.module.css';

const SmallAlert = (props) => {
  return (
    <div className={classes.alert}>
      <span className={classes.span}>{props.children}</span>
    </div>
  );
};

export default SmallAlert;
