import ReactGA from 'react-ga4';
import { useState } from 'react';
import classes from './HomePage.module.css';
// import Textarea from '@mui/joy/Textarea';
import { TbHandClick } from 'react-icons/tb';
import Card from '../../components/Card/Card';
import Button from '../../components/Buttons/Button';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AiOutlineSafety, AiOutlineSearch } from 'react-icons/ai';
import { BsArrowRightShort } from 'react-icons/bs';
import AcuraLogo from '../../images/appImages/logos/acura.svg';
import BmwLogo from '../../images/appImages/logos/bmw.svg';
import FordLogo from '../../images/appImages/logos/ford.svg';
import JaguarLogo from '../../images/appImages/logos/jaguar.svg';
import JeepLogo from '../../images/appImages/logos/jeep.svg';
import LexusLogo from '../../images/appImages/logos/lexus.svg';
import MercedesLogo from '../../images/appImages/logos/mercedes.svg';
import MgLogo from '../../images/appImages/logos/mg.svg';
import MiniLogo from '../../images/appImages/logos/mini.svg';
import NissanLogo from '../../images/appImages/logos/nissan.svg';
import PorscheLogo from '../../images/appImages/logos/porsche.svg';
import IsuzuLogo from '../../images/appImages/logos/isuzu.svg';
import LandRoverLogo from '../../images/appImages/logos/land.svg';
import VolvoLogo from '../../images/appImages/logos/volvo.svg';
import VolkswagenLogo from '../../images/appImages/logos/volkswagen.svg';
import ToyotaLogo from '../../images/appImages/logos/toyota.svg';
import KiaLogo from '../../images/appImages/logos/kia.svg';
import HyundayLogo from '../../images/appImages/logos/hyundai.svg';
import HondaLogo from '../../images/appImages/logos/honda.svg';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Login from '../../components/Login/Login';

//For Testing locally
import LogoNav from '../../images/appImages/landingImage03.png'; // Import the SVG file

function HomePage() {
  const [feedback, setFeedback] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const logosList = [
    { brand: 'toyota', img: ToyotaLogo },
    { brand: 'hyundai', img: HyundayLogo },
    { brand: 'nissan', img: NissanLogo },
    { brand: 'bmw', img: BmwLogo },
    { brand: 'mercedes-benz', img: MercedesLogo },
    { brand: 'kia', img: KiaLogo },
    { brand: 'ford', img: FordLogo },
    { brand: 'honda', img: HondaLogo },
    { brand: 'land-rover', img: LandRoverLogo },
    { brand: 'volvo', img: VolvoLogo },
    { brand: 'acura', img: AcuraLogo },
    { brand: 'volkswagen', img: VolkswagenLogo },
    { brand: 'jaguar', img: JaguarLogo },
    { brand: 'jeep', img: JeepLogo },
    { brand: 'lexus', img: LexusLogo },
    { brand: 'mg', img: MgLogo },
    { brand: 'mini', img: MiniLogo },
    { brand: 'porsche', img: PorscheLogo },
    { brand: 'isuzu', img: IsuzuLogo },
    '',
  ];
  const navigate = useNavigate();

  const handleRegister = () => {
    setShowRegister(true);
  };

  const handleSubmit = (e) => {
    var encodedMessage = encodeURIComponent('Message: ' + feedback);
    const contact = '70096354';
    var link;
    // Check if user is on a mobile device
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      link = `whatsapp://send?phone=${contact}&text=${encodedMessage}`;
    } else {
      link = `https://web.whatsapp.com/send?phone=${contact}&text=${encodedMessage}`;
    }
    window.open(link, '_blank');
  };

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Autos Nova | La plataforma de autos de Costa Rica</title>
          <meta
            name="description"
            content="La plataforma de autos de Costa Rica. Publica tu auto en minutos. Encuentra tu próximo auto en Autos Nova."
          />
          <meta
            name="keywords"
            content="Autos Nova, autosnova, autos nova cr, autos usados costa rica, vehículos Costa Rica, compra de autos usados, venta de autos usados, autos en venta Costa Rica, vcr"
          />
          <meta name="author" content="Autos Nova" />
          <link rel="canonical" href="https://AutosNovaCR.com" />
          <meta
            property="og:description"
            content="La plataforma de autos de Costa Rica. Publica tu auto en minutos. Encuentra tu próximo auto en Autos Nova."
          />
          <meta property="og:url" content="https://AutosNovaCR.com" />
          <meta
            property="og:title"
            content="Autos Nova | La plataforma de autos de Costa Rica"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Autos Nova" />
          <meta property="og:locale" content="es_CR" />
          {/* TEMP! */}
          <meta
            property="og:image"
            content="https://AutosNovaCR.com/an_og.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
      </HelmetProvider>
      {showRegister ? (
        <Login
          showLogin={showRegister}
          title={'Regístrate'}
          onClose={() => {
            setShowRegister(false);
          }}
        />
      ) : (
        <></>
      )}
      <div className={classes.header}>
        <div className={classes.headerContainer}>
          <h1 className={classes.title}>
            La plataforma de autos de Costa Rica
          </h1>
          <p>
            Explora carros que se ajustan a tu estilo. Publica tu auto en
            cuestión de minutos y conecta con posibles compradores
          </p>
          <div className={classes.headerBtn}>
            <Link
              to="/en-venta"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Button className={classes.callToActionCarrosEnVenta}>
                Carros en venta
              </Button>
            </Link>
            <Link
              to="/vender-mi-carro"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Button className={classes.callToActionBtn}>
                Anunciar mi carro
              </Button>
            </Link>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img
            alt="Imagen de un carro"
            className={classes.img}
            // src={LogoNav}
            src="https://autosnova.blob.core.windows.net/autosnova/landingImage.webp"
            loading="lazy"
          />
        </div>
      </div>

      <br />
      <div className={classes.redesSection}>
        <div>
          <div className={classes.secondTitle}>
            <h2>Encontrá tu próximo auto en nuestras plataformas</h2>
          </div>

          {/* <button className={classes.redesButton}>Redes</button> */}
          <Link to="/en-venta">
            <button className={classes.autosNovaButton}>Autos Nova</button>
          </Link>
          <button
            className={classes.instagramButton}
            onClick={() =>
              window.open(
                'https://www.instagram.com/AutosNovaCR',
                '_blank',
                'noopener noreferrer'
              )
            }
          >
            Instagram
          </button>
          <button
            className={classes.whatsappButton}
            onClick={() =>
              window.open(
                'https://whatsapp.com/channel/0029VaDPAQ70rGiEpwrWHq3y',
                '_blank',
                'noopener noreferrer'
              )
            }
          >
            WhatsApp
          </button>
          <button
            className={classes.facebookButton}
            onClick={() =>
              window.open(
                'https://www.facebook.com/AutosNovaCR',
                '_blank',
                'noopener noreferrer'
              )
            }
          >
            Facebook
          </button>
        </div>
      </div>

      <div className={classes.dealerSection}>
        <div className={classes.dealerText}>
          <h2>¿Tienes una venta de autos?</h2>
          <p>
            Con Autos Nova puedes promocionar tu negocio y llegar a tus futuros
            clientes. Regístrate gratis y crea tu perfil en minutos. El lugar para tener tu catálogo e información de contacto.
          </p>
        </div>
        <button
          className={classes.callToActionEmpezar}
          onClick={() => {
            navigate('/RegistroNegocio');
          }}
        >
          Registrarme como vendedor
        </button>
      </div>

      <div className={classes.firstSection}>
        <h3 style={{ color: '#fff', fontWeight: '600', fontSize: '25px' }}>
          ¿Por qué Autos Nova?
        </h3>
        <div className={classes.information}>
          <div className={classes.infoItem}>
            <div className={classes.icon}>
              <TbHandClick />
            </div>
            <h4>Facilidad</h4>
            <p>Encuentra o vende tu vehículo en unos pocos clicks</p>
          </div>
          <div className={classes.infoItem}>
            <div className={classes.icon}>
              <AiOutlineSearch />
            </div>
            {/* TODO: Definir si cambiamos el ícono para que no se confunda con el buscador jeje */}
            <h4>Preciso</h4>
            <p>
              Consigue el carro que tanto buscas o el comprador que tanto
              buscabas
            </p>
          </div>
          <div className={classes.infoItem}>
            <div className={classes.icon}>
              <AiOutlineSafety />
            </div>
            <h4>Seguro</h4>
            <p>
              Buscamos garantizar la seguridad del comprador y vendedor en todo
              momento
            </p>
          </div>
        </div>
        <button className={classes.smallBtn} onClick={handleRegister}>
          Registrarse
        </button>
      </div>
      {/* <div className={classes.logoSection}>
        <h2>Tus marcas favoritas</h2>
        <ul className={classes.logoList}>
          {showAll
            ? logosList.map((logo, i) => {
              if (i === logosList.length - 1) {
                return (
                  <li className={`${classes.logoItem} ${classes.logoItem}`}>
                    <p
                      onClick={() => {
                        navigate('/en-venta');
                      }}
                    >
                      Buscar ahora <BsArrowRightShort />
                    </p>
                  </li>
                );
              }
              return (
                <li
                  className={classes.logoItem}
                  onClick={() => {
                    navigate(`/en-venta?brandSlug=${logo.brand}`);
                  }}
                >
                  <img src={logo.img} alt="Logo" />
                </li>
              );
            })
            : logosList.map((logo, i) => {
              if (i === 7) {
                return (
                  <li className={`${classes.logoItem} ${classes.logoItem}`}>
                    <p
                      onClick={() => {
                        navigate('/en-venta');
                      }}
                    >
                      Buscar ahora <BsArrowRightShort />
                    </p>
                  </li>
                );
              } else if (i < 7) {
                return (
                  <li
                    className={classes.logoItem}
                    onClick={() => {
                      navigate(`/en-venta?brandSlug=${logo.brand}`);
                    }}
                  >
                    <img src={logo.img} alt="logo" />
                  </li>
                );
              }
            })}
          {showAll ? (
            <button
              className={classes.showBtn}
              onClick={() => setShowAll(false)}
            >
              <FaMinus /> Mostrar menos
            </button>
          ) : (
            <button
              className={classes.showBtn}
              onClick={() => setShowAll(true)}
            >
              <FaPlus /> Mostrar más
            </button>
          )}
        </ul>
      </div> */}
      <div className={classes.secondSection}>
        <Card
          title={'Vender con Autos Nova'}
          button={'Ir'}
          image={'contract'}
          route={'/vender-mi-carro'}
        >
          Puedes vender tu vehículo con Autos Nova de forma gratuita o con
          alguno de nuestros planes.
        </Card>
        <Card
          title={'El vehículo que buscas a unos clicks'}
          button={'Ir'}
          image={'white-car'}
          route={'/en-venta'}
        >
          Descubre los vehículos que se ajusten a tu presupuesto y a tus gustos
        </Card>
      </div>
      {/* <div style={{ marginTop: '3rem' }}>
        <div className={classes.form}>
          <div>
            <h6>
              Ayudanos a crear la mejor plataforma de compra y venta de
              vehiculos de Costa Rica.
            </h6>
          </div>
          <div className={classes.input}>
            <Textarea
              value={feedback}
              onChange={(e) => {
                setFeedback(e.target.value);
              }}
              minRows={2}
              placeholder="¿Cómo podemos mejorar tu experiencia de compra y/o venta?"
              sx={{
                width: '60%',
                '&::before': {
                  border: '1.5px solid var(--Textarea-focusedHighlight)',
                  transform: 'scaleX(0)',
                  left: '2.5px',
                  right: '2.5px',
                  bottom: 0,
                  top: 'unset',
                  transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                  borderRadius: 0,
                  borderBottomLeftRadius: '64px 20px',
                  borderBottomRightRadius: '64px 20px',
                },
                '&:focus-within::before': {
                  transform: 'scaleX(1)',
                },
              }}
            />
            <div style={{ width: '3rem' }}>
              <Button onClick={handleSubmit}>
                <LuSend />
              </Button>
            </div>
          </div> 
        </div>
      </div>*/}
    </div>
  );
}

export default HomePage;
