import ReactGA from 'react-ga4';
import classes from './Faq.module.css';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

function Faq() {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  //Auto Scroll
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana a la parte superior
  }, []); // El efecto se ejecuta una vez, cuando el componente se monta

  const faqData = [
    {
      question: '¿Qué es Autos Nova?',
      answer:
        'Autos Nova es La plataforma más avanzada para cambiar tu auto en Costa Rica. Fue diseñada pensando en ofrecer una experiencia superior para los clientes. La búsqueda de autos se vuelve más eficiente gracias a nuestros filtros y detalles, centrándonos en los aspectos más relevantes al comprar un carro. Asimismo, como vendedor, disfrutarás de una experiencia fácil y simplificada al utilizar nuestra plataforma.',
    },
    {
      question: '¿Por qué elegir Autos Nova?',
      answer:
        'En Autos Nova, te ofrecemos una experiencia única que marca la diferencia:\n\nProporcionamos visibilidad multicanal para tus anuncios, no solo en nuestra plataforma, sino también en Instagram, a través de nuestro canal de WhatsApp y en Facebook, ampliando así tu alcance de manera significativa.\n\nComo vendedor, simplificamos tu proceso. Recibirás notificaciones directamente en WhatsApp cuando se realice una acción en tu anuncio, eliminando la espera de llamadas. Además, no tendrás que esperar para subir fotos del vehículo; nuestro proceso es fácil, eficaz y sin complicaciones.\n\nNos destacamos por ofrecer una diversidad de servicios que otras plataformas no proporcionan, centrándonos en brindar una experiencia única y eficiente. En Autos Nova, tu enfoque puede estar en cerrar el trato mientras nosotros nos encargamos de los detalles.',
    },
    {
      question: '¿Cuánto cuesta un anuncio en Autos Nova?',
      answer:
        'Publicar un anuncio en Autos Nova es completamente gratuito. Sin embargo, si deseas ampliar la visibilidad de tu carro, ofrecemos la opción de adquirir el anuncio Premium a un costo adicional, brindándote mayores beneficios y exposición.',
    },
    {
      question: '¿Cómo publico mi carro en Autos Nova?',
      answer:
        'Publicar tu carro en Autos Nova es fácil y rápido. Simplemente inicia sesión, proporciona tus números de contacto, publica los detalles de tu carro y ¡listo! El proceso toma menos de 5 minutos, permitiéndote poner tu vehículo a la venta de manera eficiente.',
    },
    {
      question: '¿Cuáles son los métodos de pago en Autos Nova?',
      answer:
        'En Autos Nova, ofrecemos la conveniencia de realizar pagos a través de SINPE Móvil, un proceso que ya conoces. Este método te permite realizar transacciones de forma fácil y rápida, sin la necesidad de introducir números de tarjeta en línea. Valoramos la seguridad y simplicidad en cada paso, brindándote una experiencia de pago sin complicaciones.',
    },
    {
      question: '¿A qué número hago el SINPE Móvil?',
      answer:
        'Para realizar tu pago a través de SINPE Móvil, debes enviarlo al número 7009 6354. Puedes ver los detalles del motivo en la sección de Mis Anuncios.',
    },
    {
      question: '¿Dónde envío el comprobante de pago?',
      answer:
        'En Autos Nova, nos preocupamos por simplificar el proceso para ti. No es necesario enviar comprobantes de pago, pero si voluntariamente lo deseas, puedes hacerlo al WhatsApp del mismo número. Estamos aquí para facilitar cada paso y proporcionarte una experiencia sin complicaciones.',
    },
  ];

  return (
    <div style={{ maxWidth: '2120px', margin: '0 auto' }}>
      <HelmetProvider>
        <Helmet>
          <title>Preguntas frecuentes - Autos Nova</title>
          <meta
            name="description"
            content="Preguntas frecuentes - Autos Nova"
          />
        </Helmet>
      </HelmetProvider>
      <div className={classes.title}>
        <h1>Preguntas Frecuentes</h1>
      </div>
      <div className={classes.container}>
        {faqData.map((faq, index) => (
          <div key={index} className={classes.accordionWrapper}>
            <Accordion elevation={3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography className={classes.boldQuestion}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.justifyAnswer}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
        <Link to="/en-venta" className={classes.link}>
          <button className={classes.goBackButton}>Ver carros en venta</button>
        </Link>
      </div>
    </div>
  );
}

export default Faq;
