import React from 'react';
import classes from './LoadingSpinner.module.css';

const LoadingSpinner = () => {
  return (
    <div className={classes.spinner}>
      <div className={classes.circle}></div>
      <div className={classes.title}>
        Subiendo imágenes...
        <br />
        Esto podría tardar unos minutos
        {/* <span className={classes.dot}>.</span>
        <span className={classes.dot}>.</span>
        <span className={classes.dot}>.</span> */}
      </div>
    </div>
  );
};

export default LoadingSpinner;
