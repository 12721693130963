import toast from 'react-hot-toast';

const toastOptions = {
  duration: 6000,
  position: 'top-center',

  // Styling
  style: {},
  className: '',

  // Custom Icon
  // icon: '👏',

  // Change colors of success/error/loading icon
  // iconTheme: {
  //     primary: '#000',
  //     secondary: '#fff',
  // },

  // Aria
  ariaProps: {
    role: 'status',
    'aria-live': 'polite',
  },
};

const getToast = ({ text, type, style }) => {
  switch (type) {
    case 'SUCCESS':
      toast.success(`${text}`, { ...toastOptions, ...style });
      break;
    case 'ERROR':
      toast.error(`${text}`, { ...toastOptions, ...style });
      break;
    case 'LOADING':
      toast.loading(`${text}`, { ...toastOptions, ...style });
      break;
    case 'WARNING':
      toast.loading(`${text}`, { ...toastOptions, ...style });
      break;
    default:
      toast.success(`${text}`, { ...toastOptions, ...style });
  }
};

export default getToast;
