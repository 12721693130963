import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
// import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Modal from '../components/Modal/Modal';
// import Stack from '@mui/material/Stack';
import Button from '../components/Buttons/Button';
import { useSelector } from 'react-redux';
import classes from './MyProfile.module.css';
import { BsTelephone } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import NeedToLogin from '../components/NeedToLogin/NeedToLogin';
import {
  updateUserPhone,
  getUserRequest,
} from '../store/UserStore/userActions';
import MyProfileCard from '../components/MyProfileCard/MyProfileCard';

// TODO: Agregar Validaciones al momento de presionar el Guardar/ Si da error, no cerrar la ventada y mostrar mensaje de error
// Bug: Si el usuario cambie el número, todo bien. Pero si lo vuelve a cambiar al números anterior, el disable del guargar se pierde

import { Helmet, HelmetProvider } from 'react-helmet-async';
import getToast from '../helpers/getToast';
import {
  getTokenFromLocalStorage,
  getTokenDataFromLocalStorage,
  setTokenInLocalStorage,
} from '../helpers/jwtService';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

function MyProfile({ updateUserPhone, onLogin, getUser }) {
  const userState = useSelector((state) => state.UserProfile);
  const uuidUser = useSelector(
    (state) => state.UserProfile.userProfile.uuidUser
  );

  const user = userState.userProfile;
  const loggedIn = userState.loggedIn;

  const navigate = useNavigate();
  // Phone
  const [openPhone, setOpenPhone] = useState(false);
  const handleOpenPhone = () => setOpenPhone(true);
  const handleClosePhone = () => setOpenPhone(false);
  const [phone, setPhone] = useState(null);
  const [phoneOnCard, setPhoneOnCard] = useState(null);
  const [disableSaveButtonPhone, setDisableSaveButtonPhone] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  // Whatsapp
  const [openWhatsappPhone, setOpenWhatsappPhone] = useState(false);
  const handleOpenWhatsapp = () => setOpenWhatsappPhone(true);
  const handleCloseWhatsapp = () => setOpenWhatsappPhone(false);
  const [whatsappPhone, setWhatsappPhone] = useState(null);
  const [whatsappPhoneOnCard, setWhatsappPhoneOnCard] = useState(null);

useEffect(() => {
  const checkTokenAndSetData = async () => {
    const token = await getTokenFromLocalStorage(); // Utilizamos await aquí
    if (token) {
      // Si hay un token, establecer los datos del usuario
      const tokenData = await getTokenDataFromLocalStorage();
      if (tokenData) {
        // Si se puede obtener el tokenData, establecer los datos del usuario
        setPhone(user?.phoneNumber);
        setPhoneOnCard(user?.phoneNumber);
        setWhatsappPhone(user?.whatsappNumber);
        setWhatsappPhoneOnCard(user?.whatsappNumber);
      } else {
        // Si no se puede obtener el tokenData, redirigir al usuario a /en-venta
        navigate('/en-venta');
      }
    } else {
      // Si no hay token, redirigir al usuario a /en-venta
      navigate('/en-venta');
    }
  };

  checkTokenAndSetData();
}, [navigate, user]);


  const handleRegisterDealer = (e) => {
    e.preventDefault();
    navigate('/RegistroNegocio');
  };

  const handleChangePhone = async (event, typeOfPhone) => {
    const phoneCleaned = event.target.value.replace(/\D/g, '');
    if (typeOfPhone === 'phone') {
      setPhone(phoneCleaned);
    }
    if (typeOfPhone === 'whatsapp') {
      setWhatsappPhone(phoneCleaned);
    }
    const regexPhoneNumber = new RegExp('[0-9]{8}');
    const regexPassed = regexPhoneNumber.test(phoneCleaned);
    if (regexPassed === true && phoneCleaned !== user.phoneNumber) {
      setIsPhoneValid(true);
      setDisableSaveButtonPhone(false);
    } else {
      setIsPhoneValid(false);
      setDisableSaveButtonPhone(true);
    }
  };

  const clearInputOnClosure = () => {
    setIsPhoneValid(false);
    setDisableSaveButtonPhone(true);
  };

  const handlePhoneSave = async (typeOfPhone) => {
    let valueToUpdate = '';

    if (typeOfPhone === 'phone') {
      valueToUpdate = phone;
    }
    if (typeOfPhone === 'whatsapp') {
      valueToUpdate = whatsappPhone;
    }
    try {
      const token = getTokenFromLocalStorage();
      const isPhoneUpdated = await updateUserPhone(
        token,
        uuidUser,
        valueToUpdate,
        typeOfPhone
      );

      if (isPhoneUpdated) {
        typeOfPhone === 'phone'
          ? setPhoneOnCard(phone)
          : setPhoneOnCard(whatsappPhone);
        getToast({ type: 'SUCCESS', text: 'Se actualizó el teléfono.' });
      } else {
        getToast({
          type: 'ERROR',
          text: 'No se ha podido actualizar. Intentalo de nuevo.',
        });
      }

      const response = await getUser(
        user.email,
        user.authProvider,
        user.authProviderId
      );
      if (response) {
        setTokenInLocalStorage(response.token);
      }
    } catch (ex) {
      console.error(ex);
    }

    clearInputOnClosure();
  };

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Mi Perfil - Autos Nova</title>
          <meta name="Mi Perfil" content="Mi Perfil - Autos Nova" />
        </Helmet>
      </HelmetProvider>
      {loggedIn ? (
        <div className={classes.container}>
          <div>
            <h2>Información personal</h2>
            <p>Registrado con {user.authProvider}</p>
          </div>
          <MyProfileCard
            title={'Nombre'}
            userData={`${user.firstName} ${user.lastName}`}
          />
          <MyProfileCard
            title={'Dirección de correo electrónico'}
            userData={user.email}
          />
          <div>
            <div>
              <MyProfileCard
                onClick={handleOpenPhone}
                btnOption={true}
                title={'Número de teléfono'}
                userData={'(506) ' + phoneOnCard}
              >
                Editar
              </MyProfileCard>
            </div>
            <Modal
              open={openPhone}
              onClose={handleClosePhone}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Cambiar número de teléfono
              </Typography>
              <br />
              <div className={classes.inputBlock}>
                <BsTelephone />
                <label>+(506)&nbsp; </label>
                <input
                  maxLength={8}
                  required
                  onChange={(e) => {
                    handleChangePhone(e, 'phone');
                  }}
                  value={phone}
                />
              </div>
              <br />
              <div className={classes.buttons}>
                <Button
                  disabled={disableSaveButtonPhone}
                  onClick={() => {
                    handlePhoneSave('phone');
                    handleClosePhone();
                  }}
                  variant="contained"
                  endIcon={<SaveIcon />}
                >
                  Guardar
                </Button>
                <Button
                  className={classes.redBtn}
                  variant="outlined"
                  onClick={() => {
                    clearInputOnClosure();
                    handleClosePhone();
                  }}
                >
                  Atrás
                </Button>
              </div>
            </Modal>
          </div>

          <div>
            <div>
              <MyProfileCard
                onClick={handleOpenWhatsapp}
                btnOption={true}
                title={'Número de WhatsApp'}
                userData={'(506) ' + whatsappPhoneOnCard}
              >
                Editar
              </MyProfileCard>
            </div>
            <Modal
              open={openWhatsappPhone}
              onClose={handleCloseWhatsapp}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Cambiar número de WhatsApp
              </Typography>
              <br />
              <div className={classes.inputBlock}>
                <BsTelephone />
                <label>+(506)&nbsp; </label>
                <input
                  maxLength={8}
                  required
                  onChange={(e) => {
                    handleChangePhone(e, 'whatsapp');
                  }}
                  value={whatsappPhone}
                />
              </div>
              <br />
              <div className={classes.buttons}>
                <Button
                  disabled={disableSaveButtonPhone}
                  onClick={() => {
                    handlePhoneSave('whatsapp');
                    handleCloseWhatsapp();
                  }}
                  variant="contained"
                  endIcon={<SaveIcon />}
                // disabled={true}
                >
                  Guardar
                </Button>
                <Button
                  className={classes.redBtn}
                  variant="outlined"
                  onClick={() => {
                    handleCloseWhatsapp();
                    clearInputOnClosure();
                  }}
                >
                  Atrás
                </Button>
              </div>
            </Modal>
          </div>
          {user.userRole !== 2 ? (
            <MyProfileCard
              title={'Registrar negocio'}
              userData={
                '¿Tienes un negocio de venta de carros? Únete a Autos Nova.'
              }
              btnOption={true}
              onClick={handleRegisterDealer}
            >
              Unirme
            </MyProfileCard>
          ) : (
            <></>
          )}

          {/* <MyProfileCard
            title={'Verificación de identidad (En Camino)'}
            userData={
              'Carros de usuarios verificados tendrán prioridad en las resultados de búsqueda'
            }
          /> */}
        </div>
      ) : (
          <div> </div>
        // <NeedToLogin />
      )}
    </div>
  );
}

// export default MyProfile;

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserPhone: (token, uuidUser, newPhone, typeOfPhone) =>
      dispatch(updateUserPhone(token, uuidUser, newPhone, typeOfPhone)),
    getUser: (email, authProvider, authProviderId) =>
      dispatch(getUserRequest(email, authProvider, authProviderId)),
  };
};

const mapStateToProps = (state) => {
  return {
    // userCars: state.UserCars.userCars,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
