import axios from 'axios';
import { Link } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import classes from './CarOverview.module.css';
import { TbManualGearbox } from 'react-icons/tb';
import { LuFileSignature } from 'react-icons/lu';
import { FaMinus, FaPlus } from 'react-icons/fa6';
import React, { useEffect, useState } from 'react';
import { BiSolidCarMechanic } from 'react-icons/bi';
import { FaRoad, FaHandshake } from 'react-icons/fa';
import { TbEngine, TbLocationFilled } from 'react-icons/tb';
import { IoMdBatteryCharging, IoIosTimer, IoMdSync } from 'react-icons/io';
import { MdOutlineInvertColors, MdOutlineLocationOn } from 'react-icons/md';

// React Icons
import {
  AiOutlineBgColors,
  AiOutlineCheckCircle,
  AiOutlineCalendar,
  AiOutlineLineChart,
  AiOutlineNumber,
} from 'react-icons/ai';
import {
  BsCarFront,
  BsFillFuelPumpFill,
  BsPersonCheck,
  BsFillPlugFill,
  BsFillPersonFill,
} from 'react-icons/bs';
import {
  GiCarDoor,
  GiSandsOfTime,
  GiElectric,
  GiGearStick,
  GiCarWheel,
} from 'react-icons/gi';
import { MdOutlineAutoFixHigh } from 'react-icons/md';
import { PiSticker } from 'react-icons/pi';

const formatNumberWithCommas = (number) => {
  if (number) {
    return number.toLocaleString();
  }
  return '';
};
function CarOverview({
  details,
  carViews,
  windowSize,
  handleContactClick,
  dealerName,
}) {
  const currentYear = new Date().getFullYear();
  const yearsOld = currentYear - details.year;
  const totalKilometres =
    details.kilometres === 0 ? details.miles : details.kilometres;
  const [showAll, setShowAll] = useState(false);
  const [valorUsd, setValorUsd] = useState(undefined);
  const [valorCarroUsd, setValorCarroUsd] = useState(undefined);
  const [valorTraspaso, setValorTraspaso] = useState(undefined);

  const fetchExchangeRate = async () => {
    try {
      // Precio del USD - Banco Popular
      const response = await axios.get(
        'https://www.appsbp.com/WsSINPEMovilV2/ServiciosGeneral/indicadoresfinancieros'
      );

      // Filtrar el valor de Tipo Cambio Venta y eliminar los ceros al final
      const indicadores = response.data.Indicadores;
      const tipoCambioVenta = indicadores.find(
        (indicador) => indicador.Descripcion === 'Tipo Cambio Venta'
      );
      const valorVenta = parseInt(tipoCambioVenta.Valor);
      setValorUsd(valorVenta);
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  // Llamar a fetchExchangeRate cuando el componente se monta
  useEffect(() => {
    fetchExchangeRate();
  }, []); // El array de dependencias está vacío para que se ejecute solo una vez al montar el componente

  const calcularValorCarroUsd = (priceCRC, valorUsd) => {
    try {
      // Realiza los cálculos necesarios
      let carPriceUsd = priceCRC / valorUsd;
      carPriceUsd = Math.ceil(carPriceUsd / 5) * 5;

      // Valida para evitar NaN
      const formattedPriceUsd = Number.isInteger(carPriceUsd)
        ? carPriceUsd.toLocaleString('en-US')
        : undefined;

      setValorCarroUsd(formattedPriceUsd);
    } catch (error) {
      console.error('Error al calcular el valor en USD:', error);
      return undefined;
    }
  };

  useEffect(() => {
    // fetchExchangeRate();
    calcularValorCarroUsd(details.priceCRC, valorUsd);
    calcularTraspaso(details.priceCRC);
  }, [details.carPriceCrc, valorUsd]); // El array de dependencias está vacío para que se ejecute solo una vez al montar el componente

  // Realiza los cálculos necesarios
  const calcularTraspaso = (priceCRC) => {
    const traspasoPrice = (details.priceCRC / 20.20202).toLocaleString(
      'en-US',
      { maximumFractionDigits: 0 }
    );

    setValorTraspaso(traspasoPrice);
  };

  // Get the date in text format from details.createdOn
  const createdOn = details.approveDate; //TODO: Cambiar a FECHA DE PUBLICADO!!!
  const date = new Date(createdOn);

  const getDiasNoCircula = (plate) => {
    const ultimoDigito = parseInt(plate.slice(-1));

    switch (ultimoDigito) {
      case 1:
      case 2:
        return 'los lunes';
      case 3:
      case 4:
        return 'los martes';
      case 5:
      case 6:
        return 'los miércoles';
      case 7:
      case 8:
        return 'los jueves';
      case 9:
      case 0:
        return 'los viernes';
      default:
        return 'no hay restricciones específicas';
    }
  };

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // Get the day and month in text

  const day = date.getDate();
  const monthText = months[date.getMonth()];
  const year = date.getFullYear();
  const formattedDate = `${day} ${monthText}, ${year}`;

  function getTimeAgoText() {
    const currentDate = new Date();

    // Establecer las horas, minutos, segundos y milisegundos a 0 para ambas fechas
    currentDate.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    const timeDifference = currentDate - date;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const monthsDifference =
      currentDate.getMonth() -
      date.getMonth() +
      12 * (currentDate.getFullYear() - date.getFullYear());
    const yearsDifference = currentDate.getFullYear() - date.getFullYear();

    if (daysDifference === 0) {
      return 'Hoy';
    } else if (daysDifference === 1) {
      return '1 día';
    } else if (daysDifference < 30) {
      return `${daysDifference} días`;
    } else if (monthsDifference === 1) {
      return '1 mes';
    } else if (monthsDifference < 12) {
      return `${monthsDifference} meses`;
    } else if (yearsDifference === 1) {
      return '1 año';
    } else {
      return `${yearsDifference} años`;
    }
  }

  const timeAgoText = getTimeAgoText();

  // Calcula la cantidad de kilómetros recorridos por año
  const kilometresPerYear =
    yearsOld === 0
      ? totalKilometres.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : Math.abs(totalKilometres / yearsOld).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.priceTitle}>
          <div className={classes.price}>
            <h3>
              ₡
              {details.priceCRC.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}
              &nbsp;
            </h3>
            {valorCarroUsd && (
              <h3 className={classes.priceOtherCurrency}>
                / ~$ {valorCarroUsd}
              </h3>
            )}
          </div>
        </div>

        <div className={classes.carInformationGeneral}>
          <h3>Información General</h3>
          <div className={classes.carInfoContent}>
            <ul>
              {windowSize <= 900 && dealerName ? (
                <li className={classes.dealerProfile}>
                  <span>
                    <CgProfile />
                  </span>
                  <div className={classes.item}>
                    <span>Negocio</span>
                    <p onClick={handleContactClick}>{dealerName}</p>
                  </div>
                </li>
              ) : (
                <></>
              )}

              <li>
                <IoIosTimer className={classes.icon} />
                <div className={classes.item}>
                  <span>
                    {details.kilometreType === 'Mi' ? 'Millaje' : 'Kilometraje'}
                  </span>
                  <p>
                    {details.kilometreType === 'Mi'
                      ? `${formatNumberWithCommas(details.miles)} Mi`
                      : `${formatNumberWithCommas(details.kilometres)} Km`}
                  </p>
                </div>
              </li>

              <li>
                <FaRoad className={classes.icon} />
                <div className={classes.item}>
                  <span>
                    {details.kilometreType === 'Mi'
                      ? 'Millaje promedio por año'
                      : 'Kilometraje promedio por año'}
                  </span>
                  <p>
                    ~{kilometresPerYear}{' '}
                    {details.kilometreType === 'Mi' ? 'Mi' : 'Km'}
                  </p>
                </div>
              </li>
              <li>
                <GiSandsOfTime className={classes.icon} />
                <div className={classes.item}>
                  <span>Años de antigüedad</span>
                  <p>{yearsOld <= 0 ? `0 (Carro del año)` : yearsOld}</p>
                </div>
              </li>
              <li>
                <PiSticker className={classes.icon} />
                <div className={classes.item}>
                  <span>Marchamo más reciente</span>
                  {details.lastMarchamo ? (
                    <p>~₡{parseInt(details.lastMarchamo).toLocaleString()}</p>
                  ) : (
                    <p className={classes.greyText}>
                      No se ha especificado el marchamo
                    </p>
                  )}
                </div>
              </li>

              <li>
                <AiOutlineNumber className={classes.icon} />
                <div className={classes.item}>
                  <span>Placa</span>
                  {details.plate === '-1' ? (
                    <>
                      <p>Sin especificar</p>
                      <p className={classes.greyText}>
                        No se ha especificado la placa
                      </p>
                    </>
                  ) : (
                    <>
                      <p>**{details.plate.slice(-1)}</p>
                      <p className={classes.greyText}>
                        Restricción vehicular {getDiasNoCircula(details.plate)}
                      </p>
                    </>
                  )}
                </div>
              </li>
            </ul>
            <ul>
              {/* ESPACIO PARA COLUMNA DERECHA */}

              <li>
                <BsFillPersonFill className={classes.icon} />
                <div className={classes.item}>
                  <span>Único dueño</span>
                  <p>{details.uniqueOwner ? 'Sí' : 'No'}</p>
                </div>
              </li>
              <li>
                <MdOutlineLocationOn className={classes.icon} />
                <div className={classes.item}>
                  <span>Provincia</span>
                  <p>
                    {details.canton}, {details.provincia}
                  </p>
                </div>
              </li>
              <li>
                <BiSolidCarMechanic className={classes.icon} />
                <div className={classes.item}>
                  <span>Condición del carro</span>
                  <p>{details.condition}</p>
                </div>
              </li>
              {/*  */}

              <li>
                <FaHandshake className={classes.icon} />
                <div className={classes.item}>
                  <span>Precio negociable</span>{' '}
                  <p>{details.negotiablePrice ? 'Sí' : 'No'}</p>
                </div>
              </li>

              <li>
                <IoMdSync className={classes.icon} />
                <div className={classes.item}>
                  <span>Recibe carro</span>{' '}
                  <p>{details.ownerAcceptsCar ? 'Sí' : 'No'}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className={classes.carInformation}>
          <h3>Información del carro</h3>
          <div className={classes.carInfoContent}>
            <ul>
              <li>
                <TbManualGearbox className={classes.icon} />
                <div className={classes.item}>
                  <span>Transmisión</span>
                  <p>{details.transmission}</p>
                </div>
              </li>
              <li>
                <GiGearStick className={classes.icon} />
                <div className={classes.item}>
                  <span>Tracción</span> <p>{details.traction}</p>
                </div>
              </li>

              {/* Muestra cilindraje para carro no eléctricos */}
              {details.engineCC && (
                <li>
                  <TbEngine className={classes.icon} />
                  <div className={classes.item}>
                    <span>Cilindrada</span>
                    <p>{formatNumberWithCommas(details.engineCC)} CC</p>
                  </div>
                </li>
              )}
              {/* Capacidad de batería - Eléctricos */}
              {details.electricBatteryCapacity &&
                details.electricBatteryCapacity > 0 && (
                  <li>
                    <IoMdBatteryCharging className={classes.icon} />
                    <div className={classes.item}>
                      <span>Capacidad de batería</span>
                      <p>
                        {formatNumberWithCommas(
                          details.electricBatteryCapacity
                        )}{' '}
                        kWh
                      </p>
                    </div>
                  </li>
                )}

              {/* electricAutonomy - Eléctricos */}
              {details.electricAutonomy && (
                <li>
                  <TbLocationFilled className={classes.icon} />
                  <div className={classes.item}>
                    <span>Autonomía</span>
                    <p>{formatNumberWithCommas(details.electricAutonomy)} Km</p>
                  </div>
                </li>
              )}

              <li>
                <GiCarWheel className={classes.icon} />
                <div className={classes.item}>
                  <span>Condición de las llantas</span>
                  <p>{details.tireCondition}</p>
                </div>
              </li>
              <li>
                <GiCarDoor className={classes.icon} />
                <div className={classes.item}>
                  <span>Puertas</span> <p>{details.doors}</p>
                </div>
              </li>
              <li>
                <AiOutlineBgColors className={classes.icon} />
                <div className={classes.item}>
                  <span>Color exterior</span> <p>{details.exteriorColor}</p>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                {details.engine === 'Eléctrico' ? (
                  <>
                    <GiElectric className={classes.icon} />
                  </>
                ) : (
                  <>
                    <BsFillFuelPumpFill className={classes.icon} />
                  </>
                )}
                <div className={classes.item}>
                  <span>Combustible</span>
                  <p>{details.engine}</p>
                </div>
              </li>

              {/*  */}
              {/* Conector - Eléctricos */}
              {details.electricChargerType && (
                <li>
                  <BsFillPlugFill className={classes.icon} />
                  <div className={classes.item}>
                    <span>Tipo de conector</span>
                    <p>{details.electricChargerType}</p>
                  </div>
                </li>
              )}

              {/*  */}
              <li>
                <MdOutlineAutoFixHigh className={classes.icon} />
                <div className={classes.item}>
                  <span>Posee modificaciones</span>
                  <p>{details.modifications ? 'Si' : 'No'}</p>
                </div>
              </li>

              <li>
                <BsPersonCheck className={classes.icon} />
                <div className={classes.item}>
                  <span>Asientos</span> <p>{details.passengers}</p>
                </div>
              </li>
              <li>
                <BsCarFront className={classes.icon} />
                <div className={classes.item}>
                  <span>Estilo</span>
                  <p>{details.carType}</p>
                </div>
              </li>
              <li>
                <MdOutlineInvertColors className={classes.icon} />
                <div className={classes.item}>
                  <span>Color interior</span> <p>{details.interiorColor}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.features}>
          <h3>Características</h3>

          {details.features?.length >= 1 && showAll ? (
            <ul>
              {details.features.map((el, i) => (
                <li className={classes.featureItem} key={el}>
                  <AiOutlineCheckCircle />
                  {el}
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              {details.features?.map((el, i) => {
                if (i < 6) {
                  return (
                    <li className={classes.featureItem} key={el}>
                      <AiOutlineCheckCircle />
                      {el}
                    </li>
                  );
                }
              })}
            </ul>
          )}

          {details.features?.length >= 7 ? (
            showAll ? (
              <button
                className={classes.showBtn}
                onClick={() => setShowAll(false)}
              >
                <FaMinus /> Mostrar menos
              </button>
            ) : (
              <button
                className={classes.showBtn}
                onClick={() => {
                  setShowAll(true);
                }}
              >
                <FaPlus /> Mostrar más
              </button>
            )
          ) : (
            <></>
          )}

          {details.features == null && (
            <p className={classes.greyText}>
              No se han especificado características
            </p>
          )}
        </div>

        <div className={classes.carInformation}>
          <h3>Detalles adicionales</h3>
          <div className={classes.carInfoContent}>
            <ul>
              <li>
                <LuFileSignature className={classes.icon} />
                <div className={classes.item}>
                  <span>Aprox. de traspaso</span>
                  <p>~₡{valorTraspaso} + honorarios</p>
                </div>
              </li>

              {/* <li>
                <AiOutlineCalendar className={classes.icon} />
                <div className={classes.item}>
                  <span>Publicado</span>{' '}
                  <p>
                    {formattedDate} | {timeAgoText}
                  </p>
                </div>
              </li> */}
              {/* {carViews !== undefined && carViews >= 0 && (
                <li>
                  <AiOutlineLineChart className={classes.icon} />
                  <div className={classes.item}>
                    <span>Visitas</span>
                    <p>{carViews}</p>
                  </div>
                </li>
              )} */}
            </ul>
          </div>
        </div>
        <div className={classes.notes}>
          <h3>Notas del carro</h3>
          {details.notes ? (
            <pre style={{ fontFamily: 'Roboto' }}>{details.notes}</pre>
          ) : (
            <p className={classes.greyText}>No se han especificado notas</p>
          )}
        </div>
      </div>
      <br />
      <Link to="/en-venta" className={classes.link}>
        <button className={classes.goBackButton}>
          Ver otros carros en Autos Nova
        </button>
      </Link>
      <br />
      <br />

      <Link
        to="https://www.instagram.com/AutosNovaCR"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <button className={classes.goToInstaButton}>Carros en Instagram</button>
      </Link>
      <br />
      <br />
      <Link
        to="https://whatsapp.com/channel/0029VaDPAQ70rGiEpwrWHq3y"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <button className={classes.goToWhatsAppButton}>
          Carros en WhatsApp
        </button>
      </Link>
    </div>
  );
}

export default CarOverview;
