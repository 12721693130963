// import { useEffect } from 'react';
import classes from './MyProfileCard.module.css';
import SmallButton from '../Buttons/SmallButton';

function MyProfileCard({ title, userData, btnOption, onClick, children }) {
  return (
    <div className={classes.container}>
      <div className={classes.carsList}>
        <div className={classes.carDetails}>
          <h5>{title}</h5>
          <p>{userData}</p>
        </div>
        {btnOption ? <div><SmallButton onClick={onClick}>{children}</SmallButton ></div> : <></>}
      </div>
    </div>
  );
}

export default MyProfileCard;
