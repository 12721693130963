import { setTokenInLocalStorage } from '../../helpers/jwtService';
import {
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CLEAN_USER_START,
  CLEAN_USER_SUCCESS,
  CLEAN_USER_FAILURE,
  SET_IS_LOGGED_IN_START,
  SET_IS_LOGGED_IN_FAILURE,
  SET_IS_LOGGED_IN_SUCCESS,
  UPDATE_USER_PHONE_START,
  UPDATE_USER_PHONE_SUCCESS,
  UPDATE_USER_PHONE_FAILURE,
} from './userTypes';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const createUserStart = () => {
  return { type: CREATE_USER_START };
};

const createUserSuccess = (data) => {
  setTokenInLocalStorage(data.token)
  return { type: CREATE_USER_SUCCESS, payload: data.user.data };
};

const createUserFailure = (err) => {
  return { type: CREATE_USER_FAILURE, payload: err };
};

const getUserRequestStart = () => {
  return { type: GET_USER_START };
};

const getUserRequestSuccess = (data) => {
  return { type: GET_USER_SUCCESS, payload: data };
};

const getUserRequestFailure = (err) => {
  return { type: GET_USER_FAILURE, payload: err };
};

const cleanUserRequestStart = () => {
  return { type: CLEAN_USER_START };
};

const cleanUserRequestSuccess = () => {
  return { type: CLEAN_USER_SUCCESS };
};

const cleanUserRequestFailure = (err) => {
  return { type: CLEAN_USER_FAILURE, payload: err };
};

const setIsLoggedInStart = () => {
  return { type: SET_IS_LOGGED_IN_START };
};

const setIsLoggedInSuccess = (data) => {
  return { type: SET_IS_LOGGED_IN_SUCCESS, payload: data };
};

const setIsLoggedInFailure = (err) => {
  return { type: SET_IS_LOGGED_IN_FAILURE, payload: err };
};

const updateUserPhoneStart = () => {
  return { type: UPDATE_USER_PHONE_START };
};

const updateUserPhoneSuccess = (data) => {
  setTokenInLocalStorage(data.token)
  return { type: UPDATE_USER_PHONE_SUCCESS, payload: data.user };
};

const updateUserPhoneFailure = (err) => {
  return { type: UPDATE_USER_PHONE_FAILURE, payload: err };
};

export const createUser = (data) => (dispatch) => {
  dispatch(createUserStart());
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}/api/User/CreateUser`, data)
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        resolve(true);
      })
      .catch((err) => {
        dispatch(createUserFailure(err));
        reject(false);
      });
  });
};

export const getUserRequest = (email, authProvider, authProviderId) => (dispatch) => {
  dispatch(getUserRequestStart());
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}/api/User/GetUser?email=${email}&authProvider=${authProvider}&authProviderId=${authProviderId}`
      )
      .then((response) => {
        if (response.data.code === 200) {
          dispatch(getUserRequestSuccess(response.data));
          resolve(response.data);
        }
        if (response.data.code === 300) {
          dispatch(getUserRequestFailure());
          resolve(null);
        }
      })
      .catch((err) => {
        dispatch(getUserRequestFailure(err));
        reject();
      });
  });
};

export const cleanUserRequest = (data) => (dispatch) => {
  dispatch(cleanUserRequestStart());
  try {
    dispatch(cleanUserRequestSuccess());
  } catch (error) {
    cleanUserRequestFailure();
  }
};

export const setJwtUserRequest = (jwt) => (dispatch) => {
  dispatch(setIsLoggedInStart());
  return new Promise((resolve, reject) => {
    try {
      dispatch(setIsLoggedInSuccess(jwt));
      resolve(true)
    }
    catch (error) {
      setIsLoggedInFailure();
      reject(false)
    }
  })
};

export const updateUserPhone =
  (token, uuidUser, newPhone, typeOfPhone) => (dispatch) => {
    dispatch(updateUserPhoneStart());

    return new Promise((resolve, reject) => {

      let config = {
        method: 'put',
        url: `${baseUrl}/api/User/UpdateUserPhone?newPhone=${newPhone}&typeOfPhone=${typeOfPhone}&uuidUser=${uuidUser}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          dispatch(updateUserPhoneSuccess(response.data));
          resolve(true)
        })
        .catch((error) => {
          dispatch(updateUserPhoneFailure(error));
          reject(false)
        });
    })
  };
