import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classes from './SellYourCar.module.css';
import Button from '../components/Buttons/Button';
import { connect, useSelector } from 'react-redux';
import Pricing from '../components/Pricing/Pricing';
import imageCompression from 'browser-image-compression';
import Container from '../components/Container/Container';
import CarOffer from '../components/SellYourCar/CarOffer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CarFeatures from '../components/SellYourCar/CarFeatures';
import ImageUploader from '../components/ImageUploader/ImageUploader';
import CarReviewClients from '../components/CarReviewClients/CarReviewClients';
import {
  getCarBrands,
  getCarModels,
  getCarStyles,
  getEngineOptions,
  getFeatureList,
  createCar,
  getCarByPlate,
  addCarFeatures,
  addCarPictures,
} from '../store/VehicleStore/vehicleActions';
import {
  getProvincias,
  getCantones,
} from '../store/LocationStore/locationActions';
import SellingSteps from '../components/SellingSteps/SellingSteps';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import SmallAlert from '../components/Alert/SmallAlert';
import Login from '../components/Login/Login';
import getToast from '../helpers/getToast';
import { getTokenFromLocalStorage } from '../helpers/jwtService';

function SellYourCar({
  getFeatureList,
  checkCarByPlate,
  getBrands,
  getModels,
  carInfo,
  locationInfo,
  getStyles,
  getEngines,
  getProvinciasList,
  getCantonesList,
  addNewCar,
  postCarFeatures,
  addPictures,
}) {
  // const BASE_URL = process.env.REACT_APP_API_URL;
  const [showVehicle, setShowVehicle] = useState('');
  const [savedDetails, setSavedDetails] = useState({ details: {} });
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPictureModal, setPictureModal] = useState(false); //CAMBIAR PARA testing
  const [fileSelected, setFileSelected] = useState();
  const [pathImage, setPathImage] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [sellingStep, setSellingStep] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showReviewCar, setShowReviewCar] = useState(false); //CAMBIAR PARA testing
  const isUserLoggedIn = useSelector((state) => state.UserProfile.loggedIn);

  const localStorageValue = localStorage.getItem('cardata');
  const plate = localStorageValue ? JSON.parse(localStorageValue).plate : '';
  let formattedPlate = plate;
  if (/^[A-Za-z]{3}\d{3}$/.test(plate)) {
    formattedPlate = `${plate.substr(0, 3)}-${plate.substr(3)}`;
  }

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  const updatePage = (option) => {
    getBrands();
    getStyles();
    getEngines();
    setShowVehicle(option);
    getProvinciasList();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getFeatureList();
  }, []);

  const saveCarDetails = (details, newValues) => {
    setSavedDetails({ details, newValues });
    getFeatureList();
  };

  useEffect(() => {
    if (!hasChanged) return;

    const handleOnBeforeUnload = (event) => {
      event.preventDefault();
      return (event.returnValue = '');
    };
    window.addEventListener('beforeunload', handleOnBeforeUnload, {
      capture: true,
    });
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload, {
        capture: true,
      });
    };
  }, [hasChanged]);
  //Se utiliza el token para autenticar con el Backend

  const createNewCar = async (features, extraDetails) => {
    try {
      const body = {
        carList: {
          ...extraDetails,
          ...savedDetails.details,
        },
        valuesUnderReview: [...savedDetails.newValues],
        planId: selectedPlan,
      };
      const token = getTokenFromLocalStorage();
      const result = await addNewCar(body, token);

      if (!result) {
        getToast({
          type: 'ERROR',
          text: 'No se ha podido crear tu carro. Vuelve a intentarlo o contacta a soporte.',
        });
      }
      if (result.carId >= 1) {
        postCarFeatures(features, result.carId);
        setPictureModal(true);
        setSellingStep(2);
        window.scrollTo(0, 0);
      }
    } catch (ex) {
      getToast({
        type: 'ERROR',
        text: 'Se ha presentado un error. Vuelve a intentarlo o contacta a soporte.',
      });
    }

    //Create car retorna id y creamos features.
  };

  const saveFileSelected = (e) => {
    e.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function load() {
        setPathImage((prev) => {
          return [...prev, reader.result];
        });
      };
    });

    setFileSelected(e);
  };

  const handleStep = (step) => {
    setSellingStep(step);
  };

  useEffect(() => {
    const checkCar = async (carUuid) => {
      const response = await checkCarByPlate('', carUuid);
      if (response === 200) {
        setSellingStep(1);
        updatePage('ExistingCar');

        const plan = carInfo.plan !== -1 ? carInfo.plan : 1;

        setSelectedPlan(plan);
      }
    };
    const url = new URL(window.location.href);
    const carUuid = url.pathname.split('/').pop();
    if (carUuid !== '' && carUuid !== 'vender-mi-carro') {
      checkCar(carUuid);
    }
  }, []);

  const importFile = async (e) => {
    try {
      const formData = new FormData();
      const compressedFiles = [];

      for (let index = 0; index < fileSelected.length; index++) {
        const file = fileSelected[index];

        // Perform file checks
        const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxFileSize = 9 * 1024 * 1024; // 5MB

        if (allowedFormats.includes(file.type) && file.size <= maxFileSize) {
          const compressedFile = await imageCompression(file, {
            // Calidad de imagen
            maxSizeMB: 0.5, // Maximum file size after compression (in megabytes)
            maxWidthOrHeight: 1080, // Maximum width or height after compression
            useWebWorker: true,
          });
          compressedFiles.push(compressedFile);
          formData.append(
            'file',
            compressedFile,
            carInfo.createdCarUuid + '_' + carInfo.createdCarId
          );
        } else {
          // Invalid file format or size
          // Show an error message or perform appropriate actions
          console.log('Invalid file format or size');
          setUploading(false);
          setShowAlert(true);
        }
      }

      const addPictureResponse = await addPictures(formData);
      if (addPictureResponse) {
        setPictureModal(false);
        setShowReviewCar(true);
        setSellingStep(3);
        setUploading(false);
        window.scrollTo(0, 0);
      }
    } catch (ex) {
      setUploading(false);
      console.log(ex);
    }
  };

  const handleCheckout = () => {
    setUploading(true);

    importFile();
  };

  const handlePriceSelection = (selectedOption) => {
    if (!isUserLoggedIn) {
      setShowLogin(true);
      getToast({
        text: 'Debes registrarte antes de vender',
        style: { icon: '⚠️' },
      });
    } else {
      setSelectedPlan(selectedOption);
      setHasChanged(true);
    }
  };
  if (showPictureModal) {
    return (
      <div className={classes.container}>
        <br />
        <SellingSteps step={sellingStep} />
        <div className={classes.content}>
          <div className={classes.imgPosition}>
            <Container>
              <div className={classes.inputContainer}>
                <ImageUploader
                  onChange={saveFileSelected}
                  planNumber={selectedPlan}
                />
              </div>

              {/* TODO: Revisar loading/Uploading con Moises */}
              {uploading && (
                <div className={classes.loadingSpinner}>
                  <LoadingSpinner />
                </div>
              )}

              {/* TODO: Manera de agregar o eliminar fotos antes darle finalizar. */}

              <Button
                onClick={handleCheckout}
                disabled={uploading || fileSelected?.length === 0}
              >
                Continuar
              </Button>

              {showAlert ? (
                <SmallAlert>
                  El formato de la imagen solo puede ser jpeg, png y jpg.
                </SmallAlert>
              ) : (
                <></>
              )}
            </Container>
          </div>
        </div>
      </div>
    );
  }

  const handleClearLocalStorage = () => {
    localStorage.removeItem('cardata');
  };
  if (showSuccessMsg) {
    return (
      <div className={classes.container}>
        <HelmetProvider>
          <Helmet>
            <title>¡Listo! - Autos Nova</title>
            <meta name="description" content="¡Listo! - Autos Nova" />
          </Helmet>
        </HelmetProvider>
        <br />
        <br />
        <SellingSteps step={sellingStep} />
        <div className={classes.completed}>
          <h2>¡Listo, el vehículo {formattedPlate} ha sido registrado! </h2>
          <h6>
            La publicación será revisada por algún miembro de nuestro equipo.
          </h6>
          <br />
          <h6>
            Te llegará un mensaje de WhatsApp cuando el anuncio sea publicado.
          </h6>
        </div>
        <div className={classes.buttonContainer}>
          <Link to="/mis-anuncios" className={classes.buttonLink}>
            <Button onClick={handleClearLocalStorage}>
              Visitar mis anuncios
            </Button>
          </Link>
          <Link to="/en-venta" className={classes.buttonLink}>
            <Button onClick={handleClearLocalStorage}>
              Ver otros carros en venta
            </Button>
          </Link>
        </div>
      </div>
    );
  }

  if (showReviewCar) {
    return (
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Revisión - Autos Nova</title>
            <meta
              name="description"
              content="Vende tu carro en línea - Autos Nova"
            />
          </Helmet>
        </HelmetProvider>

        <br />
        <br />
        <SellingSteps step={sellingStep} />
        <div
          style={{
            backgroundColor: '#fff',
            marginTop: '30px',
            width: '100%',
          }}
        >
          <div>
            <br />
            <CarReviewClients
              setSellingStep={handleStep}
              getCarModels={getModels}
              carInfo={carInfo}
              savedDetails={savedDetails}
              plan={selectedPlan}
              setShowSuccessMsg={setShowSuccessMsg}
            />
          </div>
        </div>
      </div>
    );
  }

  //SE INGRESAN LOS DATOS Y AL FINALIZAR SE MUESTRA EL VEHICULO Y LOS DETALLES INGRESADOS PARA CONFIRMAR

  if (selectedPlan === 0) {
    return (
      <>
        {showLogin && (
          <Login
            showLogin={showLogin}
            onClose={() => {
              setShowLogin(false);
            }}
            title={'Regístrate para continuar'}
          />
        )}
        <Pricing handleSelect={handlePriceSelection} />
      </>
    );
  }

  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <title>Anunciar mi carro - Autos Nova</title>
          <meta
            name="description"
            content="Vende tu carro en línea - Autos Nova"
          />
        </Helmet>
      </HelmetProvider>
      <br />
      <SellingSteps step={sellingStep} />
      <div className={classes.content}>
        {savedDetails?.details.plate === undefined ? (
          <div className={classes.formPosition}>
            <CarOffer
              getEngineOptions={getEngines}
              carInformation={carInfo}
              changePage={updatePage}
              showVehicle={showVehicle}
              checkCarByPlate={checkCarByPlate}
              getCarModels={getModels}
              getCantonesList={getCantonesList}
              locationInfo={locationInfo}
              saveCarDetails={saveCarDetails}
              setSellingStep={handleStep}
            />
          </div>
        ) : (
          <div className={classes.featuresPosition}>
            <CarFeatures
              features={carInfo.carFeaturesList}
              createNewCar={createNewCar}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNewCar: (data, token) => dispatch(createCar(data, token)),
    checkCarByPlate: (plate, uuidCar) =>
      dispatch(getCarByPlate(plate, uuidCar)),
    getBrands: () => dispatch(getCarBrands()),
    getModels: (data) => dispatch(getCarModels(data)),
    getStyles: () => dispatch(getCarStyles()),
    getEngines: () => dispatch(getEngineOptions()),
    getProvinciasList: () => dispatch(getProvincias()),
    getCantonesList: (data) => dispatch(getCantones(data)),
    getFeatureList: () => dispatch(getFeatureList()),
    postCarFeatures: (data, carId) => dispatch(addCarFeatures(data, carId)),
    addPictures: (data, carId) => dispatch(addCarPictures(data, carId)),
  };
};

const mapStateToProps = (state) => {
  return {
    carInfo: state.CarOptions,
    locationInfo: state.LocationInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellYourCar);
