import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { FormControlLabel, Checkbox } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ items, updateFeatures, children, title }) {
  const [expanded, setExpanded] = useState('');

  const [carFeatures, setCarFeatures] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleFeatureCheck = (el, onChange) => {
    const featureId = el.target.value;
    const isChecked = el.target.checked;
    if (isChecked) {
      setCarFeatures((prev) => {
        //     onChange([featureId,...carFeatures]);
        return [...prev, featureId];
      });
    } else {
      setCarFeatures((prev) => {
        return prev.filter((el) => el !== featureId);
      });
    }

  };

  // useEffect(() => {
  //   updateFeatures(carFeatures);
  // }, [])

  const theme = {
    fontWeight: '600',
    display: 'inline-block', width: '100%', textAlign: 'start', fontSize: '14px !important',
    margin: '0px'
  }



  return (

    <Accordion sx={{ backgroundColor: '#fff', border: 'none', width: '100%', borderTop: '1px solid #e5e5e5' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
      <AccordionSummary sx={{ backgroundColor: '#fff' }} aria-controls="panel1d-content" id="panel1d-header">
        <h4>{title}</h4>
      </AccordionSummary> 
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>

  );
}