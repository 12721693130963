// import Map from '../components/Map/Map';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaWaze } from 'react-icons/fa';
import Rating from '@mui/material/Rating';
import getToast from '../helpers/getToast';
import classes from './MyDealer.module.css';
import { useEffect, useState } from 'react';
import { MdOutlinePageview } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Modal from '../components/Modal/Modal';
import { FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getUserCarList } from '../store/UserCarsStore/userCarsActions';
import CarItemHorizontal from '../components/CarItem/CarItemHorizontal';
import Button from '../components/Buttons/Button';
import { styled } from '@mui/material/styles';
import Review from '../components/Review/Review';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import LoadingPage from '../components/Loading/LoadingPage';
import { Link } from 'react-router-dom';
import { FaMinus, FaPlus, FaLocationDot, FaInstagram } from 'react-icons/fa6';
import { FaPhoneSquareAlt } from 'react-icons/fa';

import {
  getDealer,
  getDealerById,
  getDealerCars,
  getDealerReview,
} from '../store/DealerStore/DealerAction';

const iconFull = <StarRoundedIcon sx={{ fontSize: '30px' }} />;
const iconEmpty = <StarBorderRoundedIcon sx={{ fontSize: '30px' }} />;

function MyDealer({
  getDealerCarList,
  dealer,
  getDealer,
  user,
  getDealerById,
  getDealerReview,
  carList,
}) {
  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#3861fb',
    },
    '& .MuiRating-iconHover': {
      color: '#3861fb',
    },
  });
  const { customUrl } = useParams();
  const [isDealer, setIsDealer] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [showSch, setShowSch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valorUsd, setValorUsd] = useState(undefined);
  const [showLogin, setShowLogin] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);

  const itemsCount = carList ? carList.length : 0;
  const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;
  const navigate = useNavigate();

  const formattedDate = (date) => {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-indexed
    const year = dateObject.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
  };
  const joiningDate = dealer.createdOn ? formattedDate(dealer.createdOn) : '';
  const whatsAppNumber = dealer.whatsappNumber;
  const phoneNumber = dealer.phoneNumber;
  let carTypes = '';

  if (dealer.sellerType === 1) {
    carTypes = 'General';
  } else if (dealer.sellerType === 2) {
    carTypes = 'Alta gama';
  } else if (dealer.sellerType === 3) {
    carTypes = 'Media gama';
  } else {
    carTypes = 'Baja gama';
  }

  let fechaFormateada = '';
  if (dealer.createdOn) {
    const date = new Date(dealer.createdOn);

    // Obtener el mes, día y año de la fecha actual
    var meses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    var mes = meses[date.getMonth()];
    var dia = date.getDate();
    var año = date.getFullYear();
    fechaFormateada = mes + ', ' + año;
  }

  const daysOfWeek = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ];

  const fetchExchangeRate = async () => {
    try {
      // Precio del USD - Banco Popular
      const response = await axios.get(
        'https://www.appsbp.com/WsSINPEMovilV2/ServiciosGeneral/indicadoresfinancieros'
      );
      // Filtrar el valor de Tipo Cambio Venta y eliminar los ceros al final
      const indicadores = response.data.Indicadores;
      const tipoCambioVenta = indicadores.find(
        (indicador) => indicador.Descripcion === 'Tipo Cambio Venta'
      );
      const valorVenta = parseInt(tipoCambioVenta.Valor);
      setValorUsd(valorVenta);
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  // Llamar a fetchExchangeRate cuando el componente se monta
  useEffect(() => {
    if (valorUsd === undefined) {
      fetchExchangeRate();
    }
  }, []); // El array de dependencias está vacío para que se ejecute solo una vez al montar el componente

  
  useEffect(() => {
    setLoading(true);

    const dealerInformation = async () => {
      try {
        let res = {};

        if (customUrl) {
          res = await getDealerById(customUrl);
        } else {
          res = await getDealer(user.uuidUser);
        }

        if (!res || Object.keys(res).length === 0) {
          throw new Error('No se obtuvo ninguna respuesta del concesionario');
        }

        const uuid = res.uuid;
        const idDealer = res.idDealer;
        const carResponse = await getDealerCarList(uuid);
        const reviewResponse = await getDealerReview(idDealer);

        if (reviewResponse.code === 200) {
          setReviews(reviewResponse.data);
          setLoading(false);
        } else {
          window.location.href = '/en-venta';
        }
      } catch (error) {
        console.error(
          'Error al obtener la información del concesionario:',
          error
        );
        window.location.href = '/en-venta';
      }
      setLoading(false);
    };

    dealerInformation();
  }, []);

  useEffect(() => {
    const userIsDealer = dealer.uuid === user.uuidUser ? true : false;
    setIsDealer(userIsDealer);
  }, [dealer]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddReview = () => {
    if (user.idUser) {
      navigate(`/agregar-review/${dealer.uuid}/${formatDealerName}`);
    } else {
      getToast({
        type: 'WARNING',
        text: 'Debes iniciar sesión para continuar.',
        style: { icon: '❗' },
      });
    }
  };

  const formatDealerName =
    dealer.dealerName !== undefined
      ? dealer.dealerName.split(' ').join('-').toLowerCase()
      : '';

  const coordinates = { lat: +dealer.lat, lng: +dealer.lng };

  const dealerCars =
    carList.length > 0 ? (
      <div className="py-2 w-full">
        <ul className="grid grid-cols-6 gap-4 w-full">
          {carList.slice(0, 3).map((car) => {
            return (
              <li className="col-span-6">
                <CarItemHorizontal car={car} valorUsd={valorUsd} />
              </li>
            );
          })}
        </ul>
        <Link
          style={{
            textDecoration: 'none',
            color: 'inherit',
            fontFamily: 'inherit',
          }}
          to={`/vendedor/${dealer.customUrl}/catalogo`}
        >
          <Button className={classes.verCatalogo}>Ver todo el catálogo</Button>
        </Link>
      </div>
    ) : isDealer ? (
      <div>
        <h5>De momento no tienes autos en venta.</h5>
        <Button
          onClick={() => {
            navigate('/vender-mi-carro/');
          }}
        >
          Agregar autos
        </Button>
      </div>
    ) : (
      <h6>
        Pronto agregaremos nuestro catálogo. Por mientras, comunícate
        directamente con nosotros.
      </h6>
    );

  const encodedMessage = encodeURIComponent(
    'Hola, vi un auto en Autos Nova que me interesa'
  );
  const handleWhatsAppClick = () => {
    window.open(
      `https://wa.me/+506${whatsAppNumber}?text=${encodedMessage}`,
      '_blank'
    );
  };

  const dealerInfo =
    dealer?.dealerInfo?.length > 200 ? (
      expanded ? (
        <p style={{ textAlign: 'start', wordBreak: 'break-word' }}>
          {' '}
          {dealer.dealerInfo}{' '}
          <span
            className="border border-gray bg-slate-200	p-1 font-bold cursor-pointer"
            onClick={() => {
              setExpanded(false);
            }}
          >
            {' '}
            reducir
          </span>
        </p>
      ) : (
        <>
          <p style={{ textAlign: 'start' }}>
            {' '}
            {dealer.dealerInfo.slice(0, 200)}{' '}
            <span
              className="border border-gray bg-slate-200	p-1 font-bold cursor-pointer"
              onClick={() => {
                setExpanded(true);
              }}
            >
              {' '}
              ver más
            </span>
          </p>
        </>
      )
    ) : (
      <p style={{ textAlign: 'start', wordBreak: 'break-word' }}>
        {' '}
        {dealer.dealerInfo}
      </p>
    );

  const schedule = (
    <ul className={classes.scheduleContainer}>
      {dealer.dealerSchedules &&
        daysOfWeek.map((el, i) => {
          if (dealer.dealerSchedules[i] != null) {
            const openHour = new Date(dealer.dealerSchedules[i].openTime);
            const closeHour = new Date(dealer.dealerSchedules[i].closeTime);

            const openH = openHour.getHours().toString().padStart(2, '0');
            const openM = openHour.getMinutes().toString().padStart(2, '0');
            const closeH = closeHour.getHours().toString().padStart(2, '0');
            const closeM = closeHour.getMinutes().toString().padStart(2, '0');
            return (
              <li className={classes.scheduleItem}>
                <span>{el}</span>{' '}
                <p>
                  {' '}
                  {`${openH}:${openM}`} - {`${closeH}:${closeM}`}
                </p>
              </li>
            );
          }
          return (
            <li className={classes.scheduleItem}>
              <span>{el}</span> <p>Cerrado</p>
            </li>
          );
        })}
    </ul>
  );
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div className="max-w-screen-2xl mx-auto my-0">
      <div className="h-64 relative">
        {dealer?.coverUrl ? (
          <img
            style={{ objectFit: 'cover', height: '100%', width: '100%' }}
            src={`${BLOB_STORAGE_URL}/${dealer.coverUrl}`}
          />
        ) : (
          <div className={classes.cover}></div>
        )}
        <div className={classes.profilePic}>
          {dealer?.logoUrl ? (
            <img
              className="rounded-full shadow-md h-36 w-36 object-cover"
              src={`${BLOB_STORAGE_URL}/${dealer.logoUrl}`}
            />
          ) : (
            <img
              className="rounded-full shadow-md h-36 w-36 object-cover"
              src={require(`../images/profile-picture.png`)}
            />
          )}
        </div>
        {/* {isDealer ?
          <button
            onClick={() => {
              setEdit(true)
            }}
            className='rounded-md ml-2 absolute left-2 top-5 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold border border-gray-400 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
            Editar</button>
          : <></>} */}
      </div>
      <div className={classes.header}>
        <h1>{dealer.dealerName}</h1>
        <span>{dealer.city}</span>
        <span>Miembro desde {fechaFormateada}</span>
        <div className={classes.icons}>
          {whatsAppNumber ? (
            <a
              href={`https://wa.me/+506${whatsAppNumber}?text=${encodedMessage}`}
              target="_blank"
            >
              <FaWhatsapp />
            </a>
          ) : (
            <></>
          )}
          {dealer?.instaUser ? (
            <a
              href={`https://www.instagram.com/${dealer.instaUser
                .toLowerCase()
                .replace(/^@/, '')}`}
              target="_blank"
            >
              {' '}
              <FaInstagram />
            </a>
          ) : (
            <></>
          )}
          {dealer.website ? (
            <a href={`${dealer.website}`} target="_blank">
              <MdOutlinePageview />
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 max-w-6xl mx-auto sm:gap-10 px-1 sm:px-5">
        <div className="col-span-12 md:col-span-6">
          <h2 className={classes.title}>Catálogo</h2>
          {dealerCars}
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col content-start">
          <div className={classes.dealerInfo}>
            {dealer.dealerInfo != null ? dealerInfo : <></>}
            <br />
            <ul className={classes.itemsList}>
              <li className={classes.profileItems}>
                <p>En venta</p> <p>{carList.length} carros</p>
              </li>
              <li className={classes.profileItems}>
                <p> Categoría</p> <p>{carTypes}</p>
              </li>
              <li className={classes.profileItems}>
                <p>Financiamiento </p>
                <p>{dealer.financing ? 'Si' : 'No'}</p>
              </li>
              <li className={classes.profileItems}>
                <p>Garantía </p>
                <p>{dealer.warranty ? 'Si' : 'No'}</p>
              </li>
              <li className={classes.profileItems}>
                <p>Prueba de manejo </p> <p>{dealer.testDrive ? 'Si' : 'No'}</p>
              </li>
              <li className={classes.profileItems}>
                <p>Recibe carro</p> <p>{dealer.receivesCar ? 'Si' : 'No'}</p>
              </li>
            </ul>
          </div>
          <div className="mt-4 mb-4 justify-start">
            <h2 className={classes.title}>Datos del vendedor</h2>

            <ul className='w-full flex flex-col gap-2 mt-4'>
              <li className='flex items-center gap-2'><p>
                <FaWhatsapp /> +506{' '}
                <span
                  onClick={() => handleWhatsAppClick()}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {whatsAppNumber}
                </span>
              </p></li>
              <li className='flex items-center gap-2'> <p>
                <FaPhoneSquareAlt /> +506{' '}
                <a
                  href={`tel:${phoneNumber}`}
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                >
                  {phoneNumber}
                </a>
              </p></li>
              {dealer.instaUser ? <li>
                <a
                  href={`https://www.instagram.com/${dealer.instaUser
                    .toLowerCase()
                    .replace(/^@/, '')}`}
                  target="_blank"
                >
                  <FaInstagram /> Instagram
                </a></li> : ''}
              <li className='flex items-center gap-2'> <FaLocationDot /> {dealer.address}</li>

              <li>{dealer.website !== '' ? (
                <a href={`${dealer.website}`} target={'_blank'} className='flex items-center gap-2'>
                  <MdOutlinePageview /> Página web
                </a>
              ) : null}</li>

            </ul>
            <Button
              className={classes.callToActionBtn}
              onClick={() => {
                setShowLogin(true);
              }}
            >
              Ver horario
            </Button>
            <Modal
              title={'Horario'}
              closeBtn={true}
              open={showLogin}
              onClose={() => {
                setShowLogin(false);
              }}
            >
              {schedule}
            </Modal>
          </div>
          <div className="mt-4 mb-4 justify-start">
            <h2 className={classes.title}>Reviews</h2>
            <div className={classes.text}>
              <button className={classes.hiddenBtn}>
                <StyledRating
                  size="large"
                  value={dealer.reviewsScore}
                  readOnly
                  icon={iconFull}
                  emptyIcon={iconEmpty}
                />
                ({dealer.numOfReviews} reviews)
              </button>
              <p style={{ textAlign: 'start' }}>
                La calificación de la agencia es calculada en base a la
                totalidad de reviews.
              </p>
              {isDealer ? (
                <></>
              ) : (
                <Button
                  className={classes.callToActionBtn}
                  onClick={handleAddReview}
                >
                  Agregar un review
                </Button>
              )}
            </div>
          </div>
          <div className={classes.reviewList}>
            {reviews.length > 0 ? (
              <>
                {reviews.map((el) => {
                  return <Review review={el} />;
                })}
              </>
            ) : (
              <>
                <br />
                No hay reviews de momento.
              </>
            )}
            {/* <Button className={classes.callToActionBtn}>
          Ver todos los reviews
        </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    getDealer: (uuid) => dispatch(getDealer(uuid)),
    getDealerById: (id) => dispatch(getDealerById(id)),
    getUserCars: (token, uuid) => dispatch(getUserCarList(token, uuid)),
    getDealerCarList: (uuid) => dispatch(getDealerCars(uuid)),
    getDealerReview: (idDealer) => dispatch(getDealerReview(idDealer)),
  };
};
const mapStateToProps = (state) => {
  return {
    dealer: state.DealerReducer.dealer,
    user: state.UserProfile.userProfile,
    carList: state.DealerReducer.dealerCars,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyDealer);
