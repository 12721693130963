import axios from 'axios';
import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import PageNotFound from '../../pages/PageNotFound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CircularProgress from '@mui/material/CircularProgress';
// import CarReviewDetails from '../CarReviewDetails/CarReviewDetails';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';
import AdminLinkApproverComponent from '../AdminLinkApproverComponent/AdminLinkApproverComponent';

function AdminLinks({ }) {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const user = useSelector((state) => state.UserProfile.userProfile);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [pendingCars, setPendingCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [carsCount, setCarsCount] = useState('Cargando...');
  const [searchTerm, setSearchTerm] = useState('');


  const fetchPendingCars = async () => {
    try {
      const token = getTokenFromLocalStorage();
      let url = `${BASE_URL}/api/Admin/GetPendingCarsToPublishOnSocialMedia`;
      if (searchTerm) {
        url += `?searchTerm=${searchTerm}`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPendingCars(response.data);
      setCarsCount(response.data.length);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingCars();
  }, [BASE_URL]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    setLoading(true);
    fetchPendingCars(); // Llama a la función de búsqueda sin modificar el término de búsqueda
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Admin - Links - Admin - Autos Nova</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      {user.userRole === 3 ? (
        <div>
          <div>
            <br />
            <h1>Admin - Post tracker (Beta)</h1>
            <h4>Carros pendientes por publicar en IG/FB/WA</h4>
            <div>
              <input
                type="text"
                placeholder='Car UUID'
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
              <button onClick={handleSearch}>Buscar</button>
            </div>
            <h3>Total carros: {carsCount}</h3>

            {loading ? (
              <CircularProgress />
            ) : error ? (
              <div>Error al cargar los datos</div>
            ) : pendingCars.length === 0 ? (
              <div>No se encontraron resultados</div>
            ) : (
              pendingCars.map((car) => (
                <AdminLinkApproverComponent key={car.id} car={car} />
              ))
            )}
          </div>
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getCarsToReview: (token) => dispatch(getCarsInReview(token)),
  };
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminLinks);
