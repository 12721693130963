import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { PiShareFat } from 'react-icons/pi';
// import { AiFillPhone } from 'react-icons/ai';
import classes from './CarDetails.module.css';
// import Modal from '../components/Modal/Modal';
import Skeleton from '@mui/material/Skeleton';
// import { HiOutlineHeart } from 'react-icons/hi';
import Button from '../components/Buttons/Button';
import GridImage from '../components/Grid/GridImage';
import useTheme from '@mui/material/styles/useTheme';
import Carousel from '../components/Carousel/Carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import volcan from '../images/appImages/volcan.jpg';
import React, { useEffect, useState, useRef } from 'react';
import CarOverview from '../components/CarOverview/CarOverview';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getDealerByCarId } from '../store/DealerStore/DealerAction';
import {
  getCarDetails,
  getCarViews,
} from '../store/VehicleStore/vehicleActions';

const MobileSkeleton = () => (
  <div className={classes.skeletonMobile}>
    <Skeleton variant="rectangular" width="100%" height={190} />

    <Skeleton variant="text" width="60%" height={50} />
    <div style={{ marginTop: '-30px' }}>
      <Skeleton variant="text" width="97%" height={160} />
    </div>
    <div style={{ marginTop: '-60px' }}>
      <Skeleton variant="text" width="97%" height={200} />
    </div>
    <div style={{ marginTop: '-100px' }}>
      <Skeleton variant="text" width="97%" height={400} />
    </div>
  </div>
);

const DesktopSkeleton = () => (
  <div style={{ maxWidth: '1030px', margin: '0 auto' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginTop: '80px', // Margen superior
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <Skeleton variant="rectangular" width={540} height={450} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Skeleton variant="rectangular" width={240} height={220} />
          <Skeleton variant="rectangular" width={240} height={220} />
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Skeleton variant="rectangular" width={240} height={220} />
          <Skeleton variant="rectangular" width={240} height={220} />
        </div>
      </div>
      {/* Nuevo div con propiedades */}
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        // gap: '2rem', // Establece el espacio entre elementos en 2 rem
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '15px',
          marginTop: '-78px', // Margen superior para centrar verticalmente
          marginLeft: 'auto',
          marginRight: 'auto', // Centra el contenido horizontalmente
        }}
      >
        <div>
          <Skeleton variant="rectangular" width={570} height={60} />
        </div>
        <div>
          <Skeleton variant="rectangular" width={400} height={50} />
        </div>
      </div>
      <div
        style={{
          marginTop: '10px',
          // marginRight: '-30px',
        }}
      >
        <Skeleton variant="rectangular" width={345} height={205} />
      </div>
    </div>
    {/* INFORMACIÓN GENERAL  */}
    <div>
      <div
        style={{
          gap: '3rem', // Establece el espacio entre elementos en 2 rem
          width: '100%',
          marginTop: '-60px',
        }}
      >
        <Skeleton variant="rectangular" width={259} height={40} />
      </div>
    </div>
    {/* Bullet de icono + description */}
    <div>
      <div
        style={{
          display: 'flex',
          gap: '2rem', // Establece el espacio entre elementos en 3 rem
          width: '100%',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
      </div>
    </div>
    {/* Bullet de icono + description */}
    <div>
      <div
        style={{
          display: 'flex',
          gap: '2rem', // Establece el espacio entre elementos en 3 rem
          width: '100%',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
      </div>
    </div>
    {/* Bullet de icono + description */}
    <div>
      <div
        style={{
          display: 'flex',
          gap: '2rem', // Establece el espacio entre elementos en 3 rem
          width: '100%',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
      </div>
    </div>
    {/* Información del carro  */}
    <div>
      <div
        style={{
          gap: '3rem', // Establece el espacio entre elementos en 2 rem
          width: '100%',
          marginTop: '50px',
        }}
      >
        <Skeleton variant="rectangular" width={400} height={40} />
      </div>
    </div>
    {/* Bullet de icono + description */}
    <div>
      <div
        style={{
          display: 'flex',
          gap: '2rem', // Establece el espacio entre elementos en 3 rem
          width: '100%',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
      </div>
    </div>
    {/* Bullet de icono + description */}
    <div>
      <div
        style={{
          display: 'flex',
          gap: '2rem', // Establece el espacio entre elementos en 3 rem
          width: '100%',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
      </div>
    </div>
    {/* Bullet de icono + description */}
    <div>
      <div
        style={{
          display: 'flex',
          gap: '2rem', // Establece el espacio entre elementos en 3 rem
          width: '100%',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem', // Establece el espacio entre elementos en 3 rem
          }}
        >
          <Skeleton variant="circular" width={40} height={40} />{' '}
          {/* Skeleton redondo */}
          <Skeleton variant="rectangular" width={200} height={40} />{' '}
          {/* Skeleton rectangular */}
        </div>
      </div>
    </div>
  </div>
);

function CarDetails({
  carDetails,
  loading,
  getCarDetailsStart,
  getCarViews,
  carViews,
  dealer,
  getDealerByCarId,
}) {
  const { carUuid } = useParams();
  const navigate = useNavigate();
  const doRequest = useRef(true);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [disable, setDisable] = useState(false);
  const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detecta si la pantalla es móvil (menos de 600px)

  const user = useSelector((state) => state.UserProfile.userProfile.idUser);
  const shareContent = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Compartir vehículo',
          text: `${carDetails.brand} - ${carDetails.model} | ${carDetails.year}. Publicado en Autos Nova`,
          url: window.location.href,
        })
        .then(() => {
          console.log('Content shared successfully');
        })
        .catch((error) => {
          console.error('Error sharing content:', error);
        });
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      // Set the state based on the scroll position
      // console.log(window.scrollY, window.scroll);
      // setDisable(window.scrollY > 160);
    };
    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // TODO: Agregar Loading Placeholder
  // const lastPlateDigits = carDetails.plate ? carDetails.plate.slice(-1) : '00';
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      // Scroll to the top when the component mounts
      window.scrollTo(0, 0);
      if (doRequest.current) {
        doRequest.current = false;
        await getDealerByCarId(carUuid);
        try {
          await getCarDetailsStart(carUuid, user);
          setIsLoadingData(false);
        } catch (error) {
          // Handle any errors that occur during the data fetching
          console.error(error);
        }
      }
      // Initialize Google Analytics
      const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
      ReactGA.initialize(GOOGLE_ANALYTICS);
      // Track the page view
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (carDetails.carId) {
        try {
          await getCarViews(carDetails.carId);
          // setIsLoadingData(false);
        } catch (error) {
          // Handle any errors that occur during the data fetching
          console.error(error);
        }
      }
    };
    fetchData();
  }, [carDetails]);


const handleContactClick = (e) => {
  e.preventDefault(); // Evita que el enlace se abra en la misma pestaña
  let dealerName = dealer.dealerName.toLowerCase();
  dealerName = dealerName?.split(' ').join('');
  const url = `/vendedor/${dealer.customUrl}`;
  window.open(url, '_blank'); // Abre el enlace en una nueva pestaña
};


  const handleCallClick = () => {
    window.open(`tel:${dealer.phoneNumber}`);
  };

  const traspasoPrice = (carDetails.priceCRC / 20.20202).toLocaleString(
    'en-US',
    {
      maximumFractionDigits: 0,
    }
  );
  const totalExpectedPrice = (
    carDetails.priceCRC +
    carDetails.priceCRC / 20.20202
  ).toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
  const preFilledMessage = `Hola, me interesa el *${carDetails.brand} ${carDetails.model}, ${carDetails.year}*. Publicado en Autos Nova \n${window.location.href}`;
  const encodedMessage = encodeURIComponent(preFilledMessage); // URL-encode the pre-filled message

  if (isLoadingData) {
    return (
      <div>
        {isMobile ? (
          <MobileSkeleton /> // Componente de esqueleto para móviles
        ) : (
          <div>
            <DesktopSkeleton />
          </div>
        )}
      </div>
    );
  }

  if (!carDetails) {
    return (
      <div>
        <div className={classes.containerNotFound}>
          <div className={classes.contentNotFound}>
            <h3 className={classes.titleNotFound}>
              El auto ya no está disponible
            </h3>
            {/* <p>Puede ser que el dueño ha desactivado el anuncio</p> */}

            <div className={classes.imgContainerNotFound}>
              <img src={volcan} alt="Carro no disponble" />
            </div>
            <Link to="/en-venta" className={classes.btn}>
              <Button>Ver carros en venta</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {carDetails && (
        <Helmet>
          <title>
            {`${carDetails.brand} - ${carDetails.model} | ${carDetails.year} | Autos Nova`}
          </title>
          <meta
            name="title"
            content="Autos Nova | La plataforma de autos de Costa Rica"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Vehiculos Costa Rica" />
          <meta
            property="og:title"
            content={`${carDetails.brand} - ${carDetails.model} | ${carDetails.year} | Vehiculos Costa Rica`}
          />
          <meta
            property="og:description"
            content={`₡${carDetails.priceCRC.toLocaleString()} | ${carDetails.kilometres.toLocaleString()} KM | ${carDetails.provincia
              }`}
          />
          <meta
            property="og:image"
            content={`${BLOB_STORAGE_URL}/CAR-${carUuid}-IMG-1.png`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="675" />
          <meta property="og:site_name" content="Autos Nova.com" />
          <meta property="og:locale" content="es_CR" />
          <meta property="og:url" content={`${window.location.href}`} />
        </Helmet>
      )}

      <div className={classes.container}>
        <div className={classes.content}>
          {windowSize >= 900 ? (
            <div className={classes.btns}>
              {/* <button>
                <HiOutlineHeart /> Guardar
              </button> */}
              <button onClick={shareContent}>
                <PiShareFat /> Compartir
              </button>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.imgContainer}>
            {windowSize <= 900 ? (
              <Carousel images={carDetails.pictures} counter={true} />
            ) : (
              <GridImage items={carDetails.pictures} />
            )}
          </div>
          <div className={classes.innerContent}>
            <div className={classes.content}>
              <div className={classes.title}>
                <h2>
                  <div className={classes.year}>{carDetails.year}</div>
                  {carDetails.brand} {carDetails.model}
                </h2>
              </div>
              <div style={{ width: '100%' }}>
                <CarOverview
                  handleContactClick={handleContactClick}
                  dealerName={dealer.dealerName}
                  windowSize={windowSize}
                  details={carDetails}
                  carViews={carViews}
                />
              </div>
            </div>
            <div
              className={`${classes.contact} ${disable ? classes.sticky : ''}`}
            >
              <div>
                <p
                  onClick={handleContactClick}
                  style={{
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {dealer.dealerName}
                </p>
              </div>
              {/* <span>Reviews</span> */}
              <div>
                <span className={classes.text}>
                  Contacta al vendedor de forma gratuita
                </span>
              </div>
              <div className={classes.buttonGroup}>
                <Button
                  className={classes.btnLlamar}
                  onClick={handleCallClick}
                >
                  Llamar
                </Button>
                <Button
                  className={classes.btnWhatsapp}
                  onClick={() =>
                    window.open(
                      `https://wa.me/+506${dealer.whatsappNumber}?text=${encodedMessage}`,

                      '_blank'
                    )
                  }
                >
                  WhatsApp
                </Button>
              </div>

              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCarDetailsStart: (carUuid, user) =>
      dispatch(getCarDetails(carUuid, user)),
    getCarViews: (carId) => dispatch(getCarViews(carId)),
    getDealerByCarId: (carId) => dispatch(getDealerByCarId(carId)),
  };
};

const mapStateToProps = (state) => {
  return {
    carDetails: state.CarOptions.carDetails,
    carViews: state.CarOptions.carViews,
    dealer: state.DealerReducer.dealer,
    loading: state.CarOptions.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarDetails);
