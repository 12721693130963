import classes from './CarListContainer.module.css';
import CarItem from '../CarItem/CarItem';

export default function CarListContainer({
  likeBtn,
  carStatus,
  refreshUserCars,
  valorUsd,
  carList = [],
  sinpeDetails
}) {

  return (
    <div className={classes.container}> 
      <ul className={classes.flexContainer}>
        {carList.map((car) => {
          return (
            <li className={classes.listItem}>
              <CarItem
                likeBtn={likeBtn}
                valorUsd={valorUsd}
                car={car}
                carStatus={carStatus}
                refreshUserCars={refreshUserCars}
                sinpeDetails={sinpeDetails}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
