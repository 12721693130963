import {
    CREATE_DEALER_FAILURE,
    CREATE_DEALER_SUCCESS,
    CREATE_DEALER_START
    , GET_DEALER_TYPES_START
    , GET_DEALER_TYPES_SUCCESS
    , GET_DEALER_TYPES_FAILURE
    , GET_DEALER_START
    , GET_DEALER_SUCCESS
    , GET_DEALER_FAILURE
    , GET_DEALER_CARS_START
    , GET_DEALER_CARS_SUCCESS
    , GET_DEALER_CARS_FAILURE
    , POST_DEALER_PICTURE_START
    , POST_DEALER_PICTURES_SUCCESS
    , POST_DEALER_PICTURES_FAILURE
    , POST_DEALER_REVIEW_START
    , POST_DEALER_REVIEW_SUCCESS
    , POST_DEALER_REVIEW_FAILURE
    , GET_DEALER_REVIEW_START
    , GET_DEALER_REVIEW_SUCCESS
    , GET_DEALER_REVIEW_FAILURE
} from './DealerTypes';

const initialState = {
    loading: false,
    dealer: {},
    dealerCars: [],
    dealerTypes: [],
    reviews: [],
    error: ''
}

const createDealerStart = (state, action) => {
    return { ...state, loading: true };
}

const createDealerSuccess = (state, action) => {
    return { ...state, loading: false };
}

const createDealerFailure = (state, action) => {
    return { ...state, loading: false };
}

const getDealerTypesStart = (state, action) => {
    return { ...state, loading: true };
}

const getDealerTypesSuccess = (state, action) => {
    return { ...state, loading: false, dealerTypes: action.payload };
}

const getDealerTypesFailure = (state, action) => {
    return { ...state, loading: false };
}

const getDealerStart = (state, action) => {
    return { ...state, loading: true };
}

const getDealerSuccess = (state, action) => {
    return { ...state, loading: false, dealer: action.payload };
}

const getDealerFailure = (state, action) => {
    return { ...state, loading: false };
}

const getDealerCarsStart = (state, action) => {
    return { ...state, loading: true };
}

const getDealerCarsSuccess = (state, action) => {
    return { ...state, loading: false, dealerCars: action.payload };
}

const getDealerCarsFailure = (state, action) => {
    return { ...state, loading: false };
}
const postDealerPictureStart = (state, action) => {
    return { ...state, loading: true };
}

const postDealerPictureSuccess = (state, action) => {
    return { ...state, loading: false };
}

const postDealerPictureFailure = (state, action) => {
    return { ...state, loading: false, error: action.payload };
}

const postDealerReviewStart = (state, action) => {
    return { ...state, loading: true };
}

const postDealerReviewSuccess = (state, action) => {
    return { ...state, loading: false };
}

const postDealerReviewFailure = (state, action) => {
    return { ...state, loading: false, error: action.payload };
}
const getDealerReviewsStart = (state, action) => {
    return { ...state, loading: true };
}

const getDealerReviewsSuccess = (state, action) => {
    return { ...state, loading: false, reviews: action.payload };
}

const getDealerReviewsFailure = (state, action) => {
    return { ...state, loading: false, error: action.payload };
}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_DEALER_START:
            return createDealerStart(state, action);
        case CREATE_DEALER_SUCCESS:
            return createDealerSuccess(state, action);
        case CREATE_DEALER_FAILURE:
            return createDealerFailure(state, action);
        case GET_DEALER_TYPES_START:
            return getDealerTypesStart(state, action);
        case GET_DEALER_TYPES_SUCCESS:
            return getDealerTypesSuccess(state, action);
        case GET_DEALER_TYPES_FAILURE:
            return getDealerTypesFailure(state, action);
        case GET_DEALER_START:
            return getDealerStart(state, action);
        case GET_DEALER_SUCCESS:
            return getDealerSuccess(state, action);
        case GET_DEALER_FAILURE:
            return getDealerFailure(state, action);
        case GET_DEALER_CARS_START:
            return getDealerCarsStart(state, action);
        case GET_DEALER_CARS_SUCCESS:
            return getDealerCarsSuccess(state, action);
        case GET_DEALER_CARS_FAILURE:
            return getDealerCarsFailure(state, action);
        case POST_DEALER_PICTURE_START:
            return postDealerPictureStart(state, action);
        case POST_DEALER_PICTURES_SUCCESS:
            return postDealerPictureSuccess(state, action);
        case POST_DEALER_PICTURES_FAILURE:
            return postDealerPictureFailure(state, action);
        case POST_DEALER_REVIEW_START:
            return postDealerReviewStart(state, action);
        case POST_DEALER_REVIEW_SUCCESS:
            return postDealerReviewSuccess(state, action);
        case POST_DEALER_REVIEW_FAILURE:
            return postDealerReviewFailure(state, action);
        case GET_DEALER_REVIEW_START:
            return getDealerReviewsStart(state, action);
        case GET_DEALER_REVIEW_SUCCESS:
            return getDealerReviewsSuccess(state, action);
        case GET_DEALER_REVIEW_FAILURE:
            return getDealerReviewsFailure(state, action);
        default:
            return state;

    }

}

export default reducer;
