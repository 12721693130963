import {
  GET_USED_CARLIST_START,
  GET_USED_CARLIST_SUCCESS,
  GET_USED_CARLIST_FAILURE,
  CREATE_CAR_START,
  CREATE_CAR_SUCCESS,
  CREATE_CAR_FAILURE,
  GET_CAR_MODELS_START,
  GET_CAR_MODELS_SUCCESS,
  GET_CAR_MODELS_FAILURE,
  GET_CAR_BRANDS_START,
  GET_CAR_BRANDS_SUCCESS,
  GET_CAR_BRANDS_FAILURE,
  GET_CAR_BY_PLATE_START,
  GET_CAR_BY_PLATE_SUCCESS,
  GET_CAR_BY_PLATE_FAILURE,
  GET_CAR_STYLES_START,
  GET_CAR_STYLES_SUCCESS,
  GET_CAR_STYLES_FAILURE,
  GET_ENGINE_OPTIONS_START,
  GET_ENGINE_OPTIONS_SUCCESS,
  GET_ENGINE_OPTIONS_FAILURE,
  GET_FEATURE_LIST_START,
  GET_FEATURE_LIST_SUCCESS,
  GET_FEATURE_LIST_FAILURE,
  ADD_CAR_FEATURES_START,
  ADD_CAR_FEATURES_SUCCESS,
  ADD_CAR_FEATURES_FAILURE,
  ADD_CAR_PICTURES_START,
  ADD_CAR_PICTURES_SUCCESS,
  ADD_CAR_PICTURES_FAILURE,
  GET_CAR_DETAILS_START,
  GET_CAR_DETAILS_SUCCESS,
  GET_CAR_DETAILS_FAILURE,
  MAKE_CAR_INACTIVE_START,
  MAKE_CAR_INACTIVE_SUCCESS,
  MAKE_CAR_INACTIVE_FAILURE,
  SET_CAR_LIST_PAGE,
  SET_CAR_ORDER,
  GET_CAR_VIEWS_START,
  GET_CAR_VIEWS_SUCCESS,
  GET_CAR_VIEWS_FAILURE,
  EDIT_CAR_START,
  EDIT_CAR_SUCCESS
  , EDIT_CAR_FAILURE,
  DELETE_DRAFT_START,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAILURE
} from './vehicleTypes';
import axios from 'axios';
// import { cardActionAreaClasses } from '@mui/material';

const baseUrl = process.env.REACT_APP_API_URL;

const getUsedCarListStart = () => {
  return { type: GET_USED_CARLIST_START };
};

const getUsedCarListSuccess = (data) => {
  return { type: GET_USED_CARLIST_SUCCESS, payload: data };
};

const getUsedCarListFailure = (err) => {
  return { type: GET_USED_CARLIST_FAILURE, payload: err };
};

const createCarStart = () => {
  return { type: CREATE_CAR_START };
};

const createCarSuccess = (carUuid) => {
  return { type: CREATE_CAR_SUCCESS, payload: carUuid };
};

const createCarFailure = (err) => {
  return { type: CREATE_CAR_FAILURE, payload: err };
};

const getCarBrandsStart = () => {
  return { type: GET_CAR_BRANDS_START };
};

const getCarBrandsSuccess = (data) => {
  return { type: GET_CAR_BRANDS_SUCCESS, payload: data };
};

const getCarBrandsFailure = (err) => {
  return { type: GET_CAR_BRANDS_FAILURE, payload: err };
};

const getCarModelStart = () => {
  return { type: GET_CAR_MODELS_START };
};

const getCarModelSuccess = (data) => {
  return { type: GET_CAR_MODELS_SUCCESS, payload: data };
};

const getCarModelFailure = (err) => {
  return { type: GET_CAR_MODELS_FAILURE, payload: err };
};

const getCarByPlateStart = () => {
  return { type: GET_CAR_BY_PLATE_START };
};

const getCarByPlateSuccess = (data) => {
  return { type: GET_CAR_BY_PLATE_SUCCESS, payload: data };
};

const getCarByPlateFailure = (err) => {
  return { type: GET_CAR_BY_PLATE_FAILURE, payload: err };
};

const getCarStylesStart = () => {
  return { type: GET_CAR_STYLES_START };
};

const getCarStylesSuccess = (data) => {
  return { type: GET_CAR_STYLES_SUCCESS, payload: data };
};

const getCarStylesFailure = (err) => {
  return { type: GET_CAR_STYLES_FAILURE, payload: err };
};

const getEngineOptionsStart = () => {
  return { type: GET_ENGINE_OPTIONS_START };
};

const getEngineOptionsSuccess = (data) => {
  return { type: GET_ENGINE_OPTIONS_SUCCESS, payload: data };
};

const getEngineOptionsFailure = (err) => {
  return { type: GET_ENGINE_OPTIONS_FAILURE, payload: err };
};

const getFeatureListStart = () => {
  return { type: GET_FEATURE_LIST_START };
};

const getFeatureListSuccess = (data) => {
  return { type: GET_FEATURE_LIST_SUCCESS, payload: data };
};

const getFeatureListFailure = (err) => {
  return { type: GET_FEATURE_LIST_FAILURE, payload: err };
};

const addCarFeaturesStart = () => {
  return { type: ADD_CAR_FEATURES_START };
};

const addCarFeaturesSuccess = (data) => {
  return { type: ADD_CAR_FEATURES_SUCCESS, payload: data };
};

const addCarFeaturesFailure = (err) => {
  return { type: ADD_CAR_FEATURES_FAILURE, payload: err };
};

const addCarPicturesStart = () => {
  return { type: ADD_CAR_PICTURES_START };
};

const addCarPicturesSuccess = (data) => {
  return { type: ADD_CAR_PICTURES_SUCCESS, payload: data };
};

const addCarPicturesFailure = (err) => {
  return { type: ADD_CAR_PICTURES_FAILURE, payload: err };
};

const getCarDetailsStart = () => {
  return { type: GET_CAR_DETAILS_START };
};

const getCarDetailsSuccess = (data) => {
  return { type: GET_CAR_DETAILS_SUCCESS, payload: data };
};

const getCarDetailsFailure = (err) => {
  return { type: GET_CAR_DETAILS_FAILURE, payload: err };
};

const getCarViewsStart = () => {
  return { type: GET_CAR_VIEWS_START };
};

const getCarViewsSuccess = (data) => {
  return { type: GET_CAR_VIEWS_SUCCESS, payload: data };
};

const getCarViewsFailure = (err) => {
  return { type: GET_CAR_VIEWS_FAILURE, payload: err };
};

const makeCarInactiveStart = () => {
  return { type: MAKE_CAR_INACTIVE_START };
};

const makeCarInactiveSuccess = () => {
  return { type: MAKE_CAR_INACTIVE_SUCCESS };
};

const makeCarInactiveFailure = (err) => {
  return { type: MAKE_CAR_INACTIVE_FAILURE, payload: err };
};

const editCarStart = () => {
  return { type: EDIT_CAR_START };
};

const editCarSuccess = (res) => {
  return { type: EDIT_CAR_SUCCESS, payload: res };
};

const editCarFailure = (err) => {
  return { type: EDIT_CAR_FAILURE, payload: err };
};


const deleteDraftStart = () => {
  return { type: DELETE_DRAFT_START };
};

const deleteDraftSuccess = (uuidCar) => {
  return { type: DELETE_DRAFT_SUCCESS, payload: uuidCar };
};

const deleteDraftFailure = (err) => {
  return { type: DELETE_DRAFT_FAILURE, payload: err };
};


export const setCarListPage = (page) => {
  return { type: SET_CAR_LIST_PAGE, payload: page };
};

export const setCarOrderBy = (order) => {
  return { type: SET_CAR_ORDER, payload: order };
};


export const deleteDraft = (carUuid) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(deleteDraftStart());
    axios
      .delete(`${baseUrl}/api/Car/DeleteDraft?uuidCar=${carUuid}`)
      .then((res) => {
        if (res.data) {
          dispatch(deleteDraftSuccess(carUuid));
          return resolve(true);
        } else {
          return reject(false);
        }
      })
      .catch((err) => {
        dispatch(deleteDraftFailure(err));
        return reject(false);
      });
  });
};
export const addCarPictures = (data, carUuid, carId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(addCarPicturesStart());
    axios
      .post(`${baseUrl}/api/Car/ImportFile`, data)
      .then((res) => {
        dispatch(addCarPicturesSuccess(res.data));
        return resolve(true);
      })
      .catch((err) => {
        dispatch(addCarPicturesFailure(err));
        return reject(false);
      });
  });
};
export const getUsedCarList =
  (filter = {}, page = 1, orderBy = 0) =>
    (dispatch) => {
      dispatch(getUsedCarListStart());
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${baseUrl}/api/Car/GetCarList?orderBy=${orderBy}&page=${page}`,
            filter
          )
          .then((res) => {
            dispatch(getUsedCarListSuccess(res.data));
            resolve(true);
          })
          .catch((err) => {
            dispatch(getUsedCarListFailure(err));
            reject(false);
          });
      });
    };

export const addCarFeatures = (data, carId) => (dispatch) => {
  dispatch(addCarFeaturesStart());
  // console.log(data, 'data')
  axios
    .post(`${baseUrl}/api/Car/AddCarFeatures?carId=${carId}`, data)
    .then((res) => {
      dispatch(addCarFeaturesSuccess());
    })
    .catch((err) => {
      dispatch(addCarFeaturesFailure(err));
    });
};

export const createCar = (data, token) => (dispatch) => {
  dispatch(createCarStart());
  localStorage.setItem('cardata', JSON.stringify(data));
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${baseUrl}/api/Car/AddUsedCar`, data, config)
      .then((res) => {
        dispatch(createCarSuccess(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(createCarFailure(err));
        reject(false);
      });
  });
};

export const getCarBrands = () => (dispatch) => {
  dispatch(getCarBrandsStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/api/Car/GetCarBrands`)
      .then((res) => {
        dispatch(getCarBrandsSuccess(res.data));
        resolve(true);
      })
      .catch((err) => {
        dispatch(getCarBrandsFailure(err));
        reject(false);
      });
  });
};

export const getCarModels = (carBrand) => (dispatch) => {
  dispatch(getCarModelStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/api/Car/GetCarModels?brandSlug=${carBrand}`)
      .then((res) => {
        dispatch(getCarModelSuccess(res.data));
        resolve(true);
      })
      .catch((err) => {
        dispatch(getCarModelFailure(err));
        reject(false);
      });
  });
};

export const getCarByPlate = (plate = '', uuidCar = '') => (dispatch) => {
  dispatch(getCarByPlateStart());
  let url = ''
  if (plate !== '') {
    url += `carPlate=${plate}`
  }

  if (uuidCar !== '') {
    url += `uuidCar=${uuidCar}`
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/api/Car/GetCarByPlateOrUuid?${url}`)
      .then((res) => {
        if (res.data[0].idCar !== 0 && (res.data[0].carStatusId === 1 || res.data[0].carStatusId === 5)) {
          //El carro ya esta en venta
          resolve(402);
        } else if (res.data[0].idCar !== 0) {
          //El carro se puede listar
          dispatch(getCarByPlateSuccess(res.data));
          resolve(200);
        } else {
          //El carro no esta en la base de datos
          resolve(404);
        }
      })
      .catch((err) => {
        dispatch(getCarByPlateFailure(err));
        reject(false);
      });
  });
};

export const getCarStyles = (plate) => (dispatch) => {
  dispatch(getCarStylesStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/api/Car/GetCarStyle`)
      .then((res) => {
        dispatch(getCarStylesSuccess(res.data));
        resolve(true);
      })
      .catch((err) => {
        dispatch(getCarStylesFailure(err));
        reject(false);
      });
  });
};

export const getEngineOptions = () => (dispatch) => {
  dispatch(getEngineOptionsStart());
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/api/Car/GetEngineOptions`)
      .then((res) => {
        dispatch(getEngineOptionsSuccess(res.data));
        resolve(true);
      })
      .catch((err) => {
        dispatch(getEngineOptionsFailure(err));
        reject(false);
      });
  });
};

export const getFeatureList = () => (dispatch) => {
  dispatch(getFeatureListStart());

  axios
    .get(`${baseUrl}/api/Car/GetCarFeatures`)
    .then((res) => {
      dispatch(getFeatureListSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getFeatureListFailure(err));
    });
};

export const getCarDetails = (carUuid, userId) => async (dispatch) => {
  dispatch(getCarDetailsStart());

  return new Promise((resolve, reject) => {
    if (userId === undefined || userId === null) {
      userId = '';
    }
    axios.get(
      `${baseUrl}/api/Car/CarDetails?carUuid=${carUuid}&userId=${userId}`
    ).then(res => {
      dispatch(getCarDetailsSuccess(res.data));
      resolve(res.data)
    }).catch(error => {
      dispatch(getCarDetailsFailure(error));
      reject(false)
    });
  })


};


export const getCarViews = (carId) => async (dispatch) => {
  dispatch(getCarViewsStart());
  if (!carId) {
    return -1;
  }
  try {
    const response = await axios.get(
      `${baseUrl}/api/Car/GetCarViews?carId=${carId}`
    );
    dispatch(getCarViewsSuccess(response.data));
  } catch (error) {
    dispatch(getCarViewsFailure(error));
  }
};


export const makeCarInactive =
  (uuidCar, newStatus, authToken) => (dispatch) => {
    dispatch(makeCarInactiveStart());

    const url = `${baseUrl}/api/Car/MakeCarInactive?uuidCar=${uuidCar}&newStatus=${newStatus}`;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, null, config)
        .then(() => {
          dispatch(makeCarInactiveSuccess());
          resolve(); // Resolve the promise
        })
        .catch((err) => {
          dispatch(makeCarInactiveFailure(err));
          reject(err); // Reject the promise with the error
        });
    });
  };



export const editCar =
  (data, authToken) => (dispatch) => {
    dispatch(editCarStart());

    const url = `${baseUrl}/api/Car/UpdatePost`;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, data, config)
        .then(() => {
          dispatch(editCarSuccess());
          resolve(true); // Resolve the promise
        })
        .catch((err) => {
          dispatch(editCarFailure(err));
          reject(false); // Reject the promise with the error
        });
    });
  };

