
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropDown({ dropdownValue, setDropdownValue, items, preview }) {

    const handleDropDownSelection = (e) => {
        if (e.target.textContent) {
            const newValue = { value: e.target.value, description: e.target.textContent }
            setDropdownValue(newValue);
        }
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className=" border border-gray-400 inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {dropdownValue ?? preview}
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute max-h-48 z-50 overflow-y-auto right-0 mt-2 w-auto min-w-16 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">

                        {
                            items.map(item => {
                                return <Menu.Item key={item.value}>
                                    {({ active }) => (
                                        <li onClick={handleDropDownSelection}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm cursor-pointer'
                                            )} value={item.value}>{item.description}</li>
                                    )}
                                </Menu.Item>

                            })
                        }


                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}