// General Stats
export const GET_GENERAL_STATS_START = 'GET_GENERAL_STATS_START';
export const GET_GENERAL_STATS_SUCCESS = 'GET_GENERAL_STATS_SUCCESS';
export const GET_GENERAL_STATS_FAILURE = 'GET_GENERAL_STATS_FAILURE';

// Cars in Review
export const GET_CARS_IN_REVIEW_START = 'GET_CARS_IN_REVIEW_START';
export const GET_CARS_IN_REVIEW_SUCCESS = 'GET_CARS_IN_REVIEW_SUCCESS';
export const GET_CARS_IN_REVIEW_FAILURE = 'GET_CARS_IN_REVIEW_FAILURE';

// Update Car in Review Status
export const UPDATE_CAR_IN_REVIEW_STATUS_START =
  'UPDATE_CAR_IN_REVIEW_STATUS_START';
export const UPDATE_CAR_IN_REVIEW_STATUS_SUCCESS =
  'UPDATE_CAR_IN_REVIEW_STATUS_SUCCESS';
export const UPDATE_CAR_IN_REVIEW_STATUS_FAILURE =
  'UPDATE_CAR_IN_REVIEW_STATUS_FAILURE';

// Car Details in Review
export const GET_CAR_DETAILS_IN_REVIEW_START =
  'GET_CAR_DETAILS_IN_REVIEW_START';
export const GET_CAR_DETAILS_IN_REVIEW_SUCCESS =
  'GET_CAR_DETAILS_IN_REVIEW_SUCCESS';
export const GET_CAR_DETAILS_IN_REVIEW_FAILURE =
  'GET_CAR_DETAILS_IN_REVIEW_FAILURE';
