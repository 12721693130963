import {
  GET_PROVINCIA_START,
  GET_PROVINCIA_SUCCESS,
  GET_PROVINCIA_FAILURE,
  GET_CANTON_START,
  GET_CANTON_SUCCESS,
  GET_CANTON_FAILURE,
} from './locationTypes';

const initialState = {
  loading: false,
  error: '',
  successMsg: '',
  provinciasList: [],
  cantonesList: [],
};
const getProvinciaStart = (state, action) => {
  return { ...state, loading: true };
};

const getProvinciaSuccess = (state, action) => {
  return { ...state, loading: false, provinciasList: action.payload };
};

const getProvinciaFailure = (state, action) => {
  return { ...state, loading: false };
};

const getCantonStart = (state, action) => {
  return { ...state, loading: true };
};

const getCantonSuccess = (state, action) => {
  return { ...state, loading: false, cantonesList: action.payload };
};

const getCantonFailure = (state, action) => {
  return { ...state, loading: false };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVINCIA_START:
      return getProvinciaStart(state, action);
    case GET_PROVINCIA_SUCCESS:
      return getProvinciaSuccess(state, action);
    case GET_PROVINCIA_FAILURE:
      return getProvinciaFailure(state, action);
    case GET_CANTON_START:
      return getCantonStart(state, action);
    case GET_CANTON_SUCCESS:
      return getCantonSuccess(state, action);
    case GET_CANTON_FAILURE:
      return getCantonFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
