import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PageNotFound from '../../pages/PageNotFound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CircularProgress from '@mui/material/CircularProgress';
import CarReviewDetails from '../CarReviewDetails/CarReviewDetails';
import { getCarsInReview } from '../../store/AdminStore/adminActions';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';

function CarReview({ getCarsToReview, carListInReview, loading }) {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const user = useSelector((state) => state.UserProfile.userProfile);

  const [token, setToken] = useState('');

  useEffect(() => {
    // Obtener el token y almacenarlo en el estado local
    const tokenFromStorage = getTokenFromLocalStorage();
    setToken(tokenFromStorage);

    // Llamar a la función getCarsToReview solo si el token está disponible
    if (tokenFromStorage) {
      getCarsToReview(tokenFromStorage);
    }
  }, [getCarsToReview]); // Solo se llama a getCarsToReview cuando el componente se monta


  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  // Filtrar los coches por su plan
const premiumCars = Array.isArray(carListInReview)
  ? carListInReview.filter((car) => car.planId === 3)
  : [];
const freeCars = Array.isArray(carListInReview)
  ? carListInReview.filter((car) => car.planId === 1)
  : [];


  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Car Review - Admin - Autos Nova</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      {user.userRole === 3 ? (
        <div>
          <div>
            <br />
            <h2>Review | {carListInReview.length}</h2>
          </div>
          {/* Sección para los coches Premium */}
          {premiumCars.length > 0 ? (
            <div>
              <h1>Premium | {premiumCars.length}</h1>
              <CarReviewDetails carsInReview={premiumCars} />
            </div>
          ) : (
            <p>No hay Premium pendientes</p>
          )}
          {/* Sección para los coches Gratis */}
          {freeCars.length > 0 && (
            <div>
              <br />
              <h1>Gratis | {freeCars.length}</h1>
              <CarReviewDetails carsInReview={freeCars} />
            </div>
          )}
          {/* Mostrar mensaje cuando no hay coches Gratis */}
          {freeCars.length === 0 && <p>No hay Gratis pendientes</p>}
        </div>
      ) : (
        <PageNotFound />
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCarsToReview: (token) => dispatch(getCarsInReview(token)),
  };
};

const mapStateToProps = (state) => {
  return {
    carListInReview: state.AdminReducer.carListInReview,
    loading: state.AdminReducer.loading,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CarReview);
