import {
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CLEAN_USER_START,
  CLEAN_USER_SUCCESS,
  CLEAN_USER_FAILURE,
  SET_IS_LOGGED_IN_START,
  SET_IS_LOGGED_IN_FAILURE,
  SET_IS_LOGGED_IN_SUCCESS,
  UPDATE_USER_PHONE_START,
  UPDATE_USER_PHONE_SUCCESS,
  UPDATE_USER_PHONE_FAILURE,
} from './userTypes';

const initialState = {
  loading: false,
  error: '',
  successMsg: '',
  userProfile: {},
  loggedIn: false,
};

const createUserStart = (state, action) => {
  return { ...state, loading: true };
};
const createUserSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    userProfile: action.payload,
    successMsg: "Se creo el usuario.",
    loggedIn: true,
  };
};
const createUserFailure = (state, action) => {
  return { ...state, loading: false };
};

const getUserStart = (state, action) => {
  return { ...state, loading: true };
};

const getUserSuccess = (state, action) => {

  return {
    ...state,
    loading: false,
    userProfile: action.payload.user,
    successMsg: action.payload.message,
    loggedIn: true,
  };
};
const getUserFailure = (state, action) => {
  return { ...state, loading: false };
};

const cleanUserStart = (state, action) => {
  return { ...state, loading: false };
};

const cleanUserSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: '',
    successMsg: '',
    userProfile: {},
    loggedIn: false,
  };
};
const cleanUserFailure = (state, action) => {
  return { ...state, loading: false };
};

const setIsLoggedInStart = (state, action) => {
  return { ...state };
};

const setIsLoggedInSuccess = (state, action) => {

  return {
    ...state,
    userProfile: action.payload,
    loggedIn: true
  };
};
const setIsLoggedInFailure = (state, action) => {
  return {
    ...state,
    loggedIn: false
  };
};

const updateUserPhoneStart = (state, action) => {
  return { ...state };
};

const updateUserPhoneSuccess = (state, action) => {

  return {
    ...state,
  };
};
const updateUserPhoneFailure = (state, action) => {
  return { ...state };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_START:
      return createUserStart(state, action);
    case CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case CREATE_USER_FAILURE:
      return createUserFailure(state, action);
    case GET_USER_START:
      return getUserStart(state, action);
    case GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case GET_USER_FAILURE:
      return getUserFailure(state, action);
    case CLEAN_USER_START:
      return cleanUserStart(state, action);
    case CLEAN_USER_SUCCESS:
      return cleanUserSuccess(state, action);
    case CLEAN_USER_FAILURE:
      return cleanUserFailure(state, action);
    case SET_IS_LOGGED_IN_START:
      return setIsLoggedInStart(state, action);
    case SET_IS_LOGGED_IN_SUCCESS:
      return setIsLoggedInSuccess(state, action);
    case SET_IS_LOGGED_IN_FAILURE:
      return setIsLoggedInFailure(state, action);
    case UPDATE_USER_PHONE_START:
      return updateUserPhoneStart(state, action);
    case UPDATE_USER_PHONE_SUCCESS:
      return updateUserPhoneSuccess(state, action);
    case UPDATE_USER_PHONE_FAILURE:
      return updateUserPhoneFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
