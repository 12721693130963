export const CREATE_DEALER_START = 'CREATE_DEALER_START'
export const CREATE_DEALER_FAILURE = 'CREATE_DEALER_FAILURE'
export const CREATE_DEALER_SUCCESS = 'CREATE_DEALER_SUCCESS'

export const GET_DEALER_TYPES_START = 'GET_DEALER_TYPES_START'
export const GET_DEALER_TYPES_SUCCESS = 'GET_DEALER_TYPES_SUCCESS'
export const GET_DEALER_TYPES_FAILURE = 'GET_DEALER_TYPES_FAILURE'

export const GET_DEALER_START = 'GET_DEALER_START'
export const GET_DEALER_SUCCESS = 'GET_DEALER_SUCCESS'
export const GET_DEALER_FAILURE = 'GET_DEALER_FAILURE'

export const GET_DEALER_CARS_START = 'GET_DEALER_CARS_START'
export const GET_DEALER_CARS_SUCCESS = 'GET_DEALER_CARS_SUCCESS'
export const GET_DEALER_CARS_FAILURE = 'GET_DEALER_CARS_FAILURE'

export const POST_DEALER_PICTURE_START = 'POST_DEALER_PICTURE_START'
export const POST_DEALER_PICTURES_SUCCESS = 'POST_DEALER_PICTURES_SUCCESS'
export const POST_DEALER_PICTURES_FAILURE = 'POST_DEALER_PICTURES_FAILURE'

export const POST_DEALER_REVIEW_START = 'POST_DEALER_REVIEW_START'
export const POST_DEALER_REVIEW_SUCCESS = 'POST_DEALER_REVIEW_SUCCESS'
export const POST_DEALER_REVIEW_FAILURE = 'POST_DEALER_REVIEW_FAILURE'

export const GET_DEALER_REVIEW_START = 'GET_DEALER_REVIEW_START'
export const GET_DEALER_REVIEW_SUCCESS = 'GET_DEALER_REVIEW_SUCCESS'
export const GET_DEALER_REVIEW_FAILURE = 'GET_DEALER_REVIEWFAILURET'