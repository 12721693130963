import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classes from './CarList.module.css';
import MobileCarFilter from '../components/Filter/MobileCarFilter';
import CarFilter from '../components/Filter/CarFilter';
import useTheme from '@mui/material/styles/useTheme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MobileSkeleton from '../components/Skeleton/MobileSkeleton';
import CarListLoader from '../components/CarListLoader/CarListLoader';
import CarsForSale from '../components/CarsForSale/CarsForSale';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  getUsedCarList,
  getCarBrands,
  getCarModels,
  getCarStyles,
  getEngineOptions,
  getFeatureList,
  setCarListPage
} from '../store/VehicleStore/vehicleActions';

import { useSelector, useDispatch } from 'react-redux';
import {
  getProvincias,
  getCantones,
} from '../store/LocationStore/locationActions';
import CarListHeader from '../components/CarListHeader/CarListHeader';
import getToast from '../helpers/getToast';
function CarList({
  getForSaleCarList,
  getCarDetail,
  locationInfo,
  carList,
  getProvincia,
  brands,
  getBrands,
  getModels,
  getStyles,
  carStyles,
  getCanton,
  getEngines,
  engines,
  getPopularFeatures,
  popularFeatures,
}) {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  const loading = useSelector((state) => state.CarOptions.loading);
  const [years, setYears] = useState([]);
  const [openFiler, setOpenFiler] = useState(true);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [currentOrder, setCurrentOrder] = useState(0);
  const [currentPage, setCurrentPage] = useState('1');
  const [dropdownValue, setDropdownValue] = useState({
    id: 9,
    description: 'Ordenar por',
  });

  const dispatch = useDispatch()


  const orderChanged = async (order) => {
    setCurrentPage('1')
    dispatch(setCarListPage(1))
    setCurrentOrder(order);
    await getForSaleCarList(activeFilters, '1', order);
  }

  const pageChanged = async (page) => {
    setCurrentPage(page);
    await getForSaleCarList(activeFilters, page, currentOrder);
  }

  const updateFilter = async (newFilter) => {
    //NewFilter is a key value pair that updates the filter
    const filters = { ...activeFilters, ...newFilter }
    await getForSaleCarList(filters, currentPage, currentOrder);
    setActiveFilters(filters)
  }

  const getYearRange = () => {
    const years = [];
    for (let i = 2024; i >= 1960; i--) {
      years.push({ item: `${i}` });
    }
    setYears(years);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    try {
      window.scrollTo(0, 0);

      const executeApiCalls = async () => {
        try {
          await getForSaleCarList();
          brands.length >= 1 ? console.log('') : await getBrands();
          carStyles.length >= 1 ? console.log('') : await getStyles();
          engines.length >= 1 ? console.log('') : await getEngines();
          popularFeatures.length >= 1
            ? console.log('')
            : await getPopularFeatures();
          getYearRange();

        } catch (ex) {
          getToast({
            type: 'ERROR',
            text: 'Se presento un error. Contacta a soporte.',
          });
        }
      };


      executeApiCalls();
    } catch (ex) {
      getToast({
        type: 'ERROR',
        text: 'Se presento un error. Contacta a soporte.',
      });
    }

  }, []);


  const handleResetFilter = async () => {
    setDropdownValue({
      id: 9,
      description: 'Ordenar por'
    })
    setActiveFilters({})
    setCurrentOrder(0)
    setCurrentPage('1')
    await getForSaleCarList({}, 1, 0);
  }

  const handleResetQuickFilter = async (filterToRemove) => {

    const filters = { ...activeFilters }
    filterToRemove.forEach(keyName => {
      delete filters[`${keyName}`];
    })

    await getForSaleCarList(filters, currentPage, currentOrder);
    setActiveFilters(filters)
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.innerWidth <= 980) {
      setShowMobileFilter(true);
    }
    if (window.innerWidth >= 980) {
      setShowMobileFilter(false);
    }

    const handleResize = () => {
      if (window.innerWidth <= 980) {
        setShowMobileFilter(true);
      }
      if (window.innerWidth >= 980) {
        setShowMobileFilter(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ maxWidth: '2120px', margin: '0 auto' }}>
      <HelmetProvider>
        <Helmet>
          <title>Carros en venta | Autos Nova</title>
          <meta
            name="description"
            content="Encontrá tu próximo carro en Autos Nova"
          />
          <meta
            name="keywords"
            content="carros usados costa rica, carros en venta, lista de autos usados, compra y venta de carros costa rica, vehiculos costa rica, vcr"
          />
          <meta name="author" content="Autos Nova" />
          <link rel="canonical" href={window.location.origin + '/en-venta'} />
          <meta
            property="og:description"
            content="Encontrá tu próximo carro en Autos Nova"
          />
          <meta
            property="og:url"
            content={window.location.origin + '/en-venta'}
          />
          <meta property="og:title" content="Carros en venta | Autos Nova" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Autos Nova" />
          <meta property="og:locale" content="es_CR" />
          <meta
            property="og:image"
            content="https://AutosNovaCR.com/an_og.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>
      </HelmetProvider>

      <div
        className={classes.container}
        style={{ display: showMobileFilter ? 'flex' : 'block' }}
      >
        <div className={classes.title}>
          <h2>Carros en venta</h2>
        </div>
        <div className={classes.carListHeader}>
          <CarListHeader
            setDropdownValue={setDropdownValue}
            dropdownValue={dropdownValue}
            handleOrderChange={orderChanged}
            showMobileFilter={showMobileFilter}
            setOpenFiler={setOpenFiler}
          />
        </div>
        <div className={classes.insideContainer}>
          {!showMobileFilter ? (
            <div
              className={`${classes.desktopFilter} ${!openFiler ? classes.disableFilter : ''
                }`}
            >
              <CarFilter
                updateFilter={updateFilter}
                activeFilters={activeFilters}
                years={years}
                locationInfo={locationInfo}
                brands={brands}
                getModels={getModels}
                carStyles={carStyles}
                getCanton={getCanton}
                engineOptions={engines}
                popularFeatures={popularFeatures}
                handleResetFilter={handleResetFilter}
              />
            </div>
          ) : (
            <MobileCarFilter
              activeFilters={activeFilters}
              years={years}
              updateFilter={updateFilter}
              locationInfo={locationInfo}
              brands={brands}
              getModels={getModels}
              carStyles={carStyles}
              getCanton={getCanton}
              engineOptions={engines}
              popularFeatures={popularFeatures}
              handleResetFilter={handleResetFilter}
              handleResetQuickFilter={handleResetQuickFilter}
            />
          )}

          {loading ? <div
            style={{
              width: '100%',
              gap: '5px',
              margin: '0 auto',
              paddingLeft: '10px',
            }}
          >
            {isMobile ? (
              <div>
                <MobileSkeleton />
                <MobileSkeleton />
                <MobileSkeleton />
                <MobileSkeleton />
                <MobileSkeleton />
                <MobileSkeleton />
                {/* <MobileSkeleton />  */}
              </div>
            ) : (
              <div className={classes.flexContainer}>
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
                <CarListLoader />
              </div>
            )}
          </div> :
            <CarsForSale resetFilter={handleResetFilter} usedCars={carList} pageChanged={pageChanged} />
          }
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getForSaleCarList: (filter, page, order) =>
      dispatch(getUsedCarList(filter, page, order)),
    getProvincia: () => dispatch(getProvincias()),
    getCanton: (provincia) => dispatch(getCantones(provincia)),
    getBrands: () => dispatch(getCarBrands()),
    getModels: (brand) => dispatch(getCarModels(brand)),
    getStyles: () => dispatch(getCarStyles()),
    getEngines: () => dispatch(getEngineOptions()),
    getPopularFeatures: () => dispatch(getFeatureList()),
  };
};

const mapStateToProps = (state) => {
  return {
    locationInfo: state.LocationInfo,
    carList: state.CarOptions.carList,
    brands: state.CarOptions.carBrands,
    models: state.CarOptions.carModels,
    carStyles: state.CarOptions.carStyles,
    engines: state.CarOptions.engineOptions,
    popularFeatures: state.CarOptions.carFeaturesList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarList);
