// TODO: Hablar con Moises para ver quieremos otra página aparte, o agregar validaciones en la principal para clientes
import { BsPersonVcard } from 'react-icons/bs';
import ButtonMaterial from '@mui/material/Button';
import Button from '../components/Buttons/Button';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import classes from './CarDetailsReview.module.css';
import Stepper from '../components/Stepper/Stepper';
import { HiShieldExclamation } from 'react-icons/hi';
import { MdOutlineLocationOn } from 'react-icons/md';
import emptyCar from '../images/appImages/emptyCar.webp';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ImCheckmark, ImCancelCircle } from 'react-icons/im';

import CarOverview from '../components/CarOverview/CarOverview';
import {
  getCarDetailsInReview,
  updateCarInReviewStatus,
} from '../store/AdminStore/adminActions';

export function CarDetailsReview({
  carDetailsInReview,
  getCarDetailsInReviewStart,
  updateCarInReviewStatus,
}) {
  const { carUuid } = useParams();
  const user = useSelector((state) => state.UserProfile.userProfile);
  const token = user.token;

  const [decision, setDecision] = useState(null);

  useEffect(() => {
    // Fetch car details when the component mounts
    getCarDetailsInReviewStart(token, carUuid);
  }, [carUuid, getCarDetailsInReviewStart]);

  if (!carDetailsInReview) {
    return (
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Carro no disponible | Autos Nova</title>
          </Helmet>
        </HelmetProvider>
        <div className={classes.containerNotFound}>
          <div className={classes.contentNotFound}>
            <h1 className={classes.titleNotFound}>
              El carro no está disponible
            </h1>
            <div className={classes.imgContainerNotFound}>
              <img src={emptyCar} alt="Empty Car" />
            </div>
            <Link to="/en-venta" className={classes.btn}>
              <Button>Ver carros en venta</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const handleApprove = () => {
    updateCarInReviewStatus(token, carDetailsInReview.carId, 1);
    setDecision('approve');
  };

  const handleDeny = () => {
    updateCarInReviewStatus(token, carDetailsInReview.carId, 6);
    setDecision('deny');
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>
            {`${carDetailsInReview.brand}, ${carDetailsInReview.model} ${carDetailsInReview.year} | Autos Nova`}
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.imgContainer}>
            <Stepper
              carDetailsInReview={true}
              items={carDetailsInReview.pictures}
            />
          </div>
          <div className={classes.innerContent}>
            <div className={classes.content}>
              <div className={classes.title}>
                <h2>
                  {carDetailsInReview.brand} {carDetailsInReview.model}
                  {carDetailsInReview.year}
                </h2>
                <p
                  className={`${classes.reviewlabel} ${decision === 'approve'
                      ? classes.approvedLabel
                      : decision === 'deny'
                        ? classes.deniedLabel
                        : ''
                    }`}
                  style={{
                    backgroundColor:
                      decision === 'approve'
                        ? 'green'
                        : decision === 'deny'
                          ? 'red'
                          : 'rgb(141, 141, 141)',
                  }}
                >
                  {decision === 'approve'
                    ? 'Aprobado'
                    : decision === 'deny'
                      ? 'Rechazado'
                      : 'En revisión'}{' '}
                  | ID: {carDetailsInReview.carId}{' '}
                </p>
                {/* TODO: Agregar funcionalidad de los butones como Admins */}
                <div className={classes.buttonsMaterial}>
                  <ButtonMaterial
                    variant="contained"
                    color="error"
                    size="large"
                    className={classes.denyButton}
                    onClick={handleDeny}
                  >
                    <ImCancelCircle />
                    {/* Deny */}
                  </ButtonMaterial>
                  <ButtonMaterial
                    variant="contained"
                    size="large"
                    color="success"
                    className={classes.approveButton}
                    onClick={handleApprove}
                  >
                    <ImCheckmark />
                    {/* Approve */}
                  </ButtonMaterial>
                </div>
                <div className={classes.buttonsMaterial}>
                  <Link to="/admin/car-review" className={classes.returnButton}>
                    <ButtonMaterial
                      variant="contained"
                      size="large"
                      className={classes.returnButton}
                      style={{ width: '100%', marginTop: '10px' }}
                    >
                      Regresar
                    </ButtonMaterial>
                  </Link>
                </div>
              </div>

              <div className={classes.smallContainer}>
                <h4>Precio</h4>
                <p>
                  ₡{' '}
                  {carDetailsInReview.priceCRC
                    ? carDetailsInReview.priceCRC.toLocaleString('en-US', {
                      maximumFractionDigits: 0,
                    })
                    : carDetailsInReview.priceCRC}
                </p>
              </div>
              <div className={classes.smallContainer}>
                <h4>Detalles</h4>
                <ul>
                  <li>
                    <MdOutlineLocationOn /> {carDetailsInReview.canton},{' '}
                    {carDetailsInReview.provincia}
                  </li>
                  <li>
                    <BsPersonVcard /> {carDetailsInReview.ownerCount}{' '}
                    dueño/dueños
                  </li>
                  <li>
                    <HiShieldExclamation /> Estado:{' '}
                    {carDetailsInReview.condition}
                  </li>
                </ul>
              </div>
              <div style={{ width: '100%' }}>
                <CarOverview details={carDetailsInReview} />
              </div>
            </div>
            <div className={classes.contact}>
              <div>Contacto</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCarDetailsInReviewStart: (token, carUuid) =>
      dispatch(getCarDetailsInReview(token, carUuid)),
    updateCarInReviewStatus: (token, carDetails, status) =>
      dispatch(updateCarInReviewStatus(token, carDetails, status)),
  };
};

const mapStateToProps = (state) => {
  return {
    carDetailsInReview: state.AdminReducer.carDetailsInReview,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CarDetailsReview);
