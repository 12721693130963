import {
  GET_PROVINCIA_START,
  GET_PROVINCIA_SUCCESS,
  GET_PROVINCIA_FAILURE,
  GET_CANTON_START,
  GET_CANTON_SUCCESS,
  GET_CANTON_FAILURE,
} from './locationTypes';

import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const getProvinciaStart = () => {
  return { type: GET_PROVINCIA_START };
};

const getProvinciaSuccess = (data) => {
  return { type: GET_PROVINCIA_SUCCESS, payload: data };
};

const getProvinciaFailure = (err) => {
  return { type: GET_PROVINCIA_FAILURE, payload: err };
};

const getCantonStart = () => {
  return { type: GET_CANTON_START };
};

const getCantonSuccess = (data) => {
  return { type: GET_CANTON_SUCCESS, payload: data };
};

const getCantonFailure = (err) => {
  return { type: GET_CANTON_FAILURE, payload: err };
};

export const getProvincias = () => (dispatch) => {
  dispatch(getProvinciaStart()); 
  axios
    .get(`${baseUrl}/api/Location/GetProvincias`)
    .then((response) => {
      dispatch(getProvinciaSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getProvinciaFailure(err));
    });
};

export const getCantones = (provincia) => (dispatch) => {
  dispatch(getCantonStart());
  axios
    .get(
      `${baseUrl}/api/Location/GetCanton?idProvincia=${provincia}`
    )
    .then((response) => {
      dispatch(getCantonSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getCantonFailure(err));
    });
};
