import axios from 'axios';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import * as StackBlur from 'stackblur-canvas';
import React, { useState, useEffect } from 'react';
import classes from './AdminLinkApproverComponent.module.css';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;
const BASE_URL = process.env.REACT_APP_API_URL;
const token = getTokenFromLocalStorage();

const AdminLinkApproverComponent = ({ car }) => {
  const [instagramFacebookPublished, setInstagramFacebookPublished] = useState(
    car.instagramFacebookPublished
  );
  const [whatsappPublished, setWhatsappPublished] = useState(
    car.whatsappPublished
  );

    const [videoPublished, setVideoPublished] = useState(car.videoPublished);

  const [isApproved, setIsApproved] = useState(
    instagramFacebookPublished && whatsappPublished && videoPublished
  );

  // Calcular los años de antigüedad del carro
  const currentYear = new Date().getFullYear();
  const carYear = parseInt(car.year);
  const yearsOld = currentYear - carYear;

  const applyBackgroundAndEffects = async (imageUrl, car, index) => {
    try {
      const response = await fetch(`${BLOB_STORAGE_URL}/${imageUrl}`, {
        mode: 'cors', // Agregar este encabezado
      });
      const blob = await response.blob();

      // Crear un objeto de imagen para manipulación
      const img = new Image();

      // Escuchar el evento de carga de la imagen
      img.onload = function () {
        // Crear un lienzo para dibujar la imagen y aplicar efectos
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const size = 1200; // Tamaño del cuadrado deseado

        // Establecer el tamaño del lienzo al tamaño del cuadrado
        canvas.width = size;
        canvas.height = size;

        // Calcular el tamaño y posición de la imagen para ajustarla dentro del cuadrado
        let width = size;
        let height = size;
        let x = 0;
        let y = 0;

        if (img.width > img.height) {
          // Imagen horizontal o cuadrada
          width = size;
          height = (size / img.width) * img.height;
          y = (size - height) / 2;
        } else {
          // Imagen vertical
          height = size;
          width = (size / img.height) * img.width;
          x = (size - width) / 2;
        }

        // Dibujar la imagen en el lienzo para obtener los datos de píxeles
        ctx.drawImage(img, 0, 0, size, size);

        // Obtener los datos de píxeles de la imagen
        const imageData = ctx.getImageData(0, 0, size, size).data;

        // Calcular la cantidad de cada canal de color (rojo, verde, azul)
        let red = 0,
          green = 0,
          blue = 0;
        for (let i = 0; i < imageData.length; i += 4) {
          red += imageData[i];
          green += imageData[i + 1];
          blue += imageData[i + 2];
        }
        const totalPixels = imageData.length / 4;
        red = Math.round(red / totalPixels);
        green = Math.round(green / totalPixels);
        blue = Math.round(blue / totalPixels);

        // Convertir el color RGB a una cadena CSS
        const backgroundColor = `rgb(${red}, ${green}, ${blue})`;

        // Crear un gradiente de fondo con el color principal y negro
        const gradient = ctx.createLinearGradient(
          0,
          canvas.height - 100,
          0,
          canvas.height
        );
        gradient.addColorStop(0, 'transparent');
        gradient.addColorStop(1, 'rgba(0, 0, 0, 0.7)');

        // Dibujar el gradiente de fondo en el lienzo
        ctx.fillStyle = gradient;
        ctx.fillRect(0, canvas.height - 100, canvas.width, 100);

        // Aplicar desenfoque al lienzo
        StackBlur.canvasRGBA(canvas, 0, 0, canvas.width, canvas.height, 20);

        // Dibujar la sombra de la imagen principal
        ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = 30; // Aumentar la intensidad de la sombra
        ctx.shadowOffsetX = 15; // Desplazamiento horizontal
        ctx.shadowOffsetY = 15; // Desplazamiento vertical

        // Dibujar la imagen principal en el lienzo con sombra
        ctx.drawImage(img, x, y, width, height);

        // Dibujar texto dinámico (marca) en la esquina inferior izquierda
        // ctx.font = 'bold 120px Arial'; // Tamaño del texto aumentado
        // ctx.fillStyle = '#ffffff'; // Color del texto
        // ctx.shadowColor = 'rgba(0, 0, 0, 40)'; // Color de la sombra
        // ctx.shadowBlur = 30; // Aumentar la intensidad de la sombra
        // ctx.shadowOffsetX = 1; // Desplazamiento horizontal de la sombra
        // ctx.shadowOffsetY = 1; // Desplazamiento vertical de la sombra
        // // const text = car.brandName; // Convertir a mayúsculas
        // const text = 'Mercedez-Benz'; // Convertir a mayúsculas
        // const textWidth = ctx.measureText(text).width;
        // const textHeight = 90; // Tamaño de texto fijo
        // const margin = 50; // Margen desde el borde inferior y izquierdo
        // const maxWidth = canvas.width - margin * 2; // Ancho máximo para el texto
        // const lineHeight = textHeight * 1.2; // Altura de línea
        // let words = text.split(' ');
        // let line = '';
        // let yPosition = canvas.height - margin - textHeight;

        // for (let word of words) {
        //   let testLine = line + word + ' ';
        //   let testWidth = ctx.measureText(testLine).width;
        //   if (testWidth > maxWidth) {
        //     ctx.fillText(line, margin, yPosition);
        //     line = word + ' ';
        //     yPosition -= lineHeight;
        //   } else {
        //     line = testLine;
        //   }
        // }
        // ctx.fillText(line, margin, yPosition);

        // Obtener la imagen resultante como un blob
        canvas.toBlob(
          (blob) => {
            // Crear un objeto URL para la imagen resultante
            const url = URL.createObjectURL(blob);

            // Crear un enlace de descarga para la imagen
            const a = document.createElement('a');
            a.href = url;
            a.download = `${car.uuidCar}_${car.brandName}_${car.modelName}_${car.year}_${index}`; // Nombre de archivo deseado
            document.body.appendChild(a);
            a.click();

            // Limpiar el objeto URL creado
            URL.revokeObjectURL(url);
          },
          'image/jpeg',
          1
        ); // Tipo de archivo y calidad
      };

      // Asignar la URL del blob a la imagen
      img.src = URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error applying background and effects:', error);
    }
  };

  const downloadImages = async () => {
    try {
      // Llamar al endpoint para obtener las imágenes del carro
      const response = await axios.get(
        `${BASE_URL}/api/Admin/GetAllCarImagesByCarId?idCar=${car.idCar}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data) {
        throw new Error('No data received');
      }

      // Obtener las URLs de las imágenes del carro desde la respuesta
      const imageUrls = response.data;

      // Iterar sobre las URLs de las imágenes del carro
      imageUrls.forEach(async (imageUrl, index) => {
        await applyBackgroundAndEffects(imageUrl, car, index); // Llamar a la función applyBackgroundAndEffects
      });
    } catch (error) {
      console.error('Error downloading images:', error);
    }
  };

  useEffect(() => {
    setIsApproved(
      instagramFacebookPublished && whatsappPublished && videoPublished
    );
  }, [instagramFacebookPublished, whatsappPublished, videoPublished]);

  const handleSocialMediaToggle = async (platform, newStatus) => {
    try {
      const apiUrl = `${BASE_URL}/api/Admin/UpdateOrInsertSocialMediaPublicationLink?uuidCar=${car.uuidCar}&idCar=${car.idCar}&platform=${platform}&newStatus=${newStatus}`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.text();
      if (platform === 'instagram_facebook') {
        setInstagramFacebookPublished(newStatus);
      } else if (platform === 'whatsapp') {
        setWhatsappPublished(newStatus);
      }
      else if (platform === 'video') {
        setVideoPublished(newStatus);
      }
      
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleInstagramFacebookToggle = () => {
    const newStatus = !instagramFacebookPublished;
    handleSocialMediaToggle('instagram_facebook', newStatus);
  };

  const handleWhatsappToggle = () => {
    const newStatus = !whatsappPublished;
    handleSocialMediaToggle('whatsapp', newStatus);
  };

    const handleVideoToggle = () => {
      const newStatus = !videoPublished;
      handleSocialMediaToggle('video', newStatus);
    };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.log('Text copied to clipboard:', text);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

const handleCopyIGFBInfo = () => {
  // Calcular el promedio de kilómetros o millas por año y truncar a un entero
  const averageDistancePerYear =
    car.kilometreType === 'Mi'
      ? Math.floor(car.miles / yearsOld)
      : Math.floor(car.kilometres / yearsOld);

  const negocioLine =
    car.dealerName && !car.instaUser ? `• Negocio: ${car.dealerName}\n` : '';
  
  const negocioDisplay = car.instaUser
    ? `• Negocio: @${car.instaUser}\n`
    : negocioLine;

  const lastMarchamoLine =
    car.lastMarchamo !== null
      ? `• Valor del último marchamo: ~₡ ${car.lastMarchamo.toLocaleString()}\n`
      : `• Valor del último marchamo: Sin información\n`;

  const sellerNotesLine = car.sellerNotes
    ? `• Notas del vendedor: ${car.sellerNotes}\n\n`
    : `• Notas del vendedor: No se han especificado notas\n\n`;

  const engineCcLine =
    car.fuelTypeName !== 'Eléctrico' && car.engineCc !== null
      ? `• Cilindrada: ${car.engineCc.toLocaleString()} CC\n`
      : '';

  const distanceType = car.kilometreType === 'Mi' ? 'Millas' : 'Kms';

  const igFbDescText =
    `${car.brandName} | ${car.modelName} | ${car.year}\n\n` +
    negocioDisplay +
    `• ${distanceType}: ${
      car.kilometreType === 'Mi'
        ? car.miles.toLocaleString()
        : car.kilometres.toLocaleString()
    } ${distanceType}\n` +
    `• ${distanceType} promedio por año: ~${averageDistancePerYear.toLocaleString()} ${distanceType}\n` +
    `• Único dueño: ${car.uniqueOwner ? 'Sí' : 'No'}\n` +
    `• Años de antigüedad: ${yearsOld}\n` +
    `• Ubicación: ${car.provinciaNombre}, ${car.cantonNombre}\n` +
    `• Transmisión: ${car.transmission}\n` +
    `• Combustible: ${car.fuelTypeName}\n` +
    engineCcLine +
    `• Asientos: ${car.passengers.toLocaleString()}\n` +
    `• Recibe vehículo: ${car.ownerAcceptsCar ? 'Sí' : 'No'}\n` +
    `• Precio negociable: ${car.negotiablePrice ? 'Sí' : 'No'}\n` +
    lastMarchamoLine +
    sellerNotesLine +
    `• Precio: ₡ ${car.carPriceCrc.toLocaleString()}\n\n` +
    `• Contacto, fotos, características y todos los detalles en: https://AutosNovaCR.com/carro/${car.uuidCar}\n\n` +
    `Síguenos en nuestro canal de WhatsApp, ahí podrás ingresar a los enlaces más fácilmente \n\n\n` +
    `#VentaDeAutos #CostaRica #AutosEnVenta #AutosNova #AutosNovaCR #AutosNovaCostaRica #${car.brandName.replace(
      /\s+/g,
      ''
    )} #${car.modelName.replace(/\s+/g, '')} #${
      car.brandName
    }${car.modelName.replace(/\s+/g, '')} #${
      car.brandName
    }${car.modelName.replace(/\s+/g, '')}${car.year} #${
      car.brandName
    }CR #${car.modelName.replace(/\s+/g, '')}CR #${
      car.brandName
    }${car.modelName.replace(/\s+/g, '')}CR #${
      car.brandName
    }CostaRica #${car.modelName.replace(
      /\s+/g,
      ''
    )}CostaRica #AutosCostaRica #AutosCR #AutosEnVentaCR `;

  copyToClipboard(igFbDescText);
};



  const handleCopyWAInfo = () => {
    // Calcular el promedio de kilómetros por año
    const averageKmsPerYear = Math.floor(car.kilometres / yearsOld);

    const negocioLine = car.dealerName
      ? `• *Negocio:* ${car.dealerName}\n`
      : '';

    // Verificar si car.lastMarchamo es null antes de llamar a toLocaleString()
    const lastMarchamoLine =
      car.lastMarchamo !== null
        ? `• *Valor del último marchamo:* ~₡ ${car.lastMarchamo.toLocaleString()}\n`
        : `• *Valor del último marchamo:* Sin información\n`;

    const sellerNotesLine = car.sellerNotes
      ? `• *Notas del vendedor:* ${car.sellerNotes}\n\n`
      : `• *Notas del vendedor:* No se han especificado notas\n\n`;

    // Verificar si car.engineCc es null antes de llamar a toLocaleString()
    const engineCcLine =
      car.fuelTypeName !== 'Eléctrico' && car.engineCc !== null
        ? `• *Cilindrada:* ${car.engineCc.toLocaleString()} CC\n`
        : '';

    const waDescText =
      `*${car.brandName} | ${car.modelName} | ${car.year}*\n\n` +
      negocioLine +
      `• *Kilometraje:* ${car.kilometres.toLocaleString()} ${
        car.kilometreType
      }\n` +
      `• *Kms promedio por año:* ~${averageKmsPerYear.toLocaleString()} Kms\n` +
      `• *Único dueño:* ${car.uniqueOwner ? 'Sí' : 'No'}\n` +
      `• *Años de antigüedad:* ${yearsOld}\n` +
      `• *Ubicación:* ${car.provinciaNombre}, ${car.cantonNombre}\n` +
      `• *Transmisión:* ${car.transmission}\n` +
      `• *Combustible:* ${car.fuelTypeName}\n` +
      engineCcLine +
      `• *Asientos:* ${car.passengers.toLocaleString()}\n` +
      `• *Recibe vehículo:* ${car.ownerAcceptsCar ? 'Sí' : 'No'}\n` +
      `• *Precio negociable:* ${car.negotiablePrice ? 'Sí' : 'No'}\n` +
      lastMarchamoLine +
      sellerNotesLine +
      `• *Precio:* ₡ ${car.carPriceCrc.toLocaleString()}\n\n` +
      `• *Contacto, fotos, características y todos los detalles en: https://AutosNovaCR.com/carro/${car.uuidCar}?utm_source=wa*`;

    copyToClipboard(waDescText);
  };

  const formatApproveDate = (approveDate) => {
    const date = new Date(approveDate);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate}`;
  };

  return (
    <div
      className={`${classes.linkApprover} ${
        isApproved ? classes.approved : ''
      }`}
    >
      <div className={classes.carInfo}>
        <h2 className={classes.carTitle}>
          {car.brandName} {car.modelName} {car.year}
        </h2>
        <p className={classes.carId}>{formatApproveDate(car.approveDate)}</p>
        <p className={classes.carId}>
          {car.idCar} | {car.uuidCar}
        </p>
        <div className={classes.imageContainer}>
          <a
            href={`/carro/${car.uuidCar}`}
            className={classes.imageContainer}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${BLOB_STORAGE_URL}/CAR-${car.uuidCar}-IMG-1.jpeg`}
              alt="Car"
              className={classes.carImage}
            />
          </a>
        </div>
      </div>
      <div className={classes.checkboxes}>
        <div className={classes.toggle}>
          <span>IG/FB</span>
          <Switch
            checked={instagramFacebookPublished}
            onChange={handleInstagramFacebookToggle}
          />
        </div>
        <div className={classes.toggle}>
          <span>Video</span>
          <Switch checked={videoPublished} onChange={handleVideoToggle} />
        </div>
        <div className={classes.toggle}>
          <span>WA</span>
          <Switch checked={whatsappPublished} onChange={handleWhatsappToggle} />
        </div>
      </div>
      <div className={classes.buttons}>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.descButton}
            onClick={handleCopyIGFBInfo}
          >
            IG/FB Desc
          </Button>
          <Button
            variant="contained"
            className={classes.descButton}
            onClick={handleCopyWAInfo}
          >
            WA Desc
          </Button>
        </div>
        <div className={classes.buttonContainer}>
          <a className={classes.linkButton} onClick={downloadImages}>
            Imágenes
          </a>
          <a
            href={`${window.location.origin}/admin/VideoTracker`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkButton}
          >
            Video Tracker
          </a>

          <a
            href="https://www.canva.com/design/DAF7zFgc1no/IKNxQXhzA5zo9ZttUfAEcg/edit"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkButton}
          >
            Canva
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdminLinkApproverComponent;
