import classes from './DealerSignUp.module.css';
import * as React from 'react';
import Button from '../components/Buttons/Button';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  createDealer,
  getDealerTypes,
} from '../store/DealerStore/DealerAction';
// import Map from '../components/Map/Map';
import { TimePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
// import Alert from '../components/Alert/SmallAlert';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { getUserRequest } from '../store/UserStore/userActions';
import Login from '../components/Login/Login';
import getToast from '../helpers/getToast';
import { setTokenInLocalStorage } from '../helpers/jwtService';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import DropDown from '../components/DropDowns/CustomDropDown';
import { Exception } from '@microsoft/applicationinsights-web';
import LoadingPage from '../components/Loading/LoadingPage';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function DealerSignUp({ userProfileState, registerDealer, getUser }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [previewUrlLogo, setPreviewUrlLogo] = useState(null);

  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const user = useSelector((state) => state.UserProfile.userProfile);

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [dealerName, setDealerName] = useState('');
  const [receivesCar, setReceivesCar] = useState(false);
  const [financing, setFinancing] = useState(false);
  const [warranty, setWarranty] = useState(false);
  const [testDrive, setTestDrive] = useState(false);
  const [dealerInfo, setDealerInfo] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState('');
  const [website, setWebsite] = useState('');
  const [instaUser, setInstaUser] = useState('');
  const [schRange, setSchRange] = useState({});
  const [carType, setCarType] = useState({ value: 1, description: 'Todos' });
  const [coordinates, setCoordinates] = useState(null);
  const [alert, setAlert] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const { loggedIn, userProfile } = userProfileState;
  useEffect(() => {
    const savedDealerCustomUrl = localStorage.getItem('dealerCustomUrl');

    // Check if userRole is 2 and dealerCustomUrl is not null
    if (
      userProfileState.userProfile.userRole === 2 &&
      savedDealerCustomUrl !== null
    ) {
      // Redirect to dealer page with custom URL
      navigate(`/vendedor/${savedDealerCustomUrl}`);
    }
  }, []);

  useEffect(() => {
    const { firstName, lastName, phoneNumber } = userProfile;
    setFirstName(firstName);
    setLastName(lastName);
    setPhone(phoneNumber);
  }, [userProfile]);

  const categoryOptions = [
    { value: 1, description: 'Todos' },
    { value: 2, description: 'Alta gama' },
    { value: 3, description: 'Media gama' },
  ];

  const boolOptions = [
    { value: 1, description: 'Si' },
    { value: 0, description: 'No' },
  ];

  const provinces = [
    'Alajuela',
    'Cartago',
    'Guanacaste',
    'Heredia',
    'Limón',
    'Puntarenas',
    'San José',
  ];

  const handleDealerAbout = (e) => {
    let desc = e.target.value;
    desc = desc.replace(/['"=]/g, '');
    setDealerInfo(desc);
  };
  const handleSubmitLogo = async (id) => {
    const formData = new FormData();
    formData.append('file', selectedLogo, `logo_${id}`);
    try {
      const response = await fetch(`${baseUrl}/api/Dealer/ImportLogo`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        throw Exception('');
      }
    } catch (error) {
      getToast({ type: 'ERROR', text: 'No se pudo subir el logo.' });
    }
  };

  const handleSubmitCover = async (id) => {
    const formData = new FormData();
    formData.append('file', selectedFile, `logo_${id}`);
    try {
      const response = await fetch(`${baseUrl}/api/Dealer/ImportCover`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        throw Exception('');
      }
    } catch (error) {
      getToast({
        type: 'ERROR',
        text: 'No se pudo subir la imagen de portada.',
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedLogo(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrlLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (el) => {
    setLoading(true);
    el.preventDefault();
    if (
      dealerName !== '' &&
      city !== '' &&
      address !== '' &&
      firstName !== '' &&
      lastName !== ''
    ) {
      const obj = {
        idDealer: 0,
        dealerName: dealerName,
        address: address,
        city: city,
        createdOn: '2023-05-30T01:20:41.934Z',
        sellerType: carType.value,
        firstName: firstName,
        lastName: lastName,
        lat: `0`,
        lng: `0`,
        phoneNumber: phone,
        whatsappNumber: phone,
        uuid: userProfile.uuidUser,
        idUser: userProfile.idUser,
        website: website,
        instaUser: instaUser,
        receivesCar: receivesCar,
        financing: financing,
        warranty: warranty,
        testDrive: testDrive,
        dealerInfo: dealerInfo,
        dealerSchedules: schedule,
      };
      try {
        const response = await registerDealer(obj);

        if (selectedLogo !== null) {
          const logoResponse = handleSubmitLogo(userProfile.idUser);
          if (!logoResponse) {
            throw Exception('No se pudo subir el logo.');
          }
        }
        if (selectedFile !== null) {
          const coverResponse = handleSubmitCover(userProfile.idUser);
          if (!coverResponse) {
            throw Exception('No se pudo subir la foto de portada.');
          }
        }
        if (!response) {
          setAlert(true);
          return;
        }
        const customUrl = await response.customUrl; //Espera respuesta del Custom URL

        const userResponse = await getUser(
          user.email,
          user.authProvider,
          user.authProviderId
        );
        if (userResponse) {
          setTokenInLocalStorage(userResponse.token);
        }
        setLoading(false);
        getToast({ type: 'SUCCESS', text: 'Se ha creado tu cuenta.' });

        navigate(`/vendedor/${customUrl}`);
      } catch (ex) {
        setLoading(false);
        getToast({
          type: 'ERROR',
          text: 'Se ha presetando un error. Contacta a soporte por WhatsApp',
          // text: `${ex}`,
        });
      }
    } else {
      setLoading(false);
      getToast({
        text: 'Debes llenar todos los campos requeridos.',
        type: 'ERROR',
      });
      setAlert(true);
    }
  };

  const handleMapChange = (coordinates) => {
    setCoordinates(coordinates);
  };

  const options = {
    enableHighAccuracy: true, // Request high accuracy
    timeout: 5000, // Timeout in milliseconds (default is 0, which means no timeout)
    maximumAge: 0, // Maximum age of cached position in milliseconds (default is 0, which means no cache)
  };

  const getGeoLocation = () => {
    console.log('GEO', navigator.geolocation);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handleMapChange({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting coordinates:', error);
        },
        options
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeMondaySch = (range, day) => {
    if (range && range.length === 2) {
      const open = new Date();
      // console.log(range);
      open.setUTCHours(range[0]['$H']);
      open.setUTCMinutes(range[0]['$m']);
      const close = new Date();
      close.setUTCHours(range[1]['$H']);
      close.setUTCMinutes(range[1]['$m']);

      const sch = {
        idSchedule: 0,
        day: day,
        openTime: open,
        closeTime: close,
        idDealer: 0,
      };

      const newArr = schedule.filter((el) => el.day !== day);
      setSchedule([...newArr, sch]);

      const newRange = schRange;
      // console.log(newRange, 'NEW RANGE', newRange[day]);
      if (newRange[day] !== undefined) {
        delete newRange[day];
      }
      newRange[day] = range;
      setSchRange(newRange);
    }
  };

  const copySchedule = (day) => {
    const lastValue = Object.keys(schRange).pop();
    changeMondaySch(schRange[lastValue], day);
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <title>Registro de negocio - Autos Nova</title>
          <meta name="description" content="Registro de negocio - Autos Nova" />
        </Helmet>
      </HelmetProvider>
      {!loggedIn ? (
        <div>
          <Login
            onClose={() => {
              navigate('/');
            }}
            showLogin={!loggedIn}
            title={'Regístrate para continuar'}
          />
        </div>
      ) : (
        <div className={classes.formContainer}>
          <br />
          <br />
          <h2>Detalles personales</h2>
          <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre del dueño
              </label>
              <div className="mt-2">
                <input
                  onChange={(el) => {
                    const inputValue = el.target.value;

                    // Utilizar una expresión regular para permitir solo letras en español
                    const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]+$/;

                    if (
                      (regex.test(inputValue) || inputValue === '') &&
                      inputValue.length <= 20
                    ) {
                      setFirstName(inputValue);
                    }
                  }}
                  value={firstName}
                  placeholder="Juan"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {alert && (
                <p className="mt-1 text-sm leading-6 text-rose-600">
                  Campo requerido.
                </p>
              )}
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Primer apellido
              </label>
              <div className="mt-2">
                <input
                  onChange={(el) => {
                    const inputValue = el.target.value;
                    const regex = /^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ\s]+$/;
                    if (
                      (regex.test(inputValue) || inputValue === '') &&
                      inputValue.length <= 30
                    ) {
                      setLastName(inputValue);
                    }
                  }}
                  value={lastName}
                  placeholder="Quesada"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {alert && (
                <p className="mt-1 text-sm leading-6 text-rose-600">
                  Campo requerido.
                </p>
              )}
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Número de celular
              </label>
              <div className="mt-2">
                <input
                  onChange={(el) => {
                    const inputValue = el.target.value;

                    // Utilizar una expresión regular para permitir solo números y un máximo de 8 dígitos
                    const regex = /^[0-9]{0,8}$/;

                    if (regex.test(inputValue) || inputValue === '') {
                      setPhone(inputValue);
                    }
                  }}
                  value={phone}
                  placeholder="0000-0000"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <h4>Detalles del negocio</h4>
          <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre del negocio
              </label>
              <div className="mt-2">
                <input
                  onChange={(el) => {
                    const inputValue = el.target.value;
                    const regex = /^[a-zA-Z0-9áéíóúüÁÉÍÓÚÜñÑ\s]+$/;
                    if (
                      (regex.test(inputValue) || inputValue === '') &&
                      inputValue.length <= 30
                    ) {
                      setDealerName(inputValue);
                    }
                  }}
                  required={true}
                  value={dealerName}
                  placeholder="Escribe el nombre del negocio"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {alert && (
                <p className="mt-1 text-sm leading-6 text-rose-600">
                  Campo requerido.
                </p>
              )}
            </div>
            <div className="sm:col-span-3 ">
              <label
                htmlFor="photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Logo
              </label>
              <div className="mt-2 flex items-center gap-x-3">
                {previewUrlLogo === null ? (
                  <>
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <label
                      for="addLogo"
                      className="rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Agregar
                    </label>
                    <input
                      onChange={handleLogoChange}
                      type="file"
                      className="hidden"
                      placeholder="Agregar"
                      id="addLogo"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={previewUrlLogo}
                      alt="Preview"
                      className="rounded-full w-14 h-14 object-cover"
                    />
                    <label
                      for="logo"
                      className="rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Cambiar
                    </label>
                    <input
                      onChange={handleLogoChange}
                      type="file"
                      className="hidden"
                      id="logo"
                    />
                  </>
                )}
              </div>
            </div>{' '}
            <div className="col-span-full ">
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Acerca del negocio
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="border-gray-300 block w-full rounded-md border-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
                  maxLength={1500}
                  onChange={handleDealerAbout}
                  value={dealerInfo}
                />
              </div>
              {alert ? (
                <p className="mt-1 text-sm leading-6 text-rose-600">
                  Campo requerido.
                </p>
              ) : (
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Describe tu negocio
                </p>
              )}
            </div>
            <div className="col-span-full">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Foto de portada
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                {previewUrl === null ? (
                  <div className="text-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Sube una imagen</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">PNG, JPG</p>
                  </div>
                ) : (
                  <>
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="h-64 w-full object-cover col-span-6"
                    />
                  </>
                )}
              </div>
              {previewUrl === null ? (
                <></>
              ) : (
                <>
                  <br />
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  <label
                    for="file-upload"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold border border-gray-400 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cambiar foto
                  </label>
                  <button
                    onClick={() => {
                      setPreviewUrl(null);
                      setSelectedFile(null);
                    }}
                    className="rounded-md ml-2 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold border border-gray-400 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Borrar
                  </button>
                </>
              )}
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Dirección exacta
              </label>
              <div className="mt-2">
                <input
                  required
                  onChange={(el) => {
                    const inputValue = el.target.value;

                    // Utilizar una expresión regular para permitir solo caracteres alfanuméricos, espacios y letras en español
                    const regex = /^[a-zA-Z0-9áéíóúüÁÉÍÓÚÜñÑ\s.,]+$/;

                    if (
                      (regex.test(inputValue) || inputValue === '') &&
                      inputValue.length <= 100
                    ) {
                      setAddress(inputValue);
                    }
                  }}
                  value={address}
                  placeholder="Dirección exacta"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {alert && (
                <p className="mt-1 text-sm leading-6 text-rose-600">
                  Campo requerido.
                </p>
              )}{' '}
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="province"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Provincia
              </label>
              <div className="mt-2">
                <select
                  required
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  name="province"
                  id="province"
                  autoComplete="province"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="" disabled>
                    Seleccione una provincia
                  </option>
                  {provinces.map((province) => (
                    <option key={province} value={province}>
                      {province}
                    </option>
                  ))}
                </select>
              </div>
              {alert && (
                <p className="mt-1 text-sm leading-6 text-rose-600">
                  Campo requerido.
                </p>
              )}
            </div>
            {/* <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                Provincia
              </label>
              <div className="mt-2">
                <input
                  required
                  onChange={(el) => {
                    if (el.target.value.length <= 11) {
                      setCity(el.target.value);
                    }
                  }}
                  value={city}
                  placeholder="Provincia"
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {alert && <p className="mt-1 text-sm leading-6 text-rose-600">Campo requerido.</p>}
            </div> */}
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Sitio web o página de Facebook (Opcional)
              </label>
              {/* <br /> */}
              <div className="mt-2">
                <input
                  required
                  onChange={(el) => {
                    let desc = el.target.value;
                    desc = desc.replace(/['"=\s]/g, '');
                    setWebsite(desc);
                  }}
                  value={website}
                  placeholder="https://autosnovacr.com"
                  type="text"
                  name="first-name"
                  id="first-name"
                  maxLength={100}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>{' '}
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="instagrma"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Usuario de Instagram (Opcional)
              </label>
              {/* <br /> */}
              <div className="mt-2">
                <input
                  required
                  onChange={(el) => {
                    let inputValue = el.target.value
                      .slice(0, 30)
                      .replace(/[^a-zA-Z0-9@_\.]/g, ''); // Filtra caracteres no permitidos

                    // Verifica si el usuario escribió '@' como primer carácter
                    if (inputValue.indexOf('@') === 0) {
                      // Si '@' es el primer carácter, permitirlo y eliminar todos los otros '@'
                      inputValue = '@' + inputValue.slice(1).replace(/@/g, '');
                    }

                    setInstaUser(inputValue);
                  }}
                  value={instaUser}
                  placeholder="@Usuario de Instagram"
                  type="text"
                  name="instagrma"
                  id="instagrma"
                  maxLength={100}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-1 border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>{' '}
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="first-name"
                className=" block text-sm font-medium leading-6 text-gray-900"
              >
                ¿Qué gama de carros vende?
              </label>
              <div className="mt-2">
                <DropDown
                  setDropdownValue={(newValue) => {
                    setCarType(newValue);
                  }}
                  dropdownValue={carType.description}
                  items={categoryOptions}
                  preview={carType.description}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Recibe vehículos
              </label>
              <div className="mt-2">
                <DropDown
                  setDropdownValue={(newValue) => {
                    const value = newValue.value === 1 ? true : false;
                    setReceivesCar(value);
                  }}
                  dropdownValue={
                    receivesCar
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                  items={boolOptions}
                  preview={
                    receivesCar
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Financiamiento
              </label>
              <div className="mt-2">
                <DropDown
                  setDropdownValue={(newValue) => {
                    const value = newValue.value === 1 ? true : false;
                    setFinancing(value);
                  }}
                  dropdownValue={
                    financing
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                  items={boolOptions}
                  preview={
                    financing
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Prueba de manejo
              </label>
              <div className="mt-2">
                <DropDown
                  setDropdownValue={(newValue) => {
                    const value = newValue.value === 1 ? true : false;
                    setTestDrive(value);
                  }}
                  dropdownValue={
                    testDrive
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                  items={boolOptions}
                  preview={
                    testDrive
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Ofrece garantía
              </label>
              <div className="mt-2">
                <DropDown
                  setDropdownValue={(newValue) => {
                    const value = newValue.value === 1 ? true : false;
                    setWarranty(value);
                  }}
                  dropdownValue={
                    warranty
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                  items={boolOptions}
                  preview={
                    warranty
                      ? boolOptions[0].description
                      : boolOptions[1].description
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-x-6 gap-y-3 sm:grid-cols-6 col-span-full  ">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900 col-span-6"
              >
                Indique su horario:
              </label>
              <p className="mt-3 text-sm leading-6 text-gray-600 col-span-6">
                Si un día no abre deja el espacio en blanco.
              </p>
              <div className="col-span-6">
                <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                  Lunes:
                </label>
                <TimePicker.RangePicker
                  style={{ minWidth: '19rem' }}
                  onChange={(el) => {
                    changeMondaySch(el, 1);
                  }}
                  format={'HH:mm'}
                  minuteStep={15}
                  value={schRange[1]}
                />
              </div>
              <div className="col-span-6 flex flex-wrap">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                    Martes:
                  </label>
                  <TimePicker.RangePicker
                    style={{ minWidth: '19rem' }}
                    onChange={(el) => {
                      changeMondaySch(el, 2);
                    }}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={schRange[2]}
                  />
                </div>
                {schedule.length > 0 && (
                  <button
                    onClick={() => copySchedule(2)}
                    className="mt-2 max-h-8 sm:ml-4 self-end col-span-6 rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Repetir horario anterior
                  </button>
                )}
              </div>
              <div className="col-span-6 flex flex-wrap">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                    Miércoles:
                  </label>
                  <TimePicker.RangePicker
                    style={{ minWidth: '19rem' }}
                    onChange={(el) => {
                      changeMondaySch(el, 3);
                    }}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={schRange[3]}
                  />
                </div>
                {schedule.length > 0 && (
                  <button
                    onClick={() => copySchedule(3)}
                    className="mt-2 max-h-8 sm:ml-4 self-end col-span-6 rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Repetir horario anterior
                  </button>
                )}
              </div>
              <div className="col-span-6 flex flex-wrap">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                    Jueves:
                  </label>
                  <TimePicker.RangePicker
                    style={{ minWidth: '19rem' }}
                    onChange={(el) => {
                      changeMondaySch(el, 4);
                    }}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={schRange[4]}
                  />
                </div>
                {schedule.length > 0 && (
                  <button
                    onClick={() => copySchedule(4)}
                    className="mt-2 max-h-8 sm:ml-4 self-end col-span-6 rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Repetir horario anterior
                  </button>
                )}
              </div>
              <div className="col-span-6 flex flex-wrap">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                    Viernes:
                  </label>
                  <TimePicker.RangePicker
                    style={{ minWidth: '19rem' }}
                    onChange={(el) => {
                      changeMondaySch(el, 5);
                    }}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={schRange[5]}
                  />
                </div>
                {schedule.length > 0 && (
                  <button
                    onClick={() => copySchedule(5)}
                    className="mt-2 max-h-8 sm:ml-4 self-end col-span-6 rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Repetir horario anterior
                  </button>
                )}
              </div>
              <div className="col-span-6 flex flex-wrap">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                    Sábado:
                  </label>
                  <TimePicker.RangePicker
                    style={{ minWidth: '19rem' }}
                    onChange={(el) => {
                      changeMondaySch(el, 6);
                    }}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={schRange[6]}
                  />{' '}
                </div>
                {schedule.length > 0 && (
                  <button
                    onClick={() => copySchedule(6)}
                    className="mt-2 max-h-8 sm:ml-4 self-end col-span-6 rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Repetir horario anterior
                  </button>
                )}
              </div>
              <div className="col-span-6 flex flex-wrap">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900 col-span-6">
                    Domingo:
                  </label>
                  <TimePicker.RangePicker
                    style={{ minWidth: '19rem' }}
                    onChange={(el) => {
                      changeMondaySch(el, 7);
                    }}
                    format={'HH:mm'}
                    minuteStep={15}
                    value={schRange[7]}
                  />
                </div>
                {schedule.length > 0 && (
                  <button
                    onClick={() => copySchedule(7)}
                    className="mt-2 max-h-8 sm:ml-4 self-end col-span-6 rounded-md border border-gray-400 bg-white px-2.5 py-1.5 text-sm cursor-pointer font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Repetir horario anterior
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <div>
            <h4>Ubicación en Google Maps</h4>
            <br />
            <Map
              coordinates={coordinates}
              changeCoordinates={handleMapChange}
              getGeoLocation={getGeoLocation}
            />
          </div> */}
          <Button onClick={handleSubmit}>Empezar</Button>
        </div>
      )}{' '}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    registerDealer: (dealerProfile) => dispatch(createDealer(dealerProfile)),
    getDealerOptions: () => dispatch(getDealerTypes()),
    getUser: (email, authProvider, authProviderId) =>
      dispatch(getUserRequest(email, authProvider, authProviderId)),
  };
};

const mapStateToProps = (state) => {
  return {
    userProfileState: state.UserProfile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerSignUp);
