import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import classes from './PrivacyPolicy.module.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PrivacyPolicy() {
  const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;
  ReactGA.initialize(GOOGLE_ANALYTICS);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  // Auto Scroll
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la ventana a la parte superior
  }, []); // El efecto se ejecuta una vez, cuando el componente se monta

  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <title>Políticas de privacidad - Autos Nova</title>
          <meta
            name="description"
            content="Políticas de privacidad - Autos Nova"
          />
        </Helmet>
      </HelmetProvider>
      <div className={classes.title}>
        <h1>Políticas de privacidad</h1>
      </div>
      <div className={classes.section}>
        <p>
          En Autos Nova, la privacidad y seguridad de tus datos personales son
          nuestra máxima prioridad. Esta Política de Privacidad explica cómo
          recopilamos, utilizamos, compartimos y protegemos la información
          proporcionada por los usuarios de nuestros servicios. Al utilizar
          Autos Nova, aceptas los términos descritos en esta política.
        </p>
        <h3>Información que Recopilamos</h3>
        <p>
          Recopilamos información personal, como nombre, dirección de correo
          electrónico, números de teléfono y detalles del vehículo, cuando los
          usuarios la proporcionan voluntariamente al registrarse o publicar
          anuncios.
        </p>
        <h3>Cómo Utilizamos la Información</h3>
        <p>
          Utilizamos la información publicar los datos necesarios para publicar
          el anuncio de cada vehículos. Adicionalmente, se utiliza el número de
          WhatsApp proporcionado para notificar cuando el carro ha sido
          publicado en la plataforma.
        </p>
        <h3>Divulgación de Información</h3>
        <p>
          En Autos Nova, la divulgación de información se realiza de manera
          selectiva y cautelosa. Solo se publica la información necesaria para
          el anuncio del vehículo que el usuario ha proporcionado de manera
          voluntaria con el objetivo de facilitar el contacto. Detalles
          sensibles como el correo electrónico, nombre completo y otros datos
          personales se consideran confidenciales. Nos comprometemos a no
          exponer ni vender esta información a terceros, reafirmando nuestro
          compromiso con la privacidad y la confidencialidad de nuestros
          usuarios.
        </p>
        <h3>Seguridad de la Información</h3>
        <p>
          En Autos Nova, la seguridad de la información es una prioridad
          fundamental. Implementamos rigurosas medidas de seguridad para
          salvaguardar los datos de nuestros usuarios contra cualquier acceso no
          autorizado, alteración o divulgación no deseada. Nuestro compromiso
          incluye la adopción de las mejores prácticas y tecnologías de
          seguridad disponibles para garantizar la integridad y confidencialidad
          de la información personal. Utilizamos protocolos de cifrado
          avanzados, firewalls y otras capas de protección para mantener un
          entorno seguro y confiable para nuestros usuarios.
        </p>

        <h3>Cambios en la Política de Privacidad</h3>
        <p>
          Nos reservamos el derecho de actualizar esta Política de Privacidad en
          cualquier momento. Cualquier cambio será notificado a través de
          nuestra plataforma.
        </p>
        <h3>Contacto</h3>
        <p>
          Si tienes preguntas o inquietudes sobre nuestra Política de
          Privacidad, no dudes en ponerte en contacto con nuestro equipo de
          soporte. Estamos aquí para brindarte la asistencia que necesitas.
        </p>
        <p>
          Además, si deseas solicitar información específica sobre tus datos
          personales o realizar alguna consulta relacionada con la privacidad,
          por favor, envía un correo electrónico a info@AutosNovaCR.com.
        </p>
      </div>
      <p>
        Al utilizar Autos Nova, aceptas los términos de esta Política de
        Privacidad. Tu privacidad es esencial para nosotros, y trabajamos
        continuamente para proteger la información que nos confías.
      </p>
      <br />
      <p>Actualizado: Enero 2024</p>
      <br />
      <Link to="/en-venta" className={classes.link}>
        <button className={classes.goBackButton}>Ver carros en venta</button>
      </Link>
      {/* Agregar más secciones y contenido según sea necesario */}
    </div>
  );
}

export default PrivacyPolicy;
