export const KILOMETRES_FILTER = 'KILOMETRES_FILTER'
export const PRICE_FILTER = 'PRICE_FILTER'
export const STYLE_FILTER = 'STYLE_FILTER'
export const YEAR_FILTER = 'YEAR_FILTER'
export const LOCATION_FILTER = 'LOCATION_FILTER'
export const TRANSMISSION_FILTER = 'TRANSMISSION_FILTER'
export const FUEL_FILTER = 'FUEL_FILTER'
export const CLEAN_FILTER = 'CLEAN_FILTER'
export const CAR_MAKER = 'CAR_MAKER' 
export const CAR_BRAND = 'CAR_BRAND' 
export const UNIQUE_OWNER = 'UNIQUE_OWNER' 