import { useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, Card, CardMedia } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import classes from './GridImage.module.css';
import { GrMoreVertical } from 'react-icons/gr';
import BigCarousel from '../Carousel/BigCarousel';

const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const GridImage = ({ items }) => {
  const [open, setOpen] = useState(false);
  const imageCount = items ? items.length : 0;

  const handleModalClick = () => {
    setOpen(false);
  };

  let content;

  if (imageCount === 1) {
    let carPicture = items[0];
    content = (
      <Grid container>
        <Grid item xs={2} sm={8} md={18} sx={{ height: '100%' }}>
          <Item sx={{ height: '100%', padding: '0px' }}>
            <img
              // style={{ objectPosition: 'center', objectFit: 'scaleDown' }}
              className={classes.img}
              src={`${BLOB_STORAGE_URL}/${carPicture}`}
              alt="imagen de carro"
            />
          </Item>
        </Grid>
      </Grid>
    );
  }

  if (imageCount === 2) {
    content = items.map((carPicture) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={2} sm={8} md={12}>
            <Item sx={{ minHeight: '100%', padding: '0px', display: 'flex', alignItems: 'center', backgroundColor: '#333A41' }}>
              <img
                src={`${BLOB_STORAGE_URL}/${carPicture}`}
                alt="imagen de carro"
              />
            </Item>
          </Grid>
        </Grid>
      );
    });
  }

  // TODO: Revisar código con >2 imagenes
  if (imageCount > 2 && imageCount < 5) {
    content = (
      <>
        <Grid container spacing={2} sx={{ flex: '60%' }}>
          <Grid item xs={2} sm={8} md={12}>
            <Item sx={{ minHeight: '100%', padding: '0px', display: 'flex', alignItems: 'center', backgroundColor: '#333A41' }}>
              <img
                className={classes.imgCover}
                src={`${BLOB_STORAGE_URL}/${items[0]}`}
                alt="imagen de carro"
              />
            </Item>
          </Grid>
        </Grid>
        <Grid container spacing={1} columns={12} sx={{ flex: '40%' }}>
          <Grid item xl={12} md={12} sm={12} direction={'column'}>

            <Card sx={{ height: '100%' }}>
              <CardMedia
                style={{ height: '200px', objectFit: 'cover' }}
                component="img"
                src={`${BLOB_STORAGE_URL}/${items[1]}`}
                alt="First Photo"
              />
            </Card>

          </Grid>
          <Grid item xl={12} md={12} sm={12}>

            <Card sx={{ height: '100%' }}>
              <CardMedia
                style={{ height: '200px', objectFit: 'cover' }}
                component="img"
                src={`${BLOB_STORAGE_URL}/${items[2]}`}
                alt="First Photo"
              />
            </Card>


          </Grid>
        </Grid>
      </>
    );
  }


  if (imageCount >= 5) {
    content = <Grid container spacing={1} sx={{
      hight: '100%'
    }}>
      <Grid item xs={6} style={{ height: '100%' }}>
        <Card sx={{ height: '100%' }}>
          <CardMedia
            sx={{
              height: '100%',
            }
            }
            component="img"
            src={`${BLOB_STORAGE_URL}/${items[0]}`}
            alt="First Photo"
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          {items.map((photo, index) => {
            if (index === 0) {
              return <></>
            }
            return (<Grid item key={index} xs={6}>
              <Card>
                <CardMedia
                  component="img"
                  style={{ height: '200px', objectFit: 'cover' }}
                  src={`${BLOB_STORAGE_URL}/${items[index]}`}
                  alt={`Photo ${index}`}
                />
              </Card>
            </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid >

  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        gap: '8px',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <BigCarousel rousel open={open} images={items} closeModal={handleModalClick} />
      {content}
      <div className={classes.popUp} onClick={() => setOpen(true)}>
        <GrMoreVertical />
        <p>Mostrar todas las fotos</p>
      </div>
    </Box>
  );
};

export default GridImage;
