import { RiCloseLine } from 'react-icons/ri';

function CloseBtn({ onClick }) {
  return (
    <div style={{ cursor: 'pointer', fontSize: '25px' }} onClick={onClick}>
      <RiCloseLine />
    </div>
  );
}

export default CloseBtn;
