import classes from './CarItemHorizontal.module.css'
import { Link } from 'react-router-dom'
import { FaRoad } from 'react-icons/fa';
import { TbManualGearbox } from 'react-icons/tb';
import { GrLocation } from 'react-icons/gr';
import { CiBookmarkPlus } from "react-icons/ci";

export default function CarItemHorizontal({ car, valorUsd }) {

    const BLOB_STORAGE_URL = process.env.REACT_APP_AZURE_BLOB_URL
    let carPriceUsd = car.carPriceCrc / valorUsd;
    const formattedPriceUsd = Number.isInteger(carPriceUsd)
        ? carPriceUsd.toLocaleString('en-US')
        : undefined;

    return <Link className={classes.carItem} to={`/carro/${car.uuidCar}`} target='_blank'>
        <div className={classes.firstBlock}>
            {car.pictures.length ? (
                <img
                    sizes="300px"
                    className={classes.image}
                    src={`${BLOB_STORAGE_URL}/${car.pictures[0]}`}
                    alt="Imagen de vehículo"
                />
            ) : (
                <></>
            )}
        </div>
        <div
            to={`/carro/${car.uuidCar}`}
            target={'_blank'}
            style={{ textDecoration: 'none', color: '#000', flex: '1' }}
        >
            <div className={classes.secondBlock}>
                <div className={classes.carDetails}>
                    <div className={classes.brand}>
                        {car.brand} {car.model} {' '}
                        <span className={classes.year}>{car.year}</span>
                    </div>
                </div>
                <ul>
                    <li>
                        <FaRoad style={{ fontSize: '13px' }} />
                        {car.kilometreType === 'Km' ? (
                            <>
                                {car.kilometres !== null
                                    ? car.kilometres.toLocaleString('en-US', {
                                        maximumFractionDigits: 0,
                                    })
                                    : 0}
                                &nbsp;Km
                            </>
                        ) : (
                            <>
                                {car.miles !== null
                                    ? car.miles.toLocaleString('en-US', {
                                        maximumFractionDigits: 0,
                                    })
                                    : 0}
                                &nbsp;Mi
                            </>
                        )}
                    </li>
                    <li>
                        <GrLocation style={{ fontSize: '13px' }} />
                        {car.canton}, {car.provincia}
                    </li>
                    <li>
                        <TbManualGearbox style={{ fontSize: '13px' }} />
                        {car.transmission}
                    </li>
                </ul>
                <div className={classes.price}>
                    ₡
                    {car.carPriceCrc.toLocaleString('en-US', {
                        maximumFractionDigits: 0,
                    })}
                    {formattedPriceUsd && (
                        <div className={classes.priceUsd}>| ~${formattedPriceUsd}</div>
                    )}
                </div>
            </div>
        </div>
    </Link >

}