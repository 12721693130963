import JSConfetti from 'js-confetti'

const jsConfetti = new JSConfetti()

function callConfetti() {
    jsConfetti.addConfetti({
        // emojis: ['🟥', '🟧', '🟨', '🟩', '🟦'],
        emojiSize: 10,
        confettiRadius: 5,
        confettiNumber: 500
    })
}

function clearConfetti() {
    jsConfetti.clearCanvas()
}

export { callConfetti, clearConfetti }