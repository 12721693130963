import axios from 'axios';
import {
  GET_GENERAL_STATS_START,
  GET_GENERAL_STATS_SUCCESS,
  GET_GENERAL_STATS_FAILURE,
  GET_CARS_IN_REVIEW_START,
  GET_CARS_IN_REVIEW_SUCCESS,
  GET_CARS_IN_REVIEW_FAILURE,
  UPDATE_CAR_IN_REVIEW_STATUS_START,
  UPDATE_CAR_IN_REVIEW_STATUS_SUCCESS,
  UPDATE_CAR_IN_REVIEW_STATUS_FAILURE,
  GET_CAR_DETAILS_IN_REVIEW_START,
  GET_CAR_DETAILS_IN_REVIEW_SUCCESS,
  GET_CAR_DETAILS_IN_REVIEW_FAILURE,
} from './adminTypes';

const baseUrl = process.env.REACT_APP_API_URL;

const getGeneralStatsStart = () => ({ type: GET_GENERAL_STATS_START });
const getGeneralStatsSuccess = (data) => ({
  type: GET_GENERAL_STATS_SUCCESS,
  payload: data,
});
const getGeneralStatsFailure = (err) => ({
  type: GET_GENERAL_STATS_FAILURE,
  payload: err,
});

const getCarsInReviewStart = () => ({ type: GET_CARS_IN_REVIEW_START });
const getCarsInReviewSuccess = (data) => ({
  type: GET_CARS_IN_REVIEW_SUCCESS,
  payload: data,
});
const getCarsInReviewFailure = (err) => ({
  type: GET_CARS_IN_REVIEW_FAILURE,
  payload: err,
});

const updateCarInReviewStatusStart = () => ({
  type: UPDATE_CAR_IN_REVIEW_STATUS_START,
});
const updateCarInReviewStatusSuccess = (data) => ({
  type: UPDATE_CAR_IN_REVIEW_STATUS_SUCCESS,
  payload: data,
});
const updateCarInReviewStatusFailure = (err) => ({
  type: UPDATE_CAR_IN_REVIEW_STATUS_FAILURE,
  payload: err,
});

const getCarDetailsInReviewStart = () => ({
  type: GET_CAR_DETAILS_IN_REVIEW_START,
});
const getCarDetailsInReviewSuccess = (data) => ({
  type: GET_CAR_DETAILS_IN_REVIEW_SUCCESS,
  payload: data,
});
const getCarDetailsInReviewFailure = (err) => ({
  type: GET_CAR_DETAILS_IN_REVIEW_FAILURE,
  payload: err,
});

export const getGeneralStats = (token) => async (dispatch) => {
  dispatch(getGeneralStatsStart());
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${baseUrl}/api/Admin/GeneralStats`,
      config
    );
    dispatch(getGeneralStatsSuccess(response.data));
  } catch (err) {
    dispatch(getGeneralStatsFailure(err));
  }
};

export const getCarsInReview = (token) => async (dispatch) => {
  dispatch(getCarsInReviewStart());
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${baseUrl}/api/Admin/GetCarsInReview`,
      config
    );
    dispatch(getCarsInReviewSuccess(response.data));
  } catch (err) {
    dispatch(getCarsInReviewFailure(err));
  }
};

export const updateCarInReviewStatus =
  (token, idCar, newStatus) => async (dispatch) => {
    dispatch(updateCarInReviewStatusStart());
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/Admin/UpdateCarInReviewStatus?idCar=${idCar}&newStatus=${newStatus}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.request(config);
      dispatch(updateCarInReviewStatusSuccess(response.data));
    } catch (err) {
      dispatch(updateCarInReviewStatusFailure(err));
    }
  };

export const getCarDetailsInReview = (token, carUuid) => async (dispatch) => {
  dispatch(getCarDetailsInReviewStart());
  const config = {
    method: 'get',
    url: `${baseUrl}/api/Admin/CarDetailsInReview?carUuid=${carUuid}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.request(config);
    dispatch(getCarDetailsInReviewSuccess(response.data));
  } catch (err) {
    dispatch(getCarDetailsInReviewFailure(err));
  }
};
