import Skeleton from '@mui/material/Skeleton'; 
import classes from './MobileSkeleton.module.css'
export default function MobileSkeleton() {
  
  return <div className={classes.skeletonMobile}>
    <Skeleton variant="rectangular" width="100%" height={190} />

    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start', // Alineación a la izquierda
      }}
    >
      <Skeleton
        variant="text"
        width="15%"
        height={40}
        style={{ marginRight: '15px' }}
      />{' '}
      <Skeleton variant="text" width="40%" height={40} />
    </div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start', // Alineación a la izquierda
      }}
    >
      <Skeleton
        variant="circular"
        width={20}
        height={20}
        style={{ marginLeft: '5px', marginTop: '3px' }}
      />
      <Skeleton
        variant="text"
        width="20%"
        height={20}
        style={{ marginLeft: '15px', marginTop: '3px' }}
      />
    </div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start', // Alineación a la izquierda
      }}
    >
      <Skeleton
        variant="circular"
        width={20}
        height={20}
        style={{ marginLeft: '5px', marginTop: '3px' }}
      />
      <Skeleton
        variant="text"
        width="43%"
        height={20}
        style={{ marginLeft: '15px', marginTop: '3px' }}
      />
    </div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start', // Alineación a la izquierda
      }}
    >
      <Skeleton
        variant="circular"
        width={20}
        height={20}
        style={{ marginLeft: '5px', marginTop: '3px' }}
      />
      <Skeleton
        variant="text"
        width="12%"
        height={20}
        style={{ marginLeft: '15px', marginTop: '3px' }}
      />
    </div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start', // Alineación a la izquierda
      }}
    >
      <Skeleton
        variant="text"
        width="40%"
        height={40}
        style={{ marginRight: '15px' }}
      />{' '}
      <Skeleton variant="text" width="30%" height={40} />
    </div>
  </div>
}