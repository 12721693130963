import jwtDecode from "jwt-decode";

function getTokenDataFromLocalStorage() {
    let jwt = localStorage.getItem('token');
    if (jwt) {
        const decodedJwt = jwtDecode(jwt);
        const {
            idUser,
            uuidUser,
            firstName,
            lastName,
            email,
            authProvider,
            authProviderId,
            userRole,
            userStatus,
            phoneNumber,
            whatsappNumber,
            createdOn,
            lastActivity,
            loggedIn,
            iss,
            aud,
            exp
        } = decodedJwt;

        var unixTimestamp = exp;
        var milliseconds = unixTimestamp * 1000;
        var expirationDate = new Date(milliseconds);

        const payloadWithRightFormat = {
            idUser: parseInt(idUser),
            uuidUser: uuidUser,
            firstName,
            lastName,
            email,
            authProvider,
            authProviderId,
            userRole: parseInt(userRole),
            userStatus: parseInt(userStatus),
            phoneNumber,
            whatsappNumber,
            createdOn,
            lastActivity,
            loggedIn: loggedIn.toLowerCase() === 'true',
            iss,
            aud,
            exp: expirationDate,
        };

        return payloadWithRightFormat;
    } else {
        return null
    }
};

function getTokenFromLocalStorage() {
    const jwt = localStorage.getItem('token');
    if (jwt) {
        return jwt;
    } else {
        return null
    }
};

function setTokenInLocalStorage(token) {
    localStorage.setItem('token', token);
};


export { setTokenInLocalStorage, getTokenFromLocalStorage, getTokenDataFromLocalStorage }