import Modal from '../Modal/Modal';
import Button from '../Buttons/Button';
import { Link } from 'react-router-dom';
import { FaRoad } from 'react-icons/fa';
import classes from './CarItem.module.css';
import { GrLocation } from 'react-icons/gr';
import Carousel from '../Carousel/Carousel';
import { LuClipboard } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import getToast from '../../helpers/getToast';
import { TbManualGearbox } from 'react-icons/tb';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { CiBookmarkPlus } from "react-icons/ci";
import { RiCheckDoubleLine } from "react-icons/ri";

import {
  makeCarInactive,
  deleteDraft,
} from '../../store/VehicleStore/vehicleActions';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';

export default function CarItem({
  car,
  carStatus = 'primary',
  refreshUserCars,
  valorUsd,
  likeBtn,
  sinpeDetails,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserProfile.userProfile);

  const phoneRef = useRef(null);
  const motivoSinpeRef = useRef(null);
  const navigate = useNavigate();

  const [isOptionSelected, setOptionSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [open, setOpen] = useState(false);
  const [openDraft, setOpenDraft] = useState(false);
  const [openSinpe, setOpenSinpe] = useState(false);
  const [loading, setLoading] = useState(false);
  let carPriceUsd = car.carPriceCrc / valorUsd; // No redondeamos aún
  // Redondeamos siempre hacia arriba al múltiplo de 5 más cercano
  carPriceUsd = Math.ceil(carPriceUsd / 5) * 5;

  // Valida esto para que únicamente salga en páginas como Carros en venta, y evitar error de NaN en otras páginas
  const formattedPriceUsd = Number.isInteger(carPriceUsd)
    ? carPriceUsd.toLocaleString('en-US')
    : undefined;

  const calculateTimeRemaining = () => {
    const now = new Date();
    const targetDate = new Date(car.createdOn);
    targetDate.setHours(targetDate.getHours() + 24);
    const timeDifference = targetDate - now;

    return timeDifference > 0 ? timeDifference : 0;
  };

  const formatTime = (milliseconds) => {
    if (milliseconds <= 0) {
      return '00:00:00';
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}:${String(seconds).padStart(2, '0')}`;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  const handleDeleteButtonClick = () => {
    setOpen(true);
  };

  const handleInfoSinpeButtonClick = () => {
    setOpenSinpe(true);
  };

  const handleFinishDraft = () => {
    navigate(`/vender-mi-carro/${car.uuidCar}`);
  };

  const handleDeleteDraft = async (e) => {
    setLoading(true);
    const result = await dispatch(deleteDraft(car.uuidCar))
      .then((res) => res)
      .catch((err) => err);
    if (result === true) {
      refreshUserCars();
      setOpenDraft(false);
      getToast({ text: 'El borrador fue eliminado', type: 'SUCCESS' });
    } else {
      getToast({ text: 'Se ha presentado un error.', type: 'ERROR' });
    }
    setLoading(false);
  };

  const copyPhoneToClipboard = async (el) => {
    const value = phoneRef.current.textContent;
    try {
      await navigator.clipboard.writeText(value);
      getToast({ type: 'SUCCESS', text: 'Se copió el número correctamente' });
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };

  const copyMotivoSinpeToClipboard = async (el) => {
    const value = motivoSinpeRef.current.textContent;
    try {
      await navigator.clipboard.writeText(value);
      getToast({
        type: 'SUCCESS',
        text: 'Se copió el motivo correctamente',
      });
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
  };

  const handleDelete = async () => {
    let carNewStatus;
    if (selectedOption === 'sold') {
      carNewStatus = 2;
    } else if (selectedOption === 'notSold') {
      carNewStatus = 7;
    }
    if (carNewStatus) {
      const token = getTokenFromLocalStorage();
      dispatch(makeCarInactive(car.uuidCar, carNewStatus, token))
        .then(() => {
          refreshUserCars();
          setOptionSelected(false);
          setSelectedOption('');
          getToast({ text: 'El anuncio fue eliminado', type: 'SUCCESS' });
        })
        .catch((error) => {
          getToast({
            text: 'Se presento un error. Intentalo de nuevo.',
            type: 'ERROR',
          });
          console.error('Error in makeCarInactive:', error);
        });
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setOptionSelected(true);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return (
      <Modal open={true}>
        <div className={classes.modalContent}>
          <LoadingSpinner />
        </div>
      </Modal>
    );
  }

  return (
    <>
      <div className={classes.carItem}>
        <div className={classes.firstBlock}>
          {/* {car.isPremium ? <CiBookmarkPlus className={classes.premium} /> : <></>} */}
          {car.pictures.length ? (
            <Carousel
              likeBtn={likeBtn}
              images={car.pictures}
              carUuid={car.uuidCar}
              target={
                carStatus === 'onSale' || carStatus === 'primary'
                  ? '_blank'
                  : ''
              }
              link={
                carStatus === 'onSale' || carStatus === 'primary'
                  ? `/carro/${car.uuidCar}`
                  : ''
              }
              originSite={'en-venta'}
            />
          ) : (
            <></>
          )}
        </div>
        <Link
          to={
            carStatus === 'onSale' || carStatus === 'primary'
              ? `/carro/${car.uuidCar}`
              : ''
          }
          target={
            carStatus === 'onSale' || carStatus === 'primary' ? '_blank' : ''
          }
          style={{ textDecoration: 'none', color: '#000' }}
        >
          <div className={classes.secondBlock}>
            <div className={classes.carDetails}>
              <div className={classes.brand}>
                <span className={classes.year}>{car.year}</span>
                {car.brand} {car.model}
              </div>
            </div>
            <ul>
              <li>
                <FaRoad style={{ fontSize: '15px' }} />
                {car.kilometreType === 'Km' ? (
                  <>
                    {car.kilometres !== null
                      ? car.kilometres.toLocaleString('en-US', {
                        maximumFractionDigits: 0,
                      })
                      : 0}
                    &nbsp;Km
                  </>
                ) : (
                  <>
                    {car.miles !== null
                      ? car.miles.toLocaleString('en-US', {
                        maximumFractionDigits: 0,
                      })
                      : 0}
                    &nbsp;Mi
                  </>
                )}
              </li>
              <li>
                <GrLocation style={{ fontSize: '15px' }} />
                {car.canton}, {car.provincia}
              </li>
              <li>
                <TbManualGearbox style={{ fontSize: '15px' }} />
                {car.transmission}
              </li>
            </ul>
            <div className={classes.price}>
              ₡
              {car.carPriceCrc.toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })}
              {formattedPriceUsd && (
                <div className={classes.priceUsd}>| ~${formattedPriceUsd}</div>
              )}
            </div>
          </div>
        </Link>

        {carStatus === 'onSale' && (
          <>
            <div className={classes.buttonContainer}>
              <Link
                to={`/editar-anuncio/${car.uuidCar}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button>Editar anuncio</Button>
              </Link>
              <Button
                className={classes.deleteButton}
                onClick={handleDeleteButtonClick}
              >
                Desactivar publicación
              </Button>
            </div>

            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <div className={classes.modalContent}>
                <h2 className={classes.modalHeader}>
                  Desactivar Publicación
                  <br />
                  {car.brand} {car.model}
                </h2>
                <p>¿Estás seguro de desactivar la publicación?</p>
                <p>
                  Esto hará que el anuncio ya no sea visible para el público.
                  Tendrás que crear un nuevo anuncio si lo quieres publicar
                  nuevamente.
                </p>
                <p>¿Cuál es el motivo de desactivar el anuncio?</p>
                <div className={classes.modalOptions}>
                  <p>
                    <input
                      type="radio"
                      id="option1"
                      name="options"
                      value="sold"
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="option1">El carro se vendió.</label>
                  </p>
                  <p>
                    <input
                      type="radio"
                      id="option2"
                      name="options"
                      value="notSold"
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="option2">
                      El carro no se ha vendido, solo quiero eliminar la
                      publicación.
                    </label>
                  </p>
                </div>
                <div className={classes.modalButtons}>
                  <button
                    className={`${classes.deleteConfirmButton} ${!isOptionSelected ? classes.disabledButton : ''
                      }`}
                    onClick={handleDelete}
                    disabled={!isOptionSelected}
                  >
                    Desactivar
                  </button>
                  <button
                    className={classes.goBackButton}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Regresar
                  </button>
                </div>
              </div>
            </Modal>
          </>
        )}

        {carStatus === 'draft' && (
          <div>
            <Modal
              open={openDraft}
              onClose={() => {
                setOpenDraft(false);
              }}
            >
              <div className={classes.modalContent}>
                <h2 className={classes.modalHeader}>
                  Eliminar borrador
                  <br />
                  {car.brand} {car.model}
                </h2>
                <p>¿Estás seguro de eliminar el borrador?</p>

                <div className={classes.modalButtons}>
                  <button
                    className={classes.deleteConfirmButton}
                    onClick={handleDeleteDraft}
                  >
                    Confirmar
                  </button>
                  <button
                    className={classes.goBackButton}
                    onClick={() => {
                      setOpenDraft(false);
                    }}
                  >
                    Regresar
                  </button>
                </div>
              </div>
            </Modal>
            <div className={classes.buttonContainer}>
              <Button onClick={handleFinishDraft}>Finalizar borrador</Button>
              <Button
                style={{ backgroundColor: '#efefef' }}
                onClick={() => {
                  setOpenDraft(true);
                }}
              >
                Eliminar borrador
              </Button>
            </div>
          </div>
        )}

        {carStatus === 'onReview' && (
          <div className={classes.buttonContainer}>
            <Link
              to={`/editar-anuncio/${car.uuidCar}`}
              style={{ textDecoration: 'none', color: 'inherit', minWidth: '50%', flex: 1 }}
            >
              <Button  >Editar anuncio</Button>
            </Link>
            {car.planId === 3 ? (
              <Button
                className={classes.infoSinpeButton}
                onClick={handleInfoSinpeButtonClick}
              >
                Información de SINPE
              </Button>
            ) : (
              <></>
            )}
          </div>
        )}

        <Modal
          open={openSinpe}
          onClose={() => {
            setOpenSinpe(false);
          }}
        >
          <div className={classes.modalContent}>
            <h2 className={classes.modalHeader}>
              Información de SINPE
              <br />
              {/* {car.brand} {car.model} */}
            </h2>
            <p>Si aún no haz realizado el pago, estos son los detalles.</p>
            <p>
              <strong>Número de teléfono:</strong>{' '}
              <span ref={phoneRef}>
                {sinpeDetails ? sinpeDetails.phone : '70096354'}
                <LuClipboard
                  onClick={copyPhoneToClipboard}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </p>

            {sinpeDetails && (
              <p>
                <strong>A nombre de: </strong> {sinpeDetails.phoneOwner}
              </p>
            )}
            <p>
              <strong>Motivo: </strong>
              <span ref={motivoSinpeRef}>
                {car.plate === '-1'
                  ? `${car.uuidCar} Premium`
                  : `${car.plate} Premium`}
                <LuClipboard
                  onClick={copyMotivoSinpeToClipboard}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </p>

            <p>
              <strong>Total:</strong> 8,900 CRC
            </p>
            <p>
              <strong>Tiempo restante para realizar el pago:</strong>{' '}
              {formatTime(timeRemaining)}
            </p>
            <p>
              Si ya hiciste el pago, nuestro equipo estará por revisar la
              publicación
            </p>

            <div className={classes.modalButtons}>
              <button
                className={classes.goBackButton}
                onClick={() => {
                  setOpenSinpe(false);
                }}
              >
                Regresar
              </button>
            </div>
          </div>
        </Modal>
      </div >
    </>
  );
}
