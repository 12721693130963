import classes from './AddReview.module.css';
import { useRef, useState } from 'react';
import Button from '../../components/Buttons/Button';
import CustomDropDown from '../../components/DropDowns/CustomDropDown';
import Rating from '@mui/material/Rating';
import { Textarea } from '@mui/joy';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getDealer,
  postDealerReview,
} from '../../store/DealerStore/DealerAction';
import { useParams } from 'react-router-dom';
import getToast from '../../helpers/getToast';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const iconFull = <StarRoundedIcon sx={{ fontSize: '35px' }} />;

const iconEmpty = <StarBorderRoundedIcon sx={{ fontSize: '35px' }} />;

const AddReview = ({ getDealerRequest, dealer, user, postDealerReview }) => {
  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#3861fb',
    },
    '& .MuiRating-iconHover': {
      color: '#3861fb',
    },
  });
  const [opinion, setOpinion] = useState('');
  const [didBuy, setDidBuy] = useState(null);
  const [brand, setBrand] = useState('');
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const didBuyOptions = [
    { value: 1, description: `Si` },
    { value: 0, description: `No` },
  ];

  // console.log(dealer, 'DEALER')

  useEffect(() => {
    window.scrollTo(0, 0);
    const getDealerInfo = async (id) => {
      setLoading(true);
      try {
        const response = await getDealerRequest(id);
      } catch (ex) {
        getToast({
          type: 'ERROR',
          text: 'Se ha presentado un error. Vuelve a intentarlo o contacta a soporte.',
        });
      }
      setLoading(false);
    };
    if ((dealer && dealer.uuid !== params.uuid) || dealer === undefined) {
      getDealerInfo(params.uuid);
    }
  }, []);

  const handleSubmit = async (el) => {
    if (rate?.overallRating === undefined) {
      getToast({
        type: 'WARNING',
        text: 'Debes indicar tu calificación general.',
        style: { icon: '❗' },
      });
      return;
    }
    if (opinion.length < 50) {
      getToast({
        type: 'WARNING',
        text: 'Debes escribir una opinión de al menos 50 caracteres.',
        style: { icon: '❗' },
      });
      return;
    }
    if (didBuy === null) {
      getToast({
        type: 'WARNING',
        text: 'Debes indicar si compraste o no en esta agencia.',
        style: { icon: '❗' },
      });
      return;
    }
    if (date === null) {
      getToast({
        type: 'WARNING',
        text: 'Debes indicar la fecha de tu visita.',
        style: { icon: '❗' },
      });
      return;
    }

    const body = {
      id: 0,
      overallRating: rate?.overallRating,
      expierenceDesc: opinion,
      processExp: rate?.processExp,
      communication: rate?.communication,
      date: date,
      didBuy: true,
      deliveryExp: rate?.deliveryExp,
      transparency: rate?.transparency,
      idDealer: dealer.idDealer,
      fromUser: user.idUser,
    };

    try {
      const response = await postDealerReview(body);
      if (response.code === 200) {
        const formatDealerName =
          dealer.dealerName !== undefined
            ? dealer.dealerName.split(' ').join('-').toLowerCase()
            : '';
        navigate(`/vendedor/${dealer.customUrl}`);
        getToast({ type: 'SUCCESS', text: 'Gracias por agregar tu opinión.' });
      }
    } catch (ex) {
      getToast({
        type: 'ERROR',
        text: 'Se ha presentado un error al subir el review. Vuelve a intentarlo.',
      });
    }
  };

  const handleRateChange = (element, id) => {
    const value = element.target.value;
    let newRates = { ...rate };
    newRates[id] = value;
    setRate(newRates);
  };

  const handleDate = (element) => {
    const date = element['$d'];
    setDate(date);
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1>Comparte tu experiencia con {dealer.dealerName}</h1>
          <div className={classes.form}>
            <div>
              <h2>Calificación general</h2>
              <div>
                <StyledRating
                  defaultValue={0}
                  size="large"
                  icon={iconFull}
                  emptyIcon={iconEmpty}
                  value={rate?.overallRating ?? 0}
                  onChange={(el) => handleRateChange(el, 'overallRating')}
                />
              </div>
            </div>
            <div>
              <h2>Escribe tu opinión</h2>
              <Textarea
                maxRows={4}
                required
                size="lg"
                placeholder="..."
                onChange={(el) => {
                  if (el.target.value.length <= 500) {
                    setOpinion(el.target.value);
                  }
                }}
                value={opinion}
              />
            </div>
            <div>
              <h2>¿Cúando fue?</h2>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture={true}
                  label={'Mes y año'}
                  views={['month', 'year']}
                  onChange={handleDate}
                  shouldDisableYear={(date) => {
                    const today = new Date();
                    const yearsBefore = new Date(
                      today.getFullYear() - 4,
                      today.getMonth(),
                      today.getDate()
                    );
                    const yearsAfter = new Date(
                      today.getFullYear() + 1,
                      today.getMonth(),
                      today.getDate()
                    );
                    return date < yearsBefore || date > yearsAfter;
                  }}
                />
              </LocalizationProvider>
            </div>
            <div>
              <h2>¿Realizaste la compra con este negocio?</h2>
              <CustomDropDown
                className={classes.dropDown}
                setDropdownValue={(newValue) => {
                  setDidBuy(newValue.description);
                }}
                dropdownValue={didBuy}
                items={didBuyOptions}
                preview={'...'}
              />
            </div>
            <div>
              <h2>Califica el proceso (opcional)</h2>
              <h3>Proceso de compra</h3>
              <div>
                {' '}
                <StyledRating
                  defaultValue={0}
                  size="large"
                  icon={iconFull}
                  emptyIcon={iconEmpty}
                  onChange={(el) => handleRateChange(el, 'processExp')}
                  value={rate?.processExp ?? 0}
                />
              </div>
              <h3>Comunicación</h3>
              <div>
                {' '}
                <StyledRating
                  defaultValue={0}
                  size="large"
                  icon={iconFull}
                  emptyIcon={iconEmpty}
                  onChange={(el) => handleRateChange(el, 'communication')}
                  value={rate?.communication ?? 0}
                />
              </div>
              <h3>Transparencia del vendedor</h3>
              <div>
                {' '}
                <StyledRating
                  defaultValue={0}
                  size="large"
                  icon={iconFull}
                  emptyIcon={iconEmpty}
                  onChange={(el) => handleRateChange(el, 'transparency')}
                  value={rate?.transparency ?? 0}
                />
              </div>
              <h3>Entrega del auto</h3>
              <div>
                {' '}
                <StyledRating
                  defaultValue={0}
                  size="large"
                  icon={iconFull}
                  emptyIcon={iconEmpty}
                  onChange={(el) => handleRateChange(el, 'deliveryExp')}
                  value={rate?.deliveryExp ?? 0}
                />
              </div>
            </div>
            <Button onClick={handleSubmit}>Enviar opinión</Button>
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDealerRequest: (uuid) => dispatch(getDealer(uuid)),
    postDealerReview: (data) => dispatch(postDealerReview(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    dealer: state.DealerReducer.dealer,
    user: state.UserProfile.userProfile,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddReview);
