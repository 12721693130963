import { useSelector } from 'react-redux';
// import CarItem from '../CarItem/CarItem';
import classes from './CarsForSale.module.css';
import React, { useEffect, useState } from 'react';
// import HeaderContent from '../Header/HeaderContent';
import CarListLoader from '../CarListLoader/CarListLoader';
import PaginationComponent from '../Pagination/Pagination';
import axios from 'axios';
import volcan from '../../images/appImages/volcan.jpg';
import CarListContainer from '../CarListContainer/CarListContainer';

function CarsForSale({ resetFilter, usedCars, pageChanged }) {

  const [valorUsd, setValorUsd] = useState(undefined);

  const fetchExchangeRate = async () => {
    try {
      // Precio del USD - Banco Popular
      const response = await axios.get(
        'https://www.appsbp.com/WsSINPEMovilV2/ServiciosGeneral/indicadoresfinancieros'
      );
      // Filtrar el valor de Tipo Cambio Venta y eliminar los ceros al final
      const indicadores = response.data.Indicadores;
      const tipoCambioVenta = indicadores.find(
        (indicador) => indicador.Descripcion === 'Tipo Cambio Venta'
      );
      const valorVenta = parseInt(tipoCambioVenta.Valor);
      setValorUsd(valorVenta);
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };

  // Llamar a fetchExchangeRate cuando el componente se monta
  useEffect(() => {
    if (valorUsd === undefined) {
      fetchExchangeRate();
    }
  }, []); // El array de dependencias está vacío para que se ejecute solo una vez al montar el componente


  const resetSearch = () => {
    resetFilter(true);
  };



  return (
    <div className={classes.container}>
      {usedCars && usedCars.length > 0 ? (
        <CarListContainer carList={usedCars} valorUsd={valorUsd} />
      ) : (
        <div className={classes.noResults}>
          De momento no hay carros con los criterios de búsqueda.{' '}
          <button className={classes.resetSearch} onClick={resetSearch}>
            Reiniciar la búsqueda
          </button>
          <br />
          <br />
          <img
            src={volcan}
            alt="Imagen de Volcan sin carros"
            style={{
              width: '90%',
              maxWidth: '600px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
              marginBottom: '20px',
              cursor: 'pointer',
            }}
          />
        </div>
      )}

      <br />
      <br />
      <PaginationComponent pageChanged={pageChanged} />
    </div>
  );
}

export default CarsForSale;
