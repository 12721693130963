import React from 'react';
import classes from './Card.module.css';
import { Link } from 'react-router-dom';
import whiteCarImage from '../../images/appImages/white-car.webp';
import contractImage from '../../images/appImages/contract.webp';

// Map of image names to their respective paths
const imageMap = {
  'white-car': whiteCarImage,
  contract: contractImage,
  // Add more images here
};

function Card(props) {
  const imagePath = imageMap[props.image]; // Get the image path dynamically
  return (
    <div className={classes.card}>
      <img src={imagePath} alt="Imagen de un contrato" />
      <div className={classes.modal}>
        <h3 className={classes.title}>{props.title}</h3>
        <p className={classes.text}>{props.children}</p>
        <Link
          to={`${props.route}`}
          onClick={props.onClick}
          className={classes.btn}
        >
          {props.button}
        </Link>
      </div>
    </div>
  );
}

export default Card;
