import Modal from '../Modal/Modal';
import { connect } from 'react-redux';
import Button from '../Buttons/Button';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import classes from './Login.module.css';
import Switch from '@mui/material/Switch';
import { ImWhatsapp } from 'react-icons/im';
import SmallAlert from '../Alert/SmallAlert';
import { BsTelephone } from 'react-icons/bs';
import GoogleBtn from '../Buttons/GoogleBtn';
import { useTheme } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import MicrosoftBtn from '../Buttons/MicrosoftBtn';
// import FacebookLogin from '../Buttons/FacebookLogin';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControlLabel from '@mui/material/FormControlLabel';
import { setTokenInLocalStorage } from '../../helpers/jwtService';

// CUSTOMIZE ANY UI BUTTON
import // FacebookLoginButton,
// GoogleLoginButton,
// GithubLoginButton,
// AmazonLoginButton,
// InstagramLoginButton,
// LinkedInLoginButton,
// MicrosoftLoginButton,
// TwitterLoginButton,
// AppleLoginButton,
'react-social-login-buttons';

import {
  createUser,
  getUserRequest,
  cleanUserRequest,
  setJwtUserRequest,
} from '../../store/UserStore/userActions';
import LoadingSpinner from '../Loading/LoadingSpinner';

const Login = ({
  userProfile,
  newUser,
  getUser,
  cleanUser,
  setInfoFromJwt,
  onClose,
  showLogin,
  title,
}) => {
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [whatsApp, setWhatsApp] = useState('');
  const [isWhatsAppValid, setIsWhatsAppValid] = useState(false);
  const [userAccount, setUserAccount] = useState({});
  const [showAlert, setShowAlert] = useState({ show: false, text: '' });
  const [sameNumberForWhatsAppAndCalls, setSameNumberForWhatsAppAndCalls] =
    useState(true);

  const REDIRECT_URI = window.location.href;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



  const handleSwitchChange = (event) => {
    // El evento contiene información sobre el cambio en el switch.
    const newValue = event.target.checked; // `checked` indica si el switch está activado o desactivado.
    if (newValue) {
      setPhone(whatsApp);
    }
    setSameNumberForWhatsAppAndCalls(newValue);
  };

  const handleChangeWhatsApp = (event) => {
    const whatsAppCleaned = event.target.value.replace(/\D/g, '');
    setWhatsApp(whatsAppCleaned);

    //Asigna el mismo número de Whatsapp y llamdas
    if (sameNumberForWhatsAppAndCalls) {
      setPhone(whatsAppCleaned);
    }

    const regexPhoneNumber = new RegExp('[0-9]{8}');
    const regexPassed = regexPhoneNumber.test(whatsAppCleaned);
    if (regexPassed === true) {
      setIsWhatsAppValid(true);

      //Asigna el mismo número de Whatsapp y llamdas
      if (sameNumberForWhatsAppAndCalls) {
        setIsPhoneValid(true);
      }
    } else {
      setIsWhatsAppValid(false);
    }
  };

  const handleChangePhone = (event) => {
    const phoneCleaned = event.target.value.replace(/\D/g, '');
    setPhone(phoneCleaned);

    const regexPhoneNumber = new RegExp('[0-9]{8}');
    const regexPassed = regexPhoneNumber.test(phoneCleaned);
    if (regexPassed === true) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
  };

  const createAccount = async () => {
    try {
      if (isPhoneValid === true && isWhatsAppValid === true) {
        const newUserResponse = await newUser({
          ...userAccount,
          whatsappNumber: whatsApp,
          phoneNumber: phone,
        });
      } else if (isPhoneValid === false || isWhatsAppValid === false) {
        setShowAlert((prev) => {
          return { show: true, text: 'Verifique el formato' };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleClose = () => {
    onClose(false);
  };

  const checkUserAccount = async (user) => {
    try {
      const response = await getUser(
        user.email,
        user.authProvider,
        user.authProviderId
      );
      if (response === null) {
        setUserAccount(user);
      } else {
        setTokenInLocalStorage(response.token);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const close = () => {
    onClose(false);
  };

  if (userProfile.loading) {
    return (
      <Modal closeBtn={true} open={showLogin} onClose={handleClose}>
        <LoadingSpinner />
      </Modal>
    );
  }

  if (userProfile.loggedIn) {
    return (
      <Modal closeBtn={true} open={showLogin} onClose={handleClose}>
        <h2>
          ¡Hola {userProfile.userProfile.firstName}!
          <br />
          Ya puedes listar tus vehículos en venta
          <div className={classes.btn}>
            <Button onClick={close}>Continuar</Button>
          </div>
          {/* </div> */}
        </h2>
      </Modal>
    );
  }

  return (
    <Modal closeBtn={true} open={showLogin} onClose={handleClose}>
      {title ? <h2>{title}</h2> : <></>}
      {userAccount.userRole === undefined ? (
        <div>
          <div style={{ marginBottom: '2%' }}>
            <p>Puedes registrarte con tu cuenta de Google o Microsoft</p>
          </div>
          <br />
          <div className={classes.authContainer}>
            <GoogleBtn createUser={checkUserAccount} />
            <MicrosoftBtn createUser={checkUserAccount} />
            {/* <FacebookLogin createUser={checkUserAccount} /> */}
          </div>
        </div>
      ) : (
        <div className={classes.content}>
          <div>
            <p style={{ fontSize: 'initial' }}>
              Pon el número de teléfono donde quiere que te contacten.
            </p>

            {showAlert.show ? <SmallAlert>{showAlert.text}</SmallAlert> : <></>}
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={sameNumberForWhatsAppAndCalls}
                  onChange={handleSwitchChange}
                />
              }
              label="Usar el mismo número para WhatsApp y llamadas"
            />
          </FormGroup>
          <div>
            <div className={classes.inputBlock}>
              <ImWhatsapp />
              <label>WhatsApp: +(506) </label>
              <input
                maxLength={8}
                required
                onChange={handleChangeWhatsApp}
                value={whatsApp}
              />
            </div>
            <div className={classes.inputBlock}>
              <BsTelephone />
              <label>Llamadas: +(506)</label>
              <input
                maxLength={8}
                required
                onChange={handleChangePhone}
                value={phone}
              />
            </div>
          </div>
          <div className={classes.btn}>
            <Button onClick={createAccount}>Continuar</Button>
          </div>
          <br />
          <p style={{ color: 'grey', fontSize: '11px' }}>
            Al presionar continuar, aceptas los{' '}
            <Link
              to="/terminos"
              style={{ color: 'grey', fontSize: '11px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              términos y condiciones
            </Link>
          </p>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    newUser: (email, authProvider, authProviderId) =>
      dispatch(createUser(email, authProvider, authProviderId)),
    getUser: (email, authProvider, authProviderId) =>
      dispatch(getUserRequest(email, authProvider, authProviderId)),
    cleanUser: () => dispatch(cleanUserRequest()),
    setInfoFromJwt: () => dispatch(setJwtUserRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
