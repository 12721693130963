import './index.css';
import App from './App';
import React from 'react';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import rootReducer from './store/rootReducer';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { composeWithDevTools } from 'redux-devtools-extension'; 
import { createBrowserHistory } from 'history';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const browserHistory = createBrowserHistory({ basename: '' });
const MS_APP_INSIGHTS_KEY = process.env.REACT_APP_MS_APP_INSIGHTS_KEY;
const GOOGLE_AUTH_KEY = process.env.REACT_APP_GOOGLE_AUTH_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true,
};

var appInsights = new ApplicationInsights({
  config: {
    connectionString: MS_APP_INSIGHTS_KEY,
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    // extensions: [reactPlugin],
    // *** Add the Click Analytics plug-in. ***
    extensions: [reactPlugin, clickPluginInstance],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      // *** Add the Click Analytics plug-in. ***
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});
appInsights.loadAppInsights();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_AUTH_KEY}>
          <HelmetProvider>
            <Helmet>
              <title>Autos Nova | La plataforma de autos de Costa Rica</title>
              <meta
                name="description"
                content="La plataforma de autos de Costa Rica. Publica tu auto en minutos. Encuentra tu próximo auto en Autos Nova."
              />
              <meta
                property="og:description"
                content="La plataforma de autos de Costa Rica. Publica tu auto en minutos. Encuentra tu próximo auto en Autos Nova."
              />

              <meta
                name="keywords"
                content="Autos Nova, autos nova cr, compra y venta autos usados costa rica, compra de autos usados, venta de autos usados, autos en venta Costa Rica"
              />
              <meta
                name="title"
                content="Autos Nova | La plataforma de autos de Costa Rica"
              />
              <meta
                name="title"
                content="Autos Nova | La plataforma de autos de Costa Rica"
              />
              <meta name="author" content="Autos Nova" />
              <meta property="og:type" content="website" />
              <meta property="og:site_name" content="Autos Nova" />
              <meta property="og:locale" content="es_CR" />
              <link rel="canonical" href="https://AutosNovaCR.com" />
              <meta property="og:image" content="%PUBLIC_URL%/an_og.png" />
            </Helmet>
          </HelmetProvider>

          <App />
        </GoogleOAuthProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
