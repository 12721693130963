import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setCarListPage } from '../../store/VehicleStore/vehicleActions';
import { useEffect } from 'react';


export default function PaginationComponent({ pageChanged = null }) {

    const dispatch = useDispatch();
    const carCount = useSelector(state => state.CarOptions.carCount);
    const carPage = useSelector(state => state.CarOptions.carPage);

    useEffect(() => {
        localStorage.setItem('pageNumber', JSON.stringify(1));
    }, [])

    const pageCount = Math.ceil(carCount / 20);

    const handlePageChange = (page) => {
        localStorage.setItem('pageNumber', JSON.stringify(page));
        window.scrollTo(0, 0);
        dispatch(setCarListPage(page))
        if (pageChanged !== null) {
            pageChanged(page);
        }
    }

    return <>
        {pageCount > 1 && <Pagination
            onChange={(e, page) => { handlePageChange(page) }}
            sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
            size={'medium'}
            count={pageCount} shape='rounded'
            page={carPage}
        />}
    </>
}

