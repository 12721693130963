import classes from './Modal.module.css';
import { Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { MdClose } from "react-icons/md";


function Modal({ children, open, onClose, closeBtn, className, title = null }) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        className={className}
    >
        <div className={classes.container}>
            {title != null ? <h2 className={classes.title}>{title}</h2> : <></>}
            {closeBtn &&
                <button className={classes.closeBtn} onClick={onClose}>
                    <MdClose />
                </button>
            }
            {children}
        </div>
    </Dialog>
}

export default Modal;