import axios from 'axios';
import classes from './EditCar.module.css';
import CustomDropDown from '../../components/DropDowns/CustomDropDown';
import ChoiceChip from '../../components/ChoiceChip/ChoiceChip';
import { Link } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
import { Textarea } from '@mui/joy';
import { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';
import {
  getCarDetails,
  getFeatureList,
  editCar,
  getCarStyles,
} from '../../store/VehicleStore/vehicleActions';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import getToast from '../../helpers/getToast';
import PageNotFound from '../PageNotFound';

const initialState = {
  carUuid: null,
  kilometres: 0,
  miles: 0,
  engineCC: 0,
  condition: null,
  electricAutonomy: 0,
  tireCondition: null,
  priceCRC: 0,
  passengers: 0,
  ownerAcceptsCar: null,
  negotiablePrice: null,
  modifications: null,
  notes: null,
  year: 0,
  features: [],
  idCarType: [],
  transmission: null,
  traction: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'currentValues': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'negotiablePrice': {
      return {
        ...state,
        negotiablePrice: action.payload,
      };
    }
    case 'ownerAcceptsCar': {
      return {
        ...state,
        ownerAcceptsCar: action.payload,
      };
    }
    case 'modifications': {
      return {
        ...state,
        modifications: action.payload,
      };
    }
    case 'priceCRC': {
      return {
        ...state,
        priceCRC: action.payload,
      };
    }
    case 'engineCC': {
      return {
        ...state,
        engineCC: action.payload,
      };
    }
    case 'electricAutonomy': {
      return {
        ...state,
        electricAutonomy: action.payload,
      };
    }
    case 'kilometres': {
      return {
        ...state,
        kilometres: action.payload,
      };
    }
    case 'miles': {
      return {
        ...state,
        miles: action.payload,
      };
    }
    case 'condition': {
      return {
        ...state,
        condition: action.payload,
      };
    }
    case 'tireCondition': {
      return {
        ...state,
        tireCondition: action.payload,
      };
    }
    case 'notes': {
      return {
        ...state,
        notes: action.payload,
      };
    }
    case 'passengers': {
      return {
        ...state,
        passengers: action.payload,
      };
    }
    case 'year': {
      return {
        ...state,
        year: action.payload,
      };
    }
    case 'idCarType': {
      return {
        ...state,
        idCarType: action.payload,
      };
    }
    case 'transmission': {
      return {
        ...state,
        transmission: action.payload,
      };
    }
    case 'traction': {
      return {
        ...state,
        traction: action.payload,
      };
    }
    case 'electricBatteryCapacity': {
      return {
        ...state,
        electricBatteryCapacity: action.payload,
      };
    }
    default: {
      break;
    }
  }
}

function EditCar({
  carDetails,
  getCarDetailsStart,
  getFeatureList,
  carFeaturesList,
  editCar,
  getCarStyles,
  carStyles,
}) {

  const { anuncioId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [blockPage, setBlockPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [remainingCharacters, setRemainingCharacters] = useState(1500);
  const [carFeatureNames, setCarFeatureNames] = useState(null);
  const [carFeatures, setCarFeatures] = useState([]);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_API_URL;



  const transmissionList = [{ value: 'Automático', description: 'Automático' }, { value: 'Manual', description: 'Manual' }, { value: 'Dual', description: 'Dual' }]
  const tiposDeTraccion = [
    { value: '4X2', description: '4X2' },
    { value: '4X4', description: '4X4' },
    // { value: 'Delantera (FWD)', description: 'En las Cuatro Ruedas (AWD)' },
    // { value: 'Delantera (FWD)', description: 'A las Cuatro Ruedas (4WD/4x4)' }
  ];

  const carConditionOptions = [
    { value: 'Excelente', description: 'Excelente' },
    { value: 'Muy bueno', description: 'Muy bueno' },
    { value: 'Regular', description: 'Regular' },
  ];

  const tireConditionOptions = [
    // { description: 'Nuevas (Recien cambiadas)' },
    { value: 'Excelentes', description: 'Excelentes' },
    { value: 'Regular', description: 'Regular' },
    { value: 'Se recomienda cambio', description: 'Se recomienda cambio' },
  ];


  const noteChange = (event) => {
    const value = event.target.value;
    if (value.length <= 1500) {
      dispatch({ type: 'notes', payload: value });
      setRemainingCharacters(1500 - value.length);
    }
  };


  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear + 1; i >= 1960; i--) {
    years.push({ value: i, description: `${i}` });
  }

  const boolOptions = [{ value: 1, description: 'Si' }, { value: 0, description: 'No' }]

  const handleSave = async () => {
    try {
      const { idCarType } = state.idCarType;
      const negotiable = state.negotiablePrice ? true : false
      const acceptsCar = state.ownerAcceptsCar ? true : false
      const modifications = state.modifications ? true : false
      const token = getTokenFromLocalStorage();
      const result = await editCar({ ...state, features: carFeatures, idCarType, negotiablePrice: negotiable, ownerAcceptsCar: acceptsCar, modifications }, token);

      if (result) {
        getToast({ text: 'Tu anuncio fue editado.', type: 'SUCCESS' });
        navigate('/mis-anuncios');
      } else {
        getToast({
          text: 'Ha ocurrido un error. Vuelve a intentarlo.',
          type: 'ERROR',
        });
      }
    } catch (ex) {
      getToast({
        text: 'Ha ocurrido un error. Vuelve a intentarlo.',
        type: 'ERROR',
      }); console.log(ex)
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const startComponent = async () => {
      try {
        const styleResponse = await getCarStyles();

        if (!styleResponse) {
          throw Error("No se pudieron cargar los tipos de carro");
        }

        const response = await getCarDetailsStart(anuncioId);


        isCarOwner(response.carUuid, response.createdByUuidUser).then(res => {
          if (res === false || res.data === false) {
            setBlockPage(true)
          } setLoading(false)
        })
      } catch (ex) {
        console.log(ex)
      }
    }
    const isCarOwner = async (car, user) => {
      const token = getTokenFromLocalStorage()
      if (token == null) {
        return false;
      }
      const res = await axios.get(`${baseUrl}/api/Car/IsCarOwner?uuidCar=${car}&uuidUser=${user}`, { headers: { Authorization: `Bearer ${token}` } });
      return res
    }
    startComponent();
  }, []);

  useEffect(() => {
    const style = carStyles.filter(el => { return el.carType1 === carDetails.carType })[0]

    dispatch({
      type: 'currentValues',
      payload: {
        carUuid: carDetails.carUuid,
        kilometres: carDetails.kilometres,
        miles: carDetails.miles,
        engineCC: carDetails.engineCC,
        condition: carDetails.condition,
        electricAutonomy: carDetails.electricAutonomy ?? 0,
        tireCondition: carDetails.tireCondition,
        priceCRC: carDetails.priceCRC,
        passengers: carDetails.passengers,
        ownerAcceptsCar: carDetails.ownerAcceptsCar,
        negotiablePrice: carDetails.negotiablePrice,
        modifications: carDetails.modifications,
        notes: carDetails.notes,
        year: carDetails.year,
        features: carDetails.features,
        idCarType: style,
        transmission: carDetails.transmission,
        traction: carDetails.traction,
        electricBatteryCapacity: carDetails.electricBatteryCapacity
      },
    });

    getFeatureList();
    if (carDetails.features) {
      setCarFeatures(carDetails.features);
    }
    //   TODO - No empezar verificación hasta tener car details

    if (carDetails.carUuid) {
      // setLoading(false);
      window.scrollTo(0, 0);
    }
  }, [carDetails]);

  useEffect(() => {
    const list = carFeaturesList.map((el) => {
      return el.feature;
    });
    setCarFeatureNames(list);
  }, [carFeaturesList]);

  const handlePriceChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');

    if (event.target.value.length <= 11) {
      dispatch({ type: 'priceCRC', payload: newValue });
    }
  };

  const handleEngineCcChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
    if (event.target.value.length <= 5) {
      dispatch({ type: 'engineCC', payload: newValue });
    }
  };
  const handleAutonomyChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
    if (event.target.value.length <= 5) {
      dispatch({ type: 'electricAutonomy', payload: newValue });
    }
  };

  const handleBatteryCapacityChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
    if (event.target.value.length <= 5) {
      dispatch({ type: 'electricBatteryCapacity', payload: newValue });
    }
  };

  const handleKilometresChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
    if (event.target.value.length <= 7) {
      dispatch({ type: 'kilometres', payload: newValue });
    }
  };
  const handleMilesChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
    if (event.target.value.length <= 7) {
      dispatch({ type: 'miles', payload: newValue });
    }
  };

  const handlePassengerChange = (event) => {
    const newValue = +event.target.value.replace(/[A-Za-z!@#$%^&*(),]/g, '');
    if (event.target.value.length <= 2) {
      dispatch({ type: 'passengers', payload: newValue });
    }
  };

  if (blockPage) {
    return <PageNotFound />
  }

  if (loading) {
    return (
      <div className={classes.container}>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className={classes.post}>
      <div className={classes.title}>
        <h3>Detalles de tu anuncio</h3>
      </div>
      <table className={classes.table}>
        <tr>
          <div className={classes.rowContent}>
            <label>Marca</label>
            <input value={carDetails.brand} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Modelo</label>
            <input value={carDetails.model} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Año</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                return dispatch({ type: 'year', payload: newValue.value });
              }}
              dropdownValue={state.year}
              className={classes.dropDown}
              items={years}
              preview={state.year}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Estilo</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                dispatch({ type: 'idCarType', payload: { idCarType: newValue.value, carType1: newValue.description } });
              }}
              dropdownValue={state.idCarType.carType1}
              className={classes.dropDown}
              items={carStyles.map(el => {
                return {
                  value: el.idCarType, description: el.carType1
                }
              })}
              preview={state.idCarType.carType1} />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Combustible</label>

            <input value={carDetails.engine} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            {carDetails.kilometreType === 'Km' ? (
              <>
                <label>Kilometraje</label>
                <input
                  value={state.kilometres?.toLocaleString()}
                  onChange={handleKilometresChange}
                />
              </>
            ) : (
              <>
                <label>Millas</label>
                <input
                  value={state.miles?.toLocaleString()}
                  onChange={handleMilesChange}
                />
              </>
            )}
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Precio</label>
            <input
              value={state.priceCRC?.toLocaleString()}
              onChange={handlePriceChange}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            {carDetails.engine !== 'Eléctrico' ? (
              <>
                {' '}
                <label>Cilindrada</label>
                <input
                  value={
                    state.engineCC != null ? state.engineCC.toLocaleString() : 0
                  }
                  onChange={handleEngineCcChange}
                />{' '}
                cc
              </>
            ) : (
              <>
                <label>Autonomía</label>
                <input
                  value={state.electricAutonomy.toLocaleString()}
                  onChange={handleAutonomyChange}
                /> km
              </>
            )}
          </div>
        </tr>
        {carDetails.engine !== 'Eléctrico' ? <></> : <><tr>
          <div className={classes.rowContent}>
            <label>Capacidad de la batería</label>
            <input
              value={state.electricBatteryCapacity.toLocaleString()}
              onChange={handleBatteryCapacityChange}
            /> kWh
          </div> </tr><tr>
            <div className={classes.rowContent}>
              <label>Tipo de conector</label>
              <input
                value={carDetails.electricChargerType}
                disabled
              />
            </div>
          </tr>
        </>}


        <tr>
          <div className={classes.rowContent}>
            <label>Estado</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                dispatch({ type: 'condition', payload: newValue.description });
              }}
              dropdownValue={state.condition}
              className={classes.dropDown}
              items={carConditionOptions}
              preview={state.condition}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Estado de llantas</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                dispatch({
                  type: 'tireCondition',
                  payload: newValue.description,
                });
              }}
              dropdownValue={state.tireCondition}
              className={classes.dropDown}
              items={tireConditionOptions}
              preview={state.tireCondition}
            />
          </div>
        </tr>
        {/* <tr><div className={classes.rowContent}>

                <label>Placa</label>
                <input value={carDetails.provincia} />
                
            </div>
            </tr> */}
        <tr>
          <div className={classes.rowContent}>
            <label>Precio negociable</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                return dispatch({ type: 'negotiablePrice', payload: newValue.value });
              }}
              dropdownValue={state.negotiablePrice ? boolOptions[0].description : boolOptions[1].description}
              items={boolOptions}
              preview={state.negotiablePrice ? boolOptions[0].description : boolOptions[1].description}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Recibe vehículo</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                return dispatch({ type: 'ownerAcceptsCar', payload: newValue.value });
              }}
              dropdownValue={state.ownerAcceptsCar ? boolOptions[0].description : boolOptions[1].description}
              items={boolOptions}
              preview={state.ownerAcceptsCar ? boolOptions[0].description : boolOptions[1].description}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Asientos</label>
            <input
              value={state.passengers.toLocaleString()}
              onChange={handlePassengerChange}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Transmisión</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                dispatch({ type: 'transmission', payload: newValue.description });
              }}
              dropdownValue={state.transmission}
              className={classes.dropDown}
              items={transmissionList}
              preview={state.transmission} />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Tracción</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                dispatch({ type: 'traction', payload: newValue.description });
              }}
              dropdownValue={state.traction}
              className={classes.dropDown}
              items={tiposDeTraccion}
              preview={state.traction} />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Unico dueño</label>
            <input value={carDetails.uniqueOwner ? 'Si' : 'No'} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Posee modificaciones</label>
            <CustomDropDown
              setDropdownValue={(newValue) => {
                return dispatch({ type: 'modifications', payload: newValue.value });
              }}
              dropdownValue={state.modifications ? boolOptions[0].description : boolOptions[1].description}
              items={boolOptions}
              preview={state.modifications ? boolOptions[0].description : boolOptions[1].description}
            />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Color exterior</label>
            <input value={carDetails.exteriorColor} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Color interior</label>
            <input value={carDetails.interiorColor} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Provincia</label>
            <input value={carDetails.provincia} disabled />
          </div>
        </tr>
        <tr>
          <div className={classes.rowContent}>
            <label>Cantón</label>
            <input value={carDetails.canton} disabled />
          </div>
        </tr>
      </table>
      <div className={classes.title}>
        <h3>Extras del carro</h3>
      </div>
      <div className={classes.chip}>
        <ChoiceChip
          items={carFeatureNames}
          value={carFeatures}
          setValue={setCarFeatures}
        />
      </div>
      <div className={classes.title}>
        <h3>Notas</h3>
      </div>
      <div className={classes.noteContainer}>
        <Textarea
          value={state.notes}
          maxLength={1500}
          onChange={noteChange}
          placeholder="Notas del carro (opcional)"
        />
        <div className={`${classes.characterCounter} characterCounter`}>
          {remainingCharacters}/{1500} caracteres restantes
        </div>
      </div>
      <div className={classes.btnGroup}>
        <Button onClick={handleSave}>Guardar cambios</Button>
        <Button className={classes.btn}>
          <Link
            to={'/mis-anuncios'}
            style={{ width: '100%', textDecoration: 'none', color: 'inherit' }}
          >
            Cancelar
          </Link>
        </Button>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCarDetailsStart: (carUuid, user) =>
      dispatch(getCarDetails(carUuid, user)),
    getFeatureList: () => dispatch(getFeatureList()),
    editCar: (data, token) => dispatch(editCar(data, token)),
    getCarStyles: () => dispatch(getCarStyles()),
  };
};

const mapStateToProps = (state) => {
  return {
    carDetails: state.CarOptions.carDetails,
    loading: state.CarOptions.loading,
    carFeaturesList: state.CarOptions.carFeaturesList,
    carStyles: state.CarOptions.carStyles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCar);
