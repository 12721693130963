import React from 'react';
import classes from './MicrosoftBtn.module.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { default as msLogoSVG } from '../../images/appImages/microsoft-logo.svg';

const MicrosoftBtn = ({ createUser }) => {
  let UI_URL;

  if (window.location.hostname === 'localhost') {
    UI_URL = 'http://localhost:3000';
  } else {
    UI_URL = `https://${window.location.hostname}`;
  }
  const MS_CLIENT_ID = process.env.REACT_APP_MS_CLIENT_ID;

  const config = {
    auth: {
      clientId: MS_CLIENT_ID,
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: `${UI_URL}/`,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };

  const msalInstance = new PublicClientApplication(config);
  const handleLogin = async () => {
    try {
      const response = await msalInstance.loginPopup({
        scopes: ['User.ReadBasic.All', 'User.Read'],
      });

      // Get the access token from the response
      const accessToken = response.accessToken;

      // Make the HTTP request to the Microsoft Graph API endpoint
      const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Extract the user's first name and last name from the response
      const graphResponseData = await graphResponse.json();
      const { givenName, surname } = graphResponseData;

      // Update the state or perform any other action with the user's information
      createUser({
        idUser: 0,
        uuidUser: 'TBD',
        firstName: givenName,
        lastName: surname,
        email: response.account.username,
        authProvider: 'Microsoft',
        authProviderId: graphResponseData.id,
        userRole: 1,
        userStatus: 1,
        createdOn: '2023-03-04T16:59:43.492Z',
        lastActivity: '2023-03-04T16:59:43.492Z',
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button className={classes.microsoft_button} onClick={handleLogin}>
      <img src={msLogoSVG} alt="Microsoft Logo" />
      &nbsp; Acceder con Microsoft
    </button>
  );
};

export default MicrosoftBtn;
