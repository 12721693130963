import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import GitInfo from 'react-git-info/macro';
import axios from 'axios';
import classes from './Git.module.css';

function Git() {
  const gitInfo = GitInfo();
  const [backendHash, setBackendHash] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBackendHash = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/Admin/GetGitInfo`);
        setBackendHash(response.data);
      } catch (error) {
        console.error('Error fetching backend hash:', error);
      }
    };

    fetchBackendHash();
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Admin - Version</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      <div className={classes.gitContainer}>
        <div className={classes.gitInfo}>
          <p className={classes.textFace}>ಠ_ಠ</p>

          <p className={classes.gitVersion}>
            UI
            <br />
            {gitInfo.commit.hash
              ? gitInfo.commit.hash.substring(0, 7)
              : 'Loading...'}
          </p>
          {/* <br /> */}
          <p className={classes.textFace}>ʕ•ᴥ•ʔ</p>

          <p className={classes.gitVersion}>
            BE
            <br />
            {backendHash || 'Loading...'}
          </p>
          <p className={classes.textFace}>(ᵔᴥᵔ)</p>
        </div>
      </div>
    </div>
  );
}

export default Git;
