import axios from 'axios';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import getToast from '../../helpers/getToast';
import { BsCheckCircle } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';
import classes from './CarReviewDetails.module.css';
import { getTokenFromLocalStorage } from '../../helpers/jwtService';
import { updateCarInReviewStatus } from '../../store/AdminStore/adminActions';

const BASE_URL = process.env.REACT_APP_API_URL;

function CarReviewDetails({ carsInReview, updateCarInReview }) {
  const [carsInReviewForMap, setCarsInReviewForMap] = useState([]);

  const [sinpeStatus, setSinpeStatus] = useState('Review');
  useEffect(() => {
    if (Array.isArray(carsInReview)) {
      setCarsInReviewForMap(carsInReview);
    } else {
      setCarsInReviewForMap([]);
    }
  }, [carsInReview]);

  // Función para eliminar un carro específico del array
  const removeItemFromCarArray = (idCarToRemove) => {
    setCarsInReviewForMap((prevCars) =>
      prevCars.filter((car) => car.idCar !== idCarToRemove)
    );
  };

  // Función para calcular la edad de la cuenta en años, meses y días
  const calculateAccountAge = (createdDate) => {
    const currentDate = new Date();
    const diffInMilliseconds = Math.abs(currentDate - createdDate);
    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (days === 0) {
      return '0 días';
    }

    const months = Math.floor(days / 30);
    const remainingDays = days % 30;

    let ageString = '';

    if (months > 0) {
      ageString += `${months} mes${months !== 1 ? 'es' : ''}, `;
    }

    if (remainingDays > 0) {
      ageString += `${remainingDays} día${remainingDays !== 1 ? 's' : ''}`;
    }

    return ageString;
  };

  const formatTimeDifference = (timeDifference) => {
    const totalMinutes = Math.floor(timeDifference / (1000 * 60));
    const days = Math.floor(totalMinutes / (24 * 60));
    const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    const minutes = totalMinutes % 60;

    return `${days > 0 ? `${days}d, ` : ''}${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`;
  };

  const updateStatusForCarsInReview = async (carInfo, newStatus) => {
    const token = getTokenFromLocalStorage();
    const url = `${BASE_URL}/api/Admin/UpdateCarInReviewStatus?idCar=${carInfo.idCar}&newStatus=${newStatus}`;

    try {
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Verificar si la respuesta es exitosa (código 200)
      if (response.status === 200) {
        // Eliminar el carro del array
        setCarsInReviewForMap((prevCars) =>
          prevCars.filter((car) => car.idCar !== carInfo.idCar)
        );

        // Mostrar notificación de éxito
        if (newStatus === 1) {
          getToast({
            type: 'SUCCESS',
            text: `Carro aprobado - ${carInfo.brand}`,
          });
        } else if (newStatus === 6) {
          getToast({
            type: 'ERROR',
            text: `Carro rechazado - ${carInfo.brand}`,
          });
        }
      } else {
        // Mostrar notificación de error si la respuesta no es 200
        getToast({
          type: 'ERROR',
          text: 'Error al actualizar el estado del carro',
        });
      }
    } catch (error) {
      console.error(
        'Error al actualizar el estado del carro en revisión:',
        error
      );
      // Mostrar notificación de error si hay un error en la solicitud
      getToast({
        type: 'ERROR',
        text: 'Error al actualizar el estado del carro en revisión',
      });
    }
  };

  // const updateStatusForCarsInReview = async (carInfo, newStatus) => {
  //   console.log('Hi!');
  //   console.log('carsInReview');
  //   console.log(carsInReview);

  //   console.log('carsInReviewForMap');
  //   console.log(carsInReviewForMap);
  //   const token = getTokenFromLocalStorage();
  //   const response = await updateCarInReview(token, carInfo.idCar, newStatus);

  //   // try {
  //   //   // Actualizar el estado del carro en revisión en la API
  //   //   const response = await updateCarInReview(token, carInfo.idCar, newStatus);
  //   // } catch (error) {
  //   //   console.error(
  //   //     'Error al actualizar el estado del carro en revisión:',
  //   //     error
  //   //   );
  //   // }
  // };

  const handleDropdownSelection = async (event, idCar) => {
    const token = getTokenFromLocalStorage();
    const newStatus = event.target.value;

    try {
      const response = await axios.post(
        `${BASE_URL}/api/Admin/UpdateSinpeStatus?idCar=${idCar}&newStatus=${newStatus}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        getToast({
          type: 'SUCCESS',
          text: 'Sinpe actualizado',
        });

        // Update individual car's sinpe state
        setCarsInReviewForMap((prevCars) =>
          prevCars.map((car) => {
            if (car.idCar === idCar) {
              return { ...car, sinpeStatus: newStatus };
            }
            return car;
          })
        );
      } else {
        getToast({
          type: 'ERROR',
          text: 'Error al actualizar Sinpe',
        });
      }
    } catch (error) {
      getToast({
        type: 'ERROR',
        text: 'Error genérico',
      });
      console.error('Error al realizar la solicitud:', error);
    }
  };

  // Establecer sinpeStatus basado en el primer elemento de carsInReviewForMap, o 'Review' si no hay elementos
  useEffect(() => {
    if (carsInReviewForMap.length > 0) {
      const initialSinpeState = carsInReviewForMap[0].sinpeStatus || 'Review';
      setSinpeStatus(initialSinpeState);
    }
  }, [carsInReviewForMap]);

  return (
    <div className={classes.container}>
      <div className={classes.carsList}>
        <ul className={classes.flexContainer}>
          {carsInReviewForMap.length !== 0 ? (
            carsInReviewForMap.map((el) => {
              console.log(el);
              return (
                <li key={el.idCar} className={classes.listItem}>
                  <div className={classes.carItem}>
                    <div className={classes.firstBlock}>
                      <Carousel images={el.pictures} counter={true} />
                    </div>

                    <div className={classes.secondBlock}>
                      <div className={classes.carDetails}>
                        {/* <div className={classes.brand}>
                          <span className={classes.year}>{el.year}</span>
                          ID: <strong>{el.idCar}</strong> |{' '}
                          <strong>{el.brand}</strong>{' '}
                          <strong>{el.model}</strong> -{' '}
                          <strong>{el.plate}</strong>
                        </div> */}
                      </div>
                      <ul>
                        <li>
                          Id: <strong>{el.idCar}</strong>
                        </li>
                        <li>
                          Marca: <strong>{el.brand}</strong>
                        </li>
                        <li>
                          Modelo: <strong>{el.model}</strong>
                        </li>
                        <li>
                          Año: <strong>{el.year}</strong>
                        </li>
                        <li>
                          Placa: <strong>{el.plate}</strong>
                        </li>
                        {el.planId !== 1 && (
                          <li>
                            Sinpe Message: <strong>{el.sinpeMessage}</strong>
                          </li>
                        )}
                        {el.planId !== 1 && (
                          <li>
                            Sinpe Status: <strong>{el.sinpeStatus}</strong>
                          </li>
                        )}

                        <li>
                          Role:
                          <strong>
                            {el.userRole === 1 ? 'Persona' : 'Dealer'}
                          </strong>
                        </li>
                        {el.planId !== 1 && (
                          <li>
                            Sinpe:{' '}
                            <strong>
                              {new Date(el.sinpeCreateDtm)
                                .toLocaleString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: false,
                                })
                                .replace(/\//g, '-')}
                            </strong>{' '}
                            (
                            {formatTimeDifference(
                              new Date() - new Date(el.sinpeCreateDtm)
                            )}
                            )
                          </li>
                        )}

                        <li>
                          Precio:
                          <strong>
                            {el.carPriceCrc !== null &&
                            el.carPriceCrc !== undefined
                              ? el.carPriceCrc.toLocaleString('en-US', {
                                  maximumFractionDigits: 0,
                                }) + ' CRC'
                              : 'null'}
                          </strong>
                        </li>
                        <li>
                          Km/Mi: <strong>{el.kilometreType}</strong>
                        </li>
                        <li>
                          Km:
                          <strong>
                            {el.kilometres !== null &&
                            el.kilometres !== undefined
                              ? el.kilometres.toLocaleString('en-US', {
                                  maximumFractionDigits: 0,
                                })
                              : 'null'}
                          </strong>
                        </li>
                        <li>
                          Mi:
                          <strong>
                            {el.miles !== null && el.miles !== undefined
                              ? el.miles.toLocaleString('en-US', {
                                  maximumFractionDigits: 0,
                                })
                              : 'null'}
                          </strong>
                        </li>
                        <li>
                          Trac: <strong>{el.traction}</strong>
                        </li>
                        <li>
                          Unique Owner:
                          <strong>
                            {el.uniqueOwner !== null &&
                            el.uniqueOwner !== undefined
                              ? el.uniqueOwner.toString()
                              : 'false'}
                          </strong>
                        </li>

                        <li>
                          Trans: <strong>{el.transmission}</strong>
                        </li>

                        <li>
                          Ubi:
                          <strong>{el.canton}</strong>,{' '}
                          <strong>{el.provincia}</strong>
                        </li>
                        <li>
                          Car Created:{' '}
                          <strong>
                            {new Date(el.carCreatedOn)
                              .toLocaleString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                              })
                              .replace(/\//g, '-')}
                          </strong>{' '}
                          (
                          {formatTimeDifference(
                            new Date() - new Date(el.carCreatedOn)
                          )}
                          )
                        </li>

                        <li>
                          {/* <MdOutlinePayment style={{ fontSize: '15px' }} /> */}
                          Plan:{' '}
                          <strong>
                            {el.planId === 1
                              ? 'Gratis 🧽'
                              : el.planId === 3
                              ? 'Premium 💎'
                              : el.planId}
                          </strong>
                        </li>

                        <li>
                          {/* <AiOutlineMail style={{ fontSize: '15px' }} /> */}
                          User:
                          <strong>{el.firstName}</strong>
                          <strong>{el.lastName}</strong>
                          {/* <strong>{el.createdBy}</strong> */}
                        </li>
                        <li>
                          {/* <AiOutlineMail style={{ fontSize: '15px' }} /> */}
                          Email:<strong>{el.createdBy}</strong>
                        </li>
                        <li>
                          {/* <BsFillCalendarEventFill style={{ fontSize: '15px' }} /> */}
                          Account age:{' '}
                          <strong>
                            {calculateAccountAge(new Date(el.accountCreatedOn))}
                          </strong>{' '}
                          |{' '}
                          <strong>
                            {new Date(el.accountCreatedOn)
                              .toLocaleString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              })
                              .replace(/\//g, '-')}
                          </strong>
                        </li>

                        <li>
                          {/* <GrLocation style={{ fontSize: '15px' }} /> */}
                          Notes: {el.sellerNotes}
                        </li>
                        <li>
                          {/* <GrLocation style={{ fontSize: '15px' }} /> */}
                          <a
                            href={`https://wa.me/506${el.whatsappNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            WhatsApp: {el.whatsappNumber}
                          </a>
                        </li>
                      </ul>
                      {el.planId !== 1 && (
                        <div className={classes.selectContainer}>
                          <h3>Sinpe Status</h3>
                          <select
                            onChange={(event) =>
                              handleDropdownSelection(event, el.idCar)
                            }
                            value={el.sinpeStatus || 'Review'} // Use car's sinpe state or default to 'Review'
                          >
                            <option value="Review">Review</option>
                            <option value="Approved">Approved</option>
                            <option value="Gratis">Gratis</option>
                            <option value="Expired">Expired</option>
                          </select>
                        </div>
                      )}
                      <li className={classes.buttonsRow}>
                        <Button
                          className={classes.denyButton}
                          variant="contained"
                          color="error"
                          size="large"
                          onClick={() => updateStatusForCarsInReview(el, 6)}
                          disabled={
                            el.sinpeStatus === 'Review' && el.planId !== 1
                          }
                        >
                          <ImCancelCircle />
                        </Button>
                        <Button
                          className={classes.approveButton}
                          variant="contained"
                          size="large"
                          color="success"
                          onClick={() => updateStatusForCarsInReview(el, 1)}
                          disabled={
                            el.sinpeStatus === 'Review' && el.planId !== 1
                          }
                        >
                          <BsCheckCircle />
                        </Button>
                      </li>
                    </div>
                    <br />
                  </div>
                </li>
              );
            })
          ) : (
            <div>
              <br />
              <br />
              <br />
              <h1>No hay carros para revisar ʕ•ᴥ•ʔ </h1>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCarInReview: (token, idCar, newStatus) =>
      dispatch(updateCarInReviewStatus(token, idCar, newStatus)),
  };
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CarReviewDetails);
